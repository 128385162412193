import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardAction,
  CardBody,
} from "../../../components/Card";
import { Button } from "../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { setFormTask } from "../../../stores/prod/prodProjectSlice";
import { setModalAddDefaultTask } from "../../../stores/prod/prodDefaultTaskSlice";
import ModalAddDefaultTask from "./modalAddDefaultTask";
import { CheckRoleAction } from "../../../helpers/CheckRole";
import { BiSearch } from "react-icons/bi";

export default function TabFormTask(props) {
  const dispatch = useDispatch();
  const formTask = useSelector((state) => state.prodProject.formTask);
  const modalAddDefaultTask = useSelector(
    (state) => state.prodDefaultTask.modalAddDefaultTask
  );
  const [selectAll, setSelectAll] = useState(false);
  const [filteredData, setFilterTable] = useState(undefined);
  const [dataTable, setDataTable] = useState(undefined);
  const [searchValue, setSearchValue] = useState("");
  const [isSelected, setIsSelected] = useState(0);

  const selectAllTask = () => {
    setSelectAll(!selectAll);
    const data = formTask.map((row, index) => {
      if (!row.special_task) {
        return { ...row, is_checked: !selectAll };
      } else {
        return { ...row, is_checked: !selectAll };
      }
    });
    dispatch(setFormTask(data));
  };

  const selectTask = (selectIndex) => {
    setSelectAll(false);

    const data = formTask.map((row, index) => {
      if (index === selectIndex) {
        return { ...row, is_checked: !row.is_checked };
      } else {
        return { ...row };
      }
    });
    dispatch(setFormTask(data));
  };

  useEffect(() => {
    setFilterTable(formTask);
    setDataTable(formTask);
    if (formTask?.length > 0) {
      const filtered = [...formTask].filter((data) => data.is_checked);
      setIsSelected(filtered.length);
    }
  }, [formTask]);

  const handleSearch = (e) => {
    let searchTerm = e.target.value.toLowerCase();
    setSearchValue(e.target.value);
    const filtered = [...dataTable].filter(
      (data) =>
        data.department_name.toLowerCase().indexOf(searchTerm) > -1 ||
        data.task_name.toLowerCase().indexOf(searchTerm) > -1
    );

    setFilterTable(filtered);
  };

  return (
    <>
      {modalAddDefaultTask && <ModalAddDefaultTask />}

      <Card>
        <CardHeader>
          <CardTitle>Tasks</CardTitle>
          <CardAction>
            <div>
              <strong>{isSelected}</strong> selected from{" "}
              <strong>{formTask?.length}</strong> tasks
            </div>
            <div className="search-group w-56" style={{ height: "34px" }}>
              <div>
                <BiSearch />
              </div>
              <input
                type="search"
                placeholder="Search..."
                value={searchValue}
                onChange={handleSearch}
              />
            </div>
            {CheckRoleAction("/prod/default-tasks", "create") && (
              <Button
                title="New Default Task"
                icon="BiPlus"
                size="sm"
                color="primary"
                onClick={() => dispatch(setModalAddDefaultTask(true))}
              />
            )}
          </CardAction>
        </CardHeader>
        <CardBody>
          <p className="mb-2">
            <span className="w-3 h-3 mr-2 bg-danger inline-block rounded-full"></span>{" "}
            All tasks in this table will be stored in the project. Uncheck
            unwanted tasks or add tasks if needed.
          </p>
          <table className="table">
            <thead>
              <tr>
                <th style={{ width: "5%" }}>
                  <div className="form-checkbox">
                    <input
                      id="urgent"
                      title="Urgent"
                      type="checkbox"
                      name="urgent"
                      checked={selectAll}
                      onChange={selectAllTask}
                    />
                  </div>
                </th>
                <th style={{ width: "30%" }}>Department</th>
                <th>Task Name</th>
              </tr>
            </thead>
            <tbody>
              {filteredData &&
                filteredData.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <div className="form-checkbox">
                        <input
                          id="urgent"
                          title="Urgent"
                          type="checkbox"
                          name="urgent"
                          disabled={item.special_task ? true : false}
                          checked={item.is_checked}
                          onChange={() => selectTask(index)}
                        />
                      </div>
                    </td>
                    <td>{item.department_name}</td>
                    <td>{item.task_name}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </>
  );
}
