import React, { useEffect, useState } from "react";
import { BiEditAlt, BiTrash } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../components/Button";
import DataTable from "../../../components/DataTable";
import {
  PageHeader,
  PageHeaderAction,
  PageHeaderTitle,
} from "../../../components/PageHeader";

import {
  getDataTables,
  setModal,
  deleteData,
} from "../../../stores/um/userSlice";
import Form from "./form";
import LoadingTable from "./loadingTable";
import { CheckRoleAction, CheckRoleIndex } from "../../../helpers/CheckRole";
import { useNavigate } from "react-router-dom";

function Index() {
  const pathName = "/um/users";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const openModal = useSelector((state) => state.user.openModal);
  const data = useSelector((state) => state.user.dataTables);
  const [dataTables, setDataTables] = useState(data);
  const [formType, setFormType] = useState();
  const [dataId, setDataId] = useState();

  useEffect(() => {
    if (!CheckRoleIndex(pathName)) {
      navigate("/404");
    }
    dispatch(getDataTables());
  }, [dispatch, navigate]);

  useEffect(() => {
    setDataTables(data);
  }, [data]);

  // datatable columns
  const columns = [
    {
      header: "Full Name",
      assessor: "name",
    },
    {
      header: "User Name",
      assessor: "username",
    },
    {
      header: "Actions",
      tdStyle: { width: "5%" },
      Cell: (row) => {
        return (
          <div className="flex flex-row items-center justify-end gap-2">
            {CheckRoleAction(pathName, "edit") && (
              <span
                className="btn-action"
                onClick={() => {
                  setFormType("edit");
                  setDataId(row.id);
                  dispatch(setModal(true));
                }}
              >
                <BiEditAlt size="22" />
              </span>
            )}
            {CheckRoleAction(pathName, "delete") && (
              <span
                className="btn-action"
                onClick={() => dispatch(deleteData({ id: row.id }))}
              >
                <BiTrash size="22" />
              </span>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      {openModal && <Form formType={formType} dataId={dataId} />}

      {!dataTables && <LoadingTable />}
      {dataTables && (
        <>
          <PageHeader>
            <PageHeaderTitle>Users</PageHeaderTitle>
            <PageHeaderAction>
              <Button
                title="Add New"
                icon="BiPlus"
                color="primary"
                onClick={() => {
                  setFormType("create");
                  dispatch(setModal(true));
                }}
              />
            </PageHeaderAction>
          </PageHeader>
          <DataTable
            columns={columns}
            data={dataTables}
            options={{
              sorting: true,
              searching: true,
              bulkAction: false,
            }}
            bulkAction={{}}
            filter={[]}
          />
        </>
      )}
    </>
  );
}

export default Index;
