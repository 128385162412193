import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PageHeader, PageHeaderTitle } from "../../components/PageHeader";

import { CheckRoleIndex } from "../../helpers/CheckRole";
import { Card, CardHeader, CardTitle, CardAction, CardBody, CardFooter } from "../../components/Card";
import { Button } from "../../components/Button";
import { setPopup } from "../../stores/popupSlice";
import { InstanceApi } from "../../helpers/InstanceApi";
import { DownloadFile } from "../../helpers/DownloadFile";
import Select from "../../components/Select";
import MultiSelectText from "../../components/MultiSelectText";

function Index() {
  const pathName = "/reports/kpi-employee";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    begin_date: "",
    end_date: "",
    project_no: "",
  });

  const [dataTable, setDataTable] = useState();
  const [openFilter, setOpenFilter] = useState(false);

  const [inputEmployeeName, setInputEmployeeName] = useState("");
  const [employeeName, setEmployeeName] = useState([]);
  const [inputDepartmentName, setInputDepartmentName] = useState("");
  const [departmentName, setDepartmentName] = useState([]);
  const [inputPositionName, setInputPositionName] = useState("");
  const [positionName, setPositionName] = useState([]);

  const createOption = (label) => ({
    label,
    value: label,
  });

  const resetFilter = () => {
    setInputEmployeeName("");
    setInputDepartmentName("");
    setInputPositionName("");
    setEmployeeName("");
    setDepartmentName("");
    setPositionName("");
  };

  const handleKeyDown = (event = React.KeyboardEventHandler, field) => {
    switch (event.key) {
      case "Enter":
      case "Tab":
        switch (field) {
          case "employeeName":
            if (!inputEmployeeName) return;
            setEmployeeName((prev) => [...prev, createOption(inputEmployeeName)]);
            setInputEmployeeName("");
            break;
          case "departmentName":
            if (!inputDepartmentName) return;
            setDepartmentName((prev) => [
              ...prev,
              createOption(inputDepartmentName),
            ]);
            setInputDepartmentName("");
            break;
          case "positionName":
            if (!inputPositionName) return;
            setPositionName((prev) => [
              ...prev,
              createOption(inputPositionName),
            ]);
            setInputPositionName("");
            break;
          default:
            break;
        }
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!CheckRoleIndex(pathName)) {
      navigate("/404");
    }
  }, [dispatch, navigate]);

   const createFormSubmit = () => {
     let formSubmit = {
       begin_date: formData.begin_date,
       end_date: formData.end_date,
       employee_name: "",
       department_name: "",
       position_name: "",
     };

     if (employeeName.length > 0) {
       var array_employee_name = [];
       employeeName.map((item, _) => {
         array_employee_name.push(item.value);
       });
       formSubmit.employee_name = array_employee_name.join("|");
     }

     if (departmentName.length > 0) {
       var array_department_name = [];
       departmentName.map((item, _) => {
         array_department_name.push(item.value);
       });
       formSubmit.department_name = array_department_name.join("|");
     }
    
     if (positionName.length > 0) {
       var array_position_name = [];
       positionName.map((item, _) => {
         array_position_name.push(item.value);
       });
       formSubmit.position_name = array_position_name.join("|");
     }

     return formSubmit;
   };

  const generateReport = async (e) => {
    e.preventDefault();

    setOpenFilter(false);

    try {
      dispatch(setPopup({ open: true, type: "processing" }));
      const response = await InstanceApi.post(
        "/reports/generate-kpi-employee",
        createFormSubmit()
      );
      if (response) {
        dispatch(setPopup({ open: false }));
        setDataTable(response.data.data);
      }
    } catch (err) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: err.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
      console.error(err.response.data.message);
    }
  };

  const exportReport = async (e) => {
    e.preventDefault();

    try {
      dispatch(setPopup({ open: true, type: "processing" }));
      const response = await InstanceApi.post(
        "/reports/export-kpi-employee",
        createFormSubmit()
      );
      if (response) {
        dispatch(setPopup({ open: false }));

        dispatch(setPopup({ open: true, type: "download" }));

        const downloadUrl = "/download?file_path=" + response.data.data;
        console.log(downloadUrl);
        DownloadFile(downloadUrl);

        setTimeout(() => {
          dispatch(setPopup({ open: false }));
        }, 3000);
      }
    } catch (err) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: err.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
      console.error(err.response.data.message);
    }
  };

  return (
    <>
      <PageHeader>
        <PageHeaderTitle>Kpi Employee Report</PageHeaderTitle>
      </PageHeader>
      <Card>
        <div
          className="cursor-pointer"
          onClick={() => setOpenFilter(!openFilter)}
        >
          <CardHeader>
            <CardTitle>Report Filter</CardTitle>
            <CardAction>
              <span className="px-2 text-xl font-bold cursor-pointer">
                {openFilter ? "-" : "+"}
              </span>
            </CardAction>
          </CardHeader>
        </div>
        {openFilter && (
          <>
            <CardBody>
              <div className="grid grid-cols-4 items-center gap-4">
                <div className="form-group">
                  <label className="control-label">Begin Date</label>
                  <input
                    id="begin_date"
                    title="Begin Date"
                    type="date"
                    name="begin_date"
                    className="form-control"
                    value={formData.begin_date}
                    onChange={(e) =>
                      setFormData({ ...formData, begin_date: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">End Date</label>
                  <input
                    id="end_date"
                    title="End Date"
                    type="date"
                    name="end_date"
                    className="form-control"
                    value={formData.end_date}
                    onChange={(e) =>
                      setFormData({ ...formData, end_date: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="form-group">
                  <label className="control-label">Employee Name</label>
                  <MultiSelectText
                    inputValue={inputEmployeeName}
                    onChange={(newValue) => setEmployeeName(newValue)}
                    onInputChange={(newValue) => setInputEmployeeName(newValue)}
                    onKeyDown={(e) => handleKeyDown(e, "employeeName")}
                    value={employeeName}
                  />
                </div>

                <div className="form-group">
                  <label className="control-label">Department</label>
                  <MultiSelectText
                    inputValue={inputDepartmentName}
                    onChange={(newValue) => setDepartmentName(newValue)}
                    onInputChange={(newValue) =>
                      setInputDepartmentName(newValue)
                    }
                    onKeyDown={(e) => handleKeyDown(e, "departmentName")}
                    value={departmentName}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">Position</label>
                  <MultiSelectText
                    inputValue={inputPositionName}
                    onChange={(newValue) => setPositionName(newValue)}
                    onInputChange={(newValue) => setInputPositionName(newValue)}
                    onKeyDown={(e) => handleKeyDown(e, "positionName")}
                    value={positionName}
                  />
                </div>
              </div>
            </CardBody>
            <CardFooter>
              <div className="flex flex-row items-center gap-2">
                <>
                  <Button
                    title="Generate Report"
                    icon="BiCog"
                    color="primary"
                    onClick={generateReport}
                  />
                  <Button
                    title="Reset"
                    icon="BiReset"
                    color="secondary"
                    onClick={resetFilter}
                  />
                </>
              </div>
            </CardFooter>
          </>
        )}
      </Card>
      <div className="mt-4"></div>
      {dataTable && (
        <Card>
          <CardHeader>
            <CardAction>
              <Button
                title="Download Report"
                icon="BiDownload"
                color="primary"
                onClick={exportReport}
              />
            </CardAction>
          </CardHeader>
          <CardBody>
            <div className="w-full max-h-[700px] overflow-auto">
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ minWidth: "50px" }}>NO</th>
                    <th style={{ minWidth: "200px" }}>EMPLOYEE NAME</th>
                    <th style={{ minWidth: "200px" }}>DEPARTMENT</th>
                    <th style={{ minWidth: "200px" }}>POSITION</th>
                    <th style={{ minWidth: "100px" }}>TOTAL PROJECT</th>
                    <th style={{ minWidth: "100px" }}>TOTAL TASK</th>
                    <th style={{ minWidth: "150px" }}>KPI SCORE</th>
                  </tr>
                </thead>
                <tbody>
                  {dataTable &&
                    dataTable.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.fullname}</td>
                        <td>{item.department_name}</td>
                        <td>{item.position_name}</td>
                        <td>{item.total_project}</td>
                        <td>{item.total_task}</td>
                        <td>{item.kpi_score}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
}

export default Index;
