import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from "../../../components/Card";
import Select from "../../../components/Select";

import { useSelector, useDispatch } from "react-redux";
import {
  resetFormData,
  getDataView as getDataViewTask,
  setDataView as setDataViewTask,
  setShowTask,
} from "../../../stores/pm/taskSlice";
import { setDataByTask as setDataAttachmentByTask } from "../../../stores/pm/taskAttachmentSlice";
import { getDataByProject } from "../../../stores/pm/taskSlice";
import { Link } from "react-router-dom";

function TabTask() {
  const dispatch = useDispatch();
  const dataProject = useSelector((state) => state.project.dataView);
  const dataTask = useSelector((state) => state.task.dataByProject);
  const [dataTable, setDataTable] = useState([]);
  const [globalSearch, setGlobalSearch] = useState("");
  const [statusSearch, setStatusSearch] = useState("");

  useEffect(() => {
    dispatch(getDataByProject({ projectId: dataProject.id }));
  }, [dispatch, dataProject.id]);

  useEffect(() => {
    dispatch(resetFormData());
  }, [dispatch]);

  useEffect(() => {
    if (dataTask) {
      let filtered = [...dataTask].filter(
        (data) =>
          data.task_no.toString().toLowerCase().indexOf(globalSearch) > -1 ||
          data.task_name.toLowerCase().indexOf(globalSearch) > -1 ||
          data.department_name?.toLowerCase().indexOf(globalSearch) > -1 ||
          data.observer_name?.toLowerCase().indexOf(globalSearch) > -1 ||
          data.responsible_name?.toLowerCase().indexOf(globalSearch) > -1 ||
          data.status.toLowerCase().indexOf(globalSearch) > -1
      );

      if (statusSearch) {
        filtered = [...filtered].filter((data) => data.status === statusSearch);
      }
      setDataTable(filtered);
    }
  }, [dataTask, globalSearch, statusSearch]);

  const onClickView = (id) => {
    dispatch(setDataViewTask(undefined));
    dispatch(setDataAttachmentByTask([]));

    dispatch(getDataViewTask({ id: id }));
    dispatch(setShowTask(true));
  };

  return (
    <>
      <div className="space-y-6">
        <Card>
          <CardHeader>
            <CardTitle>Tasks</CardTitle>
          </CardHeader>
          <CardBody>
            {!dataTask && (
              <div className="text-center font-semibold">Loading...</div>
            )}
            {dataTask && (
              <>
                <div className="mb-6 w-full flex gap-2">
                  <div className="flex-1">
                    <div className="relative">
                      <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <BiSearch className="w-5 h-5 text-gray-300" />
                      </div>
                      <input
                        className="form-control-search"
                        placeholder="Search..."
                        onChange={(e) =>
                          setGlobalSearch(
                            e.target.value.toString().toLowerCase()
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="w-40">
                    <Select
                      placeholder={"All Status"}
                      onChange={(e) => setStatusSearch(e?.label)}
                      options={[
                        { value: "Open", label: "Open" },
                        { value: "In Progress", label: "In Progress" },
                        { value: "Finish", label: "Finish" },
                        { value: "Completed", label: "Completed" },
                      ]}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  {dataTable.length > 0 &&
                    dataTable.map((item, index) => (
                      <Link
                        key={index}
                        to={`${window.location}/task/${item.id}`}
                      >
                        <div className="p-4 border border-slate-tertiary rounded-md hover:bg-slate-tertiary transition duration-200 cursor-pointer">
                          <div className="flex flex-row items-start justify-between gap-4">
                            <div className="flex-1 text-text-primary font-semibold">
                              [#{item.task_no}] {item.task_name}
                            </div>
                            <div className="flex flex-row items-center gap-2">
                              <div
                                className={`badge text-xs badge-${item.status.replace(
                                  /\s/g,
                                  ""
                                )}`}
                              >
                                {item.status}
                              </div>
                              {item.reject_count > 0 && (
                                <div className="badge badge-info">
                                  Reject : {item.reject_count}
                                </div>
                              )}
                              {item.status === "Finish" && (
                                <div className="badge badge-info">
                                  Waiting Approval
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="mt-4 grid grid-cols-4 gap-4">
                            <div className="text-sm">
                              <span className="mb-2 block text-text-secondary">
                                Department
                              </span>
                              <span className="block text-text-primary">
                                {item.department_name}
                              </span>
                            </div>
                            <div className="text-sm">
                              <span className="mb-2 block text-text-secondary">
                                Observer
                              </span>
                              {item.observer_name ? (
                                <span className="block text-text-primary">
                                  {item.observer_name}
                                </span>
                              ) : (
                                <span className="block text-danger">
                                  Not assigned
                                </span>
                              )}
                            </div>
                            <div className="text-sm">
                              <span className="mb-2 block text-text-secondary">
                                Responsible
                              </span>
                              {item.responsible_name ? (
                                <span className="block text-text-primary">
                                  {item.responsible_name}
                                </span>
                              ) : (
                                <span className="block text-danger">
                                  Not assigned
                                </span>
                              )}
                            </div>
                            <div className="text-sm">
                              <span className="mb-2 block text-text-secondary">
                                Task Date
                              </span>
                              <span className="block text-text-primary">
                                {item.begin_date_convert} to{" "}
                                {item.end_date_convert}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default TabTask;
