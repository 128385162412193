import React, { useEffect, useState } from "react";
import styles from "./../../../styles/DataTable.module.css";
import { BiEditAlt, BiTrash, BiSearch } from "react-icons/bi";
import { CheckRoleAction } from "../../../helpers/CheckRole";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataTables,
  setModal,
  deleteData,
} from "../../../stores/master/clientSlice";

export default function IndexTable(props) {
  const { setFormType, setDataId } = props;
  const pathName = "/master/clients";

  const dispatch = useDispatch();
  const showing = [10, 20, 50, 100, 500];
  const [showEntries, setShowEntries] = useState(100);
  const dataTables = useSelector((state) => state.client.dataTables);
  const [dataTableSearch, setDataTableSearch] = useState("");
  const [tableBody, setTableBody] = useState(dataTables);

  useEffect(() => {
    if (dataTableSearch) {
      const timeOutId = setTimeout(
        () =>
          dispatch(
            getDataTables({
              limit: showEntries,
              page: 1,
              search: dataTableSearch,
            })
          ),
        1000
      );
      return () => clearTimeout(timeOutId);
    } else {
      dispatch(
        getDataTables({
          limit: showEntries,
          page: 1,
          search: dataTableSearch,
        })
      );
    }
  }, [dispatch, dataTableSearch]);

  useEffect(() => {
    setTableBody(dataTables?.data);
  }, [dataTables]);

  // datatable columns
  const columns = [
    {
      header: "Client Name",
      assessor: "client_name",
    },
    {
      header: "Contact",
      assessor: "contact_name",
      Cell: (row) => {
        return (
          <div className="flex flex-col gap-2">
            <span className="block font-semibold">{row.contact_name}</span>
            <span className="block">{row.email}</span>
          </div>
        );
      },
    },
    {
      header: "Client Directory",
      assessor: "file_path",
    },
    {
      header: "Active",
      tdStyle: { width: "7%" },

      Cell: (row) => {
        return row.active ? (
          <div className="badge badge-success">Active</div>
        ) : (
          <div className="badge badge-danger">InActive</div>
        );
      },
    },
    {
      header: "Actions",
      tdStyle: { width: "5%" },
      Cell: (row) => {
        return (
          <div className="flex flex-row items-center justify-end gap-2">
            {CheckRoleAction(pathName, "edit") && (
              <span
                className="btn-action"
                onClick={() => {
                  setFormType("edit");
                  setDataId(row.id);
                  dispatch(setModal(true));
                }}
              >
                <BiEditAlt size="22" />
              </span>
            )}
            {CheckRoleAction(pathName, "delete") && row.project_count === 0 && (
              <span
                className="btn-action"
                onClick={() => dispatch(deleteData({ id: row.id }))}
              >
                <BiTrash size="22" />
              </span>
            )}
          </div>
        );
      },
    },
  ];

  // PAGINATION
  const ButttonPagination = (props) => {
    const { value } = props;
    if (value === "...") {
      return (
        <button type="button" className="btn-dots">
          {value}
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className={`${styles.btnPage} ${
            dataTables?.current_page === value && styles.btnPage_Active
          }`}
          onClick={() => handleChangePage(value)}
        >
          {value}
        </button>
      );
    }
  };

  // SEARCHING
  const handleChangeShowEntries = (show) => {
    setShowEntries(show);
    dispatch(getDataTables({ limit: show, page: 1, search: dataTableSearch }));
  };

  const handleChangePage = (page) => {
    dispatch(
      getDataTables({ limit: showEntries, page: page, search: dataTableSearch })
    );
  };

  return (
    <>
      <div className={styles.dataTable_Box}>
        <div className={styles.dataTable_Header}>
          <div className={styles.dataTable_Search}>
            <div>
              <BiSearch />
            </div>
            <input
              type="search"
              placeholder="Search..."
              onChange={(e) => setDataTableSearch(e.target.value)}
            />
          </div>
        </div>
        <div className={styles.dataTable_body}>
          <table className={styles.dataTable}>
            <thead>
              <tr>
                {columns &&
                  columns.map((column, index) => (
                    <th key={index}>
                      <div>
                        <span>{column.header}</span>
                      </div>
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {tableBody && tableBody.length > 0 ? (
                tableBody.map((row, iRow) => (
                  <tr key={iRow}>
                    {columns.map((column, iCell) => (
                      <td key={iCell} style={column.tdStyle}>
                        {column.Cell
                          ? column.Cell(row)
                          : row?.[column.assessor]}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr key={1}>
                  <td colSpan={columns.length} className="text-center">
                    No data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className={styles.dataTable_Footer}>
          <div className={styles.showingInfo}>
            <div className={styles.dataTable_Select}>
              <select
                onChange={(e) => handleChangeShowEntries(e.target.value)}
                value={showEntries}
              >
                {showing.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <div>
              Showing {dataTables?.from} to {dataTables?.to} of{" "}
              {dataTables?.total} entries
            </div>
          </div>
          <div className={styles.pagination}>
            <button
              type="button"
              className={styles.btnQuick}
              onClick={() => handleChangePage(1)}
              disabled={dataTables?.current_page === 1 ? true : false}
            >
              <img
                style={{ height: "18px", margin: "0 auto" }}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAANVJREFUSEtjZKAxYKSx+QyjFhAM4cETRAkF/QIsLH/y5/SUNsKcjU0M3UtE+QBq0H4GBgYDBgaGxjk9pQ3YxLCFF0ELkA1iZGC4+PsPiwPIIBaWP2ALYWILJhR+INkCSg0HWYjTB9QwHKcF1DIcrwWsLH8O/Gdg0GdgYLjw5w+LI0gxuhiucEeOC7xBRA1L8KYiUFBRaglRyZQSSwhaAApPZJ/8/88YOLe3ZAM2MZLzAbIGkIHMzH8dQIYjFxXoYmQVFQSLTDwKiAqiUQsoCQGCemkeBwCu6qoZY5sVLQAAAABJRU5ErkJggg=="
                alt=""
              />
            </button>
            {dataTables?.links?.map((item, index) => (
              <>
                {item.label === "&laquo; Previous" && (
                  <button
                    type="button"
                    className={styles.btnQuick}
                    onClick={() =>
                      handleChangePage(dataTables?.current_page - 1)
                    }
                    disabled={item.url !== null ? false : true}
                  >
                    <img
                      style={{ height: "18px", margin: "0 auto" }}
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAL9JREFUSEtjZKAxYKSx+QyjFhAM4cEXRAkF/QIsLH/y5/SUNhJ0PgMDaZEMNXw/AwODAQMDQ+OcntIGQpYQHUTIhjMyMFz8/YfFYcGEwg9UsYBcw0GWE/QBJYYTtIBSw4mygJXlz4H/DAz6DAwMF/78YXEkJtyR44WoIKLEEoIWgFwDCipyLSHKAkosIdoCdEv+/2cMnNtbsoEq+QDZEFBwMTP/dSDGcIKpiJDriJEnKYiIMRBdzagFBEON5kEEACMtZRmcDwI5AAAAAElFTkSuQmCC"
                      alt=""
                    />
                  </button>
                )}
                {item.label !== "&laquo; Previous" &&
                  item.label !== "Next &raquo;" && (
                    <ButttonPagination key={index} value={item.label} />
                  )}
                {item.label === "Next &raquo;" && (
                  <button
                    type="button"
                    className={styles.btnQuick}
                    onClick={() =>
                      handleChangePage(dataTables?.current_page + 1)
                    }
                    disabled={item.url !== null ? false : true}
                  >
                    <img
                      style={{ height: "18px", margin: "0 auto" }}
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAALlJREFUSEvt1LENwkAMBdBvYXo2gRFgA0ZAggFA0EMPEhmBDRiBjACb0OMoyB2i4L4tRaJI6st/53+6E3T8Scf56IFiw/9T0Wp3PJhpdak2z+K2PxZQE3g4gD2Au5nOIggFLNbn0VCtboFxFKEAnziL0EAWCQEZJAWo2g3ARIDHy3T669BDgJ9DJNwnpoFMOA1kw2lguT3NRdor0/n3LacrcqRpBnXkFtMTRN6e9ARZhK6oB7INFP97A04+axn4iq7hAAAAAElFTkSuQmCC"
                      alt=""
                    />
                  </button>
                )}
              </>
            ))}
            <button
              type="button"
              className={styles.btnQuick}
              onClick={() => handleChangePage(dataTables?.last_page)}
              disabled={
                dataTables?.current_page === dataTables?.last_page
                  ? true
                  : false
              }
            >
              <img
                style={{ height: "18px", margin: "0 auto" }}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAM1JREFUSEtjZKAxYKSx+QyjFhAM4YELopSS7oY/f1gmLJhQ+AHmTGxihLyA1QcggxgYGOoZGBgu/PnD4giyBJsYIcNB8lgtSCjoF2Bl+XPgPwODPswSkGJ0MWTf4bIMZxxQyxK8kUwNSwimIkotIcoCFpY/+xkYGAwYGRgu/v7D4gAKb3QxXPFBMIgoMRxnKgJJgIKGUsPxJlNqGI7TgpSS7gIGBoZ+WJhDMxqGGNkZDaSRpkUFMS4jVg3BZEqsQSQXFZQaDNM/6gOCIQkA2au0GYe21MEAAAAASUVORK5CYII="
                alt=""
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
