import React, { useEffect, useState } from "react";
import { BiBell, BiCaretDown, BiX } from "react-icons/bi";
import Popup from "../../components/Popup";
import { Button } from "../../components/Button";
import { logout } from "./../../stores/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./sidebar";
import NotificationComponent from "./notification";
import {
  setModal as setModalNotification,
  setOpenNotification,
  readNotification,
  unreadNotification,
} from "../../stores/general/notificationSlice";
import { Link } from "react-router-dom";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

function Layout({ children }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const openPopup = useSelector((state) => state.popup.openPopup);
  const [isOpenAccount, setIsOpenAccount] = useState(false);
  const isOpenNotification = useSelector(
    (state) => state.notification.openNotification
  );
  const dataAllNotification = useSelector(
    (state) => state.notification.dataAll
  );
  const openModalNotification = useSelector(
    (state) => state.notification.openModal
  );
  const totalUnread = useSelector((state) => state.notification.totalUnread);
  // new notif
  const [notifCounter, setNotifCounter] = useState(0);
  const [account, setAccount] = useState();
  const [isLogin, setIsLogin] = useState(false);

  // useEffect(() => {
  //   dispatch(unreadNotification());    
  //   setNotifCounter(totalUnread);
  // }, [dispatch]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     dispatch(unreadNotification());
  //   }, 60000);

  //   return () => clearInterval(interval);
  // }, [dispatch]);

  // const handleOpenNotification = () => {
  //   dispatch(readNotification());
  //   dispatch(unreadNotification());

  //   dispatch(setOpenNotification(!isOpenNotification));
  // };

  // new
  useEffect(() => {
    const login = localStorage.getItem("isLogin");
    setIsLogin(login);

    dispatch(unreadNotification());
    // new notif
    setAccount(JSON.parse(localStorage.getItem("user")));
    setNotifCounter(totalUnread);
  }, [dispatch]);

  useEffect(() => {
    setNotifCounter(totalUnread);
  }, [totalUnread]);

  const handleOpenNotification = () => {
    dispatch(readNotification());
    dispatch(unreadNotification());

    dispatch(setOpenNotification(!isOpenNotification));
  };

  // WEBSOCKET
  const [laravelEcho, setLaravelEcho] = useState();
  useEffect(() => {
    if (window !== undefined) {
      const echo = new Echo({
        broadcaster: "pusher",
        key: "adlinkpmssocket",
        wsHost: "adlinksid.synology.me",
        // wsHost: "127.0.0.1",
        // wsHost: "adlink-pms-api.tyntech.net",
        wsPort: 6001,
        wssport: 6001,
        transports: ["websocket"],
        enabledTransports: ["ws", "wss"],
        forceTLS: false,
        disableStats: true,
        cluster: "mt1",
      });

      if (!laravelEcho) {
        setLaravelEcho(echo);
      }
    }
  }, []);

  useEffect(() => {
    if (isLogin) {
      const account = JSON.parse(localStorage.getItem("user"));
      laravelEcho
        .channel(`new.notif.counter.${account?.reference_id}`)
        .listen(".new.notif.counter", (event) => {
          setNotifCounter(event.data);
          if(event.data > 0){
            const audio = new Audio("/static/sound/notification.mp3");
            audio.play();
            showNotification(`You have ${event.data} unread notifications`);
          }
        });
    }
  }, [isLogin]);

  

  // browser notification
  useEffect(() => {
    if (typeof Notification !== "undefined") {
      let permission = Notification.permission;
      if (permission === "granted") {
        // showNotification();
      } else if (permission === "default") {
        requestAndShowPermission();
      } else {
        // alert("Use normal alert");
      }
    }
  }, []);

  function showNotification(message) {
    var title = "New Notification";
    var body = message;
    var notification = new Notification(title, { body });
    notification.onclick = () => {
      notification.close();
      window.parent.focus();
    };
  }
  function requestAndShowPermission() {
    Notification.requestPermission(function (permission) {
      if (permission === "granted") {
        showNotification("This trial notification from browser");
      }
    });
  }

  return (
    <>
      {openPopup.open && <Popup {...openPopup} />}

      <div>
        {/* sidebar */}
        <div className="sidebar-container overflow-y-auto sidebar-overflow">
          <div className="w-full p-6">
            <img
              src="/static/images/logo_vertical.png"
              alt=""
              className="mx-auto h-20"
            />
          </div>
          <Sidebar />
        </div>
        <div className="page">
          <div className="top-nav">
            <div className="left">
              <img src="/static/images/icon.png" alt="" />
            </div>
            <div className="right">
              {user?.user_type === "Employee" && (
                <div className="relative">
                  <div
                    className="w-10 flex items-center justify-center cursor-pointer"
                    onClick={() => handleOpenNotification()}
                  >
                    <BiBell size={22} />
                  </div>
                  {notifCounter > 0 && (
                    <span className="flex h-4 px-1 absolute -top-1 -right-1  cursor-pointer">
                      <span className="relative inline-flex h-4 px-1 rounded-full bg-danger text-xs text-white flex items-center justify-center">
                        {notifCounter}
                      </span>
                    </span>
                  )}

                  {isOpenNotification && (
                    <NotificationComponent
                      onClick={() =>
                        dispatch(setOpenNotification(!isOpenNotification))
                      }
                    />
                  )}
                </div>
              )}
              <div
                className="flex text-sm items-center justify-center gap-3"
                onClick={() => setIsOpenAccount(!isOpenAccount)}
              >
                <span className="flex flex-row items-center cursor-pointer text-slate-600">
                  <div className="avatar-xs md:avatar-sm bg-danger flex items-center justify-center">
                    <span className="text-lg text-white font-semibold">
                      {user?.name.charAt(0)}
                    </span>
                  </div>

                  <BiCaretDown size={18} />
                </span>
              </div>
              {isOpenAccount && (
                <div
                  className="account-container"
                  onClick={() => setIsOpenAccount(!isOpenAccount)}
                >
                  <div className="account-card px-6">
                    <div className="body">
                      <div className="w-20 h-20 rounded-full bg-danger flex items-center justify-center">
                        <span className="text-5xl text-white font-semibold">
                          {user?.name.charAt(0)}
                        </span>
                      </div>
                      <div className="text-center">
                        <h3 className="font-semibold">{user?.name}</h3>
                        <p className="text-text-secondary text-sm">
                          {user?.user_position_name}
                        </p>
                      </div>

                      <div className="flex flex-row items-center">
                        <Button
                          title="Logout"
                          icon="BiLogOut"
                          color="secondary"
                          onClick={() => dispatch(logout())}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* content */}
          <div className="page-content">
            {openModalNotification && (
              <div className="overlay z-40">
                <div className="modal">
                  <div className={`modal-small`}>
                    <div
                      className="modal-card"
                      style={{ paddingLeft: "0", paddingRight: "0" }}
                    >
                      <div
                        className="modal-header"
                        style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                      >
                        <h3 className="modal-title capitalize">
                          Notifications
                        </h3>
                        <button
                          className="modal-close"
                          onClick={() => dispatch(setModalNotification(false))}
                        >
                          <BiX size={18} />
                        </button>
                      </div>
                      <div
                        className="modal-content"
                        style={{ paddingLeft: "0", paddingRight: "0" }}
                      >
                        <div className="overflow-y-scroll slim-overflow pl-4 pr-1">
                          <div
                            className="space-y-2"
                            style={{ minHeight: 300, maxHeight: "80vh" }}
                          >
                            {dataAllNotification &&
                            dataAllNotification.length > 0 ? (
                              dataAllNotification.map((item, index) => (
                                <Link
                                  key={index}
                                  to={
                                    item.object === "Project"
                                      ? `pm/projects/view/${item.object_id}`
                                      : `pm/tasks/${item.object_id}`
                                  }
                                  onClick={() => {
                                    dispatch(setModalNotification(false));
                                  }}
                                >
                                  <div className="notification-list">
                                    <div className="header">
                                      <span className="title">
                                        {item.created_by}
                                      </span>
                                      <span className="date">
                                        {item.created_at_convert}
                                      </span>
                                    </div>
                                    <div className="content">
                                      <p>{item.message}</p>
                                    </div>
                                  </div>
                                </Link>
                              ))
                            ) : (
                              <div className="p-4 text-center">
                                No notifications
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {children}
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout;
