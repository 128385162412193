import React from "react";

export default function Default() {
  return (
    <div className="w-full flex items-center justify-center">
      <div className="w-full md:w-1/4 flex flex-col items-center">
        <img
          src={"/static/images/error.png"}
          alt=""
          style={{ maxWidth: 250 }}
        />
        <h1>404 Page not found!</h1>
      </div>
    </div>
  );
}
