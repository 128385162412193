import React from "react";
import styles from "../styles/DataTable.module.css";

export default function DataTable2(props) {
  const {
    columns,
    tableBody,
    dataTables,
    handleChangeShowEntries,
    showEntries,
    handleChangePage,
  } = props;
  const showing = [10, 20, 50, 100, 500];

  // PAGINATION
  const ButttonPagination = (props) => {
    const { value } = props;
    if (value === "...") {
      return (
        <button type="button" className="btn-dots">
          {value}
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className={`${styles.btnPage} ${
            dataTables?.current_page === value && styles.btnPage_Active
          }`}
          onClick={() => handleChangePage(value)}
        >
          {value}
        </button>
      );
    }
  };

  return (
    <>
      <div className={styles.dataTable_body}>
        <table className={styles.dataTable}>
          <thead>
            <tr>
              {columns &&
                columns.map((column, index) => (
                  <th key={index}>
                    <div>
                      <span>{column.header}</span>
                    </div>
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {tableBody && tableBody.length > 0 ? (
              tableBody.map((row, iRow) => (
                <tr key={iRow}>
                  {columns.map((column, iCell) => (
                    <td key={iCell} style={column.tdStyle}>
                      {column.Cell ? column.Cell(row) : row?.[column.assessor]}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr key={1}>
                <td colSpan={columns.length} className="text-center">
                  No data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className={styles.dataTable_Footer}>
        <div className={styles.showingInfo}>
          <div className={styles.dataTable_Select}>
            <select
              onChange={(e) => handleChangeShowEntries(e.target.value)}
              value={showEntries}
            >
              {showing.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div>
            Showing {dataTables?.from ?? 0} to {dataTables?.to ?? 0} of{" "}
            {dataTables?.total} entries
          </div>
        </div>
        <div className={styles.pagination}>
          <button
            type="button"
            className={styles.btnQuick}
            onClick={() => handleChangePage(1)}
            disabled={dataTables?.current_page === 1 ? true : false}
          >
            <img
              style={{ height: "18px", margin: "0 auto" }}
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAANVJREFUSEtjZKAxYKSx+QyjFhAM4cETRAkF/QIsLH/y5/SUNsKcjU0M3UtE+QBq0H4GBgYDBgaGxjk9pQ3YxLCFF0ELkA1iZGC4+PsPiwPIIBaWP2ALYWILJhR+INkCSg0HWYjTB9QwHKcF1DIcrwWsLH8O/Gdg0GdgYLjw5w+LI0gxuhiucEeOC7xBRA1L8KYiUFBRaglRyZQSSwhaAApPZJ/8/88YOLe3ZAM2MZLzAbIGkIHMzH8dQIYjFxXoYmQVFQSLTDwKiAqiUQsoCQGCemkeBwCu6qoZY5sVLQAAAABJRU5ErkJggg=="
              alt=""
            />
          </button>
          {dataTables?.links?.map((item, index) => (
            <div key={index}>
              {item.label === "&laquo; Previous" && (
                <button
                  key={index}
                  type="button"
                  className={styles.btnQuick}
                  onClick={() => handleChangePage(dataTables?.current_page - 1)}
                  disabled={item.url !== null ? false : true}
                >
                  <img
                    style={{ height: "18px", margin: "0 auto" }}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAL9JREFUSEtjZKAxYKSx+QyjFhAM4cEXRAkF/QIsLH/y5/SUNhJ0PgMDaZEMNXw/AwODAQMDQ+OcntIGQpYQHUTIhjMyMFz8/YfFYcGEwg9UsYBcw0GWE/QBJYYTtIBSw4mygJXlz4H/DAz6DAwMF/78YXEkJtyR44WoIKLEEoIWgFwDCipyLSHKAkosIdoCdEv+/2cMnNtbsoEq+QDZEFBwMTP/dSDGcIKpiJDriJEnKYiIMRBdzagFBEON5kEEACMtZRmcDwI5AAAAAElFTkSuQmCC"
                    alt=""
                  />
                </button>
              )}
              {item.label !== "&laquo; Previous" &&
                item.label !== "Next &raquo;" && (
                  <ButttonPagination key={index} value={item.label} />
                )}
              {item.label === "Next &raquo;" && (
                <button
                  key={index}
                  type="button"
                  className={styles.btnQuick}
                  onClick={() => handleChangePage(dataTables?.current_page + 1)}
                  disabled={item.url !== null ? false : true}
                >
                  <img
                    style={{ height: "18px", margin: "0 auto" }}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAALlJREFUSEvt1LENwkAMBdBvYXo2gRFgA0ZAggFA0EMPEhmBDRiBjACb0OMoyB2i4L4tRaJI6st/53+6E3T8Scf56IFiw/9T0Wp3PJhpdak2z+K2PxZQE3g4gD2Au5nOIggFLNbn0VCtboFxFKEAnziL0EAWCQEZJAWo2g3ARIDHy3T669BDgJ9DJNwnpoFMOA1kw2lguT3NRdor0/n3LacrcqRpBnXkFtMTRN6e9ARZhK6oB7INFP97A04+axn4iq7hAAAAAElFTkSuQmCC"
                    alt=""
                  />
                </button>
              )}
            </div>
          ))}
          <button
            type="button"
            className={styles.btnQuick}
            onClick={() => handleChangePage(dataTables?.last_page)}
            disabled={
              dataTables?.current_page === dataTables?.last_page ? true : false
            }
          >
            <img
              style={{ height: "18px", margin: "0 auto" }}
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAM1JREFUSEtjZKAxYKSx+QyjFhAM4YELopSS7oY/f1gmLJhQ+AHmTGxihLyA1QcggxgYGOoZGBgu/PnD4giyBJsYIcNB8lgtSCjoF2Bl+XPgPwODPswSkGJ0MWTf4bIMZxxQyxK8kUwNSwimIkotIcoCFpY/+xkYGAwYGRgu/v7D4gAKb3QxXPFBMIgoMRxnKgJJgIKGUsPxJlNqGI7TgpSS7gIGBoZ+WJhDMxqGGNkZDaSRpkUFMS4jVg3BZEqsQSQXFZQaDNM/6gOCIQkA2au0GYe21MEAAAAASUVORK5CYII="
              alt=""
            />
          </button>
        </div>
      </div>
    </>
  );
}