import { createSlice } from '@reduxjs/toolkit';

export const layoutSlice = createSlice({
    name: 'layout',
    initialState: {
        pageTitle: undefined,
        pageSubtitle: undefined
    },
    reducers: {
        setInitialState: (state, action) => {
            state[action.payload.name] = action.payload.value
        }
    }
})

export const { setInitialState } = layoutSlice.actions
export default layoutSlice.reducer

