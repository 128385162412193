import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from "../../../components/Card";
import { useDispatch, useSelector } from "react-redux";
import { getDataByProject } from "../../../stores/pm/projectMoRevSlice";
import { CheckRoleAction } from "../../../helpers/CheckRole";
import { BiSearch } from "react-icons/bi";

export default function TabMoRevHistoryWidget(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const { responsibleId, ownerId } = props;
  const dispatch = useDispatch();
  const dataProject = useSelector((state) => state.project.dataView);
  const dataMoRevByProject = useSelector(
    (state) => state.projectMoRev.dataByProject
  );
  const [listItem, setListItem] = useState(undefined);

  useEffect(() => {
    dispatch(getDataByProject({ projectId: dataProject.id }));
  }, [dispatch, dataProject.id]);

  useEffect(() => {
    setListItem(dataMoRevByProject);
  }, [dataMoRevByProject]);

  const handleSearch = (value) => {
    const filtered = [...dataMoRevByProject].filter(
      (data) =>
        data?.status?.toString().toLowerCase().indexOf(value) > -1 ||
        data?.owner_name?.toString().toLowerCase().indexOf(value) > -1 ||
        data?.created_at_convert?.toString().toLowerCase().indexOf(value) >
          -1 ||
        data?.to_mo_no?.toString().toLowerCase().indexOf(value) > -1 ||
        data?.to_mo_no?.toString().toLowerCase().indexOf(value) > -1 ||
        data?.to_mo_value?.toString().toLowerCase().indexOf(value) > -1 ||
        data?.to_spot?.toString().toLowerCase().indexOf(value) > -1 ||
        data?.to_program_name?.toString().toLowerCase().indexOf(value) > -1 ||
        data?.from_description?.toString().toLowerCase().indexOf(value) > -1 ||
        data?.from_mo_no?.toString().toLowerCase().indexOf(value) > -1 ||
        data?.from_mo_value?.toString().toLowerCase().indexOf(value) > -1 ||
        data?.from_product_name?.toString().toLowerCase().indexOf(value) > -1 ||
        data?.from_spot?.toString().toLowerCase().indexOf(value) > -1 ||
        data?.from_program_name?.toString().toLowerCase().indexOf(value) > -1 ||
        data?.from_station_name?.toString().toLowerCase().indexOf(value) > -1 ||
        data?.notes?.toString().toLowerCase().indexOf(value) > -1
    );

    setListItem(filtered);
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Items</CardTitle>
        </CardHeader>
        <CardBody>
          {!listItem && (
            <div className="text-center font-semibold">Loading...</div>
          )}
          {listItem && (
            <>
              <div className="mb-2">
                <div className="relative">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <BiSearch className="w-5 h-5 text-gray-300" />
                  </div>
                  <input
                    className="form-control-search"
                    placeholder="Search..."
                    onChange={(e) =>
                      handleSearch(e.target.value.toString().toLowerCase())
                    }
                  />
                </div>
              </div>
              <div className="space-y-2">
                {listItem?.length === 0 ? (
                  <span className="py-4 block text-center font-semibold">
                    No item
                  </span>
                ) : (
                  <>
                    <div className="flex flex-col gap-2">
                      {listItem.map((item, index) => (
                        <div
                          key={index}
                          className="p-4 border border-slate-tertiary rounded-md hover:bg-slate-tertiary transition duration-200"
                        >
                          <div className="mb-6 flex flex-row items-center justify-between gap-4">
                            <span className="font-semibold">
                              {item.type === "New" && "New Item"}
                              {item.type === "Update" && "Update Item"}
                              {item.type === "Delete" && "Delete Item"}
                            </span>
                            <div className="flex flex-row items-center justify-between gap-1">
                              <span className={`badge badge-${item.status}`}>
                                {item.status}
                              </span>
                              <span className="badge badge-info">
                                {item.owner_name}, {item.created_at_convert}
                              </span>
                            </div>
                          </div>
                          {item.type === "New" && (
                            <div className="w-full">
                              <div className="mt-4 grid grid-cols-10 gap-2 text-sm">
                                <div className="col-span-1">
                                  <div className="h-10 w-10 flex items-center justify-center bg-success text-white rounded">
                                    New
                                  </div>
                                </div>
                                <div className="col-span-2">
                                  <span className="mb-2 block text-text-secondary">
                                    Item No.
                                  </span>
                                  <span className="block">{item.to_mo_no}</span>
                                </div>
                                <div className="col-span-2">
                                  <span className="mb-2 block text-text-secondary">
                                    Item Value
                                  </span>
                                  <span className="block">
                                    {(CheckRoleAction(
                                      "/pm/projects",
                                      "view_mo_value"
                                    ) ||
                                      user.reference_id === responsibleId ||
                                      user.reference_id === ownerId) &&
                                      item.to_mo_value.toLocaleString("id-ID", {
                                        style: "currency",
                                        currency: "IDR",
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 2,
                                      })}
                                  </span>
                                </div>
                                <div className="col-span-2">
                                  <span className="mb-2 block text-text-secondary">
                                    Product
                                  </span>
                                  <span className="block">
                                    {item.to_product_name}
                                  </span>
                                </div>
                                <div className="col-span-1">
                                  <span className="mb-2 block text-text-secondary">
                                    Spot
                                  </span>
                                  <span className="block">{item.to_spot}</span>
                                </div>
                                <div className="col-span-2">
                                  <span className="mb-2 block text-text-secondary">
                                    Program
                                  </span>
                                  <span className="block">
                                    {item.to_program_name} @{" "}
                                    {item.to_station_name}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                          {item.type === "Update" && (
                            <>
                              <div className="w-full">
                                <div className="grid grid-cols-10 gap-2 text-sm">
                                  <div className="col-span-1">
                                    <div className="h-10 w-10 flex items-center justify-center bg-info text-white rounded">
                                      From
                                    </div>
                                  </div>
                                  <div className="col-span-2">
                                    <span className="mb-2 block text-text-secondary">
                                      Item No.
                                    </span>
                                    <span className="block">
                                      {item.from_mo_no}
                                    </span>
                                  </div>
                                  <div className="col-span-8">
                                    <span className="mb-2 block text-text-secondary">
                                      Item Description.
                                    </span>
                                    <span className="block">
                                      {item.from_description}
                                    </span>
                                  </div>
                                  <div className="col-span-2">
                                    <span className="mb-2 block text-text-secondary">
                                      Item Value
                                    </span>
                                    <span className="block">
                                      {(CheckRoleAction(
                                        "/pm/projects",
                                        "view_mo_value"
                                      ) ||
                                        user.reference_id === responsibleId ||
                                        user.reference_id === ownerId) &&
                                        item.from_mo_value.toLocaleString(
                                          "id-ID",
                                          {
                                            style: "currency",
                                            currency: "IDR",
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                    </span>
                                  </div>
                                  <div className="col-span-2">
                                    <span className="mb-2 block text-text-secondary">
                                      Product
                                    </span>
                                    <span className="block">
                                      {item.from_product_name}
                                    </span>
                                  </div>
                                  <div className="col-span-1">
                                    <span className="mb-2 block text-text-secondary">
                                      Spot
                                    </span>
                                    <span className="block">
                                      {item.from_spot}
                                    </span>
                                  </div>
                                  <div className="col-span-2">
                                    <span className="mb-2 block text-text-secondary">
                                      Program
                                    </span>
                                    <span className="block">
                                      {item.from_program_name} @{" "}
                                      {item.from_station_name}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-4 w-full">
                                <div className="mt-4 grid grid-cols-10 gap-2 text-sm">
                                  <div className="col-span-1">
                                    <div className="h-10 w-10 flex items-center justify-center bg-success text-white rounded">
                                      To
                                    </div>
                                  </div>
                                  <div className="col-span-2">
                                    <span className="mb-2 block text-text-secondary">
                                      Item No.
                                    </span>
                                    <span className="block">
                                      {item.to_mo_no}
                                    </span>
                                  </div>
                                  <div className="col-span-2">
                                    <span className="mb-2 block text-text-secondary">
                                      Item Value
                                    </span>
                                    <span className="block">
                                      {(CheckRoleAction(
                                        "/pm/projects",
                                        "view_mo_value"
                                      ) ||
                                        user.reference_id === responsibleId ||
                                        user.reference_id === ownerId) &&
                                        item.to_mo_value.toLocaleString(
                                          "id-ID",
                                          {
                                            style: "currency",
                                            currency: "IDR",
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                    </span>
                                  </div>
                                  <div className="col-span-2">
                                    <span className="mb-2 block text-text-secondary">
                                      Product
                                    </span>
                                    <span className="block">
                                      {item.to_product_name}
                                    </span>
                                  </div>
                                  <div className="col-span-1">
                                    <span className="mb-2 block text-text-secondary">
                                      Spot
                                    </span>
                                    <span className="block">
                                      {item.to_spot}
                                    </span>
                                  </div>
                                  <div className="col-span-2">
                                    <span className="mb-2 block text-text-secondary">
                                      Program
                                    </span>
                                    <span className="block">
                                      {item.to_program_name} @{" "}
                                      {item.to_station_name}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {item.type === "Delete" && (
                            <div className="w-full">
                              <div className="mt-4 grid grid-cols-10 gap-2 text-sm">
                                <div className="col-span-1">
                                  <div className="h-10 w-10 flex items-center justify-center bg-danger text-white rounded">
                                    Delete
                                  </div>
                                </div>
                                <div className="col-span-2">
                                  <span className="mb-2 block text-text-secondary">
                                    Item No.
                                  </span>
                                  <span className="block">
                                    {item.from_mo_no}
                                  </span>
                                </div>
                                <div className="col-span-2">
                                  <span className="mb-2 block text-text-secondary">
                                    Item Value
                                  </span>
                                  <span className="block">
                                    {(CheckRoleAction(
                                      "/pm/projects",
                                      "view_mo_value"
                                    ) ||
                                      user.reference_id === responsibleId ||
                                      user.reference_id === ownerId) &&
                                      item.from_mo_value.toLocaleString(
                                        "id-ID",
                                        {
                                          style: "currency",
                                          currency: "IDR",
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                  </span>
                                </div>
                                <div className="col-span-2">
                                  <span className="mb-2 block text-text-secondary">
                                    Product
                                  </span>
                                  <span className="block">
                                    {item.from_product_name}
                                  </span>
                                </div>
                                <div className="col-span-1">
                                  <span className="mb-2 block text-text-secondary">
                                    Spot
                                  </span>
                                  <span className="block">
                                    {item.from_spot}
                                  </span>
                                </div>
                                <div className="col-span-2">
                                  <span className="mb-2 block text-text-secondary">
                                    Program
                                  </span>
                                  <span className="block">
                                    {item.from_program_name} @{" "}
                                    {item.from_station_name}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="mt-4 w-full">
                            <span className="font-semibold">
                              Revision Notes :
                            </span>
                            <div className="mt-2 text-sm">{item.notes}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
}
