import React, { useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setDataView as setDataViewTask,
  getDataView,
} from "../../../stores/pm/taskSlice";
import { setDataByTask as setDataResponsibleByTask } from "../../../stores/pm/taskResponsibleSlice";
import { setDataByTask as setDataObserverByTask } from "../../../stores/pm/taskObserverSlice";
import { setDataByTask as setDataAttachmentByTask } from "../../../stores/pm/taskAttachmentSlice";
import ShowContent from "./showContent";

function Show() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDataViewTask(undefined));
    dispatch(setDataResponsibleByTask([]));
    dispatch(setDataObserverByTask([]));
    dispatch(setDataAttachmentByTask([]));

    if (!id) {
      navigate("/pm/tasks");
    } else {
      dispatch(getDataView({ id: id }));
    }
  }, [dispatch, navigate, id]);

  const handleClickBack = () => {
    navigate("/pm/tasks");
  };

  return (
    <ShowContent
      taskId={id}
      fromSubmit={"task"}
      onClickBack={handleClickBack}
    />
  );
}

export default Show;
