import React, { useEffect } from "react";
import { PageHeader, PageHeaderTitle } from "../../../components/PageHeader";
import { useNavigate } from "react-router-dom";
import { CheckRoleIndex } from "../../../helpers/CheckRole";
import IndexTable from "./indexTable";


function Index() {
  const pathName = "/prod/tasks";
  const navigate = useNavigate();

  useEffect(() => {
    if (!CheckRoleIndex(pathName)) {
      navigate("/404");
    }
  }, [navigate]);

  const onClickView = (id) => {
    navigate(`/prod/tasks/${id}`);
  };

  const onClickViewProject = (id) => {
    navigate(`/prod/projects/view/${id}`);
  };

  return (
    <>
      <PageHeader>
        <PageHeaderTitle>Tasks</PageHeaderTitle>
      </PageHeader>

      <IndexTable
        onClickView={onClickView}
        onClickViewProject={onClickViewProject}
      />
    </>
  );
}

export default Index;
