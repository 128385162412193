import React, { useEffect, useState } from "react";
import { BiCopy, BiEditAlt, BiTrash } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/Button";
import DataTable from "../../../components/DataTable";
import {
  PageHeader,
  PageHeaderAction,
  PageHeaderTitle,
} from "../../../components/PageHeader";

import { getDataTables, deleteData } from "../../../stores/um/userRightSlice";
import LoadingTable from "./loadingTable";
import { CheckRoleAction, CheckRoleIndex } from "../../../helpers/CheckRole";

function Index() {
  const pathName = "/um/user-rights";

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.userRight.dataTables);
  const [dataTables, setDataTables] = useState(data);

  useEffect(() => {
    if (!CheckRoleIndex(pathName)) {
      navigate("/404");
    }
    dispatch(getDataTables());
  }, [dispatch, navigate]);

  useEffect(() => {
    setDataTables(data);
  }, [data]);

  const onClickEdit = (id) => {
    navigate(`/um/user-rights/edit/${id}`);
  };
  const onClickCopy = (id) => {
    navigate(`/um/user-rights/copy/${id}`);
  };

  // datatable columns
  const columns = [
    {
      header: "User Right Name",
      assessor: "user_right_name",
    },
    {
      header: "User Right By",
      assessor: "user_right_type",
    },
    {
      header: "User Name",
      assessor: "user_name",
      Cell: (row) => {
        return row.user_right_type === "User" ? row.user_name : "";
      },
    },
    {
      header: "Employee Name",
      assessor: "employee_name",
      Cell: (row) => {
        return row.user_right_type === "Employee" ? row.employee_name : "";
      },
    },
    {
      header: "Position Name",
      assessor: "position_name",
      Cell: (row) => {
        return row.user_right_type === "Position" ? row.position_name : "";
      },
    },
    {
      header: "Actions",
      tdStyle: { width: "5%" },
      Cell: (row) => {
        return (
          <div className="flex flex-row items-center justify-end gap-2">
            {CheckRoleAction(pathName, "edit") && (
              <>
                <span
                  className="btn-action"
                  onClick={() => onClickCopy(row.id)}
                >
                  <BiCopy size="22" />
                </span>
                <span
                  className="btn-action"
                  onClick={() => onClickEdit(row.id)}
                >
                  <BiEditAlt size="22" />
                </span>
              </>
            )}
            {CheckRoleAction(pathName, "delete") && (
              <span
                className="btn-action"
                onClick={() => dispatch(deleteData({ id: row.id }))}
              >
                <BiTrash size="22" />
              </span>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      {!dataTables && <LoadingTable />}
      {dataTables && (
        <>
          <PageHeader>
            <PageHeaderTitle>User Rights</PageHeaderTitle>
            <PageHeaderAction>
              <Button
                title="Add New"
                icon="BiPlus"
                color="primary"
                onClick={() => navigate("/um/user-rights/create")}
              />
            </PageHeaderAction>
          </PageHeader>
          <DataTable
            columns={columns}
            data={dataTables}
            options={{
              sorting: true,
              searching: true,
              bulkAction: false,
            }}
            bulkAction={{}}
            filter={[]}
          />
        </>
      )}
    </>
  );
}

export default Index;
