import React, { useEffect, useState } from "react";
import { Button } from "../../../components/Button";
import ModalForm from "../../../components/ModalForm";

import { useSelector, useDispatch } from "react-redux";
import {
  resetFormData,
  setModal,
  getFormData,
  setFormData,
  submit,
} from "../../../stores/master/productSlice";
import Select from "../../../components/Select";
import { getDataOptions as getDataOptionClient } from "../../../stores/master/clientSlice";

function Form(props) {
  const { formType, dataId } = props;
  const dispatch = useDispatch();
  const openPopup = useSelector((state) => state.popup.openPopup);
  const openModal = useSelector((state) => state.product.openModal);
  const dataOptionClient = useSelector((state) => state.client.dataOptions);
  const formData = useSelector((state) => state.product.formData);
  const [modalTitle, setModalTitle] = useState();

  useEffect(() => {
    dispatch(resetFormData());
    dispatch(getDataOptionClient());
    
    if (formType === "create") {
      setModalTitle("Add Product");
      dispatch(setFormData({name: 'active', value: 1}));
    } else {
      setModalTitle("Edit Product");
      dispatch(getFormData({ id: dataId }));
    }
  }, [dispatch, formType, dataId]);

  const handleInput = (e) => {
    dispatch(setFormData(e.target));
  };

  return (
    <>
      {!openPopup.open && openModal && (
        <ModalForm
          modalTitle={modalTitle}
          modalSize="small"
          closeModal={() => dispatch(setModal(false))}
        >
          <div className="w-full">
            <div className="form-group">
              <label className="control-label">
                Product Name <span className="text-danger">*</span>
              </label>
              <input
                id="product_name"
                title="Product Name"
                type="text"
                name="product_name"
                className="form-control"
                value={formData.product_name}
                onChange={handleInput}
                required
              />
            </div>
            <div className="form-group">
              <label className="control-label">
                Client <span className="text-danger">*</span>
              </label>
              <Select
                id="client_id"
                title="Client"
                name="client_id"
                placeholder={"Choose Client..."}
                value={dataOptionClient.filter(
                  (option) => option.value === formData.client_id
                )}
                onChange={(e) =>
                  dispatch(setFormData({ name: "client_id", value: e?.value }))
                }
                options={dataOptionClient}
                required={true}
              />
            </div>
            <div className="form-group">
              <label className="control-label">Active</label>
              <div className="form-checkbox">
                <input
                  id="active"
                  title="Active"
                  type="checkbox"
                  name="active"
                  checked={formData?.active?.toString() === "1" ? true : false}
                  onChange={(e) => {
                    if (e.target.checked) {
                      e.target.value = 1;
                    } else {
                      e.target.value = 0;
                    }
                    handleInput(e);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="mt-8 flex gap-2">
            <Button
              title="Save"
              icon="BiSave"
              color="primary"
              onClick={() => dispatch(submit({ formType: formType }))}
            />
            <Button
              title="Cancel"
              icon="BiX"
              color="secondary"
              onClick={() => dispatch(setModal(false))}
            />
          </div>
        </ModalForm>
      )}
    </>
  );
}

export default Form;
