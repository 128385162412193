import { createSlice } from "@reduxjs/toolkit";
import { InstanceApi } from "../../helpers/InstanceApi";
import { Validate } from "../../helpers/Validate";
import { setPopup } from "../popupSlice";
import { addFormTask } from "./projectSlice";

export const pmDefaultTaskSlice = createSlice({
  name: "pmDefaultTask",
  initialState: {
    openModal: false,
    modalAddDefaultTask: false,
    dataTables: undefined,
    dataList: undefined,
    dataOptions: [],
    dataFilter: [],
    dataView: undefined,
    formValidate: {
      department_id: { required: true, title: "Department" },
      task_name: { required: true, title: "Task Name" },
      task_duration: { required: true, title: "Task Duration" },
    },
    formData: {
      id: "",
      department_id: "",
      task_name: "",
      task_duration: "",
    },
  },
  reducers: {
    resetDataView: (state) => {
      state.dataView = undefined;
    },
    setModal: (state, action) => {
      state.openModal = action.payload;
    },
    setModalAddDefaultTask: (state, action) => {
      state.modalAddDefaultTask = action.payload;
    },
    setDataList: (state, action) => {
      state.dataList = action.payload;
    },
    setDataTables: (state, action) => {
      state.dataTables = action.payload;
    },
    setDataOptions: (state, action) => {
      state.dataOptions = action.payload;
    },
    setDataFilter: (state, action) => {
      state.dataFilter = action.payload;
    },
    setDataView: (state, action) => {
      state.dataView = action.payload;
    },
    setFormData: (state, action) => {
      state.formData[action.payload.name] = action.payload.value;
    },
  },
});

export const resetFormData = () => async (dispatch, getState) => {
  const formData = getState().pmDefaultTask.formData;
  Object.keys(formData).forEach((key) => {
    dispatch(setFormData({ name: key, value: "" }));
  });
};

export const getDataList = () => async (dispatch) => {
  try {
    dispatch(setDataList(undefined));

    const response = await InstanceApi.get("/pm/default-tasks");
    if (response) {
      dispatch(setDataList(response.data.data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getDataTables = (params) => async (dispatch) => {
  try {
    dispatch(setDataTables(undefined));
    dispatch(setDataView(undefined));
    dispatch(setPopup({ open: true, type: "loading" }));

    const response = await InstanceApi.get("/pm/default-tasks", {params: params});
    if (response) {
      dispatch(setDataTables(response.data.data));
      dispatch(setPopup({ open: false }));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getDataOptions = (params) => async (dispatch) => {
  try {
    const response = await InstanceApi.get(`/pm/default-tasks`);
    if (response) {
      const filtered = response.data.data.filter(
        (row) => !row.special_task && true
      );
      const data = filtered.map((item) => {
        return {
          value: item.id,
          label: item.department_name + " | " + item.task_name,
        };
      });

      dispatch(setDataOptions(data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getDataView = (params) => async (dispatch) => {
  try {
    dispatch(setPopup({ open: true, type: "loading" }));

    const response = await InstanceApi.get("/pm/default-tasks/" + params.id);
    if (response) {
      dispatch(setDataView(response.data.data));
      dispatch(setPopup({ open: false }));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(error.response.data.message);
  }
};

export const getFormData = (params) => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "loading" }));

    const response = await InstanceApi.get("/pm/default-tasks/" + params.id);
    if (response) {
      const data = response.data.data;
      const formData = getState().pmDefaultTask.formData;
      Object.keys(formData).forEach((key) => {
        dispatch(setFormData({ name: key, value: data?.[key] ?? "" }));
      });

      dispatch(setPopup({ open: false }));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(error.response.data.message);
  }
};

export const submit = (params) => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "processing" }));
    const limit = 100;
    const formValidate = getState().pmDefaultTask.formValidate;
    const formData = getState().pmDefaultTask.formData;
    const validate = Validate(formValidate, formData);

    if (!validate.isValid) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: validate.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
    } else {
      let response = "";
      if (params.formType === "create") {
        response = await InstanceApi.post("/pm/default-tasks", formData);
        dispatch(
          setPopup({
            open: true,
            type: "success",
            message: response.data.message,
            handleOk: () => {
              dispatch(setPopup({ open: false }));
              dispatch(setModal(false));
              dispatch(resetFormData());
              dispatch(getDataTables({ limit: limit, page: 1, search: "" }));
            },
          })
        );
      } else {
        response = await InstanceApi.patch(
          "/pm/default-tasks/" + formData.id,
          formData
        );
        dispatch(
          setPopup({
            open: true,
            type: "success",
            message: response.data.message,
            handleOk: () => {
              dispatch(setPopup({ open: false }));
              dispatch(setModal(false));
              dispatch(resetFormData());
              dispatch(getDataTables({ limit: limit, page: 1, search: "" }));
            },
          })
        );
      }
    }
  } catch (err) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: err.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(err.response.data.message);
  }
};

export const submitModalAddDefaultTask = () => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "processing" }));
    const formData = getState().pmDefaultTask.formData;
    const validate = Validate(formData);

    if (!validate.isValid) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: validate.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
    } else {
      let response = await InstanceApi.post("/pm/default-tasks", formData);
      if (response) {
        dispatch(
          setPopup({
            open: true,
            type: "success",
            message: response.data.message,
            handleOk: () => {
              dispatch(setPopup({ open: false }));
              dispatch(setModalAddDefaultTask(false));
              dispatch(resetFormData());
              dispatch(addFormTask(response.data.data));
            },
          })
        );
      }
    }
  } catch (err) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: err.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(err.response.data.message);
  }
};

export const deleteData = (params) => (dispatch) => {
  try {
    dispatch(
      setPopup({
        open: true,
        type: "delete",
        message: "Are you sure to delete this data?",
        handleOk: async () => {
          try {
            const response = await InstanceApi.delete(
              "/pm/default-tasks/" + params.id
            );
            if (response) {
              dispatch(
                setPopup({
                  open: true,
                  type: "success",
                  message: response.data.message,
                  handleOk: () => {
                    dispatch(setPopup({ open: false }));
                    dispatch(getDataTables());
                  },
                })
              );
            }
          } catch (error) {
            dispatch(
              setPopup({
                open: true,
                type: "error",
                message: error.message,
                handleOk: () => {
                  dispatch(setPopup({ open: false }));
                },
              })
            );
          }
        },
        handleCancel: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  } catch (err) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: err.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const {
  resetDataView,
  setModal,
  setModalAddDefaultTask,
  setDataList,
  setDataTables,
  setDataOptions,
  setDataFilter,
  setDataView,
  setFormData,
} = pmDefaultTaskSlice.actions;
export default pmDefaultTaskSlice.reducer;
