import React, { useEffect, useState, useRef } from "react";
import { Button } from "../../../components/Button";
import ModalForm from "../../../components/ModalForm";
import Select from "../../../components/Select";

import { useSelector, useDispatch } from "react-redux";
import {
  resetFormData,
  setModalResponsible,
  setFormAssign,
  assignTask,
} from "../../../stores/prod/prodTaskSlice";
import { getDataOptions as getDataOptionEmployee } from "../../../stores/master/employeeSlice";
import ReactQuill from "react-quill";
import { setPopup } from "../../../stores/popupSlice";
import { InstanceApi } from "../../../helpers/InstanceApi";
import { BiTrash } from "react-icons/bi";

function FormResponsible() {
  const dispatch = useDispatch();
  const taskId = useSelector((state) => state.prodTask.dataView.id);
  const dataOptionEmployee = useSelector((state) => state.employee.dataOptions);
  const formData = useSelector((state) => state.prodTaskResponsible.formData);
  const formAssign = useSelector((state) => state.prodTask.formAssign);
  const [notes, setNotes] = useState("");
  const [modalAttachment, setModalAttachment] = useState(false);
  const [dataTemporaryAttachment, setDataTemporaryAttachment] = useState([]);

  useEffect(() => {
    dispatch(resetFormData());
    dispatch(setFormAssign({ name: "id", value: taskId }));
    dispatch(getDataOptionEmployee());
  }, [dispatch, taskId]);

  const editorToolbar = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };

  const editorFormat = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];

  // attachment
  const inputRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);
  const [file, setFile] = useState();

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const saveAttachment = () => {
    setDataTemporaryAttachment([
      ...dataTemporaryAttachment,
      {
        name: file.name,
        value: file,
      },
    ]);

    setModalAttachment(false);
    setFile(undefined);
  };

  const deleteAttachment = (deleteIndex) => {
    let data = dataTemporaryAttachment.filter(
      (_, index) => index !== deleteIndex
    );
    setDataTemporaryAttachment(data);
  };

  console.log(formAssign);

  // submit
  const submit = async () => {
    dispatch(setPopup({ open: true, type: "processing" }));
    const formData = new FormData();

    // Update the formData object
    formData.append("id", formAssign.id);
    formData.append("responsible_id", formAssign.responsible_id);
    formData.append("notes", notes);
    if (dataTemporaryAttachment.length > 0) {
      dataTemporaryAttachment.map((item, _) =>
        formData.append("attachment[]", item.value, item.name)
      );
    }

    try {
      const response = await InstanceApi.post(`/prod/tasks/assign`, formData);
      if (response) {
        dispatch(
          setPopup({
            open: true,
            type: "success",
            message: response.data.message,
            handleOk: () => {
              dispatch(setPopup({ open: false }));
              window.location.reload();
            },
          })
        );
      }
    } catch (error) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: error.response.data.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
    }
  };

  return (
    <>
      {dataOptionEmployee && (
        <ModalForm
          modalTitle="Add Responsible"
          modalSize="medium"
          closeModal={() => dispatch(setModalResponsible(false))}
        >
          <div className="w-full md:w-96">
            <div className="form-group">
              <label className="control-label">
                Employee <span className="text-danger">*</span>
              </label>
              <input
                id="responsible_id"
                title="Employee"
                type="hidden"
                name="responsible_id"
                value={formData.responsible_id}
                required
              />
              <Select
                inputId="employee-select"
                placeholder={"Choose employee..."}
                onChange={(e) =>
                  dispatch(
                    setFormAssign({ name: "responsible_id", value: e?.value })
                  )
                }
                options={dataOptionEmployee}
                required={true}
              />
            </div>
          </div>
          <div className="mt-8">
            <div className="form-group">
              <label className="control-label">Assign Notes</label>
              <div className="form-control h-60">
                <ReactQuill
                  id="notes"
                  title="Assign Notes"
                  name="notes"
                  className="form-control h-48"
                  value={notes}
                  onChange={(e) => setNotes(e)}
                  required={true}
                  modules={editorToolbar}
                  formats={editorFormat}
                />
              </div>
            </div>
            <div className="mt-8 form-group">
              <div className="mb-2 flex flex-row items-center justify-between gap-4">
                <label className="control-label">Attachments</label>
                <Button
                  title="Add Attachment"
                  icon="BiPlus"
                  size="sm"
                  color="primary"
                  onClick={() => setModalAttachment(true)}
                />
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ width: "5%" }}>No.</th>
                    <th>Files</th>
                    <th style={{ width: "5%" }}>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {dataTemporaryAttachment.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>
                        <span
                          className="btn-action"
                          onClick={() => deleteAttachment(index)}
                        >
                          <BiTrash size="22" />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="mt-8 flex gap-2">
              <Button
                title="Save"
                icon="BiSave"
                color="primary"
                onClick={submit}
              />
              <Button
                title="Cancel"
                icon="BiX"
                color="secondary"
                onClick={() => dispatch(setModalResponsible(false))}
              />
            </div>
          </div>
        </ModalForm>
      )}
      {modalAttachment && (
        <ModalForm
          modalTitle="Upload Attachment"
          modalSize="medium"
          closeModal={() => setModalAttachment(false)}
        >
          <form onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
            <div
              className={`modal-upload-box ${
                dragActive ? "border-slate-800" : ""
              }`}
            >
              <div className="body">
                <input
                  id="input-file-upload"
                  type="file"
                  className="hidden"
                  ref={inputRef}
                  onChange={handleChange}
                />
                <p>
                  Drag and drop your file here or{" "}
                  <span
                    className="cursor-pointer underline text-primary-hover"
                    onClick={onButtonClick}
                  >
                    browse
                  </span>
                </p>
                {file && <p>{file.name}</p>}
              </div>
              <div className="mt-6 flex flex-row gap-2 items-center justify-center">
                {file ? (
                  <Button
                    title="Upload"
                    icon="BiUpload"
                    color="primary"
                    onClick={saveAttachment}
                  />
                ) : (
                  <Button title="Upload" icon="BiUpload" color="disabled" />
                )}
              </div>

              {dragActive && (
                <div
                  className="absolute w-full h-full rounded top-0 right-0 left-0 bottom-0"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
            </div>
          </form>
        </ModalForm>
      )}
    </>
  );
}

export default FormResponsible;
