import { createSlice } from "@reduxjs/toolkit";
import { InstanceApi } from "../../helpers/InstanceApi";

export const prodDashboardSlice = createSlice({
  name: "prodDashboard",
  initialState: {    
    dataProjectYear: undefined,
    dataProjectActive: undefined,
    dataTaskActive: undefined,
    dataProjectActivity: undefined,
  },
  reducers: {
    setDataProjectYear: (state, action) => {
      state.dataProjectYear = action.payload;
    },
    setDataProjectActive: (state, action) => {
      state.dataProjectActive = action.payload;
    },
    setDataTaskActive: (state, action) => {
      state.dataTaskActive = action.payload;
    },
    setDataProjectActivity: (state, action) => {
      state.dataProjectActivity = action.payload;
    },
  },
});

export const getDataDashboard = () => async (dispatch) => {
  try {
    dispatch(setDataProjectYear(undefined));
    dispatch(setDataProjectActive(undefined));
    dispatch(setDataTaskActive(undefined));
    dispatch(setDataProjectActivity(undefined));

    const projectYear = await InstanceApi.get("/prod/projects/year");
    if (projectYear) {
      dispatch(setDataProjectYear(projectYear.data.data));
    }
    const projectActive = await InstanceApi.get("/prod/projects/active");
    if (projectActive) {
      dispatch(setDataProjectActive(projectActive.data.data));
    }
    const taskActive = await InstanceApi.get("/prod/tasks/active");
    if (taskActive) {
      dispatch(setDataTaskActive(taskActive.data.data));
    }
    const activity = await InstanceApi.get("/prod/projects/activity");
    if (activity) {
      dispatch(setDataProjectActivity(activity.data.data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const {
  setDataProjectYear,
  setDataProjectActive,
  setDataTaskActive,
  setDataProjectActivity,
} = prodDashboardSlice.actions;
export default prodDashboardSlice.reducer;
