import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDataByProject as getDataMoRevByProject, getDataPendingByProject as getDataMoRevPendingByProject } from "../../../stores/pm/projectMoRevSlice";
import TabMoRevWidget from "../tabs/tabMoRevWidget";

function TabMoRev() {
  const dispatch = useDispatch();
  const dataProject = useSelector((state) => state.project.dataView);

  useEffect(() => {
    // dispatch(getDataMoRevByProject({ projectId: dataProject.id }));
    dispatch(getDataMoRevPendingByProject({ projectId: dataProject.id }));
  }, [dispatch, dataProject.id]);

  return (
    <TabMoRevWidget
      ownerId={dataProject.owner_id}
    />
  );
}

export default TabMoRev;
