import React from 'react'
import * as BI from "react-icons/bi"

export default function ModalForm(props) {
    return (
        <div className='overlay z-40'>
            <div className='modal'>
                <div className={`modal-${props.modalSize}`} >
                    <div className="modal-card">
                        <div className='modal-header'>
                            <h3 className="modal-title capitalize">{props.modalTitle}</h3>
                            <button className="modal-close" onClick={() => props.closeModal(false)}>
                                <BI.BiX size={18}/>
                            </button>
                        </div>
                        <div className='modal-content'>
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
  }