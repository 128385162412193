import React from "react";
import { PageHeader, PageHeaderAction, PageHeaderTitle } from "../../components/PageHeader";
import PmDashboard from "./pmDashboard";
import { CheckRoleIndex } from "../../helpers/CheckRole";
import ProdDashboard from "./prodDashboard";
import { CheckRoleAction } from "../../helpers/CheckRole";
import { Button } from "../../components/Button";
import { useNavigate } from "react-router-dom";

function Index() {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  if (!user || user?.user_right.length === 0) {
    localStorage.removeItem("isLogin");
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");

    window.location.href = "/";
  }

  return (
    <>
      <PageHeader>
        <PageHeaderTitle>Dashboard</PageHeaderTitle>
        <PageHeaderAction>
          {CheckRoleAction('/pm/projects', "create") && (
            <Button
              title="Project"
              icon="BiPlus"
              color="primary"
              onClick={() => navigate(`${'/pm/projects'}/create`)}
            />
          )}
        </PageHeaderAction>
      </PageHeader>

      <div className="space-y-4">
        {CheckRoleIndex("/pm/projects") &&
          CheckRoleIndex("/pm/tasks") &&
          user.user_type === "Employee" && <PmDashboard />}
        {CheckRoleIndex("/prod/projects") &&
          CheckRoleIndex("/prod/tasks") &&
          user.user_type === "Employee" && <ProdDashboard />}
      </div>
    </>
  );
}

export default Index;
