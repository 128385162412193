import React, { useState } from "react";
import { BiLink, BiFile } from "react-icons/bi";
import { Button } from "../../../components/Button";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import {
  PageHeader,
  PageHeaderAction,
  PageHeaderTitle,
} from "../../../components/PageHeader";

import { useSelector } from "react-redux";
import LoadingShow from "./loadingShow";
import TabAssignAttachment from "./tabAssignAttachment";
import TabProdAttachment from "./tabProdAttachment";
import CardResponsible from "./cardResponsible";
import CardObserver from "./cardObserver";
import parse from "html-react-parser";

function ShowContent(props) {
  const { onClickBack } = props;
  const dataTask = useSelector((state) => state.prodTask.dataView);
  const dataAttachment = useSelector(
    (state) => state.prodTaskAttachment.dataByTask
  );
  const [openTab, setOpenTab] = useState("tab-assign-attachment");

  return (
    <>
      {!dataTask ? (
        <LoadingShow />
      ) : (
        <>
          <PageHeader>
            <PageHeaderTitle>
              Task <small>Detail of task</small>
            </PageHeaderTitle>
            <PageHeaderAction>
              <Button
                title="Back"
                icon="BiArrowBack"
                color="secondary"
                onClick={onClickBack}
              />
            </PageHeaderAction>
          </PageHeader>

          <div className="flex md:flex-row gap-4">
            <div className="md:w-3/4 space-y-4">
              <Card>
                <CardBody>
                  <div className="space-y-4">
                    <div className="mb-6 flex gap-6">
                      <div className="flex-1">
                        <h1 className="font-semibold">
                          [#{dataTask.task_no}] {dataTask.task_name}
                        </h1>
                      </div>
                    </div>
                    <div className="w-full">
                      <span className="label-detail">
                        This task in group (production project)
                      </span>
                      <div>
                        <a
                          href={`/prod/projects/view/${dataTask.project_id}`}
                          className="inline-block"
                        >
                          <span className="flex flex-row gap-2 items-center text-primary-hover">
                            <BiLink /> [#{dataTask.project_no}]{" "}
                            {dataTask.project_name}
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="w-full">
                      <span className="label-detail">Assign Notes</span>
                      {dataTask.notes &&
                        dataTask.notes != "" &&
                        parse(dataTask?.notes)}
                    </div>
                  </div>
                </CardBody>
              </Card>

              <div className="tabs">
                <div className="tab-nav">
                  <button
                    type="button"
                    className={`${
                      openTab === "tab-assign-attachment" && "active"
                    }`}
                    onClick={() => setOpenTab("tab-assign-attachment")}
                  >
                    <BiFile /> Assign Attachments
                  </button>
                  <button
                    type="button"
                    className={`${
                      openTab === "tab-prod-attachment" && "active"
                    }`}
                    onClick={() => setOpenTab("tab-prod-attachment")}
                  >
                    <BiFile /> Production Attachments
                  </button>
                </div>
                {openTab === "tab-assign-attachment" && (
                  <div className="tab-panel">
                    <TabAssignAttachment />
                  </div>
                )}
                {openTab === "tab-prod-attachment" && (
                  <div className="tab-panel">
                    <TabProdAttachment />
                  </div>
                )}
              </div>
            </div>
            <div className="md:w-1/4 space-y-4">
              <div className="space-y-2">
                <div
                  className={`py-2 px-4 w-full shadow-lg shadow-shadow rounded-lg relative ${
                    dataTask.status === "In Progress"
                      ? "bg-inprogress"
                      : dataTask.status === "Canceled"
                      ? "bg-danger"
                      : "bg-success"
                  }`}
                >
                  <span className="text-white font-semibold">
                    {dataTask.status}
                    {/* {dataTask.total_attachment === dataTask.spot
                      ? "Completed"
                      : "In Progress"} */}
                  </span>
                </div>
                <Card>
                  <CardBody>
                    <div className="space-y-4">
                      <div className="w-full">
                        <span className="label-detail">Assign Date</span>
                        <span className="text-text-secondary">
                          {dataTask.assign_date_convert ?? "-"}
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <CardObserver />
                <CardResponsible />
                <Card>
                  <CardHeader>
                    <CardTitle>Item Detail</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="space-y-4">
                      <div className="w-full">
                        <span className="label-detail">Item No.</span>
                        <span className="text-text-secondary">
                          {dataTask.mo_no}
                        </span>
                      </div>
                      <div className="w-full">
                        <span className="label-detail">Product</span>
                        <div className="flex flex-row items-center gap-2">
                          <span className="team-name">
                            {dataTask.product_name}
                          </span>
                        </div>
                      </div>
                      <div className="w-full">
                        <span className="label-detail">Total Spot</span>
                        <div className="flex flex-row items-center gap-2">
                          <span className="team-name">
                            {dataTask.total_attachment} / {dataTask.spot}
                          </span>
                        </div>
                      </div>
                      <div className="w-full">
                        <span className="label-detail">Program</span>
                        <div className="flex flex-row items-center gap-2">
                          <span className="team-name">
                            {dataTask.program_name}
                          </span>
                        </div>
                      </div>
                      <div className="w-full">
                        <span className="label-detail">Station</span>
                        <div className="flex flex-row items-center gap-2">
                          <span className="team-name">
                            {dataTask.station_name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ShowContent;
