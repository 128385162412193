import React from "react";
import { Button } from "./Button";

export default function Popup(props) {
  let { type, title, icon, message, handleOk, handleCancel } = { ...props };

  switch (type) {
    case "loading":
      title = "Loading...";
      icon = "Loading.png";
      message = "Please wait a moment";
      break;
    case "processing":
      title = "Processing...";
      icon = "Processing.png";
      message = "Please wait a moment";
      break;
    case "upload":
      title = "Uploading...";
      icon = "Processing.png";
      message = "Please wait a moment";
      break;
    case "download":
      title = "Downloading...";
      icon = "Processing.png";
      message = "Please wait a moment";
      break;
    case "error":
      title = "Something went wrong!";
      icon = "Error.png";
      break;
    case "success":
      title = "Success!";
      icon = "Success.png";
      break;
    case "confirm":
      title = "Please Confirm!";
      icon = "Confirm.png";
      break;
    case "delete":
      title = "Please Confirm!";
      icon = "Confirm.png";
      break;
    default:
      break;
  }

  return (
    <div className="overlay z-50">
      <div className="popup">
        <div className="popup-card">
          <div>
            <h1 className="popup-title">{title}</h1>
            <p className="popup-message">{message}</p>
          </div>
          {type === "success" && (
            <div className="popup-button">
              <Button
                title="OK"
                icon="BiCheck"
                color="primary"
                onClick={handleOk}
              />
            </div>
          )}
          {type === "error" && message !== "Token has expired" && (
            <div className="popup-button">
              <Button
                title="OK"
                icon="BiCheck"
                color="primary"
                onClick={handleOk}
              />
            </div>
          )}
          {type === "error" && message === "Token has expired" && (
            <div className="popup-button">
              <Button
                title="OK"
                icon="BiCheck"
                color="primary"
                onClick={() => {
                  localStorage.removeItem("isLogin");
                  localStorage.removeItem("user");
                  localStorage.removeItem("access_token");

                  window.location.href = "/";
                }}
              />
            </div>
          )}
          {type === "confirm" && (
            <div className="popup-button">
              <Button
                title="No"
                icon="BiX"
                color="secondary"
                onClick={handleCancel}
              />
              <Button
                title="Yes"
                icon="BiCheck"
                color="primary"
                onClick={handleOk}
              />
            </div>
          )}
          {type === "delete" && (
            <div className="popup-button">
              <Button
                title="No"
                icon="BiX"
                color="primary"
                onClick={handleCancel}
              />
              <Button
                title="Yes"
                icon="BiCheck"
                color="secondary"
                onClick={handleOk}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
