import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiEditAlt, BiTrash } from "react-icons/bi";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardAction,
} from "../../../components/Card";
import { Button } from "../../../components/Button";
import ModalForm from "../../../components/ModalForm";
import Select from "../../../components/Select";
import { Validate } from "../../../helpers/Validate";
import { setPopup } from "../../../stores/popupSlice";
import { getDataOptions as getDataOptionPosition } from "../../../stores/master/positionSlice";
import { getDataOptions as getDataOptionEmployee } from "../../../stores/master/employeeSlice";
import {
  setModal,
  setDataTemporary,
  setFormData,
  resetFormData,
  getDataByWorkflow,
} from "../../../stores/wf/workflowDetailSlice";
import { useParams } from "react-router-dom";

export default function CardDetail(props) {
  const dispatch = useDispatch();
  const openModal = useSelector((state) => state.workflowDetail.openModal);
  const dataOptionLevel = useSelector((state) => state.workflowDetail.level);
  const dataOptionRequestFrom = useSelector(
    (state) => state.workflowDetail.requestFrom
  );
  const dataOptionApprovalBy = useSelector(
    (state) => state.workflowDetail.approvalBy
  );
  const dataOptionPosition = useSelector((state) => state.position.dataOptions);
  const dataOptionEmployee = useSelector((state) => state.employee.dataOptions);
  const dataTemporary = useSelector(
    (state) => state.workflowDetail.dataTemporary
  );
  const formData = useSelector((state) => state.workflowDetail.formData);
  const formValidate = useSelector(
    (state) => state.workflowDetail.formValidate
  );
  const { id } = useParams();
  const [formType, setFormType] = useState();
  const [indexEdit, setIndexEdit] = useState();

  const handleInput = (e) => {
    dispatch(setFormData({ name: e.target.name, value: e.target.value }));
  };

  // const addNewData = () => {
  //   const validate = Validate(formValidate, formData);

  //   if (!validate.isValid) {
  //     dispatch(
  //       setPopup({
  //         open: true,
  //         type: "error",
  //         message: validate.message,
  //         handleOk: () => {
  //           dispatch(setPopup({ open: false }));
  //         },
  //       })
  //     );
  //   } else {
  //     dispatch(setDataTemporary([...dataTemporary, formData]));
  //     dispatch(setModal(false));
  //   }
  // };

  useEffect(() => {
    dispatch(
      setFormData({
        name: "request_reference_id",
        value: null,
      })
    );
  }, [dispatch, formData.request_from]);

  useEffect(() => {
    dispatch(
      setFormData({
        name: "approval_reference_id",
        value: null,
      })
    );
  }, [dispatch, formData.approval_by]);

  const addData = () => {
    dispatch(resetFormData());
    setFormType("create");
    dispatch(setModal(true));
  };

  const editData = (editIndex) => {
    // dispatch(resetFormData());

    setIndexEdit(editIndex);
    setFormType("edit");

    const temp = [...dataTemporary];
    const item = temp[editIndex];
    Object.entries(item).forEach((row) => {
      dispatch(setFormData({ name: row[0], value: row[1] }));
    });

    dispatch(setModal(true));
  };

  const saveData = (type) => {
    const validate = Validate(formValidate, formData);
    let filtered = dataTemporary;

    filtered = [...filtered].filter(
      (data) =>
        data.level === formData.level &&
        data.request_from === formData.request_from &&
        data.request_reference_id === formData.request_reference_id &&
        data.approval_by === formData.approval_by &&
        data.approval_reference_id === formData.approval_reference_id &&
        data.next_level.toString() === (formData.next_level === "" || formData.next_level === "0" ? "0" : "1") 
    );

    if (!validate.isValid) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: validate.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
    } else {
      if (filtered.length > 0) {
        dispatch(
          setPopup({
            open: true,
            type: "error",
            message: "This configuration already exists!",
            handleOk: () => {
              dispatch(setPopup({ open: false }));
            },
          })
        );
      } else {
        if (type === "create") {
          dispatch(setDataTemporary([...dataTemporary, formData]));
        } else {
          const temp = [...dataTemporary];
          temp[indexEdit] = formData;
          dispatch(setDataTemporary(temp));
        }
        dispatch(setModal(false));
      }
    }
  };

  useEffect(() => {
    dispatch(getDataByWorkflow({ workflowId: id }));
    dispatch(setDataTemporary([...dataTemporary, formData]));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getDataOptionPosition());
    dispatch(getDataOptionEmployee());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setFormData({ ...formData, reference_id: "" }));
  }, [dispatch, formData.level_type]);

  const deleteData = (deleteIndex) => {
    let data = dataTemporary.filter((row, index) => {
      if (deleteIndex !== index) {
        return true;
      }
    });
    dispatch(setDataTemporary(data));
  };

  return (
    <>
      {openModal && (
        <ModalForm
          modalTitle={formType === "create" ? "Add Detail" : "Edit Detail"}
          modalSize="small"
          closeModal={() => dispatch(setModal(false))}
        >
          <div className="w-full">
            <div className="form-group">
              <label className="control-label">
                Level<span className="text-danger">*</span>
              </label>
              <Select
                id="level"
                title="Level"
                name="level"
                placeholder={"Choose..."}
                value={dataOptionLevel.filter(
                  (option) => option.value === formData.level
                )}
                onChange={(e) =>
                  dispatch(setFormData({ name: "level", value: e?.value }))
                }
                options={dataOptionLevel}
                required={true}
              />
            </div>
            <div className="form-group">
              <label className="control-label">
                Request From<span className="text-danger">*</span>
              </label>
              <Select
                id="request_from"
                title="Request From"
                name="request_from"
                placeholder={"Choose..."}
                value={dataOptionRequestFrom.filter(
                  (option) => option.value === formData.request_from
                )}
                onChange={(e) =>
                  dispatch(
                    setFormData({ name: "request_from", value: e?.value })
                  )
                }
                options={dataOptionRequestFrom}
                required={true}
              />
            </div>
            <div className="form-group">
              <label className="control-label">
                Request From Reference<span className="text-danger">*</span>
              </label>
              {formData.request_from === "Employee" && (
                <Select
                  id="request_reference_id"
                  title="Request From Reference"
                  name="request_reference_id"
                  placeholder={"Choose..."}
                  value={dataOptionEmployee.filter(
                    (option) => option.value === formData.request_reference_id
                  )}
                  onChange={(e) => {
                    dispatch(
                      setFormData({
                        name: "request_reference_id",
                        value: e?.value,
                      })
                    );
                    dispatch(
                      setFormData({
                        name: "request_employee_name",
                        value: e?.label,
                      })
                    );
                  }}
                  options={dataOptionEmployee}
                  required={true}
                />
              )}
              {formData.request_from === "Position" && (
                <Select
                  id="request_reference_id"
                  title="Subject"
                  name="request_reference_id"
                  placeholder={"Choose..."}
                  value={dataOptionPosition.filter(
                    (option) => option.value === formData.request_reference_id
                  )}
                  onChange={(e) => {
                    dispatch(
                      setFormData({
                        name: "request_reference_id",
                        value: e?.value,
                      })
                    );
                    dispatch(
                      setFormData({
                        name: "request_position_name",
                        value: e?.label,
                      })
                    );
                  }}
                  options={dataOptionPosition}
                  required={true}
                />
              )}
            </div>
            <div className="form-group">
              <label className="control-label">
                Approval By<span className="text-danger">*</span>
              </label>
              <Select
                id="approval_by"
                title="Approval By"
                name="approval_by"
                placeholder={"Choose..."}
                value={dataOptionApprovalBy.filter(
                  (option) => option.value === formData.approval_by
                )}
                onChange={(e) =>
                  dispatch(
                    setFormData({ name: "approval_by", value: e?.value })
                  )
                }
                options={dataOptionApprovalBy}
                required={true}
              />
            </div>
            <div className="form-group">
              <label className="control-label">
                Approval By Reference<span className="text-danger">*</span>
              </label>
              {formData.approval_by === "Employee" && (
                <Select
                  id="approval_reference_id"
                  title="Approval By Reference"
                  name="approval_reference_id"
                  placeholder={"Choose..."}
                  value={dataOptionEmployee.filter(
                    (option) => option.value === formData.approval_reference_id
                  )}
                  onChange={(e) => {
                    dispatch(
                      setFormData({
                        name: "approval_reference_id",
                        value: e?.value,
                      })
                    );
                    dispatch(
                      setFormData({
                        name: "approval_employee_name",
                        value: e?.label,
                      })
                    );
                  }}
                  options={dataOptionEmployee}
                  required={true}
                />
              )}
              {formData.approval_by === "Position" && (
                <Select
                  id="approval_reference_id"
                  title="Subject"
                  name="approval_reference_id"
                  placeholder={"Choose..."}
                  value={dataOptionPosition.filter(
                    (option) => option.value === formData.approval_reference_id
                  )}
                  onChange={(e) => {
                    dispatch(
                      setFormData({
                        name: "approval_reference_id",
                        value: e?.value,
                      })
                    );
                    dispatch(
                      setFormData({
                        name: "approval_position_name",
                        value: e?.label,
                      })
                    );
                  }}
                  options={dataOptionPosition}
                  required={true}
                />
              )}
            </div>
            <div className="flex flex-row gap-4">
              {/* <div className="md:w-1/2">
                <div className="form-group">
                  <label className="control-label">Related User</label>
                  <div className="form-checkbox">
                    <input
                      id="related_user"
                      title="Related User"
                      type="checkbox"
                      name="related_user"
                      checked={
                        formData?.related_user?.toString() === "1"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          e.target.value = 1;
                        } else {
                          e.target.value = 0;
                        }
                        handleInput(e);
                      }}
                    />
                  </div>
                </div>
              </div> */}
              {formData.level !== 3 && (
                <div className="md:w-1/2">
                  <div className="form-group">
                    <label className="control-label">Next Level</label>
                    <div className="form-checkbox">
                      <input
                        id="next_level"
                        title="Next Level"
                        type="checkbox"
                        name="next_level"
                        checked={
                          formData?.next_level?.toString() === "1"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            e.target.value = 1;
                          } else {
                            e.target.value = 0;
                          }
                          handleInput(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="mt-8 flex gap-4">
            <Button
              title="Save"
              icon="BiSave"
              color="primary"
              onClick={() => saveData(formType)}
            />
            <Button
              title="Cancel"
              icon="BiX"
              color="secondary"
              onClick={() => dispatch(setModal(false))}
            />
          </div>
        </ModalForm>
      )}
      <Card>
        <CardHeader>
          <CardTitle>Detail Workflow</CardTitle>
          <CardAction>
            <Button
              title="Add"
              icon="BiPlus"
              size="sm"
              color="primary"
              onClick={addData}
            />
          </CardAction>
        </CardHeader>
        <CardBody>
          <table className="table">
            <thead>
              <tr>
                <th style={{ width: "5%" }}>Level</th>
                <th>Request From</th>
                <th>Request From Reference</th>
                <th>Approval By</th>
                <th>Approval By Reference</th>
                {/* <th style={{ width: "5%" }}>Related User</th> */}
                <th style={{ width: "5%" }}>Next Level</th>
                <th style={{ width: "5%" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {dataTemporary.map((item, index) => (
                <tr key={index}>
                  <td>{item.level}</td>
                  <td>{item.request_from}</td>
                  <td>
                    {item.request_from === "Position" &&
                      item.request_position_name}
                    {item.request_from === "Employee" &&
                      item.request_employee_name}
                  </td>
                  <td>{item.approval_by}</td>
                  <td>
                    {item.approval_by === "Position" &&
                      item.approval_position_name}
                    {item.approval_by === "Employee" &&
                      item.approval_employee_name}
                  </td>
                  {/* <td>
                    {item.related_user?.toString() === "1" ? "Yes" : "No"}
                  </td> */}
                  <td>{item.next_level?.toString() === "1" ? "Yes" : "No"}</td>
                  <td>
                    <div className="flex flex-row items-center gap-2">
                      <span
                        className="text-info cursor-pointer"
                        onClick={() => editData(index)}
                      >
                        <BiEditAlt size="22" />
                      </span>
                      <span
                        className="text-info cursor-pointer"
                        onClick={() => deleteData(index)}
                      >
                        <BiTrash size="22" />
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </>
  );
}
