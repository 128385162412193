import { createSlice } from "@reduxjs/toolkit";
import { InstanceApi } from "../../helpers/InstanceApi";
import { Validate } from "../../helpers/Validate";
import { setPopup } from "../popupSlice";

import { getDataByTask as getDataResponsibleByTask } from "./taskResponsibleSlice";
import { getDataByTask as getDataObserverByTask } from "./taskObserverSlice";
import { getDataByTask as getDataHistoryByTask } from "./taskHistorySlice";
import { getDataByTask as getDataAttachmentByTask } from "./taskAttachmentSlice";

export const taskSlice = createSlice({
  name: "task",
  initialState: {
    openModal: false,
    openModalResponsible: false,
    openShowTask: false,
    modalAddTask: false,
    indexDisplay: "table",
    dataCount: undefined,
    dataDeadline: undefined,
    dataTables: undefined,
    dataOptions: [],
    dataView: undefined,
    dataByProject: undefined,
    dataTemporaryFinishNotes: "",
    dataTemporaryApprovalNotes: "",
    dataTemporaryAttachment: [],
    formData: {
      id: "",
      project_id: "",
      task_no: "",
      department_id: "",
      task_name: "",
      task_duration: "",
      owner_id: "",
      observer_id: "",
      responsible_id: "",
      deadline: "",
      assign_date: "",
      begin_date: "",
      end_date: "",
      progress: "",
      notes: "",
      status: "",
      approval_notes: "",
      score: "",
      mo_no: "",
      mo_value: "",
      urgent: "",
      level: "",
      revision: "",
      required: "",
      enabled: "",
      alias: "",
      special_task: "",
    },
    formAssign: {
      id: "",
      responsible_id: "",
    },
  },
  reducers: {
    setModal: (state, action) => {
      state.openModal = action.payload;
    },
    setModalResponsible: (state, action) => {
      state.openModalResponsible = action.payload;
    },
    setShowTask: (state, action) => {
      state.openShowTask = action.payload;
    },
    setIndexDisplay: (state, action) => {
      state.indexDisplay = action.payload;
    },
    setDataTables: (state, action) => {
      state.dataTables = action.payload;
    },
    setDataOptions: (state, action) => {
      state.dataOptions = action.payload;
    },
    setDataByProject: (state, action) => {
      state.dataByProject = action.payload;
    },
    setDataView: (state, action) => {
      state.dataView = action.payload;
    },
    setFormData: (state, action) => {
      state.formData[action.payload.name] = action.payload.value;
    },
    setFormAssign: (state, action) => {
      state.formAssign[action.payload.name] = action.payload.value;
    },
    setDataCount: (state, action) => {
      state.dataCount = action.payload;
    },
    setDataDeadline: (state, action) => {
      state.dataDeadline = action.payload;
    },
    setDataTemporaryFinishNotes: (state, action) => {
      state.dataTemporaryFinishNotes = action.payload;
    },
    setDataTemporaryApprovalNotes: (state, action) => {
      state.dataTemporaryApprovalNotes = action.payload;
    },
    setDataTemporaryAttachment: (state, action) => {
      state.dataTemporaryAttachment = action.payload;
    },
  },
});

export const resetFormData = () => async (dispatch, getState) => {
  const formData = getState().task.formData;
  Object.keys(formData).forEach((key) => {
    dispatch(setFormData({ name: key, value: "" }));
  });
};

export const getDataTables = (params) => async (dispatch) => {
  try {
    dispatch(setDataTables(undefined));
    dispatch(setPopup({ open: true, type: "loading" }));

    const response = await InstanceApi.get("/pm/tasks", { params: params });
    if (response) {
      dispatch(setDataTables(response.data.data));
      dispatch(setDataView(undefined));
      dispatch(setPopup({ open: false }));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const getDataByProject = (params) => async (dispatch) => {
  try {
    dispatch(setDataByProject(undefined));
    const response = await InstanceApi.get(
      "/pm/tasks/project/" + params.projectId
    );
    if (response) {
      dispatch(setDataByProject(response.data.data));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const getDataView = (params) => async (dispatch) => {
  try {
    dispatch(setPopup({ open: true, type: "loading" }));

    const response = await InstanceApi.get("/pm/tasks/" + params.id);
    if (response) {
      dispatch(setDataView(response.data.data));
      dispatch(getDataAttachmentByTask({ taskId: params.id }));
      dispatch(getDataHistoryByTask({ taskId: params.id }));
      dispatch(setPopup({ open: false }));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const getFormData = (params) => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "loading" }));

    const response = await InstanceApi.get("/pm/tasks/" + params.id);
    if (response) {
      const data = response.data.data;
      const formData = getState().task.formData;

      Object.keys(formData).forEach((key) => {
        dispatch(setFormData({ name: key, value: data?.[key] }));
      });

      dispatch(setPopup({ open: false }));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const getTaskToday = (params) => async (dispatch) => {
  try {
    dispatch(setDataTables(undefined));
    const response = await InstanceApi.get("/pm/tasks/today/" + params.status);

    if (response) {
      dispatch(setDataTables(response.data.data));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const assignTask = (params) => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "processing" }));
    const dataTask = getState().task.dataView;
    const formAssign = getState().task.formAssign;
    const response = await InstanceApi.post("/pm/tasks/assign", formAssign);
    if (response) {
      dispatch(
        setPopup({
          open: true,
          type: "success",
          message: response.data.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
            dispatch(setModalResponsible(false));
            dispatch(getDataView({ id: dataTask.id }));
          },
        })
      );
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const deleteResponsible = () => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "processing" }));
    const dataTask = getState().task.dataView;
    const response = await InstanceApi.post("/pm/tasks/delete-responsible", {
      id: dataTask.id,
    });
    if (response) {
      dispatch(
        setPopup({
          open: true,
          type: "success",
          message: response.data.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
            dispatch(getDataView({ id: dataTask.id }));
          },
        })
      );
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const startTask = (params) => async (dispatch, getState) => {
  try {
    dispatch(
      setPopup({
        open: true,
        type: "confirm",
        message: "Are you sure to start this task?",
        handleOk: async () => {
          try {
            dispatch(setPopup({ open: true, type: "processing" }));
            const dataTask = getState().task.dataView;
            const response = await InstanceApi.post("/pm/tasks/start", {
              id: dataTask.id,
            });
            dispatch(
              setPopup({
                open: true,
                type: "success",
                message: response.data.message,
                handleOk: () => {
                  dispatch(setPopup({ open: false }));
                  dispatch(setModal(false));
                  dispatch(getDataView({ id: dataTask.id }));
                },
              })
            );
          } catch (error) {
            dispatch(
              setPopup({
                open: true,
                type: "error",
                message: error.response.data.message,
                handleOk: () => {
                  dispatch(setPopup({ open: false }));
                },
              })
            );
          }
        },
        handleCancel: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const finishTask = (params) => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "processing" }));
    const dataTask = getState().task.dataView;
    const response = await InstanceApi.post("/pm/tasks/finish", {
      id: dataTask.id,
    });
    if (response) {
      dispatch(
        setPopup({
          open: true,
          type: "success",
          message: response.data.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
            dispatch(setModal(false));
            window.location.reload();
          },
        })
      );
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const approveTask = (params) => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "processing" }));
    const dataTask = getState().task.dataView;
    const response = await InstanceApi.post("/pm/tasks/approve", {
      id: dataTask.id,
    });
    dispatch(
      setPopup({
        open: true,
        type: "success",
        message: response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
          dispatch(setModal(false));
          dispatch(getDataView({ id: dataTask.id }));
        },
      })
    );
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const rejectTask = (params) => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "processing" }));
    const dataTask = getState().task.dataView;
    const response = await InstanceApi.post("/pm/tasks/reject", {
      id: dataTask.id,
    });
    dispatch(
      setPopup({
        open: true,
        type: "success",
        message: response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
          dispatch(setModal(false));
          dispatch(getDataView({ id: dataTask.id }));
        },
      })
    );
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const submit = (params) => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "processing" }));
    const formData = getState().task.formData;
    const validate = Validate(formData);

    if (!validate.isValid) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: validate.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
    } else {
      let response = "";
      if (params.formType === "create") {
        response = await InstanceApi.post("/pm/tasks", formData);
        if (response) {
          if (params.fromSubmit === "project") {
            dispatch(
              setPopup({
                open: true,
                type: "success",
                message: response.data.message,
                handleOk: () => {
                  dispatch(setPopup({ open: false }));
                  dispatch(setModal(false));
                  dispatch(getDataTables());
                  dispatch(
                    getDataByProject({ projectId: formData.project_id })
                  );
                },
              })
            );
          } else {
            dispatch(
              setPopup({
                open: true,
                type: "success",
                message: response.data.message,
                handleOk: () => {
                  dispatch(setPopup({ open: false }));
                  dispatch(setModal(false));
                  // dispatch( getDataView({id: response.data.data.id}) )
                  window.location.href = "/pm/tasks/" + response.data.data.id;
                },
              })
            );
          }
        }
      } else {
        response = await InstanceApi.patch(
          "/pm/tasks/" + formData.id,
          formData
        );
        if (response) {
          dispatch(
            setPopup({
              open: true,
              type: "success",
              message: response.data.message,
              handleOk: () => {
                dispatch(setPopup({ open: false }));
                dispatch(setModal(false));
                dispatch(resetFormData());
                dispatch(getDataView({ id: formData.id }));
              },
            })
          );
        }
      }
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const deleteData = (params) => (dispatch) => {
  try {
    dispatch(
      setPopup({
        open: true,
        type: "delete",
        message: "Are you sure to delete this data?",
        handleOk: async () => {
          try {
            const response = await InstanceApi.delete("/pm/tasks/" + params.id);
            if (response) {
              dispatch(
                setPopup({
                  open: true,
                  type: "success",
                  message: response.data.message,
                  handleOk: () => {
                    dispatch(setPopup({ open: false }));
                    window.location.href = "/pm/tasks";
                  },
                })
              );
            }
          } catch (error) {
            dispatch(
              setPopup({
                open: true,
                type: "error",
                message: error.response.data.message,
                handleOk: () => {
                  dispatch(setPopup({ open: false }));
                },
              })
            );
          }
        },
        handleCancel: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const getDataCount = (params) => async (dispatch) => {
  try {
    dispatch(setDataCount(undefined));

    let paramsString = [];
    Object.entries(params).forEach((data) => {
      paramsString.push(data.join("="));
    });

    const response = await InstanceApi.get(
      "/pm/tasks/count?" + paramsString.join("&")
    );
    if (response) {
      const data = response.data;
      dispatch(setDataCount(data));
    }
  } catch (error) {}
};

export const getDataDeadline = (params) => async (dispatch) => {
  try {
    dispatch(setDataDeadline(undefined));

    const response = await InstanceApi.get("/pm/tasks/deadline");
    if (response) {
      dispatch(setDataDeadline(response.data));
    }
  } catch (error) {}
};

export const {
  setModal,
  setModalResponsible,
  setDataCount,
  setDataDeadline,
  setShowTask,
  setIndexDisplay,
  setDataTables,
  setDataOptions,
  setDataByProject,
  setDataView,
  setFormData,
  setFormAssign,
  setDataTemporaryFinishNotes,
  setDataTemporaryApprovalNotes,
  setDataTemporaryAttachment,
} = taskSlice.actions;
export default taskSlice.reducer;
