import React from "react"
import CreatableSelect from 'react-select/creatable'

export default function MultiSelectText(props) {
  const customStyles = {
    control: (base, { isDisabled }) => ({
      ...base,
      "&:hover": { borderColor: "none" },
      "&:focus": { borderColor: "none" },
      borderColor: "#e5e7eb",
      boxShadow: "none",
      borderRadius: "0.375rem",
      backgroundColor: isDisabled ? "#e2e8f0" : "white",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    input: (styles) => ({ ...styles, borderColor: "none" }),
  };

  return (
    <CreatableSelect
      styles={customStyles}
      components={{
        DropdownIndicator: null,
      }}
      isClearable={true}
      isMulti={true}
      menuIsOpen={false}
      placeholder="Type something and press enter..."
      isDisabled={props.disabled}
      {...props}
    />
  );
}