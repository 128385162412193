import React, {useEffect} from 'react'

import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setDataView as setDataViewTask, getDataView } from '../../../stores/prod/prodTaskSlice';
import { setDataByTask as setDataResponsibleByTask } from '../../../stores/prod/prodTaskResponsibleSlice';
import { setDataByTask as setDataObserverByTask } from '../../../stores/prod/prodTaskObserverSlice';
import { setDataByTask as setDataAttachmentByTask } from '../../../stores/prod/prodTaskAttachmentSlice';
import ShowContent from './showContent';

function Show() {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch( setDataViewTask(undefined) )
    dispatch( setDataResponsibleByTask([]) )
    dispatch( setDataObserverByTask([]) )
    dispatch( setDataAttachmentByTask([]) )

    if(!id) {
      navigate('/prod/tasks')
    } else {
      dispatch( getDataView({id: id}) )
    }
  }, [dispatch, navigate, id])

  const handleClickBack = () => {
    navigate('/prod/tasks')
  }

  return (
    <ShowContent taskId={id}  fromSubmit={'task'} onClickBack={handleClickBack} />
  )
}

export default Show