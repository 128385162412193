import React, { useEffect, useState } from "react";
import { Button } from "../../../components/Button";
import ModalForm from "../../../components/ModalForm";

import { useSelector, useDispatch } from "react-redux";
import {
  resetFormData,
  setModal,
  getFormData,
  setFormData,
  submit,
} from "../../../stores/um/userSlice";

function Form(props) {
  const { formType, dataId } = props;
  const dispatch = useDispatch();
  const openPopup = useSelector((state) => state.popup.openPopup);
  const openModal = useSelector((state) => state.user.openModal);
  const formData = useSelector((state) => state.user.formData);
  const [modalTitle, setModalTitle] = useState();

  useEffect(() => {
    dispatch(resetFormData());

    if (formType === "create") {
      setModalTitle("Add User");
    } else {
      setModalTitle("Edit User");
      dispatch(getFormData({ id: dataId }));
    }
  }, [dispatch, formType, dataId]);

  const handleInput = (e) => {
    dispatch(setFormData(e.target));
  };

  return (
    <>
      {!openPopup.open && openModal && (
        <ModalForm
          modalTitle={modalTitle}
          modalSize="small"
          closeModal={() => dispatch(setModal(false))}
        >
          <div className="w-full">
            <div className="form-group">
              <label className="control-label">
                Full Name <span className="text-danger">*</span>
              </label>
              <input
                id="name"
                title="Full Name"
                type="text"
                name="name"
                className="form-control"
                value={formData.name}
                onChange={handleInput}
                required
              />
            </div>
            <div className="form-group">
              <label className="control-label">
                User Name <span className="text-danger">*</span>
              </label>
              <input
                id="username"
                title="User Name"
                type="text"
                name="username"
                className="form-control"
                value={formData.username}
                onChange={handleInput}
                required
              />
            </div>
            <div className="form-group">
              <label className="control-label">
                Password {formType === 'create' && <span className="text-danger">*</span>}
              </label>
              <input
                id="password"
                title="Password"
                type="password"
                name="password"
                className="form-control"
                value={formData.password}
                onChange={handleInput}
                required={formType === 'create' ? true : false}
              />
            </div>
          </div>

          <div className="mt-8 flex gap-2">
            <Button
              title="Save"
              icon="BiSave"
              color="primary"
              onClick={() => dispatch(submit({ formType: formType }))}
            />
            <Button
              title="Cancel"
              icon="BiX"
              color="secondary"
              onClick={() => dispatch(setModal(false))}
            />
          </div>
        </ModalForm>
      )}
    </>
  );
}

export default Form;
