import React from 'react'
import {PageHeader, PageHeaderAction, PageHeaderTitle} from '../../../components/PageHeader'
import {Card, CardBody} from '../../../components/Card'

const LoadingRow = () => {
  const divWidth = ["w-full", "w-2/4", "w-3/4"];

  return (
    <div className="grid grid-cols-6 gap-4">
      <div
        className={`${
          divWidth[Math.floor(Math.random() * divWidth.length)]
        } h-2 loading`}
      ></div>
      <div className="w-full">
        <div className="flex flex-row items-center gap-4">
          <div className="avatar-sm bg-slate-tertiary animate-pulse"></div>
          <div className="flex-1 flex flex-col gap-2">
            <div
              className={`${
                divWidth[Math.floor(Math.random() * divWidth.length)]
              } h-2 loading`}
            ></div>
          </div>
        </div>
      </div>
      <div
        className={`${
          divWidth[Math.floor(Math.random() * divWidth.length)]
        } h-2 loading`}
      ></div>
      <div
        className={`${
          divWidth[Math.floor(Math.random() * divWidth.length)]
        } h-2 loading`}
      ></div>
      <div
        className={`${
          divWidth[Math.floor(Math.random() * divWidth.length)]
        } h-2 loading`}
      ></div>
      <div
        className={`${
          divWidth[Math.floor(Math.random() * divWidth.length)]
        } h-2 loading`}
      ></div>
      <div
        className={`${
          divWidth[Math.floor(Math.random() * divWidth.length)]
        } h-2 loading`}
      ></div>
    </div>
  );
};

function LoadingShow() {
  return (
    <>
      <PageHeader>
        <PageHeaderTitle>
          Project <small>Detail of project</small>
        </PageHeaderTitle>
      </PageHeader>

      <div className="flex md:flex-row gap-6">
        <div className="md:w-3/4">
          <Card>
            <CardBody>
              <div className="space-y-6">
                <div className="w-2/4 h-4 loading"></div>
                <div className="space-y-2">
                  <div className="w-3/4 h-2 loading"></div>
                  <div className="w-2/4 h-2 loading"></div>
                  <div className="w-3/4 h-2 loading"></div>
                  <div className="w-2/4 h-2 loading"></div>
                </div>
              </div>
            </CardBody>
          </Card>
          <div className="h-4"></div>
          <div className="mb-4">
            <div className="flex flex-row items-center gap-2">
              <div className="w-32 h-6 loading"></div>
              <div className="w-32 h-6 loading"></div>
              <div className="w-32 h-6 loading"></div>
            </div>
          </div>
          <Card>
            <CardBody>
              <div className="mb-10 w-full flex gap-2">
                <div className="block w-96 h-6 loading"></div>
                <div className="w-40 h-6 loading"></div>
                <div className="w-40 h-6 loading"></div>
              </div>
              <div className="mb-8 grid grid-cols-6 gap-4">
                <div className="w-full h-4 loading"></div>
                <div className="w-full h-4 loading"></div>
                <div className="w-full h-4 loading"></div>
                <div className="w-full h-4 loading"></div>
                <div className="w-full h-4 loading"></div>
                <div className="w-full h-4 loading"></div>
              </div>
              <div className="mb-10 space-y-6">
                <LoadingRow />
                <LoadingRow />
                <LoadingRow />
                <LoadingRow />
                <LoadingRow />
                <LoadingRow />
                <LoadingRow />
                <LoadingRow />
                <LoadingRow />
                <LoadingRow />
              </div>
              <div className="flex flex-row items-center justify-end gap-2">
                <div className="w-40 h-4 loading"></div>
                <div className="w-24 h-6 loading"></div>
                <div className="w-24 h-6 loading"></div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="md:w-1/4 space-y-4">
          <div className="space-y-2">
            <div className="py-3 px-4 w-full bg-white shadow-lg shadow-shadow rounded-lg">
              <div className="w-3/4 h-3 loading"></div>
            </div>
            <Card>
              <CardBody>
                <div className="space-y-4">
                  <div className="space-y-2">
                    <div className="w-1/4 h-2 loading"></div>
                    <div className="w-2/4 h-2 loading"></div>
                  </div>
                  <div className="space-y-2">
                    <div className="w-1/4 h-2 loading"></div>
                    <div className="w-2/4 h-2 loading"></div>
                  </div>
                  <div className="space-y-2">
                    <div className="w-1/4 h-2 loading"></div>
                    <div className="w-2/4 h-2 loading"></div>
                  </div>
                  <div className="flex flex-row items-center gap-4">
                    <div className="avatar-md bg-slate-tertiary animate-pulse"></div>
                    <div className="w-32 h-2 loading"></div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <Card>
            <CardBody>
              <div className="space-y-6">
                <div className="flex flex-row items-center gap-2 justify-between">
                  <div className="w-1/4 h-4 loading"></div>
                  <div className="w-1/4 h-4 loading"></div>
                </div>
                <div className="mx-auto w-40 h-40 rounded-full bg-slate-tertiary animate-pulse"></div>
                <div className="flex flex-row items-center gap-2 justify-center">
                  <div className="w-1/5 h-2 loading"></div>
                  <div className="w-1/5 h-2 loading"></div>
                  <div className="w-1/5 h-2 loading"></div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default LoadingShow