import { createSlice } from "@reduxjs/toolkit";
import { InstanceApi } from "../../helpers/InstanceApi";
import { setPopup } from "../popupSlice";

export const projectMoRevSlice = createSlice({
  name: "projectMoRev",
  initialState: {
    openModal: false,
    openModalApproval: false,
    dataByProject: undefined,
    dataPendingByProject: undefined,
    dataTemporary: [],
    formValidate: {
      mo_no: { required: true, title: "MO No." },
      mo_value: { required: true, title: "MO Value" },
      spot: { required: true, title: "Spot" },
      product_id: { required: true, title: "Product" },
      station_id: { required: true, title: "Station" },
      program_id: { required: true, title: "Program" },
    },
    formData: {
      id: "",
      project_id: "",
      current_mo_no: "",
      mo_no: "",
      mo_value: "",
      description: "",
      spot: "",
      product_id: "",
      product_name: "",
      station_id: "",
      station_name: "",
      program_id: "",
      program_name: "",
      notes: "",
    },
    formDelete: [],
    formAttachment: [],
  },
  reducers: {
    setModal: (state, action) => {
      state.openModal = action.payload;
    },
    setModalApproval: (state, action) => {
      state.openModalApproval = action.payload;
    },
    setDataByProject: (state, action) => {
      state.dataByProject = action.payload;
    },
    setDataTemporary: (state, action) => {
      state.dataTemporary = action.payload;
    },
    setDataPendingByProject: (state, action) => {
      state.dataPendingByProject = action.payload;
    },
    setFormData: (state, action) => {
      state.formData[action.payload.name] = action.payload.value;
    },
    setFormDelete: (state, action) => {
      state.formDelete = action.payload;
    },
    setFormAttachment: (state, action) => {
      state.formAttachment = action.payload;
    },
  },
});

export const resetFormData = () => async (dispatch, getState) => {
  const formData = getState().projectMoRev.formData;
  Object.keys(formData).forEach((key) => {
    dispatch(setFormData({ name: key, value: "" }));
  });
};

export const getDataByProject = (params) => async (dispatch) => {
  try {
    dispatch(setDataByProject(undefined));
    const response = await InstanceApi.get(
      "/pm/projects/" + params.projectId + "/mo-rev"
    );
    if (response) {
      dispatch(setDataByProject(response.data.data));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(error);
  }
};

export const getDataPendingByProject = (params) => async (dispatch) => {
  try {
    dispatch(setDataPendingByProject(undefined));
    const response = await InstanceApi.get(
      "/pm/projects/" + params.projectId + "/mo-rev-pending"
    );
    if (response) {
      dispatch(setDataPendingByProject(response.data.data));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(error);
  }
};

export const submit = () => async (dispatch, getState) => {
  const dataTemporary = getState().projectMoRev.dataTemporary;
  if (dataTemporary.length > 0) {
    try {
      dispatch(setPopup({ open: true, type: "processing" }));
      const dataProject = getState().project.dataView;
      const formAttachment = getState().projectMoRev.formAttachment;
      const formData = new FormData();
      // formData.append("data[]", dataTemporary);
      // formData.append("delete", formDelete);
      formData.append("project_id", dataProject.id);
      if (formAttachment.length > 0) {
        formAttachment.map((item, _) =>
          formData.append("attachment[]", item.value, item.name)
        );
      }
      if (dataTemporary.length > 0) {
        dataTemporary.map((item, _) =>
          formData.append("mo[]", JSON.stringify(item))
        );
      }

      const response = await InstanceApi.post("/pm/projects/mo-rev", formData);
      if (response) {
        dispatch(
          setPopup({
            open: true,
            type: "success",
            message: response.data.message,
            handleOk: () => {
              dispatch(setPopup({ open: false }));
              window.location.reload();
            },
          })
        );
      }
    } catch (error) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: error.response.data.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
      console.error(error);
    }
  } else {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: "Media Order cannot be empty!",
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const {
  setModal,
  setModalApproval,
  setDataByProject,
  setDataTemporary,
  setDataPendingByProject,
  setFormData,
  setFormAttachment,
  setFormDelete,
} = projectMoRevSlice.actions;
export default projectMoRevSlice.reducer;
