import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PageHeader, PageHeaderTitle } from "../../components/PageHeader";

import { CheckRoleIndex } from "../../helpers/CheckRole";
import {
  Card,
  CardHeader,
  CardTitle,
  CardAction,
  CardBody,
  CardFooter,
} from "../../components/Card";
import { Button } from "../../components/Button";
import { setPopup } from "../../stores/popupSlice";
import { InstanceApi } from "../../helpers/InstanceApi";
import { DownloadFile } from "../../helpers/DownloadFile";
import Select from "../../components/Select";
import MultiSelectText from "../../components/MultiSelectText";

function Index() {
  const pathName = "/reports/logs";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    begin_date: "",
    end_date: "",
  });

  const [dataTable, setDataTable] = useState();

  useEffect(() => {
    if (!CheckRoleIndex(pathName)) {
      navigate("/404");
    }
  }, [dispatch, navigate]);

  const createFormSubmit = () => {
    let formSubmit = {
      begin_date: formData.begin_date,
      end_date: formData.end_date,
    };

    return formSubmit;
  };

  const generateReport = async (e) => {
    e.preventDefault();

    try {
      dispatch(setPopup({ open: true, type: "processing" }));
      const response = await InstanceApi.post(
        "/reports/generate-logs",
        createFormSubmit()
      );
      if (response) {
        dispatch(setPopup({ open: false }));
        setDataTable(response.data.data);
      }
    } catch (err) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: err.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
      console.error(err.response.data.message);
    }
  };

  const exportReport = async (e) => {
    e.preventDefault();

    try {
      dispatch(setPopup({ open: true, type: "processing" }));
      const response = await InstanceApi.post(
        "/reports/export-logs",
        createFormSubmit()
      );
      if (response) {
        dispatch(setPopup({ open: false }));

        dispatch(setPopup({ open: true, type: "download" }));

        const downloadUrl = "/download?file_path=" + response.data.data;
        console.log(downloadUrl);
        DownloadFile(downloadUrl);

        setTimeout(() => {
          dispatch(setPopup({ open: false }));
        }, 3000);
      }
    } catch (err) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: err.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
      console.error(err.response.data.message);
    }
  };

  return (
    <>
      <PageHeader>
        <PageHeaderTitle>Log Report</PageHeaderTitle>
      </PageHeader>
      <Card>
        <CardHeader>
          <CardTitle>Report Filter</CardTitle>
        </CardHeader>
        <CardBody>
          <div className="grid grid-cols-4 items-center gap-4">
            <div className="form-group">
              <label className="control-label">Begin Date</label>
              <input
                id="begin_date"
                title="Begin Date"
                type="date"
                name="begin_date"
                className="form-control"
                value={formData.begin_date}
                onChange={(e) =>
                  setFormData({ ...formData, begin_date: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label className="control-label">End Date</label>
              <input
                id="end_date"
                title="End Date"
                type="date"
                name="end_date"
                className="form-control"
                value={formData.end_date}
                onChange={(e) =>
                  setFormData({ ...formData, end_date: e.target.value })
                }
              />
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <div className="flex flex-row items-center gap-2">
            <>
              <Button
                title="Generate Report"
                icon="BiCog"
                color="primary"
                onClick={generateReport}
              />
            </>
          </div>
        </CardFooter>
      </Card>
      <div className="mt-4"></div>
      {dataTable && (
        <Card>
          <CardHeader>
            <CardAction>
              <Button
                title="Download Report"
                icon="BiDownload"
                color="primary"
                onClick={exportReport}
              />
            </CardAction>
          </CardHeader>
          <CardBody>
            <div className="w-full max-h-[700px] overflow-auto">
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ minWidth: "50px" }}>NO</th>
                    <th style={{ minWidth: "150px" }}>LOG TIME</th>
                    <th style={{ minWidth: "200px" }}>MODULE NAME</th>
                    <th style={{ minWidth: "200px" }}>USER NAME</th>
                    <th style={{ minWidth: "200px" }}>PROCESS NAME</th>
                    <th style={{ minWidth: "400px" }}>DESCRIPTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {dataTable &&
                    dataTable.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.log_time}</td>
                        <td>{item.model_name}</td>
                        <td>{item.user_name}</td>
                        <td>{item.process_name}</td>
                        <td>{item.description}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
}

export default Index;
