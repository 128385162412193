import { createSlice } from "@reduxjs/toolkit";
import { InstanceApi } from '../../helpers/InstanceApi';
import { Validate } from "../../helpers/Validate";
import { setPopup } from "../popupSlice";

export const taskResponsibleSlice = createSlice({
    name: 'taskResponsible',
    initialState: {
        openModal: false,
        dataByTask: [],
        formData: {
            id: '',
            task_id: '',
            employee_id: '',
        }
    },
    reducers: {
        setModal: (state, action) => {
            state.openModal = action.payload
        },
        setDataByTask: (state, action) => {
            state.dataByTask = action.payload
        },
        setFormData: (state, action) => {
            state.formData[action.payload.name] = action.payload.value
        }
    }
})

export const resetFormData = () => async (dispatch, getState) => {
    const formData = getState().taskResponsible.formData
    Object.keys(formData).forEach(key => {
        dispatch( setFormData({name: key, value: ''}) )
    });
}

export const getDataByTask = (params) => async (dispatch) => {
    try {
        const response = await InstanceApi.get('/pm/tasks/'+params.taskId+'/responsible')
        if(response){
            dispatch( setDataByTask(response.data.data) )
        }
    } catch (error) { 
        dispatch( setPopup({
            open: true, 
            type: 'error', 
            message: error.message,
            handleOk: () => {
                dispatch(setPopup({open: false}))
            } 
        }))
        console.error(error)
    }
}

export const submit = () => async (dispatch, getState) => {
    try {
        dispatch( setPopup({open: true, type: 'processing'}) )
        const dataResponsible = getState().taskResponsible.formData;
        const validate = Validate(dataResponsible)

        if(!validate.isValid){
            dispatch(
                setPopup({
                    open: true, 
                    type: 'error',
                    message: validate.message, 
                    handleOk: () => {
                        dispatch(setPopup({open: false}))
                    } 
                })
            )
        } else {
            const response = await InstanceApi.post(
              "/pm/tasks/responsible",
              dataResponsible
            );
            if(response){
                dispatch(
                    setPopup({
                        open: true, 
                        type: 'success',
                        message: response.data.message, 
                        handleOk: () => {
                            dispatch( setPopup({open: false}) )
                            dispatch( setModal(false) )
                            dispatch( getDataByTask({taskId: dataResponsible.task_id}) )
                        } 
                    })
                )
            }      
        }
    } catch (error) { 
        dispatch( setPopup({
            open: true, 
            type: 'error', 
            message: error.message,
            handleOk: () => {
                dispatch(setPopup({open: false}))
            } 
        }))
        console.error(error)
    }
}

export const deleteData = (params) => (dispatch, getState) => {
    try {
        const dataTask = getState().task.dataView;
        dispatch( setPopup({
            open: true,
            type: 'confirm',
            message: 'Are you sure to delete this from responsible?',
            handleOk: async () => {
                dispatch( setPopup({open: true, type: 'processing'}) )
                try {
                    const response = await InstanceApi.delete("/pm/tasks/responsible/"+params.id)
                    dispatch(
                        setPopup({
                            open: true, 
                            type: 'success',
                            message: response.data.message, 
                            handleOk: () => {
                                dispatch( setPopup({open: false}) )
                                dispatch( getDataByTask({taskId: dataTask.id}) )
                            } 
                        })
                    )                    
                } catch (error) {
                    dispatch( setPopup({
                        open: true, 
                        type: 'error', 
                        message: error.message,
                        handleOk: () => {
                            dispatch(setPopup({open: false}))
                        } 
                    }))
                    console.error(error)
                }
            },
            handleCancel: () => {
                dispatch( setPopup({open: false}) )
            }
        }))
    } catch (error) {
        dispatch( setPopup({
            open: true, 
            type: 'error', 
            message: error.message,
            handleOk: () => {
                dispatch(setPopup({open: false}))
            } 
        }))
    }
}

export const { setModal, setDataByTask, setFormData } = taskResponsibleSlice.actions
export default taskResponsibleSlice.reducer