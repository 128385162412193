import React, { useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardAction,
} from "../../../components/Card";
import { Button } from "../../../components/Button";
import { BiTrash } from "react-icons/bi";
import ModalForm from "../../../components/ModalForm";
import {
  setModal,
  setDataTemporary,
} from "../../../stores/pm/projectAttachmentSlice";
import { useDispatch, useSelector } from "react-redux";

export default function TabFormAttachment(props) {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);
  const [file, setFile] = useState();
  const dataTemporary = useSelector(
    (state) => state.projectAttachment.dataTemporary
  );
  const openModal = useSelector((state) => state.projectAttachment.openModal);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(
      setDataTemporary([...dataTemporary, { name: file.name, value: file }])
    );

    dispatch(setModal(false));
    setFile(undefined);
  };

  const deleteData = (deleteIndex) => {
    let data = dataTemporary.filter((row, index) => {
      if (deleteIndex !== index) {
        return true;
      }
    });
    dispatch(setDataTemporary(data));
  };

  return (
    <>
      {openModal && (
        <ModalForm
          modalTitle="Upload Attachment"
          modalSize="medium"
          closeModal={() => dispatch(setModal(false))}
        >
          <form onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
            <div
              className={`modal-upload-box ${
                dragActive ? "border-slate-800" : ""
              }`}
            >
              <div className="body">
                <input
                  id="input-file-upload"
                  type="file"
                  className="hidden"
                  ref={inputRef}
                  onChange={handleChange}
                />
                <p>
                  Drag and drop your file here or{" "}
                  <span
                    className="cursor-pointer underline text-primary-hover"
                    onClick={onButtonClick}
                  >
                    browse
                  </span>
                </p>
                {file && <p>{file.name}</p>}
              </div>
              <div className="mt-6 flex flex-row gap-2 items-center justify-center">
                {file ? (
                  <Button
                    title="Upload"
                    icon="BiUpload"
                    color="primary"
                    onClick={handleSubmit}
                  />
                ) : (
                  <Button title="Upload" icon="BiUpload" color="disabled" />
                )}
              </div>

              {dragActive && (
                <div
                  className="absolute w-full h-full rounded top-0 right-0 left-0 bottom-0"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
            </div>
          </form>
        </ModalForm>
      )}

      <Card>
        <CardHeader>
          <CardTitle>Attachments</CardTitle>
          <CardAction>
            <Button
              title="Add"
              icon="BiPlus"
              size="sm"
              color="primary"
              onClick={() => dispatch(setModal(true))}
            />
          </CardAction>
        </CardHeader>
        <CardBody>
          <p className="mb-2">
            <span className="w-3 h-3 mr-2 bg-danger inline-block rounded-full"></span>{" "}
            All attachment in this table will be stored in the project. Please
            delete unwanted attachment or add attachment if needed.
          </p>
          <table className="table">
            <thead>
              <tr>
                <th style={{ width: "5%" }}>No.</th>
                <th>Files</th>
                <th style={{ width: "5%" }}>Remove</th>
              </tr>
            </thead>
            <tbody>
              {dataTemporary.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>
                    <span
                      className="text-info cursor-pointer"
                      onClick={() => deleteData(index)}
                    >
                      <BiTrash size="22" />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </>
  );
}
