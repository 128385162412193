import React, { useEffect } from "react";
import { PageHeader, PageHeaderTitle } from "../../../components/PageHeader";
import { CheckRoleIndex } from "../../../helpers/CheckRole";
import { useNavigate } from "react-router-dom";
import IndexTable from "./indexTable";

function Index() {
  const pathName = "/pm/tasks";
  const navigate = useNavigate();

  useEffect(() => {
    if (!CheckRoleIndex(pathName)) {
      navigate("/404");
    }
  }, [navigate]);

  return (
    <>
      <PageHeader>
        <PageHeaderTitle>Tasks</PageHeaderTitle>
      </PageHeader>

      <IndexTable />
    </>
  );
}

export default Index;
