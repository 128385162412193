import React, { useState, useRef, useEffect } from "react";
import { Button } from "../../../components/Button";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../components/Card";
import { useDispatch, useSelector } from "react-redux";
import { setPopup } from "../../../stores/popupSlice";
import { CardTitle } from "../../../components/Card";
import { CheckRoleAction } from "../../../helpers/CheckRole";
import { InstanceApi } from "../../../helpers/InstanceApi";
import ModalForm from "../../../components/ModalForm";
import { BiEditAlt, BiTrash } from "react-icons/bi";
import Inputmask from "inputmask";
import Select from "../../../components/Select";
import { Validate } from "../../../helpers/Validate";
import { getDataOptions as getDataOptionStation } from "../../../stores/master/stationSlice";
import { getDataOptions as getDataOptionProgram } from "../../../stores/master/programSlice";
import { getDataOptions as getDataOptionProduct } from "../../../stores/master/productSlice";
import {
  setModal as setModalMo,
  setDataTemporary as setDataTemporaryMo,
  setFormData as setFormDataMo,
  resetFormData as resetFormDataMo,
  getDataByProject as getDataByProjectMo,
} from "../../../stores/pm/projectMoSlice";

import { setDataTemporaryFinishNotes, setDataTemporaryAttachment } from "../../../stores/pm/taskSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function TabFinish() {
  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const dataTask = useSelector((state) => state.task.dataView);
  const [modalAttachment, setModalAttachment] = useState(false);
  const [finishNotes, setFinishNotes] = useState("");

  const openModalMo = useSelector((state) => state.projectMo.openModal);
  const dataOptionStation = useSelector((state) => state.station.dataOptions);
  const dataOptionProgram = useSelector((state) => state.program.dataOptions);
  const dataOptionProduct = useSelector((state) => state.product.dataOptions);
  const dataTemporaryMo = useSelector((state) => state.projectMo.dataTemporary);
  const dataTemporaryFinishNotes = useSelector((state) => state.task.dataTemporaryFinishNotes);
  const dataTemporaryAttachment = useSelector((state) => state.task.dataTemporaryAttachment);
  const formDataMo = useSelector((state) => state.projectMo.formData);
  const formValidateMo = useSelector((state) => state.projectMo.formValidate);
  const [formTypeMo, setFormTypeMo] = useState("create");
  const [indexEditMo, setIndexEditMo] = useState();
  const editorToolbar = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };

  const editorFormat = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];

  // attachment
  const inputRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);
  const [file, setFile] = useState();

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const saveAttachment = () => {
    dispatch(setDataTemporaryAttachment([
      ...dataTemporaryAttachment,
      {
        name: file.name,
        value: file,
      },
    ]));

    setModalAttachment(false);
    setFile(undefined);
  };

  const deleteAttachment = (deleteIndex) => {
    let data = dataTemporaryAttachment.filter((_, index) => index !== deleteIndex);
    dispatch(setDataTemporaryAttachment(data));
  };

  // Item
  useEffect(() => {
    dispatch(getDataByProjectMo({ projectId: dataTask.project_id }));
  }, [dispatch, dataTask.project_id]);

  const handleInputMo = (e) => {
    if (e.target.name === "mo_value") {
      const value = Number(e.target.value.toString().replace(/\./g, ""));
      dispatch(setFormDataMo({ name: e.target.name, value: value }));
    } else {
      dispatch(setFormDataMo({ name: e.target.name, value: e.target.value }));
    }
  };

  const checkMoNo = async (mo_no, type) => {
    const errorMsg = "Item No "+mo_no+" already exists!";
    // try {
      // const response = await InstanceApi.get(
      //   "/pm/projects/mo/check?mo_no=" + mo_no
      // );
      // if (response) {
        let filtered = [];
        if (type === "create") {
          filtered = [...dataTemporaryMo].filter(
            (data) =>
              data.mo_no.toString().toLowerCase().indexOf(mo_no.toLowerCase()) >
              -1
          );
        } else {
          filtered = [...dataTemporaryMo].filter(
            (_, index) => index !== indexEditMo
          );

          filtered = [...filtered].filter(
            (data) =>
              data.mo_no.toString().toLowerCase().indexOf(mo_no.toLowerCase()) >
              -1
          );
        }

        if (filtered.length > 0) {
          dispatch(
            setPopup({
              open: true,
              type: "error",
              message: errorMsg,
              handleOk: () => {
                dispatch(setPopup({ open: false }));
              },
            })
          );
        } else {
          if (type === "create") {
            dispatch(setDataTemporaryMo([...dataTemporaryMo, formDataMo]));
          } else {
            const temp = [...dataTemporaryMo];
            temp[indexEditMo] = formDataMo;

            dispatch(setDataTemporaryMo(temp));
          }
          dispatch(setModalMo(false));
        }
      // }
    // } catch (error) {
    //   dispatch(
    //     setPopup({
    //       open: true,
    //       type: "error",
    //       message: errorMsg,
    //       handleOk: () => {
    //         dispatch(setPopup({ open: false }));
    //       },
    //     })
    //   );
    // }
  };

  const saveDataMo = (type) => {
    const validate = Validate(formValidateMo, formDataMo);

    if (!validate.isValid) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: validate.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
    } else {
      // check available mo no
      checkMoNo(formDataMo.mo_no, type);
    }
  };

  useEffect(() => {
    if (openModalMo) {
      if (formTypeMo === "create") {
        dispatch(resetFormDataMo());
      }
      dispatch(getDataOptionStation());
      dispatch(getDataOptionProduct({ clientId: dataTask.client_id }));

      let inputMoValue = new Inputmask({
        alias: "currency",
        prefix: "",
        groupSeparator: ".",
        radixPoint: ",",
        digits: 0,
        allowMinus: false,
        allowPlus: true,
        placeholder: "0",
      });
      inputMoValue.mask(document.getElementById("mo_value"));

      let inputSpot = new Inputmask({
        alias: "numeric",
        digits: 0,
        allowMinus: false,
        allowPlus: true,
        placeholder: "0",
      });
      inputSpot.mask(document.getElementById("spot"));
    }
  }, [dispatch, formTypeMo, openModalMo]);

  useEffect(() => {
    dispatch(setFormDataMo({ ...formDataMo, program_id: "" }));
    dispatch(getDataOptionProgram({ stationId: formDataMo.station_id }));
  }, [dispatch, formDataMo.station_id]);

  const addDataMo = () => {
    setFormTypeMo("create");
    dispatch(setModalMo(true));
  };

  const editDataMo = (editIndex) => {
    dispatch(resetFormDataMo());
    setIndexEditMo(editIndex);
    setFormTypeMo("edit");

    const temp = [...dataTemporaryMo];
    const item = temp[editIndex];
    Object.entries(item).forEach((row) => {
      dispatch(setFormDataMo({ name: row[0], value: row[1] }));
    });
    dispatch(setModalMo(true));
  };

  const deleteDataMo = (deleteIndex) => {
    let data = dataTemporaryMo.filter((_, index) => index !== deleteIndex);
    dispatch(setDataTemporaryMo(data));
  };

  // submit form
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (dataTask.alias === "media_order") {
      if (dataTemporaryMo.length > 0) {
        processSubmit();
      } else {
        dispatch(
          setPopup({
            open: true,
            type: "error",
            message: "Item is required!",
            handleOk: () => {
              dispatch(setPopup({ open: false }));
            },
          })
        );
      }
    } else {
      processSubmit();
    }
  };

  const processSubmit = async () => {
    dispatch(setPopup({ open: true, type: "processing" }));
    const formData = new FormData();

    // Update the formData object
    formData.append("notes", dataTemporaryFinishNotes);
    formData.append("task_id", dataTask.id);
    if (dataTemporaryAttachment.length > 0) {
      dataTemporaryAttachment.map((item, _) =>
        formData.append("attachment[]", item.value, item.name)
      );
    }
    if (dataTemporaryMo.length > 0) {
      dataTemporaryMo.map((item, _) =>
        formData.append("mo[]", JSON.stringify(item))
      );
    }

    try {
      const response = await InstanceApi.post(`/pm/tasks/finish`, formData);
      if (response) {
        dispatch(
          setPopup({
            open: true,
            type: "success",
            message: response.data.message,
            handleOk: () => {
              dispatch(setPopup({ open: false }));
              window.location.reload();
            },
          })
        );
      }
    } catch (error) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: error.response.data.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
    }
  };

  return (
    <>
      {modalAttachment && (
        <ModalForm
          modalTitle="Upload Attachment"
          modalSize="medium"
          closeModal={() => setModalAttachment(false)}
        >
          <form onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
            <div
              className={`modal-upload-box ${
                dragActive ? "border-slate-800" : ""
              }`}
            >
              <div className="body">
                <input
                  id="input-file-upload"
                  type="file"
                  className="hidden"
                  ref={inputRef}
                  onChange={handleChange}
                />
                <p>
                  Drag and drop your file here or{" "}
                  <span
                    className="cursor-pointer underline text-primary-hover"
                    onClick={onButtonClick}
                  >
                    browse
                  </span>
                </p>
                {file && <p>{file.name}</p>}
              </div>
              <div className="mt-6 flex flex-row gap-2 items-center justify-center">
                {file ? (
                  <Button
                    title="Upload"
                    icon="BiUpload"
                    color="primary"
                    onClick={saveAttachment}
                  />
                ) : (
                  <Button title="Upload" icon="BiUpload" color="disabled" />
                )}
              </div>

              {dragActive && (
                <div
                  className="absolute w-full h-full rounded top-0 right-0 left-0 bottom-0"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
            </div>
          </form>
        </ModalForm>
      )}

      {openModalMo && (
        <ModalForm
          modalTitle={formTypeMo === "create" ? "Add Item" : "Edit Item"}
          modalSize="medium"
          closeModal={() => dispatch(setModalMo(false))}
        >
          <div className="w-full">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="form-group">
                <label className="control-label">
                  Item No. <span className="text-danger">*</span>
                </label>
                <input
                  id="mo_no"
                  title="Item No."
                  type="text"
                  name="mo_no"
                  className="form-control"
                  value={formDataMo.mo_no}
                  onChange={handleInputMo}
                  required
                />
              </div>
              <div className="form-group">
                <label className="control-label">
                  Item Value <span className="text-danger">*</span>
                </label>
                <input
                  id="mo_value"
                  title="Item Value"
                  type="text"
                  name="mo_value"
                  className="form-control"
                  value={formDataMo.mo_value}
                  onChange={handleInputMo}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label">
                Item Description <span className="text-danger">*</span>
              </label>
              <input
                id="description"
                title="Item Description"
                type="text"
                name="description"
                className="form-control"
                value={formDataMo.description}
                onChange={handleInputMo}
                required
              />
            </div>
            <div className="form-group">
              <label className="control-label">
                Product <span className="text-danger">*</span>
              </label>
              <Select
                id="product_id"
                title="Product"
                name="product_id"
                placeholder={"Choose..."}
                value={dataOptionProduct.filter(
                  (option) => option.value === formDataMo.product_id
                )}
                onChange={(e) => {
                  dispatch(
                    setFormDataMo({ name: "product_id", value: e?.value })
                  );
                  dispatch(
                    setFormDataMo({ name: "product_name", value: e?.label })
                  );
                }}
                options={dataOptionProduct}
                required={true}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
              <div className="col-span-1 form-group">
                <label className="control-label">
                  Spot <span className="text-danger">*</span>
                </label>
                <input
                  id="spot"
                  title="Spot"
                  type="text"
                  name="spot"
                  className="form-control"
                  value={formDataMo.spot}
                  onChange={handleInputMo}
                  required
                />
              </div>

              <div className="col-span-2 form-group">
                <label className="control-label">
                  Media Channel <span className="text-danger">*</span>
                </label>
                <Select
                  id="station_id"
                  title="Media Channel"
                  name="station_id"
                  placeholder={"Choose..."}
                  value={dataOptionStation.filter(
                    (option) => option.value === formDataMo.station_id
                  )}
                  onChange={(e) => {
                    dispatch(
                      setFormDataMo({ name: "station_id", value: e?.value })
                    );
                    dispatch(
                      setFormDataMo({ name: "station_name", value: e?.label })
                    );
                  }}
                  options={dataOptionStation}
                  required={true}
                />
              </div>
              <div className="col-span-2 form-group">
                <label className="control-label">
                  Program <span className="text-danger">*</span>
                </label>
                <Select
                  id="program_id"
                  title="Program"
                  name="program_id"
                  placeholder={"Choose..."}
                  value={dataOptionProgram.filter(
                    (option) => option.value === formDataMo.program_id
                  )}
                  onChange={(e) => {
                    dispatch(
                      setFormDataMo({ name: "program_id", value: e?.value })
                    );
                    dispatch(
                      setFormDataMo({ name: "program_name", value: e?.label })
                    );
                  }}
                  options={dataOptionProgram}
                  required={true}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label">Notes</label>
              <textarea
                id="notes"
                title="Notes"
                name="notes"
                className="form-control h-60"
                value={formDataMo.notes}
                onChange={handleInputMo}
              />
            </div>
          </div>

          <div className="mt-8 flex gap-2">
            <Button
              title="Save"
              icon="BiCheck"
              color="primary"
              onClick={() => saveDataMo(formTypeMo)}
            />
            <Button
              title="Cancel"
              icon="BiX"
              color="secondary"
              onClick={() => dispatch(setModalMo(false))}
            />
          </div>
        </ModalForm>
      )}

      <Card>
        <CardHeader>
          <CardTitle>Finish Task</CardTitle>
        </CardHeader>

        <CardBody>
          <div className="form-group">
            <label className="control-label">Finish Notes</label>
            <div className="form-control h-60">
              <ReactQuill
                id="notes"
                title="Finish Notes"
                name="notes"
                className="form-control h-48"
                value={dataTemporaryFinishNotes}
                onChange={(e) =>
                  dispatch(setDataTemporaryFinishNotes(e))
                }
                required={true}
                modules={editorToolbar}
                formats={editorFormat}
              />
            </div>
          </div>
          <div className="mt-8 form-group">
            <div className="mb-2 flex flex-row items-center justify-between gap-4">
              <label className="control-label">Attachments</label>
              <Button
                title="Add Attachment"
                icon="BiPlus"
                size="sm"
                color="primary"
                onClick={() => setModalAttachment(true)}
              />
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: "5%" }}>No.</th>
                  <th>Files</th>
                  <th style={{ width: "5%" }}>Remove</th>
                </tr>
              </thead>
              <tbody>
                {dataTemporaryAttachment.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>
                      <span
                        className="btn-action"
                        onClick={() => deleteAttachment(index)}
                      >
                        <BiTrash size="22" />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {dataTask.alias === "media_order" && (
            <div className="mt-8 form-group">
              <div className="mb-2 flex flex-row items-center justify-between gap-4">
                <label className="control-label">
                  Item <span className="text-danger">*</span>
                </label>
                <Button
                  title="Add Item"
                  icon="BiPlus"
                  size="sm"
                  color="primary"
                  onClick={addDataMo}
                />
              </div>
              <p className="mb-2">
                <span className="w-3 h-3 mr-2 bg-danger inline-block rounded-full"></span>{" "}
                All item in this table will be stored in the project. Please
                delete unwanted item or add item if needed.
              </p>
              <div className="flex flex-col gap-2">
                {dataTemporaryMo.map((item, index) => (
                  <div key={index} className="flex flex-row gap-2">
                    <div className="flex-1 p-4 border border-slate-tertiary rounded-md hover:bg-slate-tertiary transition duration-200">
                      <div className="flex flex-row items-start justify-between gap-4">
                        <div className="flex-1 font-semibold">
                          Item No. : {item.mo_no}
                        </div>
                        <div className="flex flex-row items-center gap-2">
                          <div className="p-2 font-semibold border border-slate-tertiary rounded-md">
                            {(CheckRoleAction(
                              "/pm/projects",
                              "view_mo_value"
                            ) ||
                              user.reference_id === dataTask.responsible_id) &&
                              Number(
                                item.mo_value.toString().replace(/\./g, "")
                              ).toLocaleString("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                maximumFractionDigits: 0,
                              })}
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 grid grid-cols-8 gap-4">
                        <div className="text-sm col-span-8">
                          <span className="mb-2 block text-text-secondary">
                            Item Description
                          </span>
                          <span className="block">{item.description}</span>
                        </div>
                        <div className="text-sm col-span-2">
                          <span className="mb-2 block text-text-secondary">
                            Product
                          </span>
                          <span className="block">{item.product_name}</span>
                        </div>
                        <div className="text-sm col-span-1">
                          <span className="mb-2 block text-text-secondary">
                            Spot
                          </span>
                          <span className="block">{item.spot}</span>
                        </div>
                        <div className="text-sm col-span-2">
                          <span className="mb-2 block text-text-secondary">
                            Program
                          </span>
                          <span className="block">
                            {item.program_name} @ {item.station_name}
                          </span>
                        </div>
                        <div className="text-sm col-span-3">
                          <span className="mb-2 block text-text-secondary">
                            Notes
                          </span>
                          <span className="block">{item.notes}</span>
                        </div>
                      </div>
                    </div>
                    <div className="flex-none p-2 flex flex-col items-center justify-start gap-2">
                      <span
                        className="btn-action"
                        onClick={() => editDataMo(index)}
                      >
                        <BiEditAlt size="22" />
                      </span>
                      <span
                        className="btn-action"
                        onClick={() => deleteDataMo(index)}
                      >
                        <BiTrash size="22" />
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </CardBody>
        <CardFooter>
          {dataTask.status === "In Progress" &&
            user.reference_id === dataTask.responsible_id &&
            CheckRoleAction("/pm/tasks", "finish") && (
              <div className="mt-8">
                <Button
                  title="Finish"
                  icon="BiCheck"
                  color="primary"
                  onClick={handleSubmit}
                />
              </div>
            )}
        </CardFooter>
      </Card>
    </>
  );
}

export default TabFinish;
