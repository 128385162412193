import { createSlice } from "@reduxjs/toolkit";
import { InstanceApi } from "../../helpers/InstanceApi";
import { Validate } from "../../helpers/Validate";
import { setPopup } from "../popupSlice";
import { setShowTask } from "./prodTaskSlice";

export const prodProjectSlice = createSlice({
  name: "prodProject",
  initialState: {
    openModal: false,
    modalAddTask: false,
    dataCount: undefined,
    dataDeadline: undefined,
    dataTables: undefined,
    dataOptions: [],
    dataView: undefined,
    formValidate: {
      project_name: { required: true, title: "Project Name" },
      descriptions: { required: true, title: "Description" },
    },
    formData: {
      id: "",
      project_id: "",
      project_mo_id: "",
      project_no: "",
      project_name: "",
      descriptions: "",
      owner_id: "",
      status: "",
    },
    formTask: [],
    rowTask: {
      task_id: "",
      task_duration: "",
    },
    dataMo: undefined,
  },
  reducers: {
    setModal: (state, action) => {
      state.openModal = action.payload;
    },
    setModalAddTask: (state, action) => {
      state.modalAddTask = action.payload;
    },
    setDataTables: (state, action) => {
      state.dataTables = action.payload;
    },
    setDataOptions: (state, action) => {
      state.dataOptions = action.payload;
    },
    setDataView: (state, action) => {
      state.dataView = action.payload;
    },
    setFormData: (state, action) => {
      state.formData[action.payload.name] = action.payload.value;
    },
    setDataCount: (state, action) => {
      state.dataCount = action.payload;
    },
    setDataDeadline: (state, action) => {
      state.dataDeadline = action.payload;
    },
    setFormTask: (state, action) => {
      state.formTask = action.payload;
    },
    setDataMo: (state, action) => {
      state.dataMo = action.payload;
    },
  },
});

export const resetFormData = () => async (dispatch, getState) => {
  const formData = getState().prodProject.formData;
  Object.keys(formData).forEach((key) => {
    dispatch(setFormData({ name: key, value: "" }));
  });
};

export const getDataTables = (params) => async (dispatch) => {
  try {
    dispatch(setDataTables(undefined));
    dispatch(setPopup({ open: true, type: "loading" }));

    const response = await InstanceApi.get("/prod/projects", {params: params});
    if (response) {
      dispatch(setDataTables(response.data.data));
      dispatch(setDataView(undefined));
      dispatch(setPopup({ open: false }));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const getDataOptions = () => async (dispatch) => {
  try {
    const response = await InstanceApi.get("/prod/projects");
    if (response) {
      const data = response.data.data.map((item) => {
        return {
          value: item.id,
          label: `[#${item.project_no}] ${item.project_name}`,
        };
      });

      dispatch(setDataOptions(data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getDataView = (params) => async (dispatch) => {
  try {
    dispatch(setPopup({ open: true, type: "loading" }));
    const response = await InstanceApi.get("/prod/projects/" + params.id);
    if (response) {
      if (response.data.data) {
        dispatch(setDataView(response.data.data));
        dispatch(setShowTask(false));
        dispatch(setPopup({ open: false }));
      } else {
        window.location.href = "/404";
      }
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const getFormData = (params) => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "loading" }));

    const response = await InstanceApi.get("/prod/projects/" + params.id);
    if (response) {
      const data = response.data.data;
      const formData = getState().project.formData;

      Object.keys(formData).forEach((key) => {
        dispatch(setFormData({ name: key, value: data?.[key] }));
      });

      dispatch(setPopup({ open: false }));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const finishProject = () => async (dispatch, getState) => {
  try {
    dispatch(
      setPopup({
        open: true,
        type: "confirm",
        message: "Are you sure to finish this project?",
        handleOk: async () => {
          try {
            dispatch(setPopup({ open: true, type: "processing" }));

            const dataProject = getState().prodProject.dataView;
            const response = await InstanceApi.post("/prod/projects/finish", {
              id: dataProject.id,
            });

            if (response) {
              dispatch(
                setPopup({
                  open: true,
                  type: "success",
                  message: response.data.message,
                  handleOk: () => {
                    dispatch(setPopup({ open: false }));
                    dispatch(setModal(false));
                    dispatch(getDataView({ id: dataProject.id }));
                    window.location.reload();
                  },
                })
              );
            }
          } catch (error) {
            dispatch(
              setPopup({
                open: true,
                type: "error",
                message: error.message,
                handleOk: () => {
                  dispatch(setPopup({ open: false }));
                },
              })
            );
          }
        },
        handleCancel: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};
export const cancelProject = () => async (dispatch, getState) => {
  try {
    dispatch(
      setPopup({
        open: true,
        type: "confirm",
        message: "Are you sure to cancel this project?",
        handleOk: async () => {
          try {
            dispatch(setPopup({ open: true, type: "processing" }));

            const dataProject = getState().prodProject.dataView;
            const response = await InstanceApi.post("/prod/projects/cancel", {
              id: dataProject.id,
            });

            if (response) {
              dispatch(
                setPopup({
                  open: true,
                  type: "success",
                  message: response.data.message,
                  handleOk: () => {
                    dispatch(setPopup({ open: false }));
                    dispatch(setModal(false));
                    dispatch(getDataView({ id: dataProject.id }));
                  },
                })
              );
            }
          } catch (error) {
            dispatch(
              setPopup({
                open: true,
                type: "error",
                message: error.message,
                handleOk: () => {
                  dispatch(setPopup({ open: false }));
                },
              })
            );
          }
        },
        handleCancel: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const submit = () => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "processing" }));
    const formValidate = getState().prodProject.formValidate;
    const formData = getState().prodProject.formData;
    const formTask = getState().prodProject.formTask;
    const validate = Validate(formValidate, formData);

    const submitForm = new FormData();
    submitForm.append("project", JSON.stringify(formData));
    // submitForm.append("tasks", JSON.parse(formTask));
    if (formTask.length > 0) {
      formTask.map((item, _) =>
        submitForm.append("tasks[]", JSON.stringify(item))
      );
    }

    if (!validate.isValid) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: validate.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
    } else {
      let response = await InstanceApi.post("/prod/projects", submitForm);

      if (response) {
        dispatch(
          setPopup({
            open: true,
            type: "success",
            message: response.data.message,
            handleOk: () => {
              dispatch(setPopup({ open: false }));
              dispatch(setModal(false));
              dispatch(resetFormData());
              window.location.href =
                "/prod/projects/view/" + response.data.data.id;
            },
          })
        );
      }
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const deleteData = (params) => (dispatch) => {
  try {
    dispatch(
      setPopup({
        open: true,
        type: "delete",
        message: "Are you sure to delete this data?",
        handleOk: async () => {
          try {
            dispatch(setPopup({ open: true, type: "processing" }));

            const response = await InstanceApi.delete(
              "/prod/projects/" + params.id
            );
            if (response) {
              dispatch(
                setPopup({
                  open: true,
                  type: "success",
                  message: response.data.message,
                  handleOk: () => {
                    dispatch(setPopup({ open: false }));
                    window.location.href = "/prod/projects";
                  },
                })
              );
            }
          } catch (error) {
            dispatch(
              setPopup({
                open: true,
                type: "error",
                message: error.message,
                handleOk: () => {
                  dispatch(setPopup({ open: false }));
                },
              })
            );
          }
        },
        handleCancel: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  } catch (err) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: err.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const getDataCount = (params) => async (dispatch) => {
  try {
    dispatch(setDataCount(undefined));

    let paramsString = [];
    Object.entries(params).forEach((data) => {
      paramsString.push(data.join("="));
    });

    const response = await InstanceApi.get(
      "/prod/projects/count?" + paramsString.join("&")
    );
    if (response) {
      const data = response.data;
      dispatch(setDataCount(data));
    }
  } catch (error) {}
};

export const getDataDeadline = () => async (dispatch) => {
  try {
    dispatch(setDataDeadline(undefined));

    const response = await InstanceApi.get("/prod/projects/deadline");
    if (response) {
      dispatch(setDataDeadline(response.data));
    }
  } catch (error) {}
};

export const getDataMo = (params) => async (dispatch) => {
  dispatch(setDataMo(undefined)); 
  const response = await InstanceApi.get("/prod/projects/mo/" + params.id);
  if (response) {
    if (response.data.data) {
      dispatch(setDataMo(response.data.data));        
    }
  }
};

export const {
  setModal,
  setModalAddTask,
  setDataCount,
  setDataDeadline,
  setDataTables,
  setDataOptions,
  setDataView,
  setFormData,
  setFormTask,
  setDataMo,
} = prodProjectSlice.actions;
export default prodProjectSlice.reducer;
