// export const apiUrl = 'http://192.168.1.25/adlink_pms_api/public/api'
// export const apiUrl = "http://192.168.30.4:8083/api";
// export const apiUrl = "http://localhost:8000/api";
// export const apiUrl = "http://localhost:8003/api";
// export const apiUrl = "http://adlink_pms_api.test/api";
export const apiUrl = "https://adlink-pms-api.tyntech.net/api";
// export const apiUrl = "http://adlinksid.synology.me:5680/api";
// export const apiUrl = "http://adlinksid.synology.me:5681/api";

export const editorToolbar = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
};

export const editorFormat = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];
