import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/Button";
import {
  PageHeader,
  PageHeaderAction,
  PageHeaderTitle,
} from "../../../components/PageHeader";
import { CheckRoleAction, CheckRoleIndex } from "../../../helpers/CheckRole";

import {
  setModal,
} from "../../../stores/master/employeeSlice";
import Form from "./form";
import { setPopup } from "../../../stores/popupSlice";
import { InstanceApi } from "../../../helpers/InstanceApi";
import ModalForm from "../../../components/ModalForm";
import { DownloadFile } from "../../../helpers/DownloadFile";
import IndexTable from "./indexTable";

function Index() {
  const pathName = "/master/employees";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const openPopup = useSelector((state) => state.popup.openPopup);
  const openModal = useSelector((state) => state.employee.openModal);
  const [formType, setFormType] = useState();
  const [dataId, setDataId] = useState();
  const [openModalImport, setModalImport] = useState(false);

  // attachment
  const inputRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);
  const [file, setFile] = useState();

  const getDownloadTemplate = (path) => {
    dispatch(setPopup({ open: true, type: "download" }));

    const downloadUrl = "/download?file_path=templates/TEMPLATE_EMPLOYEES.csv";
    DownloadFile(downloadUrl);

    setTimeout(() => {
      dispatch(setPopup({ open: false }));
    }, 3000);
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const submitImport = async (e) => {
    e.preventDefault();

    dispatch(setPopup({ open: true, type: "upload" }));
    const formSubmit = new FormData();

    try {
      formSubmit.append("file", file, file.name);

      const response = await InstanceApi.post(
        `/master/employees/import`,
        formSubmit
      );
      if (response) {
        dispatch(
          setPopup({
            open: true,
            type: "success",
            message: response.data.message,
            handleOk: () => {
              dispatch(setPopup({ open: false }));
              setModalImport(false);
              window.location.reload();
            },
          })
        );
      }
    } catch (error) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: error.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
    }
  };

  // load data
  useEffect(() => {
    if (!CheckRoleIndex(pathName)) {
      navigate("/404");
    }
  }, [dispatch, navigate]);

  const exportData = async () => {
    try {
      dispatch(setPopup({ open: true, type: "processing" }));
      const response = await InstanceApi.get("/master/employees/export");
      if (response) {
        dispatch(setPopup({ open: false }));
        dispatch(setPopup({ open: true, type: "download" }));

        const downloadUrl = "/download?file_path=" + response.data.data;
        console.log(downloadUrl);
        DownloadFile(downloadUrl);

        setTimeout(() => {
          dispatch(setPopup({ open: false }));
        }, 3000);
      }
    } catch (err) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: err.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
      console.error(err.response.data.message);
    }
  };

  return (
    <>
      {!openPopup.open && openModalImport && (
        <ModalForm
          modalTitle="Import Employees"
          modalSize="medium"
          closeModal={() => setModalImport(false)}
        >
          <form onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
            <div
              className={`modal-upload-box ${
                dragActive ? "border-slate-800" : ""
              } !h-32`}
            >
              <div className="body">
                <input
                  id="input-file-upload"
                  type="file"
                  className="hidden"
                  ref={inputRef}
                  onChange={handleChange}
                />
                <p>
                  Drag and drop your file here or{" "}
                  <span
                    className="cursor-pointer underline text-primary-hover"
                    onClick={onButtonClick}
                  >
                    browse
                  </span>
                </p>
                {file && <p>{file.name}</p>}
              </div>

              {dragActive && (
                <div
                  className="absolute w-full h-full rounded top-0 right-0 left-0 bottom-0"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
            </div>
            <div className="mt-6 flex flex-row gap-2 items-center justify-center">
              <Button
                title="Download Template"
                icon="BiDownload"
                color="primary"
                onClick={() => getDownloadTemplate()}
              />
              {file ? (
                <Button
                  title="Import"
                  icon="BiUpload"
                  color="primary"
                  onClick={submitImport}
                />
              ) : (
                <Button title="Import" icon="BiUpload" color="disabled" />
              )}
            </div>
          </form>
        </ModalForm>
      )}
      {openModal && <Form formType={formType} dataId={dataId} />}

        <>
          <PageHeader>
            <PageHeaderTitle>Employees</PageHeaderTitle>
            <PageHeaderAction>
              {CheckRoleAction(pathName, "create") && (
                <>
                  <Button
                    title="Add New"
                    icon="BiPlus"
                    color="primary"
                    onClick={() => {
                      setFormType("create");
                      dispatch(setModal(true));
                    }}
                  />
                  <Button
                    title="Import"
                    icon="BiUpload"
                    color="secondary"
                    onClick={() => {
                      setModalImport(true);
                    }}
                  />
                  <Button
                    title="Export"
                    icon="BiDownload"
                    color="secondary"
                    onClick={() => {
                      exportData(true);
                    }}
                  />
                </>
              )}
            </PageHeaderAction>
          </PageHeader>
          <IndexTable setDataId={setDataId} setFormType={setFormType} />
        </>
    </>
  );
}

export default Index;
