import { createSlice } from "@reduxjs/toolkit";
import { InstanceApi } from "../../helpers/InstanceApi";
import { Validate } from "../../helpers/Validate";
import { setPopup } from "../popupSlice";

export const projectAttachmentSlice = createSlice({
  name: "projectAttachment",
  initialState: {
    openModal: false,
    dataByProject: undefined,
    dataTemporary: [],
    formData: {
      id: "",
      project_id: "",
      file_name: "",
      file: "",
      mime_type: "",
      file_size: "",
    },
  },
  reducers: {
    setModal: (state, action) => {
      state.openModal = action.payload;
    },
    setDataTemporary: (state, action) => {
      state.dataTemporary = action.payload;
    },
    setDataByProject: (state, action) => {
      state.dataByProject = action.payload;
    },
    setFormData: (state, action) => {
      state.formData[action.payload.name] = action.payload.value;
    },
  },
});

export const resetFormData = () => async (dispatch, getState) => {
  const formData = getState().projectAttachment.formData;
  Object.keys(formData).forEach((key) => {
    dispatch(setFormData({ name: key, value: "" }));
  });
};

export const getDataByProject = (params) => async (dispatch) => {
  try {
    dispatch(setDataByProject(undefined));
    const response = await InstanceApi.get(
      "/pm/projects/" + params.projectId + "/attachment"
    );
    if (response) {
      dispatch(setDataByProject(response.data.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const submit = () => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "processing" }));
    const dataAttachment = getState().projectAttachment.formData;
    const validate = Validate(dataAttachment);

    if (!validate.isValid) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: validate.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
    } else {
      const response = await InstanceApi.post(
        "/pm/projects/attachment",
        dataAttachment
      );
      if (response) {
        dispatch(
          setPopup({
            open: true,
            type: "success",
            message: response.data.message,
            handleOk: () => {
              dispatch(setPopup({ open: false }));
              dispatch(setModal(false));
              dispatch(resetFormData());
              dispatch(
                getDataByProject({
                  projectId: dataAttachment.project_id,
                })
              );
            },
          })
        );
      }
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(error);
  }
};

export const deleteData = (params) => (dispatch, getState) => {
  try {
    const dataProject = getState().project.dataView;
    dispatch(
      setPopup({
        open: true,
        type: "delete",
        message: "Are you sure to delete this from attachments?",
        handleOk: async () => {
          dispatch(setPopup({ open: true, type: "processing" }));

          try {
            const response = await InstanceApi.delete(
              "/pm/projects/attachment/" + params.id
            );
            if (response) {
              dispatch(
                setPopup({
                  open: true,
                  type: "success",
                  message: response.data.message,
                  handleOk: () => {
                    dispatch(setPopup({ open: false }));
                    dispatch(getDataByProject({ projectId: dataProject.id }));
                  },
                })
              );
            }
          } catch (error) {
            dispatch(
              setPopup({
                open: true,
                type: "error",
                message: error.message,
                handleOk: () => {
                  dispatch(setPopup({ open: false }));
                },
              })
            );
            console.error(error);
          }
        },
        handleCancel: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(error);
  }
};

export const { setModal, setDataTemporary, setDataByProject, setFormData } =
  projectAttachmentSlice.actions;
export default projectAttachmentSlice.reducer;
