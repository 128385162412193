import React, { useEffect, useState } from "react";
import styles from "./../../../styles/DataTable.module.css";
import { BiTrash, BiSearch, BiSearchAlt, BiFilterAlt } from "react-icons/bi";
import { CheckRoleAction } from "../../../helpers/CheckRole";
import { useDispatch, useSelector } from "react-redux";
import { getDataTables, deleteData } from "../../../stores/pm/projectSlice";
import { Button } from "../../../components/Button";
import ModalForm from "../../../components/ModalForm";
import MultiSelectText from "../../../components/MultiSelectText";
import { Link } from "react-router-dom";
import Select from "../../../components/Select";
import DataTable2 from "../../../components/DataTable2";

export default function IndexTable() {
  const pathName = "/pm/projects";

  const dispatch = useDispatch();
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [formFilter, setFormFilter] = useState({
    begin_date: "",
    end_date: "",
    status: [],
    inputProjectNo: "",
    project_no: [],
    inputProjectName: "",
    project_name: [],
    inputOwnerName: "",
    owner_name: [],
    inputClientName: "",
    client_name: [],
    inputStationName: "",
    stationName: [],
  });
  const [filterData, setFilterData] = useState()
  const dataOptionStatus = [
    { value: "In Progress", label: "In Progress" },
    { value: "Finish", label: "Finish" },
    { value: "Completed", label: "Completed" },
    { value: "Canceled", label: "Canceled" },
  ];

  const dataTables = useSelector((state) => state.project.dataTables);
  const [showEntries, setShowEntries] = useState(100);
  const [dataTableSearch, setDataTableSearch] = useState("");
  const [tableBody, setTableBody] = useState(dataTables);

  useEffect(() => {
    handleResetFilter();
  }, [dispatch]);

  useEffect(() => {
    setTableBody(dataTables?.data);
  }, [dataTables]);

  // DATATABLES
  const columns = [
    {
      header: "Project No",
      assessor: "project_no",
      Cell: (row) => {
        return (
          <div className="flex flex-col gap-1">
            <Link
              to={`${window.location}/view/${row.id}`}
              className="text-link hover:text-link-hover cursor-pointer"
            >
              #{row.project_no}
            </Link>
            <div className="gap-2">
              {row.urgent === 1 && (
                <span className="w-fit py-1 px-2 bg-warning text-xs text-white rounded-full">
                  Urgent
                </span>
              )}
              {row.total_mo_rev > 0 && (
                <span className="w-fit py-1 px-2 bg-warning text-xs text-white rounded-full">
                  Revision Item
                </span>
              )}
            </div>
          </div>
        );
      },
    },
    {
      header: "Project Name",
      assessor: "project_name",
      Cell: (row) => {
        return (
          <div className="flex flex-col gap-2">
            <Link
              to={`${window.location}/view/${row.id}`}
              className="text-link hover:text-link-hover cursor-pointer"
            >
              {row.project_name}
            </Link>
            <div>{row.client_name}</div>
          </div>
        );
      },
    },
    {
      header: "Project Owner",
      assessor: "owner_name",
    },
    {
      header: "Deadline",
      assessor: "deadline_convert",
      Cell: (row) => {
        return (
          <div className="flex flex-col gap-1">
            <span>{row.deadline_convert}</span>
            {(row.status === "Open" || row.status === "In Progress") && (
              <span className="whitespace-nowrap text-danger text-sm">
                {row.date_diff_deadline > 0
                  ? row.date_diff_deadline < 8 &&
                    `${row.date_diff_deadline} days left`
                  : `Over ${row.date_diff_deadline * -1} days`}
              </span>
            )}
          </div>
        );
      },
    },
    {
      header: "Progress",
      assessor: "task_progress",
      Cell: (row) => {
        return (
          <div className="flex flex-row gap-1 items-center">
            <div
              className="h-3 bg-slate-tertiary rounded-full"
              style={{ width: "80px" }}
            >
              {row.total_task > 0 && (
                <span
                  className={`h-3 block bg-success rounded-full`}
                  style={{
                    width:
                      (row.total_completed_task > 0
                        ? Math.round(row.task_progress) * 0.8
                        : "0") + "px",
                  }}
                ></span>
              )}
            </div>
            <span className="text-[11px]">
              {row.total_completed_task}/{row.total_task}
            </span>
          </div>
        );
      },
    },
    {
      header: "Status",
      assessor: "status",
      tdStyle: { width: "5%" },
      Cell: (row) => {
        return (
          <div className="space-y-1">
            <div
              className={`block badge badge-${row.status.replace(/\s/g, "")}`}
            >
              {row.status}
            </div>
            {row.status === "In Progress" &&
              row.total_completed_task === row.total_task && (
                <div className="block badge badge-info">Waiting To Finish</div>
              )}
            {row.approval_id && (
              <div className="block badge badge-info">Waiting Approval</div>
            )}
          </div>
        );
      },
    },
    {
      header: "Actions",
      tdStyle: { width: "5%" },
      Cell: (row) => {
        return (
          <div className="flex flex-row items-center justify-end gap-2">
            <Link
              to={`${window.location}/view/${row.id}`}
              className="btn-action"
            >
              <BiSearchAlt size="22" />
            </Link>
            {CheckRoleAction(pathName, "delete") &&
              row.total_completed_task === 0 && (
                <span
                  className="btn-action"
                  onClick={() => dispatch(deleteData({ id: row.id }))}
                >
                  <BiTrash size="22" />
                </span>
              )}
          </div>
        );
      },
    },
  ];

  const handleChangeShowEntries = (show) => {
    setShowEntries(show);
    dispatch(
      getDataTables({
        limit: show,
        page: 1,
        search: dataTableSearch,
        ...filterData,
      })
    );
  };

  const handleChangePage = (page) => {
    dispatch(
      getDataTables({
        limit: showEntries,
        page: page,
        search: dataTableSearch,
        ...filterData,
      })
    );
  };

  useEffect(() => {
    if (dataTableSearch) {
      const timeOutId = setTimeout(
        () =>
          dispatch(
            getDataTables({
              limit: showEntries,
              page: 1,
              search: dataTableSearch,
              ...filterData,
            })
          ),
        1000
      );
      return () => clearTimeout(timeOutId);
    } 
    else {
      dispatch(
        getDataTables({
          limit: showEntries,
          page: 1,
          search: dataTableSearch,
          ...filterData,
        })
      );
    }
  }, [dispatch, dataTableSearch]);

  // FILTER
  const createOption = (label) => ({
    label,
    value: label,
  });

  const onChangeFilter = (fieldName, value) => {
    setFormFilter({ ...formFilter, [fieldName]: value });
  };

  const handleResetFilter = () => {
    setFormFilter({
      begin_date: "",
      end_date: "",
      status: [],
      inputProjectNo: "",
      project_no: [],
      inputProjectName: "",
      project_name: [],
      inputOwnerName: "",
      owner_name: [],
      inputClientName: "",
      client_name: [],
    });
    setFilterData(undefined)
    setFiltered(false);
  };

  const handleKeyDown = (
    event = React.KeyboardEventHandler,
    inputName,
    fieldName
  ) => {
    switch (event.key) {
      case "Enter":
      case "Tab":
        if (!formFilter[inputName]) return;
        setFormFilter({
          ...formFilter,
          [fieldName]: [
            ...formFilter[fieldName],
            createOption(formFilter[inputName]),
          ],
          [inputName]: "",
        });
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const applyFilter = () => {
    let formSubmit = {
      begin_date: formFilter.begin_date,
      end_date: formFilter.end_date,
    };
    for (const key in formFilter) {
      if (!["begin_date", "end_date"].includes(key)) {
        if (Array.isArray(formFilter[key])) {
          if (formFilter[key]?.length > 0) {
            let new_aray = [];
            formFilter[key].map((item, _) => {
              new_aray.push(item.value);
            });
            formSubmit[key] = new_aray.join("|");
          }
        } else {
          if (formFilter[key] !== "") {
            formSubmit[key] = formFilter[key];
          }
        }
      }
    }

    setOpenFilterModal(false);
    setFilterData(formSubmit);
    dispatch(
      getDataTables({
        limit: showEntries,
        page: 1,
        search: dataTableSearch,
        ...formSubmit,
      })
    );

    for (const key in formSubmit) {
      if (formSubmit[key] !== "") {
        setFiltered(true);
      }
    }
  };

  return (
    <>
      {openFilterModal && (
        <ModalForm
          modalTitle="Filter"
          modalSize="medium"
          closeModal={() => setOpenFilterModal(false)}
        >
          <div className="grid grid-cols-4 items-center gap-4">
            <div className="form-group">
              <label className="control-label">Begin Date</label>
              <input
                id="begin_date"
                title="Begin Date"
                type="date"
                name="begin_date"
                className="form-control"
                value={formFilter.begin_date}
                onChange={(e) =>
                  setFormFilter({ ...formFilter, begin_date: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label className="control-label">End Date</label>
              <input
                id="end_date"
                title="End Date"
                type="date"
                name="end_date"
                className="form-control"
                value={formFilter.end_date}
                onChange={(e) =>
                  setFormFilter({ ...formFilter, end_date: e.target.value })
                }
              />
            </div>
          </div>
          <div className="w-full">
            <div className="form-group">
              <label className="control-label">Project No</label>
              <MultiSelectText
                inputValue={formFilter.inputProjectNo}
                onChange={(newValue) => onChangeFilter("project_no", newValue)}
                onInputChange={(newValue) =>
                  onChangeFilter("inputProjectNo", newValue)
                }
                onKeyDown={(e) =>
                  handleKeyDown(e, "inputProjectNo", "project_no")
                }
                value={formFilter.project_no}
              />
            </div>
            <div className="form-group">
              <label className="control-label">Project Name</label>
              <MultiSelectText
                inputValue={formFilter.inputProjectName}
                onChange={(newValue) =>
                  onChangeFilter("project_name", newValue)
                }
                onInputChange={(newValue) =>
                  onChangeFilter("inputProjectName", newValue)
                }
                onKeyDown={(e) =>
                  handleKeyDown(e, "inputProjectName", "project_name")
                }
                value={formFilter.project_name}
              />
            </div>
            <div className="form-group">
              <label className="control-label">Owner</label>
              <MultiSelectText
                inputValue={formFilter.inputOwnerName}
                onChange={(newValue) => onChangeFilter("owner_name", newValue)}
                onInputChange={(newValue) =>
                  onChangeFilter("inputOwnerName", newValue)
                }
                onKeyDown={(e) =>
                  handleKeyDown(e, "inputOwnerName", "owner_name")
                }
                value={formFilter.owner_name}
              />
            </div>
            <div className="form-group">
              <label className="control-label">Client</label>
              <MultiSelectText
                inputValue={formFilter.inputClientName}
                onChange={(newValue) => onChangeFilter("client_name", newValue)}
                onInputChange={(newValue) =>
                  onChangeFilter("inputClientName", newValue)
                }
                onKeyDown={(e) =>
                  handleKeyDown(e, "inputClientName", "client_name")
                }
                value={formFilter.client_name}
              />
            </div>
            <div className="form-group">
              <label className="control-label">Status</label>
              <Select
                placeholder={"Choose Reference Project..."}
                className="basic-multi-select"
                isMulti
                options={dataOptionStatus}
                value={formFilter.status}
                onChange={(e) => {
                  var array = [];
                  e.map((item, _) => {
                    array.push({ label: item.value, value: item.value });
                  });
                  setFormFilter({
                    ...formFilter,
                    status: array,
                  });
                }}
              />
            </div>
          </div>

          <div className="mt-8 flex gap-2">
            <Button
              title="Apply"
              icon="BiCheck"
              color="primary"
              onClick={applyFilter}
            />
            <Button
              title="Reset"
              icon="BiX"
              color="secondary"
              onClick={handleResetFilter}
            />
          </div>
        </ModalForm>
      )}

      <div className={styles.dataTable_Box}>
        <div className={styles.dataTable_Header}>
          <div className={styles.dataTable_Search}>
            <div>
              <BiSearch />
            </div>
            <input
              type="search"
              placeholder="Search..."
              onChange={(e) => setDataTableSearch(e.target.value)}
            />
          </div>
          <div className="relative">
            <button
              type="button"
              className="btn-icon btn-icon-md btn-primary"
              onClick={() => setOpenFilterModal(true)}
            >
              <BiFilterAlt />
            </button>
            {filtered && (
              <div className="absolute -top-1 -right-1 h-3 w-3 rounded-full bg-danger"></div>
            )}
          </div>
        </div>
        <DataTable2
          columns={columns}
          tableBody={tableBody}
          dataTables={dataTables}
          handleChangeShowEntries={handleChangeShowEntries}
          showEntries={showEntries}
          handleChangePage={handleChangePage}
        />
      </div>
    </>
  );
}
