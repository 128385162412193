import { createSlice } from "@reduxjs/toolkit";
import { InstanceApi } from "../../helpers/InstanceApi";

export const pmDashboardSlice = createSlice({
  name: "pmDashboard",
  initialState: {
    dataProjectCount: undefined,
    dataTaskCount: undefined,
    dataProjectDeadline: undefined,
    dataProjectActive: undefined,
    dataTaskActive: undefined,
    dataProjectWaitingApproval: undefined,
    dataProjectActivity: undefined,
  },
  reducers: {
    setDataProjectCount: (state, action) => {
      state.dataProjectCount = action.payload;
    },
    setDataTaskCount: (state, action) => {
      state.dataTaskCount = action.payload;
    },
    setDataProjectDeadline: (state, action) => {
      state.dataProjectDeadline = action.payload;
    },
    setDataProjectActive: (state, action) => {
      state.dataProjectActive = action.payload;
    },
    setDataTaskActive: (state, action) => {
      state.dataTaskActive = action.payload;
    },
    setDataProjectWaitingApproval: (state, action) => {
      state.dataProjectWaitingApproval = action.payload;
    },
    setDataProjectActivity: (state, action) => {
      state.dataProjectActivity = action.payload;
    },
  },
});

export const getDataDashboard = () => async (dispatch) => {
  try {
    dispatch(setDataProjectDeadline(undefined));
    dispatch(setDataProjectActive(undefined));
    dispatch(setDataTaskActive(undefined));
    dispatch(setDataProjectWaitingApproval(undefined));
    dispatch(setDataProjectActivity(undefined));
    dispatch(setDataProjectCount(undefined));
    dispatch(setDataTaskCount(undefined));

    const waitingApproval = await InstanceApi.get(
      "/pm/projects/waiting-approval"
    );
    if (waitingApproval) {
      dispatch(setDataProjectWaitingApproval(waitingApproval.data.data));
    }

    const projectYear = await InstanceApi.get("/pm/projects/count");
    if (projectYear) {
      dispatch(setDataProjectCount(projectYear.data.data));
    }

    const taskYear = await InstanceApi.get("/pm/tasks/count");
    if (taskYear) {
      dispatch(setDataTaskCount(taskYear.data.data));
    }

    const activity = await InstanceApi.get("/pm/projects/activity?limit=50");
    if (activity) {
      dispatch(setDataProjectActivity(activity.data.data.data));
    }

    const projectDeadline = await InstanceApi.get("/pm/projects/deadline");
    if (projectDeadline) {
      dispatch(setDataProjectDeadline(projectDeadline.data.data));
    }
    const projectActive = await InstanceApi.get("/pm/projects/active");
    if (projectActive) {
      dispatch(setDataProjectActive(projectActive.data.data));
    }
    const taskActive = await InstanceApi.get("/pm/tasks/active");
    if (taskActive) {
      dispatch(setDataTaskActive(taskActive.data.data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const {
  setDataProjectCount,
  setDataTaskCount,
  setDataProjectDeadline,
  setDataProjectActive,
  setDataTaskActive,
  setDataProjectWaitingApproval,
  setDataProjectActivity,
} = pmDashboardSlice.actions;
export default pmDashboardSlice.reducer;
