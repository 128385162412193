import React from "react";
import {
  Card,
  CardAction,
  CardBody,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import { IconButton } from "../../../components/Button";

import { useSelector, useDispatch } from "react-redux";
import {
  setModalResponsible,
  deleteResponsible,
} from "../../../stores/prod/prodTaskSlice";
import FormResponsible from "./formResponsible";
import { CheckRoleAction } from "../../../helpers/CheckRole";

function CardResponsible() {
  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const dataTask = useSelector((state) => state.prodTask.dataView);
  const openModal = useSelector((state) => state.prodTask.openModalResponsible);

  return (
    <>
      {openModal && <FormResponsible />}

      <Card>
        <CardHeader>
          <CardTitle>Responsible</CardTitle>
          {!dataTask.responsible_id &&
            dataTask.status !== "Canceled" &&
            CheckRoleAction("/prod/tasks", "assign") && (
              <CardAction>
                <IconButton
                  icon="BiPlus"
                  size="sm"
                  color="primary"
                  onClick={() => dispatch(setModalResponsible(true))}
                />
              </CardAction>
            )}
        </CardHeader>
        <CardBody>
          {dataTask.responsible_id && (
            <div className="flex flex-row gap-2 items-center justify-between">
              <div className="flex-1 flex flex-col gap-1">
                <span className="block font-semibold">
                  {dataTask.responsible_name}
                </span>
                <span className="block text-sm text-text-secondary">
                  {dataTask.responsible_position_name}
                </span>
              </div>
              {CheckRoleAction("/prod/tasks", "assign") &&
                dataTask.status === "In Progress" && (
                  <div className="flex-none justify-end">
                    <IconButton
                      icon="BiTrash"
                      color="tertiary"
                      onClick={() => dispatch(deleteResponsible())}
                    />
                  </div>
                )}
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
}

export default CardResponsible;
