import React, { useEffect, useState } from "react";
import styles from "./../../../styles/DataTable.module.css";
import { BiSearch, BiEditAlt } from "react-icons/bi";
import { CheckRoleAction } from "../../../helpers/CheckRole";
import { useDispatch, useSelector } from "react-redux";
import { getDataTables } from "../../../stores/email/emailTemplateSlice";
import { Link } from "react-router-dom";
import DataTable2 from "../../../components/DataTable2";

export default function IndexTable() {
  const pathName = "/email/template";

  const dispatch = useDispatch();

  const dataTables = useSelector((state) => state.emailTemplate.dataTables);
  const [showEntries, setShowEntries] = useState(100);
  const [dataTableSearch, setDataTableSearch] = useState("");
  const [tableBody, setTableBody] = useState(dataTables);

  useEffect(() => {
    setTableBody(dataTables?.data);
  }, [dataTables]);

  // DATATABLES
  const columns = [
    {
      header: "Template Name",
      assessor: "name",
    },
    {
      header: "Subject",
      assessor: "subject",
    },
    {
      header: "CC",
      assessor: "cc",
    },
    {
      header: "BCC",
      assessor: "bcc",
    },
    {
      header: "Actions",
      tdStyle: { width: "5%" },
      Cell: (row) => {
        return (
          <div className="flex flex-row items-center justify-end gap-2">
            {CheckRoleAction(pathName, "edit") && (
              <Link to={`/email/template/edit/${row.id}`}>
              <span
                className="btn-action"
                >
                <BiEditAlt size="22" />
              </span>
                </Link>
            )}
          </div>
        );
      },
    },
  ];

  const handleChangeShowEntries = (show) => {
    setShowEntries(show);
    dispatch(
      getDataTables({
        limit: show,
        page: 1,
        search: dataTableSearch,
      })
    );
  };

  const handleChangePage = (page) => {
    dispatch(
      getDataTables({
        limit: showEntries,
        page: page,
        search: dataTableSearch,
      })
    );
  };

  useEffect(() => {
    if (dataTableSearch) {
      const timeOutId = setTimeout(
        () =>
          dispatch(
            getDataTables({
              limit: showEntries,
              page: 1,
              search: dataTableSearch,
            })
          ),
        1000
      );
      return () => clearTimeout(timeOutId);
    } else {
      dispatch(
        getDataTables({
          limit: showEntries,
          page: 1,
          search: dataTableSearch,
        })
      );
    }
  }, [dispatch, dataTableSearch]);


  return (
    <>
      <div className={styles.dataTable_Box}>
        <div className={styles.dataTable_Header}>
          <div className={styles.dataTable_Search}>
            <div>
              <BiSearch />
            </div>
            <input
              type="search"
              placeholder="Search..."
              onChange={(e) => setDataTableSearch(e.target.value)}
            />
          </div>
        </div>
        <DataTable2
          columns={columns}
          tableBody={tableBody}
          dataTables={dataTables}
          handleChangeShowEntries={handleChangeShowEntries}
          showEntries={showEntries}
          handleChangePage={handleChangePage}
        />
      </div>
    </>
  );
}
