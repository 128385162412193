import React, { useState, useEffect } from "react";
import {
  Card,
  CardAction,
  CardBody,
  CardHeader,
  CardTitle,
} from "../../components/Card";
import Badge from "../../components/Badge";
import { useDispatch, useSelector } from "react-redux";
import { BiChevronRight, BiUser, BiUserCircle } from "react-icons/bi";
import { FaExclamation } from "react-icons/fa";
import { getDataDashboard } from "../../stores/prod/prodDashboardSlice";
import { useNavigate } from "react-router-dom";
import { getDataCompleted } from "../../stores/pm/projectSlice";

export default function ProdDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dataCompletedProject = useSelector((state) => state.project.dataCompleted); 
  const dataProjectYear = useSelector(
    (state) => state.prodDashboard.dataProjectYear
  );
  const dataProjectActive = useSelector(
    (state) => state.prodDashboard.dataProjectActive
  );
  const dataTaskActive = useSelector(
    (state) => state.prodDashboard.dataTaskActive
  );
  const dataProjectActivity = useSelector(
    (state) => state.prodDashboard.dataProjectActivity
  );

  const [filterProjectActive, setFilterProjectActive] = useState(undefined);
  const [filterTaskActive, setFilterTaskActive] = useState(undefined);

  useEffect(() => {
    dispatch(getDataCompleted());
    dispatch(getDataDashboard());
  }, [dispatch]);

  useEffect(() => {
    setFilterProjectActive(dataProjectActive);
  }, [dataProjectActive]);

  useEffect(() => {
    setFilterTaskActive(dataTaskActive);
  }, [dataTaskActive]);

  const ListItemProject = (props) => {
    const { item } = props;
    const status = item.status.replace(" ", "").toLowerCase();
    return (
      <div
        className={`h-18 w-80 p-2 border border-slate-tertiary rounded-md flex flex-row items-center gap-2 transition duration-300`}
      >
        <div className="flex-1 space-y-2">
          <div className="w-full flex flex-row items-start gap-2">
            <div className="flex-1 line-clamp-2 text-sm font-semibold">
              {item.project_no}
            </div>
            {item.urgent === 1 && (
              <div className="h-4 w-4 flex items-center justify-center bg-warning text-xs text-white rounded-full">
                <FaExclamation />
              </div>
            )}
          </div>
          <div className="w-full truncate text-sm">{item.project_name}</div>
          <div className="space-y-1 text-[11px]">
            <div className="flex flex-row gap-2 items-center justify-between">
              <span className=" flex flex-row items-center gap-1">
                <div className={`h-2.5 w-2.5 rounded-full bg-${status}`}></div>
                {item.status}
              </span>
              <span className=" flex flex-row items-center gap-1 truncate">
                <div className={`h-2.5 w-2.5 rounded-full`}>
                  <BiUser />
                </div>
                <div className="truncate">{item.owner_name}</div>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  const ListItemProjectActive = (props) => {
    const { item } = props;
    const status = item.status.replace(" ", "").toLowerCase();
    return (
      <div
        className={`p-2 border border-slate-tertiary rounded-md flex flex-row items-center gap-2 cursor-pointer hover hover:bg-slate-tertiary transition duration-300`}
        onClick={() => {
          navigate(`/prod/projects/view/${item.id}`);
        }}
      >
        <div className="flex-1 space-y-2">
          <div className="w-full flex flex-row items-start gap-2">
            <div className="flex-1 line-clamp-2 text-sm font-semibold">
              [#{item.project_no}] {item.project_name}
            </div>
            {item.urgent === 1 && (
              <div className="h-4 w-4 flex items-center justify-center bg-warning text-xs text-white rounded-full">
                <FaExclamation />
              </div>
            )}
          </div>
          <div className="space-y-1 text-[11px]">
            <div className="flex flex-row gap-1 items-center justify-between">
              <span className=" flex flex-row items-center gap-1">
                <div className={`h-2.5 w-2.5 rounded-full bg-${status}`}></div>
                {item.status}
              </span>
            </div>
          </div>
        </div>
        <div className="flex-none text-xl text-text-secondary">
          <BiChevronRight />
        </div>
      </div>
    );
  };

  const ListItemTaskActive = (props) => {
    const { item } = props;
    return (
      <div
        className={`p-2 border border-slate-tertiary rounded-md flex flex-row items-center gap-2 cursor-pointer hover hover:bg-slate-tertiary transition duration-300`}
        onClick={() => {
          navigate(`/prod/tasks/${item.id}`);
        }}
      >
        <div className="flex-1 space-y-4">
          <div className="line-clamp-1 text-sm font-semibold">
            [#{item.task_no}] {item.task_name}
          </div>
          <div className="space-y-1 text-[11px]">
            <div
              className="h-1.5 bg-slate-tertiary rounded-full"
              style={{ width: "100%" }}
            >
              {item.spot > 0 && (
                <span
                  className={`h-1.5 block bg-inprogress rounded-full`}
                  style={{
                    width:
                      (item.total_attachment > 0
                        ? parseFloat(item.task_progress)
                        : "0") + "%",
                  }}
                ></span>
              )}
            </div>
            <div className="flex flex-row items-center gap-1 text-sm text-text-secondary">
              <BiUser />{" "}
              {item.responsible_name ? (
                <span className="line-clamp-1">{item.responsible_name}</span>
              ) : (
                <span className="text-danger">No responsible</span>
              )}
            </div>
          </div>
        </div>
        <div className="flex-none text-xl text-text-secondary">
          <BiChevronRight />
        </div>
      </div>
    );
  };

  const ListItemActivity = (props) => {
    const { item } = props;
    return (
      <div className="p-2 border border-slate-tertiary rounded-md">
        <div className="mb-2 flex flex-row items-center justify-between">
          <div className="flex-1 text-sm font-semibold truncate">
            {item.created_by}
          </div>
          <div className="text-xs">{item.created_at}</div>
        </div>
        <div className="text-sm">{item.message}</div>
        {item.notes && item.notes !== "" && (
          <div className="mt-4">
            <span className="block text-xs font-semibold">Notes :</span>
            <div className="text-sm">{item.notes}</div>
          </div>
        )}
      </div>
    );
  };

  const Loading = () => {
    return (
      <div className="w-full h-8 flex items-center justify-center">
        <svg
          className="animate-spin h-5 w-5 text-text-secondary"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="white"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="white"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    );
  };

  const handleFilterActiveProject = (e) => {
    const value = e.target.value;
    let filtered = dataProjectActive;
    switch (value) {
      case "All":
        filtered = dataProjectActive;
        break;
      case "Urgent":
        filtered = [...dataProjectActive].filter((data) => data.urgent === 1);
        break;
      case "In Progress":
      case "Finish":
        filtered = [...dataProjectActive].filter(
          (data) => data.status === value
        );
        break;
      default:
        break;
    }

    setFilterProjectActive(filtered);
  };

  const handleFilterActiveTask = (e) => {
    const value = e.target.value;
    let filtered = dataTaskActive;
    switch (value) {
      case "All":
        filtered = dataTaskActive;
        break;
      case "Open":
      case "In Progress":
        filtered = [...dataTaskActive].filter((data) => data.status === value);
        break;
      default:
        break;
    }

    setFilterTaskActive(filtered);
  };

  return (
    <>
      <div className="w-full flex flex-row gap-4">
        <div className="w-[75%] space-y-4">
          <div className="w-full">
            <Card>
              <CardHeader>
                <CardTitle>Completed BD Project</CardTitle>
                {dataCompletedProject && (
                  <CardAction>
                    <div className="badge badge-info">
                      {dataCompletedProject.length}
                    </div>
                  </CardAction>
                )}
              </CardHeader>
              <CardBody>
                <div className="w-full flex justify-between  overflow-x-hidden">
                  <div className="w-full pb-1 flex overflow-x-scroll slim-x-overflow [&>div]:flex-shrink-0 gap-2">
                    {!dataCompletedProject && (
                      <div className="h-18 w-full p-2 text-center font-semibold">
                        Loading...
                      </div>
                    )}
                    {dataCompletedProject &&
                      (dataCompletedProject.length > 0 ? (
                        dataCompletedProject.map((item, index) => (
                          <ListItemProject key={index} item={item} />
                        ))
                      ) : (
                        <div className="h-18 w-full p-2 text-center font-semibold">
                          No project completed
                        </div>
                      ))}
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="w-full grid grid-cols-2 gap-4">
            <div className="col-span-1">
              <Card>
                <CardHeader>
                  <CardTitle>Active Projects</CardTitle>
                  <CardAction>
                    <select
                      className="form-control !h-8 text-sm"
                      onChange={handleFilterActiveProject}
                    >
                      <option value="All">All</option>
                      <option value="Urgent">Urgent</option>
                      <option value="In Progress">In Progress</option>
                      <option value="Finish">Finish</option>
                    </select>
                  </CardAction>
                </CardHeader>
                <div
                  className="card-body"
                  style={{ paddingLeft: "0", paddingRight: "0" }}
                >
                  <div className="overflow-y-scroll slim-overflow pl-4 pr-1">
                    <div
                      className="space-y-2"
                      style={{ minHeight: 367, maxHeight: 367 }}
                    >
                      {!filterProjectActive && (
                        <div className="h-[58px] w-full p-2 text-center font-semibold">
                          Loading...
                        </div>
                      )}
                      {filterProjectActive &&
                        (filterProjectActive.length > 0 ? (
                          filterProjectActive.map((item, index) => (
                            <ListItemProjectActive key={index} item={item} />
                          ))
                        ) : (
                          <div className="h-[58px] p-2 text-center font-semibold">
                            No active project
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div className="col-span-1">
              <Card>
                <CardHeader>
                  <CardTitle>Active Task</CardTitle>
                  <CardAction>
                    <select
                      className="form-control !h-8 text-sm"
                      onChange={handleFilterActiveTask}
                    >
                      <option value="All">All</option>
                      <option value="Open">Open</option>
                      <option value="In Progress">In Progress</option>
                    </select>
                  </CardAction>
                </CardHeader>
                <div
                  className="card-body"
                  style={{ paddingLeft: "0", paddingRight: "0" }}
                >
                  <div className="overflow-y-scroll slim-overflow pl-4 pr-1">
                    <div
                      className="space-y-2"
                      style={{ minHeight: 367, maxHeight: 367 }}
                    >
                      {!filterTaskActive && (
                        <div className="h-[58px] w-full p-2 text-center font-semibold">
                          Loading...
                        </div>
                      )}
                      {filterTaskActive &&
                        (filterTaskActive.length > 0 ? (
                          filterTaskActive.map((item, index) => (
                            <ListItemTaskActive key={index} item={item} />
                          ))
                        ) : (
                          <div className="h-[58px] p-2 text-center font-semibold">
                            No task active
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
        <div className="w-[25%]">
          <div className="space-y-4">
            <Card>
              <CardHeader>
                <CardTitle>Project This Year</CardTitle>
                {dataProjectYear && (
                  <CardAction>
                    <Badge type="info">{dataProjectYear.total_project}</Badge>
                  </CardAction>
                )}
              </CardHeader>
              <CardBody>
                <div className="grid grid-cols-2 gap-2">
                  <div className="col-span-1 text-center rounded-lg p-2 bg-inprogress text-white">
                    <span className="block text-3xl font-bold">
                      {!dataProjectYear ? (
                        <Loading />
                      ) : (
                        dataProjectYear.total_inprogress
                      )}
                    </span>
                    <span className="block text-[11px] font-normal">
                      In Progress
                    </span>
                  </div>
                  <div className="col-span-1 text-center rounded-lg p-2 bg-completed text-white">
                    <span className="block text-3xl font-bold">
                      {!dataProjectYear ? (
                        <Loading />
                      ) : (
                        dataProjectYear.total_completed
                      )}
                    </span>
                    <span className="block text-[11px] font-normal">
                      Completed
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Project Activity</CardTitle>
              </CardHeader>
              <div
                className="card-body"
                style={{ paddingLeft: "0", paddingRight: "0" }}
              >
                <div className="overflow-y-scroll slim-overflow pl-4 pr-1">
                  <div
                    className="space-y-2"
                    style={{ minHeight: 410, maxHeight: 410 }}
                  >
                    {!dataProjectActivity && (
                      <div className="w-full text-center font-semibold">
                        Loading...
                      </div>
                    )}
                    {dataProjectActivity &&
                      (dataProjectActivity.length > 0 ? (
                        dataProjectActivity.map((item, index) => (
                          <ListItemActivity key={index} item={item} />
                        ))
                      ) : (
                        <div className="text-center font-semibold">
                          No project activity
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
