import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDataByProject } from "../../../stores/pm/projectMoSlice";
import { getDataByProject as getDataMoRevByProject, getDataPendingByProject as getDataMoRevPendingByProject } from "../../../stores/pm/projectMoRevSlice";
import TabMoWidget from "../tabs/tabMoWidget";

function TabMo() {
  const dispatch = useDispatch();
  const dataProject = useSelector((state) => state.project.dataView);
  const dataByProject = useSelector((state) => state.projectMo.dataByProject);
  

  useEffect(() => {
    dispatch(getDataByProject({ projectId: dataProject.id }));
    // dispatch(getDataMoRevByProject({ projectId: dataProject.id }));
    // dispatch(getDataMoRevPendingByProject({ projectId: dataProject.id }));
  }, [dispatch, dataProject.id]);

  return (
    <TabMoWidget
      moList={dataByProject}
      ownerId={dataProject.owner_id}
    />
  );
}

export default TabMo;
