import axios from "axios";
import { apiUrl } from "../config/Constant";
import { setPopup } from "../stores/popupSlice";

const ISSERVER = typeof window === "undefined";
let instance = undefined

if (!ISSERVER) {
    const accessToken = localStorage.getItem('access_token');
    const item = JSON.parse(accessToken);
    // const now = new Date();

    // if (now.getTime() > item.expiry) {
    //   localStorage.removeItem("isLogin");
    //   localStorage.removeItem("user");
    //   localStorage.removeItem("access_token");

    //   window.location.href = "/";
    // }

    if(accessToken){
        instance = axios.create({
          baseURL: apiUrl,
          headers: { Authorization: "Bearer " + item.value },
        });
    }
}

export const InstanceApi = instance