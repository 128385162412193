import React, { useEffect, useState } from "react";
import { Card, CardBody } from "../../../components/Card";
import { Button } from "../../../components/Button";
import Badge from "../../../components/Badge";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from "recharts";

import { useSelector, useDispatch } from "react-redux";
import {
  setDetailTabOpen,
  finishProject,
  cancelProject,
  deleteData,
  getDataOptions as getDataOptionProject,
  setModalCancel,
} from "../../../stores/pm/projectSlice";

import { BiFile, BiTask, BiCheck, BiHistory } from "react-icons/bi";
import { checkApproval } from "../../../stores/wf/workflowSlice";
import { CheckRoleAction } from "../../../helpers/CheckRole";
import TabApprovalStart from "./tabApprovalStart";
import TabApprovalFinish from "./tabApprovalFinish";
import TabApprovalCancel from "./tabApprovalCancel";
import TabActivity from "./tabActivity";
import TabAttachment from "./tabAttachment";
import TabMoRev from "./tabMoRev";
import TabMo from "./tabMo";
import TabTask from "./tabTask";
import TabMoRevHistory from "./tabMoRevHistory";
import parse from "html-react-parser";
import ModalForm from "../../../components/ModalForm";
import Select from "../../../components/Select";
import { getDataOptions as getDataOptionClient } from "../../../stores/master/clientSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Validate } from "../../../helpers/Validate";
import { setPopup } from "../../../stores/popupSlice";
import { InstanceApi } from "../../../helpers/InstanceApi";
import CancelForm from "./cancelForm";

function TabOverview() {
  const user = JSON.parse(localStorage.getItem("user"));
  const pathName = "/pm/projects";
  const dispatch = useDispatch();
  const detailTabOpen = useSelector((state) => state.project.detailTabOpen);
  const dataProject = useSelector((state) => state.project.dataView);
  const dataOptionProject = useSelector((state) => state.project.dataOptions);
  const dataOptionClient = useSelector((state) => state.client.dataOptions);
  const approval = useSelector((state) => state.workflow.approval);

  const [pieChartWidth, setPieChartWidth] = useState(0);
  const [pieData, setPieData] = useState([{ name: "", value: 1 }]);
  const [pieColors, setPieColors] = useState([]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="py-2 px-4 border-none bg-slate-tertiary bg-opacity-80">
          <span>{`${payload[0].name} : ${payload[0].value}`}</span>
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    dispatch(setDetailTabOpen("tab-activity"));
    if (dataProject?.status === "In Progress") {
      dispatch(
        checkApproval({ obj: "Project", type: "Start", objId: dataProject?.id })
      );
    } else if (dataProject?.status === "Finish") {
      dispatch(
        checkApproval({
          obj: "Project",
          type: "Finish",
          objId: dataProject?.id,
        })
      );
    } else if (dataProject?.status === "Cancel") {
      dispatch(
        checkApproval({
          obj: "Project",
          type: "Cancel",
          objId: dataProject?.id,
        })
      );
    }
  }, [dispatch, dataProject]);

  useEffect(() => {
    const cardWidth = document.getElementById("card-pieChart")?.offsetWidth;
    if (cardWidth) {
      setPieChartWidth(cardWidth);
    }

    if (dataProject) {
      if (dataProject.total_task > 0) {
        setPieData([
          { name: "Open", value: parseInt(dataProject?.total_open_task) ?? 0 },
          {
            name: "In Progress",
            value: parseInt(dataProject?.total_inprogress_task) ?? 0,
          },
          {
            name: "Completed",
            value: parseInt(dataProject?.total_completed_task) ?? 0,
          },
        ]);
        setPieColors(["#06b6d4", "#f59e0b", "#22c55e"]);
      }
    }
  }, [dataProject]);

  // edit project
  const [formEdit, setFormEdit] = useState({
    id: dataProject?.id,
    client_id: dataProject?.client_id,
    project_name: dataProject?.project_name,
    descriptions: dataProject?.descriptions,
    deadline: dataProject?.deadline,
  });
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const editProject = () => {
    dispatch(getDataOptionProject());
    dispatch(getDataOptionClient());
    setOpenModalEdit(true);
  };

  const formValidate = useSelector((state) => state.project.formValidate);
  const submitEdit = async () => {
    try {
      dispatch(setPopup({ open: true, type: "processing" }));
      const validate = Validate(formValidate, formEdit);

      if (!validate.isValid) {
        dispatch(
          setPopup({
            open: true,
            type: "error",
            message: validate.message,
            handleOk: () => {
              dispatch(setPopup({ open: false }));
            },
          })
        );
      } else {
        let response = await InstanceApi.patch(
          "/pm/projects/" + formEdit.id,
          formEdit
        );

        if (response) {
          dispatch(
            setPopup({
              open: true,
              type: "success",
              message: response.data.message,
              handleOk: () => {
                dispatch(setPopup({ open: false }));
                setOpenModalEdit(false);
                window.location.reload();
              },
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: error.response.data.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
    }
  };
  const editorToolbar = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };

  const editorFormat = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];

  const dateToday = () => {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  // cancel project
  const openModalCancel = useSelector((state) => state.project.openModalCancel);

  return (
    <>
      {openModalEdit && (
        <ModalForm
          modalTitle="Edit Project"
          modalSize="medium"
          closeModal={() => setOpenModalEdit(false)}
        >
          <div className="w-full">
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
              <div className="cols-span-1">
                <div className="form-group">
                  <label className="control-label">
                    Client <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="client_id"
                    title="Client"
                    name="client_id"
                    placeholder={"Choose Client..."}
                    value={dataOptionClient.filter(
                      (option) => option.value === formEdit.client_id
                    )}
                    onChange={(e) =>
                      setFormEdit({ ...formEdit, client_id: e?.value })
                    }
                    options={dataOptionClient}
                    required={true}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
              <div className="cols-span-1">
                <div className="form-group">
                  <label className="control-label">Reference Project</label>
                  <Select
                    id="reference_project_id"
                    title="Reference Project"
                    name="reference_project_id"
                    placeholder={"Choose Reference Project..."}
                    value={dataOptionProject.filter(
                      (option) => option.value === formEdit.reference_project_id
                    )}
                    onChange={(e) =>
                      setFormEdit({
                        ...formEdit,
                        reference_project_id: e?.value,
                      })
                    }
                    options={dataOptionProject}
                    required={false}
                  />
                </div>
              </div>
              <div className="cols-span-1">
                <div className="form-group">
                  <label className="control-label">
                    Project Name <span className="text-danger">*</span>
                  </label>
                  <input
                    id="project_name"
                    title="Project Name"
                    type="text"
                    name="project_name"
                    className="form-control"
                    value={formEdit.project_name}
                    onChange={(e) =>
                      setFormEdit({
                        ...formEdit,
                        project_name: e?.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="control-label">
                Description <span className="text-danger">*</span>
              </label>
              <div className="form-control h-60">
                <ReactQuill
                  id="descriptions"
                  title="Description"
                  name="descriptions"
                  className="form-control h-48"
                  value={formEdit.descriptions}
                  onChange={(e) =>
                    setFormEdit({
                      ...formEdit,
                      descriptions: e,
                    })
                  }
                  required={true}
                  modules={editorToolbar}
                  formats={editorFormat}
                />
              </div>
            </div>
            <div className="flex flex-row gap-4">
              <div className="md:w-3/12">
                <div className="form-group">
                  <label className="control-label">
                    Deadline <span className="text-danger">*</span>
                  </label>
                  <input
                    id="deadline"
                    title="Deadline"
                    type="date"
                    name="deadline"
                    className="form-control"
                    min={dateToday()}
                    value={formEdit.deadline}
                    onChange={(e) =>
                      setFormEdit({
                        ...formEdit,
                        deadline: e?.target.value,
                      })
                    }
                    required={true}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8 flex gap-2">
            <Button
              title="Save"
              icon="BiSave"
              color="primary"
              onClick={() => submitEdit()}
            />
            <Button
              title="Cancel"
              icon="BiX"
              color="secondary"
              onClick={() => setOpenModalEdit(false)}
            />
          </div>
        </ModalForm>
      )}

      {openModalCancel && <CancelForm />}
      <div className="flex md:flex-row gap-4">
        <div className="md:w-3/4 space-y-4">
          <Card>
            <CardBody>
              <div className="space-y-4">
                <div className="mb-6 flex flex-row items-start justify-between gap-1">
                  <div className="flex-1">
                    <h1 className="font-semibold">
                      [#{dataProject.project_no}] {dataProject.project_name}
                    </h1>
                  </div>
                  {dataProject.urgent > 0 && (
                    <div className="flex-none">
                      <div className="badge badge-warning">Urgent</div>
                    </div>
                  )}
                </div>
                <div className="w-full">
                  <span className="label-detail">Client</span>
                  <p>{dataProject.client_name}</p>
                </div>
                <div className="w-full">
                  <span className="label-detail">Description</span>
                  {parse(dataProject.descriptions)}
                </div>
                {dataProject.urgent > 0 && (
                  <div className="w-full">
                    <span className="label-detail">Urgent Notes</span>
                    <p>{dataProject.urgent_notes}</p>
                  </div>
                )}
              </div>
              <div className="mt-10 w-full flex flex-row items-center gap-2">
                {user.reference_id === dataProject.owner_id &&
                  dataProject.status === "In Progress" &&
                  dataProject.total_completed_task ===
                    dataProject.total_task && (
                    <Button
                      title="Finish Project"
                      icon="BiCheck"
                      color="primary"
                      onClick={() => dispatch(finishProject())}
                    />
                  )}
                {user.reference_id === dataProject.owner_id &&
                  dataProject.status === "In Progress" && (
                    <Button
                      title="Edit Project"
                      icon="BiEdit"
                      color="primary"
                      onClick={() => editProject()}
                    />
                  )}
                {user.reference_id === dataProject.owner_id &&
                  dataProject.status === "In Progress" &&
                  dataProject.total_completed_task > 0 &&
                  dataProject.approval_id === null &&
                  CheckRoleAction(pathName, "cancel") && (
                    <Button
                      title="Cancel Project"
                      icon="BiX"
                      color="secondary"
                      onClick={() => dispatch(setModalCancel(true))}
                    />
                  )}
                {user.reference_id === dataProject.owner_id &&
                  dataProject.total_completed_task === 0 &&
                  CheckRoleAction(pathName, "delete") && (
                    <Button
                      title="Delete Project"
                      icon="BiTrash"
                      color="secondary"
                      onClick={() =>
                        dispatch(deleteData({ id: dataProject.id }))
                      }
                    />
                  )}
              </div>
            </CardBody>
          </Card>

          <div className="tabs">
            <div className="tab-nav">
              <button
                type="button"
                className={`${detailTabOpen === "tab-activity" && "active"}`}
                onClick={() => dispatch(setDetailTabOpen("tab-activity"))}
              >
                <BiHistory /> Last Activity
              </button>
              <button
                type="button"
                className={`btn ${
                  detailTabOpen === "tab-task" ? "active" : ""
                }`}
                onClick={() => dispatch(setDetailTabOpen("tab-task"))}
              >
                <BiTask /> Tasks
              </button>
              <button
                type="button"
                className={`btn ${
                  detailTabOpen === "tab-attachment" ? "active" : ""
                }`}
                onClick={() => dispatch(setDetailTabOpen("tab-attachment"))}
              >
                <BiFile /> Attachments
              </button>
              <button
                type="button"
                className={`btn ${detailTabOpen === "tab-mo" ? "active" : ""}`}
                onClick={() => dispatch(setDetailTabOpen("tab-mo"))}
              >
                <BiFile /> Items
              </button>
              {dataProject.total_mo_rev > 0 && (
                <button
                  type="button"
                  className={`btn ${
                    detailTabOpen === "tab-moRev" ? "active" : ""
                  }`}
                  onClick={() => dispatch(setDetailTabOpen("tab-moRev"))}
                >
                  <BiFile /> Revision Item
                </button>
              )}
              {dataProject.total_mo_rev_history > 0 && (
                <button
                  type="button"
                  className={`btn ${
                    detailTabOpen === "tab-moRevHistory" ? "active" : ""
                  }`}
                  onClick={() => dispatch(setDetailTabOpen("tab-moRevHistory"))}
                >
                  <BiHistory /> Revision Item History
                </button>
              )}
              {approval && dataProject.status === "In Progress" && (
                <button
                  type="button"
                  className={`${
                    detailTabOpen === "tab-approval-start" && "active"
                  }`}
                  onClick={() =>
                    dispatch(setDetailTabOpen("tab-approval-start"))
                  }
                >
                  <BiCheck /> Approve / Reject Start Project
                </button>
              )}
              {approval && dataProject.status === "Finish" && (
                <button
                  type="button"
                  className={`${
                    detailTabOpen === "tab-approval-finish" && "active"
                  }`}
                  onClick={() =>
                    dispatch(setDetailTabOpen("tab-approval-finish"))
                  }
                >
                  <BiCheck /> Approve / Reject Finish Project
                </button>
              )}
              {approval && dataProject.status === "Cancel" && (
                <button
                  type="button"
                  className={`${
                    detailTabOpen === "tab-approval-cancel" && "active"
                  }`}
                  onClick={() =>
                    dispatch(setDetailTabOpen("tab-approval-cancel"))
                  }
                >
                  <BiCheck /> Approve / Reject Cancel Project
                </button>
              )}
            </div>
            {detailTabOpen === "tab-activity" && (
              <div className="tab-panel">
                <TabActivity />
              </div>
            )}
            {detailTabOpen === "tab-task" && (
              <div className="tab-panel">
                <TabTask />
              </div>
            )}
            {detailTabOpen === "tab-attachment" && (
              <div className="tab-panel">
                <TabAttachment />
              </div>
            )}
            {detailTabOpen === "tab-mo" && (
              <div className="tab-panel">
                <TabMo />
              </div>
            )}
            {detailTabOpen === "tab-moRev" && (
              <div className="tab-panel">
                <TabMoRev />
              </div>
            )}
            {detailTabOpen === "tab-moRevHistory" && (
              <div className="tab-panel">
                <TabMoRevHistory />
              </div>
            )}
            {detailTabOpen === "tab-approval-start" && (
              <div className="tab-panel">
                <TabApprovalStart />
              </div>
            )}
            {detailTabOpen === "tab-approval-finish" && (
              <div className="tab-panel">
                <TabApprovalFinish />
              </div>
            )}
            {detailTabOpen === "tab-approval-cancel" && (
              <div className="tab-panel">
                <TabApprovalCancel />
              </div>
            )}
          </div>
        </div>
        <div className="md:w-1/4 space-y-4">
          <div className="space-y-2">
            <div
              className={`py-2 px-4 w-full text-sm shadow-lg shadow-shadow rounded-lg ${
                dataProject.status === "Open"
                  ? "bg-open"
                  : dataProject.status === "In Progress"
                  ? "bg-warning"
                  : dataProject.status === "Canceled"
                  ? "bg-danger"
                  : dataProject.status === "Finish" ||
                    dataProject.status === "Cancel"
                  ? "bg-info"
                  : "bg-success"
              }`}
            >
              <span className="text-white font-semibold">
                {dataProject.status}{" "}
                {(dataProject.status === "Finish" ||
                  dataProject.status === "Cancel") &&
                  "and waiting approval"}
              </span>
              <span className="text-white text-sm">
                {dataProject.status !== "Completed" ? " since " : " "}
                {dataProject.updated_at_convert}
              </span>
            </div>
            {dataProject.total_completed_task < dataProject.total_task &&
              dataProject.status !== "Cancel" && (
                <div className="py-2 px-4 w-full shadow-lg shadow-shadow rounded-lg bg-info text-white text-sm">
                  Waiting for all tasks to be completed
                </div>
              )}
            {dataProject.status === "In Progress" &&
              dataProject.total_completed_task === dataProject.total_task && (
                <div className="py-2 px-4 w-full shadow-lg shadow-shadow rounded-lg bg-info text-white text-sm">
                  Waiting to finish Project by {dataProject.owner_name}
                </div>
              )}
            {dataProject.approval_id && (
              <div className="py-2 px-4 w-full shadow-lg shadow-shadow rounded-lg bg-info text-white text-sm">
                Waiting approval {dataProject.approval_type} Project by{" "}
                {dataProject.approval_by}
              </div>
            )}
            <Card>
              <CardBody>
                <div className="space-y-4">
                  <div className="w-full">
                    <span className="label-detail">Created on</span>
                    <span className="text-text-secondary">
                      {dataProject.created_at_convert}
                    </span>
                  </div>
                  <div className="w-full">
                    <span className="label-detail">Deadline</span>
                    <span className="text-text-secondary">
                      {dataProject.deadline_convert}
                    </span>
                  </div>
                  <div className="w-full">
                    <span className="label-detail">Project Date</span>
                    <div className="w-full flex flex-row gap-2 items-center text-text-secondary">
                      <span>{dataProject.begin_date_convert}</span>
                      to
                      <span>{dataProject.end_date_convert}</span>
                    </div>
                  </div>
                  <div className="w-full">
                    <span className="label-detail">Project Owner</span>
                    <div className="flex flex-row items-center gap-2">
                      <span className="team-name">
                        {dataProject.owner_name}
                      </span>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <Card>
            <CardBody>
              <div id="card-pieChart" className="mb-6 w-full flex gap-6">
                <div className="flex-1">
                  <h3 className="font-semibold">Progress</h3>
                </div>
                <div className="self-start">
                  <Badge type="info">{dataProject.total_task} Tasks</Badge>
                </div>
              </div>
              <div style={{ width: pieChartWidth, height: pieChartWidth }}>
                <ResponsiveContainer>
                  <PieChart>
                    {dataProject.total_task > 0 ? (
                      <>
                        <Pie
                          dataKey="value"
                          data={pieData}
                          cx="50%"
                          cy="50%"
                          margin={{ top: 0, right: -20, left: -20, bottom: 0 }}
                        >
                          {pieData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={pieColors[index % pieColors.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip content={<CustomTooltip />} />
                        <Legend width={pieChartWidth} align="center" />
                      </>
                    ) : (
                      <Pie
                        dataKey="value"
                        data={pieData}
                        cx="50%"
                        cy="50%"
                        fill="#e2e8f0"
                      />
                    )}
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default TabOverview;
