import { createSlice } from "@reduxjs/toolkit";
import { InstanceApi } from "../../helpers/InstanceApi";
import { Validate } from "../../helpers/Validate";
import { setPopup } from "../popupSlice";

export const emailSmtpSlice = createSlice({
  name: "emailSmtp",
  initialState: {
    formValidate: {
      smtp_host: { required: true, title: "SMTP Host" },
      smtp_port: { required: true, title: "SMTP Port" },
      smtp_username: { required: true, title: "SMTP Username" },
      smtp_password: { required: true, title: "SMTP Password" },
      smtp_encryption: { required: true, title: "SMTP Encryption" },
      from_name: { required: true, title: "From Name" },
    },
    formData: {
      id: "",
      smtp_host: "",
      smtp_port: "",
      smtp_username: "",
      smtp_password: "",
      smtp_encryption: "",
      from_name: "",
    },
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData[action.payload.name] = action.payload.value;
    },
  },
});

export const getFormData = (params) => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "loading" }));

    const response = await InstanceApi.get("/email/smtp/" + params.id);
    if (response) {
      const data = response.data.data;
      const formData = getState().emailSmtp.formData;
      Object.keys(formData).forEach((key) => {
        dispatch(setFormData({ name: key, value: data?.[key] ?? "" }));
      });

      dispatch(setPopup({ open: false }));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(error.response.data.message);
  }
};

export const submit = () => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "processing" }));
    const formValidate = getState().emailSmtp.formValidate;
    const formData = getState().emailSmtp.formData;
    const validate = Validate(formValidate, formData);

    if (!validate.isValid) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: validate.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
    } else {
      const response = await InstanceApi.patch(
        "/email/smtp/" + formData.id,
        formData
      );

      if (response) {
        const data = response.data.data;
        const formData = getState().emailSmtp.formData;
        Object.keys(formData).forEach((key) => {
          dispatch(setFormData({ name: key, value: data?.[key] ?? "" }));
        });

        dispatch(
          setPopup({
            open: true,
            type: "success",
            message: response.data.message,
            handleOk: () => {
              dispatch(setPopup({ open: false }));            },
          })
        );
      }
    }
  } catch (err) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: err.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(err.response.data.message);
  }
};

export const { setFormData } =
  emailSmtpSlice.actions;
export default emailSmtpSlice.reducer;
