import React, { useState, useEffect } from "react";
import {
  Card,
  CardAction,
  CardBody,
  CardHeader,
  CardTitle,
} from "../../components/Card";
import Badge from "../../components/Badge";
import { useDispatch, useSelector } from "react-redux";
import { BiChevronRight, BiNote, BiNotepad, BiUser } from "react-icons/bi";
import { FaExclamation } from "react-icons/fa";
import { getDataDashboard } from "../../stores/pm/pmDashboardSlice";
import { Link, useNavigate } from "react-router-dom";

export default function PmDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dataProjectCount = useSelector(
    (state) => state.pmDashboard.dataProjectCount
  );
  const dataTaskCount = useSelector((state) => state.pmDashboard.dataTaskCount);
  const dataProjectDeadline = useSelector(
    (state) => state.pmDashboard.dataProjectDeadline
  );
  const dataProjectActive = useSelector(
    (state) => state.pmDashboard.dataProjectActive
  );
  const dataTaskActive = useSelector(
    (state) => state.pmDashboard.dataTaskActive
  );
  const dataProjectWaitingApproval = useSelector(
    (state) => state.pmDashboard.dataProjectWaitingApproval
  );
  const dataProjectActivity = useSelector(
    (state) => state.pmDashboard.dataProjectActivity
  );

  const [filterProjectActive, setFilterProjectActive] = useState(undefined);
  const [filterTaskActive, setFilterTaskActive] = useState(undefined);

  useEffect(() => {
    dispatch(getDataDashboard());
  }, [dispatch]);

  useEffect(() => {
    setFilterProjectActive(dataProjectActive);
  }, [dataProjectActive]);

  useEffect(() => {
    setFilterTaskActive(dataTaskActive);
  }, [dataTaskActive]);

  const ListItemProject = (props) => {
    const { item } = props;
    const status = item.status.replace(" ", "").toLowerCase();
    return (
      <Link to={`/pm/projects/view/${item.id}`}>
        <div
          className={`h-full w-80 p-2 rounded-md flex flex-row items-center gap-2 cursor-pointer hover hover:bg-slate-tertiary transition duration-300 border border-slate-tertiary`}
        >
          <div className="flex-1 h-full flex flex-col items-start justify-between gap-4">
            <div className="flex-1 w-full flex flex-row items-start gap-2">
              <div className="flex-1 line-clamp-2 text-sm text-text-primary font-semibold">
                [#{item.project_no}] {item.project_name}
              </div>
              {item.urgent === 1 && (
                <div className="h-4 w-4 flex items-center justify-center bg-warning text-xs text-white rounded-full">
                  <FaExclamation />
                </div>
              )}
            </div>
            <div className="w-full space-y-1 text-[11px]">
              <div className="flex flex-row gap-1 items-center justify-between">
                <span className="text-text-secondary">
                  {item.deadline_convert}
                </span>
                <span className=" text-danger">
                  {item.date_diff_deadline > 0 ? (
                    item.date_diff_deadline < 8 && (
                      <span>{item.date_diff_deadline} days left</span>
                    )
                  ) : (
                    <span>Over {item.date_diff_deadline * -1} days</span>
                  )}
                </span>
              </div>
              <div
                className="h-1.5 bg-slate-tertiary rounded-full"
                style={{ width: "100%" }}
              >
                {item.total_task > 0 && (
                  <span
                    className={`h-1.5 block bg-${status} rounded-full`}
                    style={{
                      width:
                        (item.total_completed_task > 0
                          ? parseFloat(item.task_progress)
                          : "0") + "%",
                    }}
                  ></span>
                )}
              </div>
              <div className="flex flex-row gap-1 items-center justify-between">
                <span className=" flex flex-row items-center gap-1 text-text-secondary">
                  <div
                    className={`h-2.5 w-2.5 rounded-full bg-${status}`}
                  ></div>
                  {item.status}
                </span>
                <span className="text-text-secondary">
                  {item.total_completed_task}/{item.total_task}
                </span>
              </div>
            </div>
          </div>
          <div className="flex-none text-2xl text-text-secondary">
            <BiChevronRight />
          </div>
        </div>
      </Link>
    );
  };

  const ListItemProjectActive = (props) => {
    const { item } = props;
    const status = item.status.replace(" ", "").toLowerCase();
    return (
      <Link to={`/pm/projects/view/${item.id}`}>
        <div
          className={`p-2 border border-slate-tertiary rounded-md flex flex-row items-center gap-2 cursor-pointer hover hover:bg-slate-tertiary transition duration-300`}
        >
          <div className="flex-1 space-y-4">
            <div className="w-full flex flex-row items-start gap-2">
              <div className="flex-1 line-clamp-2 text-sm text-text-primary font-semibold">
                [#{item.project_no}] {item.project_name}
              </div>
              {item.urgent === 1 && (
                <div className="h-4 w-4 flex items-center justify-center bg-warning text-xs text-white rounded-full">
                  <FaExclamation />
                </div>
              )}
            </div>
            <div className="space-y-1 text-[11px]">
              <div className="flex flex-row gap-1 items-center justify-between">
                <span className="text-text-secondary">
                  {item.deadline_convert}
                </span>
                <span className=" text-danger">
                  {item.date_diff_deadline > 0 ? (
                    item.date_diff_deadline < 8 && (
                      <span>{item.date_diff_deadline} days left</span>
                    )
                  ) : (
                    <span>Over {item.date_diff_deadline * -1} days</span>
                  )}
                </span>
              </div>
              <div
                className="h-1.5 bg-slate-tertiary rounded-full"
                style={{ width: "100%" }}
              >
                {item.total_task > 0 && (
                  <span
                    className={`h-1.5 block bg-${status} rounded-full`}
                    style={{
                      width:
                        (item.total_completed_task > 0
                          ? parseFloat(item.task_progress)
                          : "0") + "%",
                    }}
                  ></span>
                )}
              </div>
              <div className="flex flex-row gap-1 items-center justify-between">
                <span className="flex flex-row items-center gap-1 text-text-secondary">
                  <div
                    className={`h-2.5 w-2.5 rounded-full bg-${status}`}
                  ></div>
                  {item.status}
                </span>
                <span className="text-text-secondary">
                  {item.total_completed_task}/{item.total_task}
                </span>
              </div>
            </div>
          </div>
          <div className="flex-none text-xl text-text-secondary">
            <BiChevronRight />
          </div>
        </div>
      </Link>
    );
  };

  const ListItemTaskActive = (props) => {
    const { item } = props;
    const status = item.status.replace(" ", "").toLowerCase();
    return (
      <Link to={`/pm/tasks/${item.id}`}>
        <div
          className={`p-2 border border-slate-tertiary rounded-md flex flex-row items-center gap-2 cursor-pointer hover hover:bg-slate-tertiary transition duration-300`}
        >
          <div className="flex-1 space-y-4">
            <div className="line-clamp-1 text-sm text-text-primary font-semibold">
              [#{item.task_no}] {item.task_name}
            </div>
            <div className="grid grid-cols-3 gap-2 text-[11px]">
              <div className="flex flex-row items-center gap-1 text-sm text-text-secondary">
                <div className={`h-2.5 w-2.5 rounded-full bg-${status}`}></div>
                <span>{item.status}</span>
              </div>
              <div className="col-span-2 flex flex-row items-center gap-1 text-sm text-text-secondary">
                <BiUser />{" "}
                {item.responsible_name ? (
                  <span className="line-clamp-1">{item.responsible_name}</span>
                ) : (
                  <span className="text-danger">No responsible</span>
                )}
              </div>
            </div>
          </div>
          <div className="flex-none text-xl text-text-secondary">
            <BiChevronRight />
          </div>
        </div>
      </Link>
    );
  };

  const ListItemApproval = (props) => {
    const { item } = props;
    let url = "/pm/projects";
    switch (item.object) {
      case "Project":
        url = `/pm/projects/view/${item.object_id}`;
        break;
      case "Task":
        url = `/pm/tasks/${item.object_id}`;
        break;
      case "ProjectItem":
        url = `/pm/projects/view/${item.project_id}`;
        break;
      default:
        break;
    }

    return (
      <Link to={url}>
        <div className="p-2 border border-slate-tertiary rounded-md cursor-pointer hover hover:bg-slate-tertiary transition duration-300">
          <div className="mb-2 flex flex-row items-center justify-between">
            <div className="flex-1 text-sm font-semibold text-text-primary truncate">
              {item.title}
            </div>
            <div className="text-xs text-text-secondary">
              {item.created_at_convert}
            </div>
          </div>
          <div className="space-y-2 text-text-secondary">
            <div className="text-sm">{item.object_name}</div>
            <div className="text-sm">
              Waiting approval by {item.approval_by}
            </div>
          </div>
        </div>
      </Link>
    );
  };

  const ListItemActivity = (props) => {
    const { item } = props;

    return (
      <div className="p-2 border border-slate-tertiary rounded-md">
        <div className="mb-2 flex flex-row items-center justify-between">
          <div className="flex-1 text-sm text-text-primary font-semibold truncate">
            {item.created_by}
          </div>
          <div className="text-xs text-text-secondary">{item.created_at}</div>
        </div>
        <div className="text-sm text-text-secondary">{item.message}</div>
        {item.notes && item.notes !== "" && (
          <div className="mt-4 flex flex-row items-center gap-2 text-text-secondary">
            <BiNotepad />
            <div className="text-sm">1 notes</div>
          </div>
        )}
      </div>
    );
  };

  const Loading = () => {
    return (
      <div className="w-full h-8 flex items-center justify-center">
        <svg
          className="animate-spin h-5 w-5 text-text-secondary"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="white"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="white"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    );
  };

  const handleFilterActiveProject = (e) => {
    const value = e.target.value;
    let filtered = dataProjectActive;
    switch (value) {
      case "All":
        filtered = dataProjectActive;
        break;
      case "Urgent":
        filtered = [...dataProjectActive].filter((data) => data.urgent === 1);
        break;
      case "In Progress":
      case "Finish":
        filtered = [...dataProjectActive].filter(
          (data) => data.status === value
        );
        break;
      default:
        break;
    }

    setFilterProjectActive(filtered);
  };

  const handleFilterActiveTask = (e) => {
    const value = e.target.value;
    let filtered = dataTaskActive;
    switch (value) {
      case "All":
        filtered = dataTaskActive;
        break;
      case "Open":
      case "In Progress":
        filtered = [...dataTaskActive].filter((data) => data.status === value);
        break;
      default:
        break;
    }

    setFilterTaskActive(filtered);
  };

  return (
    <>
      <div className="w-full flex flex-row gap-4">
        <div className="w-[75%] space-y-4">
          <div className="w-full grid grid-cols-2 gap-4">
            <div className="col-span-1">
              <Card>
                <CardHeader>
                  <CardTitle>Project This Year</CardTitle>
                  {dataProjectCount && (
                    <CardAction>
                      <Badge type="info">
                        {dataProjectCount.total_project}
                      </Badge>
                    </CardAction>
                  )}
                </CardHeader>
                <CardBody>
                  <div className="grid grid-cols-4 gap-2">
                    <div className="col-span-1 text-center rounded-lg p-2 bg-inprogress text-white">
                      <span className="block text-3xl font-bold">
                        {!dataProjectCount ? (
                          <Loading />
                        ) : (
                          dataProjectCount.total_inprogress
                        )}
                      </span>
                      <span className="block text-[11px] font-normal">
                        In Progress
                      </span>
                    </div>
                    <div className="col-span-1 text-center rounded-lg p-2 bg-finish text-white">
                      <span className="block text-3xl font-bold">
                        {!dataProjectCount ? (
                          <Loading />
                        ) : (
                          dataProjectCount.total_finish
                        )}
                      </span>
                      <span className="block text-[11px] font-normal">
                        Finish
                      </span>
                    </div>
                    <div className="col-span-1 text-center rounded-lg p-2 bg-completed text-white">
                      <span className="block text-3xl font-bold">
                        {!dataProjectCount ? (
                          <Loading />
                        ) : (
                          dataProjectCount.total_completed
                        )}
                      </span>
                      <span className="block text-[11px] font-normal">
                        Completed
                      </span>
                    </div>
                    <div className="col-span-1 text-center rounded-lg p-2 bg-canceled text-white">
                      <span className="block text-3xl font-bold">
                        {!dataProjectCount ? (
                          <Loading />
                        ) : (
                          dataProjectCount.total_canceled
                        )}
                      </span>
                      <span className="block text-[11px] font-normal">
                        Canceled
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-span-1">
              <Card>
                <CardHeader>
                  <CardTitle>Tasks</CardTitle>
                  {dataTaskCount && (
                    <CardAction>
                      <Badge type="info">{dataTaskCount.total_task}</Badge>
                    </CardAction>
                  )}
                </CardHeader>
                <CardBody>
                  <div className="grid grid-cols-4 gap-2">
                    <div className="col-span-1 text-center rounded-lg p-2 bg-open text-white">
                      <span className="block text-3xl font-bold">
                        {!dataTaskCount ? (
                          <Loading />
                        ) : (
                          dataTaskCount.total_open
                        )}
                      </span>
                      <span className="block text-[11px] font-normal">
                        Open
                      </span>
                    </div>
                    <div className="col-span-1 text-center rounded-lg p-2 bg-inprogress text-white">
                      <span className="block text-3xl font-bold">
                        {!dataTaskCount ? (
                          <Loading />
                        ) : (
                          dataTaskCount.total_inprogress
                        )}
                      </span>
                      <span className="block text-[11px] font-normal">
                        In Progress
                      </span>
                    </div>
                    <div className="col-span-1 text-center rounded-lg p-2 bg-finish text-white">
                      <span className="block text-3xl font-bold">
                        {!dataTaskCount ? (
                          <Loading />
                        ) : (
                          dataTaskCount.total_finish
                        )}
                      </span>
                      <span className="block text-[11px] font-normal">
                        Finish
                      </span>
                    </div>
                    <div className="col-span-1 text-center rounded-lg p-2 bg-completed text-white">
                      <span className="block text-3xl font-bold">
                        {!dataTaskCount ? (
                          <Loading />
                        ) : (
                          dataTaskCount.total_completed
                        )}
                      </span>
                      <span className="block text-[11px] font-normal">
                        Completed
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
          <div className="w-full">
            <Card>
              <CardHeader>
                <CardTitle>Deadline Project</CardTitle>
                {dataProjectDeadline && (
                  <CardAction>
                    <div className="badge badge-info">
                      {dataProjectDeadline.length}
                    </div>
                  </CardAction>
                )}
              </CardHeader>
              <CardBody>
                <div className="w-full flex justify-between  overflow-x-hidden">
                  <div className="w-full pb-1 flex overflow-x-scroll slim-x-overflow [&>div]:flex-shrink-0 gap-2">
                    {!dataProjectDeadline && (
                      <div className="h-[98px] w-full p-2 text-center font-semibold">
                        Loading...
                      </div>
                    )}
                    {dataProjectDeadline &&
                      (dataProjectDeadline.length > 0 ? (
                        dataProjectDeadline.map((item, index) => (
                          <ListItemProject key={index} item={item} />
                        ))
                      ) : (
                        <div className="h-[98px] w-full p-2 text-center font-semibold">
                          No project deadline
                        </div>
                      ))}
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="w-full grid grid-cols-2 gap-4">
            <div className="col-span-1">
              <Card>
                <CardHeader>
                  <CardTitle>Active Projects</CardTitle>
                  <CardAction>
                    <select
                      className="form-control !h-8 text-sm"
                      onChange={handleFilterActiveProject}
                    >
                      <option value="All">All</option>
                      <option value="Urgent">Urgent</option>
                      <option value="In Progress">In Progress</option>
                      <option value="Finish">Finish</option>
                    </select>
                  </CardAction>
                </CardHeader>
                <div
                  className="card-body"
                  style={{ paddingLeft: "0", paddingRight: "0" }}
                >
                  <div className="overflow-y-scroll slim-overflow pl-4 pr-1">
                    <div
                      className="flex flex-col gap-2"
                      style={{ minHeight: 300, maxHeight: 300 }}
                    >
                      {!filterProjectActive && (
                        <div className="h-[58px] w-full p-2 text-center font-semibold">
                          Loading...
                        </div>
                      )}
                      {filterProjectActive &&
                        (filterProjectActive.length > 0 ? (
                          filterProjectActive.map((item, index) => (
                            <ListItemProjectActive key={index} item={item} />
                          ))
                        ) : (
                          <div className="h-[58px] p-2 text-center font-semibold">
                            No active project
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div className="col-span-1">
              <Card>
                <CardHeader>
                  <CardTitle>Active Task</CardTitle>
                  <CardAction>
                    <select
                      className="form-control !h-8 text-sm"
                      onChange={handleFilterActiveTask}
                    >
                      <option value="All">All</option>
                      <option value="Open">Open</option>
                      <option value="In Progress">In Progress</option>
                    </select>
                  </CardAction>
                </CardHeader>
                <div
                  className="card-body"
                  style={{ paddingLeft: "0", paddingRight: "0" }}
                >
                  <div className="overflow-y-scroll slim-overflow pl-4 pr-1">
                    <div
                      className="flex flex-col gap-2"
                      style={{ minHeight: 300, maxHeight: 300 }}
                    >
                      {!filterTaskActive && (
                        <div className="h-[58px] w-full p-2 text-center font-semibold">
                          Loading...
                        </div>
                      )}
                      {filterTaskActive &&
                        (filterTaskActive.length > 0 ? (
                          filterTaskActive.map((item, index) => (
                            <ListItemTaskActive key={index} item={item} />
                          ))
                        ) : (
                          <div className="h-[58px] p-2 text-center font-semibold">
                            No task active
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
        <div className="w-[25%]">
          <div className="space-y-4">
            <Card>
              <CardHeader>
                <CardTitle>Waiting Approval</CardTitle>
                {dataProjectWaitingApproval && (
                  <CardAction>
                    <div className="badge badge-info">
                      {dataProjectWaitingApproval.length}
                    </div>
                  </CardAction>
                )}
              </CardHeader>
              <div
                className="card-body"
                style={{ paddingLeft: "0", paddingRight: "0" }}
              >
                <div className="overflow-y-scroll slim-overflow pl-4 pr-1">
                  <div
                    className="flex flex-col gap-2"
                    style={{ minHeight: 293, maxHeight: 293 }}
                  >
                    {!dataProjectWaitingApproval && (
                      <div className="w-full text-center font-semibold">
                        Loading...
                      </div>
                    )}
                    {dataProjectWaitingApproval &&
                      (dataProjectWaitingApproval.length > 0 ? (
                        dataProjectWaitingApproval.map((item, index) => (
                          <ListItemApproval key={index} item={item} />
                        ))
                      ) : (
                        <div className="text-center font-semibold">
                          No waiting approval
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Project Activity</CardTitle>
              </CardHeader>
              <div
                className="card-body"
                style={{ paddingLeft: "0", paddingRight: "0" }}
              >
                <div className="overflow-y-scroll slim-overflow pl-4 pr-1">
                  <div
                    className="gap-2"
                    style={{ minHeight: 309, maxHeight: 309 }}
                  >
                    {!dataProjectActivity && (
                      <div className="w-full text-center font-semibold">
                        Loading...
                      </div>
                    )}
                    {dataProjectActivity &&
                      (dataProjectActivity.length > 0 ? (
                        dataProjectActivity.map((item, index) => {
                          let url = "";
                          if (item.object === "Project") {
                            url = `/pm/projects/view/${item.project_id}`;
                          } else {
                            url = `/pm/tasks/${item.task_id}`;
                          }
                          return (
                            <div key={index}>
                              <Link to={url}>
                                <ListItemActivity item={item} />
                              </Link>
                            </div>
                          );
                        })
                      ) : (
                        <div className="text-center font-semibold">
                          No project activity
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
