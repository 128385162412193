import React from 'react'

export const Card = ({children}, props) => {
  return (
    <div className='card' {...props}>
      {children}
    </div>
  )
}

export const CardHeader = ({ children }) => {
  return <div className="card-header">{children}</div>;
};

export const CardTitle = ({children}) => {
  return (
    <div className="card-title">
      <span>{children}</span>
    </div>
  );
}

export const CardFilter = ({children}) => {
  return (
    <div className="card-filter">
      {children}
    </div>
  );
}

export const CardAction = ({children}) => {
  return (
    <div className="card-action">
      {children}
    </div>
  );
}

export const CardBody = ({children}) => {
  return (
    <div className="card-body">
      {children}
    </div>
  );
}
export const CardFooter = ({children}) => {
  return (
    <div className="card-footer">
      {children}
    </div>
  );
}
