import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PageHeader, PageHeaderTitle } from "../../components/PageHeader";

import { CheckRoleIndex } from "../../helpers/CheckRole";
import { Card, CardBody, CardFooter } from "../../components/Card";
import { Button } from "../../components/Button";
import { setPopup } from "../../stores/popupSlice";
import { InstanceApi } from "../../helpers/InstanceApi";
import { DownloadFile } from "../../helpers/DownloadFile";

function Index() {
  const pathName = "/reports/clients";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!CheckRoleIndex(pathName)) {
      navigate("/404");
    }
  }, [dispatch, navigate]);

  const submit = async (e) => {
    e.preventDefault();

    try {
      dispatch(setPopup({ open: true, type: "processing" }));

      const response = await InstanceApi.post("/reports/clients");
      if (response) {
        dispatch(setPopup({ open: false }));

        dispatch(setPopup({ open: true, type: "download" }));

        const downloadUrl = "/download?file_path=" + response.data.data;
        console.log(downloadUrl);
        DownloadFile(downloadUrl);

        setTimeout(() => {
          dispatch(setPopup({ open: false }));
        }, 3000);
      }
    } catch (err) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: err.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
      console.error(err.response.data.message);
    }
  };

  return (
    <>
      <PageHeader>
        <PageHeaderTitle>Client Report</PageHeaderTitle>
      </PageHeader>
      <Card>
        <CardBody>
          <Button
            title="Generate Report"
            icon="BiDownload"
            color="primary"
            onClick={submit}
          />
        </CardBody>
      </Card>
    </>
  );
}

export default Index;
