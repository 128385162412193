import { createSlice } from "@reduxjs/toolkit";
import { InstanceApi } from '../../helpers/InstanceApi'

export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    openModal: false,
    openNotification: false,
    dataAll: [],
    dataNew: [],
    totalUnread: 0,
  },
  reducers: {
    setModal: (state, action) => {
      state.openModal = action.payload;
    },
    setOpenNotification: (state, action) => {
      state.openNotification = action.payload;
    },
    setDataAll: (state, action) => {
      state.dataAll = action.payload;
    },
    setDataNew: (state, action) => {
      state.dataNew = action.payload;
    },
    setTotalUnread: (state, action) => {
      state.totalUnread = action.payload;
    },
  },
});

export const getDataAll = () => async (dispatch) => {
    try {
        const response = await InstanceApi.get('/general/notifications');
        if(response){
            dispatch( setDataAll(response.data.data) )
        }
    } catch (error) {
        console.log(error)
    }
}

export const getDataNew = () => async (dispatch) => {
    try {
        const response = await InstanceApi.get('/general/notifications/new');
        if(response){
            dispatch( setDataNew(response.data.data));
        }
    } catch (error) {
        console.log(error)
    }
}

export const unreadNotification = () => async (dispatch) => {
    try {
        const response = await InstanceApi.get('/general/notifications/unread');
        if (response) {
          dispatch(setTotalUnread(response.data.data));
        }
    } catch (error) {
        console.log(error)
    }
}

export const readNotification = () => async (dispatch) => {
    try {
        await InstanceApi.get('/general/notifications/read');
    } catch (error) {
        console.log(error)
    }
}

export const {
  setModal,
  setOpenNotification,
  setDataAll,
  setDataNew,
  setTotalUnread,
} = notificationSlice.actions;
export default notificationSlice.reducer