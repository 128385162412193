import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiChevronRight, BiSearch } from "react-icons/bi";
import { getDataByTask } from "../../../stores/pm/taskAttachmentSlice";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import { setPopup } from "../../../stores/popupSlice";
import { DownloadFile } from "../../../helpers/DownloadFile";

function TabAttachment() {
  const dispatch = useDispatch();
  const dataTask = useSelector((state) => state.task.dataView);
  const dataByTask = useSelector((state) => state.taskAttachment.dataByTask);
  const [listItem, setListItem] = useState(undefined);

  useEffect(() => {
    dispatch(getDataByTask({ taskId: dataTask.id }));
  }, [dispatch, dataTask.id]);

  const getDownloadFile = (path) => {
    dispatch(setPopup({ open: true, type: "download" }));

    const downloadUrl = "/download?file_path=" + path;
    DownloadFile(downloadUrl);

    setTimeout(() => {
      dispatch(setPopup({ open: false }));
    }, 3000);
  };

  useEffect(() => {
    setListItem(dataByTask);
  }, [dataByTask]);

  const handleSearch = (value) => {
    const filtered = [...dataByTask].filter(
      (data) =>
        data?.file_name?.toString().toLowerCase().indexOf(value) > -1 ||
        data?.file_size?.toString().toLowerCase().indexOf(value) > -1 ||
        data?.type?.toString().toLowerCase().indexOf(value) > -1 ||
        data?.created_at_convert?.toString().toLowerCase().indexOf(value) > -1
    );

    setListItem(filtered);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Attachments</CardTitle>
      </CardHeader>
      <CardBody>
        {!listItem && (
          <div className="text-center font-semibold">Loading...</div>
        )}
        {listItem && (
          <>
            <div className="mb-2">
              <div className="relative">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <BiSearch className="w-5 h-5 text-gray-300" />
                </div>
                <input
                  className="form-control-search"
                  placeholder="Search..."
                  onChange={(e) =>
                    handleSearch(e.target.value.toString().toLowerCase())
                  }
                />
              </div>
            </div>
            <div className="space-y-2">
              {listItem.length === 0 ? (
                <div className="py-4 text-center font-semibold">
                  Empty attachment
                </div>
              ) : (
                <>
                  {listItem.map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-row items-center gap-2 bg-slate-50 border border-slate-tertiary hover:bg-slate-tertiary rounded-lg truncate text-left cursor-pointer"
                      onClick={() => getDownloadFile(item.file_path)}
                    >
                      <div className="flex-1 flex flex-col gap-4 py-4 px-4 ">
                        <div className="truncate">
                          <span className="truncate text-base font-semibold">
                            {" "}
                            {item.file_name}
                          </span>
                        </div>
                        <div className="grid grid-cols-5 gap-2">
                          <div className="col-span-1 text-sm">
                            <span className="mb-2 block text-text-secondary">
                              Size
                            </span>
                            <span className="block">{item.file_size}</span>
                          </div>
                          <div className="col-span-2 text-sm">
                            <span className="mb-2 block text-text-secondary">
                              From
                            </span>
                            <span className="block">{item.type}</span>
                          </div>
                          <div className="col-span-2 text-sm">
                            <span className="mb-2 block text-text-secondary">
                              Created Date
                            </span>
                            <span className="block">
                              {item.created_at_convert}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-center px-4">
                        <BiChevronRight size={20} />
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
}

export default TabAttachment;
