import React, { useEffect, useState, useRef } from "react";
import { InstanceApi } from "../../../helpers/InstanceApi";
import { BiTrash } from "react-icons/bi";
import { Button } from "../../../components/Button";
import ModalForm from "../../../components/ModalForm";
import Select from "../../../components/Select";

import { useSelector, useDispatch } from "react-redux";
import {
  resetFormData,
  getDataView,
  setModalResponsible,
  setFormAssign,
  assignTask,
  setDataTemporaryAttachment,
} from "../../../stores/pm/taskSlice";
import { getDataOptions as getDataOptionEmployee } from "../../../stores/master/employeeSlice";
import { setPopup } from "../../../stores/popupSlice";


function FormResponsible() {
  const dispatch = useDispatch();
  const [modalAttachment, setModalAttachment] = useState(false);
  const taskId = useSelector((state) => state.task.dataView.id);
  const projectId = useSelector((state) => state.task.dataView.project_id);
  const dataOptionEmployee = useSelector((state) => state.employee.dataOptions);
  const dataFormAssign = useSelector((state) => state.task.formAssign);
  const dataTemporaryAttachment = useSelector(
    (state) => state.task.dataTemporaryAttachment
  );
  const formData = useSelector((state) => state.taskResponsible.formData);

  // attachment
  const inputRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);
  const [file, setFile] = useState();

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const saveAttachment = () => {
    dispatch(
      setDataTemporaryAttachment([
        ...dataTemporaryAttachment,
        {
          name: file.name,
          value: file,
        },
      ])
    );

    setModalAttachment(false);
    setFile(undefined);
  };

  const deleteAttachment = (deleteIndex) => {
    let data = dataTemporaryAttachment.filter(
      (_, index) => index !== deleteIndex
    );
    dispatch(setDataTemporaryAttachment(data));
  };

  useEffect(() => {
    dispatch(resetFormData());
    dispatch(setFormAssign({ name: "id", value: taskId }));
    dispatch(getDataOptionEmployee({ projectId: projectId }));
  }, [dispatch, taskId, projectId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (dataFormAssign.responsible_id === "") {
        dispatch(
          setPopup({
            open: true,
            type: "error",
            message: "Employee is required!",
            handleOk: () => {
              dispatch(setPopup({ open: false }));
            },
          })
        );
    } else {
      processSubmit();
    }
  };

  const processSubmit = async () => {
    dispatch(setPopup({ open: true, type: "processing" }));
    const formData = new FormData();

    // Update the formData object
    formData.append("id", dataFormAssign.id);
    formData.append("responsible_id", dataFormAssign.responsible_id);
    if (dataTemporaryAttachment.length > 0) {
      dataTemporaryAttachment.map((item, _) =>
        formData.append("attachment[]", item.value, item.name)
      );
    }

    try {
      const response = await InstanceApi.post(`/pm/tasks/assign`, formData);
      if (response) {
        dispatch(
          setPopup({
            open: true,
            type: "success",
            message: response.data.message,
            handleOk: () => {
              dispatch(setPopup({ open: false }));
              dispatch(setModalResponsible(false));
              dispatch(getDataView({ id: dataFormAssign.id }));
            },
          })
        );
      }
    } catch (error) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: error.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
    }
  };

  return (
    <>
      {dataOptionEmployee && (
        <ModalForm
          modalTitle="Add Responsible"
          modalSize="medium"
          closeModal={() => dispatch(setModalResponsible(false))}
        >
          <form>
            <div className="w-96">
              <div className="form-group">
                <label className="control-label">
                  Employee <span className="text-danger">*</span>
                </label>
                <input
                  id="employee_id"
                  title="Employee"
                  type="hidden"
                  name="employee_id"
                  value={formData.employee_id}
                  required
                />
                <Select
                  inputId="employee-select"
                  placeholder={"Choose employee..."}
                  onChange={(e) =>
                    dispatch(
                      setFormAssign({ name: "responsible_id", value: e?.value })
                    )
                  }
                  options={dataOptionEmployee}
                  required={true}
                />
              </div>
            </div>

            <div className="mt-8 form-group">
              <div className="mb-2 flex flex-row items-center justify-between gap-4">
                <label className="control-label">Attachments</label>
                <Button
                  title="Add Attachment"
                  icon="BiPlus"
                  size="sm"
                  color="primary"
                  onClick={() => setModalAttachment(true)}
                />
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ width: "5%" }}>No.</th>
                    <th>Files</th>
                    <th style={{ width: "5%" }}>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {dataTemporaryAttachment.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>
                        <span
                          className="btn-action"
                          onClick={() => deleteAttachment(index)}
                        >
                          <BiTrash size="22" />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="mt-8 flex gap-2">
              <Button
                title="Save"
                icon="BiSave"
                color="primary"
                onClick={handleSubmit}
              />
              <Button
                title="Cancel"
                icon="BiX"
                color="secondary"
                onClick={() => dispatch(setModalResponsible(false))}
              />
            </div>
          </form>
        </ModalForm>
      )}

      {modalAttachment && (
        <ModalForm
          modalTitle="Upload Attachment"
          modalSize="medium"
          closeModal={() => setModalAttachment(false)}
        >
          <form onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
            <div
              className={`modal-upload-box ${
                dragActive ? "border-slate-800" : ""
              }`}
            >
              <div className="body">
                <input
                  id="input-file-upload"
                  type="file"
                  className="hidden"
                  ref={inputRef}
                  onChange={handleChange}
                />
                <p>
                  Drag and drop your file here or{" "}
                  <span
                    className="cursor-pointer underline text-primary-hover"
                    onClick={onButtonClick}
                  >
                    browse
                  </span>
                </p>
                {file && <p>{file.name}</p>}
              </div>
              <div className="mt-6 flex flex-row gap-2 items-center justify-center">
                {file ? (
                  <Button
                    title="Upload"
                    icon="BiUpload"
                    color="primary"
                    onClick={saveAttachment}
                  />
                ) : (
                  <Button title="Upload" icon="BiUpload" color="disabled" />
                )}
              </div>

              {dragActive && (
                <div
                  className="absolute w-full h-full rounded top-0 right-0 left-0 bottom-0"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
            </div>
          </form>
        </ModalForm>
      )}
    </>
  );
}

export default FormResponsible;
