import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  PageHeader,
  PageHeaderTitle,
} from "../../../components/PageHeader";
import { CheckRoleIndex } from "../../../helpers/CheckRole";
import IndexTable from "./indexTable";

export default function Index() {
  const pathName = "/email/template";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!CheckRoleIndex(pathName)) {
      navigate("/404");
    }
  }, [dispatch, navigate]);


  return (
    <>
      <PageHeader>
        <PageHeaderTitle>Email Templates</PageHeaderTitle>
      </PageHeader>
      <IndexTable />
    </>
  );
}
