import React, { useEffect, useState } from "react";
import { Button } from "../../../components/Button";
import ModalForm from "../../../components/ModalForm";

import { useSelector, useDispatch } from "react-redux";
import {
  resetFormData,
  setModal,
  getFormData,
  setFormData,
  submit,
} from "../../../stores/master/clientSlice";

function Form(props) {
  const { formType, dataId } = props;
  const dispatch = useDispatch();
  const openPopup = useSelector((state) => state.popup.openPopup);
  const openModal = useSelector((state) => state.client.openModal);
  const formData = useSelector((state) => state.client.formData);
  const [modalTitle, setModalTitle] = useState();

  useEffect(() => {
    dispatch(resetFormData());

    if (formType === "create") {
      setModalTitle("Add Client");
      dispatch(setFormData({ name: "active", value: 1 }));
    } else {
      setModalTitle("Edit Client");
      dispatch(getFormData({ id: dataId }));
    }
  }, [dispatch, formType, dataId]);

  const handleInput = (e) => {
    dispatch(setFormData(e.target));
  };

  return (
    <>
      {!openPopup.open && openModal && (
        <ModalForm
          modalTitle={modalTitle}
          modalSize="small"
          closeModal={() => dispatch(setModal(false))}
        >
          <div className="w-full">
            <div className="form-group">
              <label className="control-label">
                Client Name <span className="text-danger">*</span>
              </label>
              <input
                id="client_name"
                title="Client Name"
                type="text"
                name="client_name"
                className="form-control"
                value={formData.client_name}
                onChange={handleInput}
                required
              />
            </div>
            <div className="form-group">
              <label className="control-label">Address</label>
              <textarea
                id="address"
                title="Address"
                name="address"
                className="form-control h-60"
                defaultValue={formData.address}
                onChange={handleInput}
              />
            </div>
            <div className="form-group">
              <label className="control-label">Contact Name</label>
              <input
                id="contact_name"
                title="Contact Name"
                type="text"
                name="contact_name"
                className="form-control"
                value={formData.contact_name}
                onChange={handleInput}
              />
            </div>
            <div className="form-group">
              <label className="control-label">Email</label>
              <input
                id="email"
                title="Email"
                type="text"
                name="email"
                className="form-control"
                value={formData.email}
                onChange={handleInput}
              />
            </div>
            <div className="form-group">
              <label className="control-label">Active</label>
              <div className="form-checkbox">
                <input
                  id="active"
                  title="Active"
                  type="checkbox"
                  name="active"
                  checked={formData?.active?.toString() === "1" ? true : false}
                  onChange={(e) => {
                    if (e.target.checked) {
                      e.target.value = 1;
                    } else {
                      e.target.value = 0;
                    }
                    handleInput(e);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="mt-8 flex gap-2">
            <Button
              title="Save"
              icon="BiSave"
              color="primary"
              onClick={() => dispatch(submit({ formType: formType }))}
            />
            <Button
              title="Cancel"
              icon="BiX"
              color="secondary"
              onClick={() => dispatch(setModal(false))}
            />
          </div>
        </ModalForm>
      )}
    </>
  );
}

export default Form;
