import React, { useEffect, useState, useRef } from "react";
import { InstanceApi } from "../../../helpers/InstanceApi";
import { useDispatch, useSelector } from "react-redux";
import { BiChevronRight, BiSearch } from "react-icons/bi";
import {
  getDataByProject,
  setModal,
  deleteData,
} from "../../../stores/pm/projectAttachmentSlice";
import {
  Card,
  CardAction,
  CardBody,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import { setPopup } from "../../../stores/popupSlice";
import { DownloadFile } from "../../../helpers/DownloadFile";
import { Button, IconButton } from "../../../components/Button";
import ModalForm from "../../../components/ModalForm";

function TabAttachment() {
  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const dataProject = useSelector((state) => state.project.dataView);
  const dataByProject = useSelector(
    (state) => state.projectAttachment.dataByProject
  );
  const [listItem, setListItem] = useState(undefined);

  const openModal = useSelector((state) => state.projectAttachment.openModal);
  const inputRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);
  const [file, setFile] = useState();
  const [openActionDropdown, setOpenActionDropdown] = useState(false);
  const [actionDropdownIndex, setActionDropdownIndex] = useState("");

  useEffect(() => {
    dispatch(getDataByProject({ projectId: dataProject.id }));
    setOpenActionDropdown(false);
    setActionDropdownIndex("");
  }, [dispatch, dataProject.id]);

  const getDownloadFile = (path) => {
    setOpenActionDropdown(false);
    setActionDropdownIndex("");
    dispatch(setPopup({ open: true, type: "download" }));

    const downloadUrl = "/download?file_path=" + path;
    DownloadFile(downloadUrl);

    setTimeout(() => {
      dispatch(setPopup({ open: false }));
    }, 3000);
  };

  const handleClickAction = (index) => {
    setActionDropdownIndex(index);
    if (actionDropdownIndex === index) {
      setOpenActionDropdown(!openActionDropdown);
    } else {
      setOpenActionDropdown(true);
    }
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(setPopup({ open: true, type: "upload" }));
    const formSubmit = new FormData();
    try {
      formSubmit.append("attachment[]", file, file.name);

      const response = await InstanceApi.post(
        `/pm/projects/${dataProject.id}/attachment`,
        formSubmit
      );
      if (response) {
        dispatch(
          setPopup({
            open: true,
            type: "success",
            message: response.data.message,
            handleOk: () => {
              dispatch(setPopup({ open: false }));
              dispatch(setModal(false));
              dispatch(getDataByProject({ projectId: dataProject.id }));
              setFile(undefined);
            },
          })
        );
      }
    } catch (error) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: error.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
    }
  };

  useEffect(() => {
    setListItem(dataByProject);
  }, [dataByProject]);

  const handleSearch = (value) => {
    const filtered = [...dataByProject].filter(
      (data) =>
        data?.file_name?.toString().toLowerCase().indexOf(value) > -1 ||
        data?.file_size?.toString().toLowerCase().indexOf(value) > -1 ||
        data?.type?.toString().toLowerCase().indexOf(value) > -1 ||
        data?.created_at_convert?.toString().toLowerCase().indexOf(value) > -1
    );

    setListItem(filtered);
  };

  return (
    <>
      {openModal && (
        <ModalForm
          modalTitle="Upload Attachment"
          modalSize="medium"
          closeModal={() => dispatch(setModal(false))}
        >
          <form onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
            <div
              className={`modal-upload-box ${
                dragActive ? "border-slate-800" : ""
              }`}
            >
              <div className="body">
                <input
                  id="input-file-upload"
                  type="file"
                  className="hidden"
                  ref={inputRef}
                  onChange={handleChange}
                />
                <p>
                  Drag and drop your file here or{" "}
                  <span
                    className="cursor-pointer underline text-primary-hover"
                    onClick={onButtonClick}
                  >
                    browse
                  </span>
                </p>
                {file && <p>{file.name}</p>}
              </div>
              <div className="mt-6 flex flex-row gap-2 items-center justify-center">
                {file ? (
                  <Button
                    title="Upload"
                    icon="BiUpload"
                    color="primary"
                    onClick={handleSubmit}
                  />
                ) : (
                  <Button title="Upload" icon="BiUpload" color="disabled" />
                )}
              </div>

              {dragActive && (
                <div
                  className="absolute w-full h-full rounded top-0 right-0 left-0 bottom-0"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
            </div>
          </form>
        </ModalForm>
      )}
      <Card>
        <CardHeader>
          <CardTitle>Attachments</CardTitle>
          <CardAction>
            {dataProject.owner_id === user.reference_id &&
              dataProject.status === "In Progress" && (
                <Button
                  title="Add Attachment"
                  icon="BiPlus"
                  size="sm"
                  color="primary"
                  onClick={() => dispatch(setModal(true))}
                />
              )}
          </CardAction>
        </CardHeader>
        <CardBody>
          {!listItem && (
            <div className="text-center font-semibold">Loading...</div>
          )}
          {listItem && (
            <>
              <div className="mb-2">
                <div className="relative">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <BiSearch className="w-5 h-5 text-gray-300" />
                  </div>
                  <input
                    className="form-control-search"
                    placeholder="Search..."
                    onChange={(e) =>
                      handleSearch(e.target.value.toString().toLowerCase())
                    }
                  />
                </div>
              </div>
              <div className="space-y-2">
                {listItem.length === 0 ? (
                  <div className="py-4 text-center font-semibold">
                    Empty attachment
                  </div>
                ) : (
                  <>
                    {listItem.map((item, index) => (
                      <>
                        <div
                          key={index}
                          className="flex flex-row items-start gap-2 bg-slate-50 border border-slate-tertiary rounded-lg truncate text-left"
                          // onClick={() => getDownloadFile(item.file_path)}
                        >
                          <div className="flex-1 flex flex-col gap-4 py-4 px-4 ">
                            <div className="truncate">
                              <span className="truncate text-base font-semibold">
                                {" "}
                                {item.file_name}
                              </span>
                            </div>
                            <div className="grid grid-cols-5 gap-2">
                              <div className="col-span-1 text-sm">
                                <span className="mb-2 block text-text-secondary">
                                  Size
                                </span>
                                <span className="block">{item.file_size}</span>
                              </div>
                              <div className="col-span-2 text-sm">
                                <span className="mb-2 block text-text-secondary">
                                  From
                                </span>
                                <span className="block">{item.type}</span>
                              </div>
                              <div className="col-span-2 text-sm">
                                <span className="mb-2 block text-text-secondary">
                                  Created Date
                                </span>
                                <span className="block">
                                  {item.created_at_convert}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="text-right relative">
                            <IconButton
                              size="md"
                              color="tertiary"
                              icon="BiDotsVerticalRounded"
                              onClick={() => handleClickAction(index)}
                            />
                            {openActionDropdown &&
                              actionDropdownIndex === index && (
                                <div className="absolute top-10 right-0 py-1 bg-white rounded-md shadow-md border border-slate-tertiary text-sm text-left">
                                  <span
                                    className="block px-2 py-1 hover:bg-slate-tertiary transition duration-200 cursor-pointer"
                                    onClick={() =>
                                      getDownloadFile(item.file_path)
                                    }
                                  >
                                    Download
                                  </span>
                                  {dataProject.owner_id === user.reference_id &&
                                    dataProject.status === "In Progress" && (
                                      <span
                                        className="block px-2 py-1 hover:bg-slate-tertiary transition duration-200 cursor-pointer"
                                        onClick={() =>
                                          dispatch(deleteData({ id: item.id }))
                                        }
                                      >
                                        Delete
                                      </span>
                                    )}
                                </div>
                              )}
                          </div>
                        </div>
                      </>
                    ))}
                  </>
                )}
              </div>
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
}

export default TabAttachment;
