import React from "react";
import Popup from "../../components/Popup";
import { useDispatch, useSelector } from "react-redux";
import { inputData, login } from "../../stores/authSlice";

function Login() {
  const dispatch = useDispatch();
  const openPopup = useSelector((state) => state.popup.openPopup);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login());
  };

  return (
    <>
      {openPopup.open && <Popup {...openPopup} />}

      <div className="w-full h-screen md:fixed md:flex md:flex-row bg-white">
        <div className="w-full h-full md:w-2/5 py-6 px-10 md:p-6 bg-white flex flex-col gap-4 items-center justify-between overflow-y-auto">
          <div className="w-full">
            <img
              src="/static/images/logo_vertical.png"
              className="h-20 m-auto md:m-0"
              alt=""
            />
          </div>
          <div className="w-full md:w-2/3">
            <div className="w-full space-y-2 text-center">
              <span className="block text-4xl font-bold">Welcome back</span>
              <span className="block text-sm md:text-base text-text-secondary">
                Enter your details to start this app
              </span>
            </div>
            <div className="mt-6 w-full">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="control-label">Username</label>
                  <input
                    id="username"
                    title="Username"
                    type="text"
                    className="form-control"
                    name="username"
                    placeholder="Username"
                    onChange={(e) => dispatch(inputData(e.target))}
                    autoFocus
                  />
                </div>
                <div className="form-group">
                  <div className="flex flex-row items-center justify-between">
                    <label className="control-label">Password</label>
                  </div>
                  <input
                    id="password"
                    title="Password"
                    type="password"
                    className="form-control"
                    name="password"
                    placeholder="Password"
                    onChange={(e) => dispatch(inputData(e.target))}
                  />
                </div>
                <div className="mt-8 form-group">
                  <button
                    type="submit"
                    className="btn btn-primary w-full flex items-center justify-center"
                  >
                    Sign In
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="w-full text-center">
            <span className="block text-sm md:text-base text-text-secondary">
              &copy; 2023 | Adlink Sinemedia
            </span>
          </div>
        </div>
        <div
          className="w-0 md:w-3/5 h-full"
          style={{
            background: "url('/static/images/bg_login.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        ></div>
      </div>
    </>
  );
}

export default Login;
