import React, { useEffect } from "react";
import {
  PageHeader,
  PageHeaderAction,
  PageHeaderTitle,
} from "../../../components/PageHeader";
import { Button } from "../../../components/Button";

import { useSelector, useDispatch } from "react-redux";
import {
  setDataView as setDataViewProject,
  getDataView,
} from "../../../stores/prod/prodProjectSlice";
import { useParams, useNavigate } from "react-router-dom";
import TabAbout from "./tabAbout";
import ShowTask from "../tasks/showFromProject";

import LoadingShow from "./loadingShow";

function Show() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dataProject = useSelector((state) => state.prodProject.dataView);
  const dataTask = useSelector((state) => state.prodTask.dataView);
  const openShowTask = useSelector((state) => state.prodTask.openShowTask);

  useEffect(() => {
    dispatch(setDataViewProject(undefined));

    if (!id) {
      navigate("prod/projects");
    } else {
      dispatch(getDataView({ id: id }));
    }
  }, [dispatch, navigate, id]);

  return (
    <>
      {!dataProject ? (
        <LoadingShow />
      ) : openShowTask && dataTask ? (
        <ShowTask
          projectId={dataProject.id}
          taskId={dataTask.id}
          fromSubmit={"project"}
        />
      ) : (
        <>
          <PageHeader>
            <PageHeaderTitle>
              Projects <small>Detail of Project</small>
            </PageHeaderTitle>
            <PageHeaderAction>
              <Button
                title="Back"
                icon="BiArrowBack"
                color="secondary"
                onClick={() => navigate("/prod/projects")}
              />
            </PageHeaderAction>
          </PageHeader>
          <TabAbout />
        </>
      )}
    </>
  );
}

export default Show;
