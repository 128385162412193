import React, { useEffect, useState, useRef } from "react";
import {
  PageHeader,
  PageHeaderTitle,
  PageHeaderAction,
} from "../../../components/PageHeader";
import { BiTask, BiFile } from "react-icons/bi";
import Select from "../../../components/Select";
import { Button } from "../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  resetFormData,
  setFormData,
  submit,
  getDataOptions as getDataOptionProject,
  setFormTask,
} from "../../../stores/pm/projectSlice";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import { getDataOptions as getDataOptionClient } from "../../../stores/master/clientSlice";
import {
  getDataList as getDataListTask,
} from "../../../stores/pm/pmDefaultTaskSlice";

import TabFormTask from "./tabFormTask";
import TabFormAttachment from "./tabFormAttachment";
import { CheckRoleAction } from "../../../helpers/CheckRole";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function Form() {
  const pathName = "/pm/projects";
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openTab, setOpenTab] = useState("tab-task");
  const formData = useSelector((state) => state.project.formData);
  const dataOptionProject = useSelector((state) => state.project.dataOptions);
  const dataOptionClient = useSelector((state) => state.client.dataOptions);
  const defaultTaskList = useSelector((state) => state.pmDefaultTask.dataList);
  const [pageTitle, setPageTitle] = useState();
  let { form, id } = useParams();

  const editorToolbar = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };

  const editorFormat = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];

  useEffect(() => {
    if (!CheckRoleAction(pathName, "create")) {
      navigate("/404");
    }
    dispatch(resetFormData());
    dispatch(getDataOptionClient());
    dispatch(getDataListTask());
    dispatch(getDataOptionProject());

    setPageTitle("Create");
    dispatch(setFormData({ name: "owner_id", value: user.id }));
    dispatch(setFormData({ name: "owner_name", value: user.name }));
    dispatch(setFormData({ name: "created_by", value: user.id }));
  }, [dispatch, navigate, form, id, user.id, user.name]);

  const handleInput = (e) => {
    dispatch(setFormData(e.target));    
  };

  useEffect(() => {
    dispatch(setFormTask(defaultTaskList));
  }, [dispatch, defaultTaskList]);

  const dateToday = () => {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }  

  return (
    <>
      <PageHeader>
        <PageHeaderTitle>
          Projects <small>{pageTitle}</small>
        </PageHeaderTitle>
        <PageHeaderAction>
          <Button
            title="Save"
            icon="BiSave"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              dispatch(submit());
            }}
          />
          <Button
            title="Cancel"
            icon="BiX"
            color="secondary"
            onClick={() => navigate("/pm/projects")}
          />
        </PageHeaderAction>
      </PageHeader>
      <div className="space-y-4">
        <Card>
          <CardHeader>
            <CardTitle>Detail Project</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="w-full">
              <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
                <div className="cols-span-1">
                  <div className="form-group">
                    <label className="control-label">
                      Client <span className="text-danger">*</span>
                    </label>
                    <Select
                      id="client_id"
                      title="Client"
                      name="client_id"
                      placeholder={"Choose Client..."}
                      value={dataOptionClient.filter(
                        (option) => option.value === formData.client_id
                      )}
                      onChange={(e) =>
                        dispatch(
                          setFormData({ name: "client_id", value: e?.value })
                        )
                      }
                      options={dataOptionClient}
                      required={true}
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
                <div className="cols-span-1">
                  <div className="form-group">
                    <label className="control-label">Reference Project</label>
                    <Select
                      id="reference_project_id"
                      title="Reference Project"
                      name="reference_project_id"
                      placeholder={"Choose Reference Project..."}
                      value={dataOptionProject.filter(
                        (option) =>
                          option.value === formData.reference_project_id
                      )}
                      onChange={(e) =>
                        dispatch(
                          setFormData({
                            name: "reference_project_id",
                            value: e?.value,
                          })
                        )
                      }
                      options={dataOptionProject}
                      required={false}
                    />
                  </div>
                </div>
                <div className="cols-span-1">
                  <div className="form-group">
                    <label className="control-label">
                      Project Name <span className="text-danger">*</span>
                    </label>
                    <input
                      id="project_name"
                      title="Project Name"
                      type="text"
                      name="project_name"
                      className="form-control"
                      value={formData.project_name}
                      onChange={handleInput}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label">
                  Description <span className="text-danger">*</span>
                </label>
                <div className="form-control h-60">
                  <ReactQuill
                    id="descriptions"
                    title="Description"
                    name="descriptions"
                    className="form-control h-48"
                    value={formData.descriptions}
                    onChange={(e) => dispatch(setFormData({name: 'descriptions', value: e}))}
                    required={true}
                    modules={editorToolbar}
                    formats={editorFormat}
                  />
                </div>

                {/* <textarea
                  id="descriptions"
                  title="Description"
                  name="descriptions"
                  className="form-control h-60"
                  defaultValue={formData.descriptions}
                  onChange={handleInput}
                  required={true}
                /> */}
              </div>
              <div className="flex flex-row gap-4">
                <div className="md:w-3/12">
                  <div className="form-group">
                    <label className="control-label">
                      Deadline <span className="text-danger">*</span>
                    </label>
                    <input
                      id="deadline"
                      title="Deadline"
                      type="date"
                      name="deadline"
                      className="form-control"
                      min={dateToday()}
                      value={formData.deadline}
                      onChange={handleInput}
                      required={true}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  </div>
                </div>
                <div className="md:w-1/12">
                  <div className="form-group">
                    <label className="control-label">Urgent</label>
                    <div className="form-checkbox">
                      <input
                        id="urgent"
                        title="Urgent"
                        type="checkbox"
                        name="urgent"
                        checked={formData.urgent ? true : false}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
                <div className="md:w-8/12">
                  <div className="form-group">
                    <label className="control-label">
                      Urgent Notes{" "}
                      {formData.urgent ? (
                        <span className="text-danger">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <textarea
                      id="urgent_notes"
                      title="Urgent Notes"
                      name="urgent_notes"
                      className={`form-control h-60 ${
                        formData.urgent ? "" : "disabled"
                      }`}
                      value={formData.urgent_notes}
                      onChange={handleInput}
                      required={formData.urgent ? true : false}
                      disabled={formData.urgent ? false : true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>

        <div className="tabs">
          <div className="tab-nav">
            <button
              type="button"
              className={`btn ${openTab === "tab-task" ? "active" : ""}`}
              onClick={() => setOpenTab("tab-task")}
            >
              <BiTask /> Task
            </button>
            <button
              type="button"
              className={`btn ${openTab === "tab-attachment" ? "active" : ""}`}
              onClick={() => setOpenTab("tab-attachment")}
            >
              <BiFile /> Attachments
            </button>
          </div>
          {openTab === "tab-task" && (
            <div className="tab-panel">
              <TabFormTask />
            </div>
          )}
          {openTab === "tab-attachment" && (
            <div className="tab-panel">
              <TabFormAttachment />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
