import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PageHeader, PageHeaderTitle } from "../../components/PageHeader";

import { CheckRoleIndex } from "../../helpers/CheckRole";
import {
  Card,
  CardHeader,
  CardTitle,
  CardAction,
  CardBody,
  CardFooter,
} from "../../components/Card";
import { Button } from "../../components/Button";
import { setPopup } from "../../stores/popupSlice";
import { InstanceApi } from "../../helpers/InstanceApi";
import { DownloadFile } from "../../helpers/DownloadFile";
import Select from "../../components/Select";
import MultiSelectText from "../../components/MultiSelectText";

function Index() {
  const pathName = "/reports/tasks";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    begin_date: "",
    end_date: "",
    status: "",
  });

  const [dataTable, setDataTable] = useState();
  const [openFilter, setOpenFilter] = useState(false);
  const dataOptionStatus = [
    { value: "Open", label: "Open" },
    { value: "In Progress", label: "In Progress" },
    { value: "Finish", label: "Finish" },
    { value: "Completed", label: "Completed" },
  ];

  const [inputTaskNo, setInputTaskNo] = useState("");
  const [taskNo, setTaskNo] = useState([]);
  const [inputTaskName, setInputTaskName] = useState("");
  const [taskName, setTaskName] = useState([]);
  const [inputProjectNo, setInputProjectNo] = useState("");
  const [projectNo, setProjectNo] = useState([]);
  const [inputProjectName, setInputProjectName] = useState("");
  const [projectName, setProjectName] = useState([]);
  const [inputItemNo, setInputItemNo] = useState("");
  const [itemNo, setItemNo] = useState([]);
  const [inputObserverName, setInputObserverName] = useState("");
  const [observerName, setObserverName] = useState([]);
  const [inputResponsibleName, setInputResponsibleName] = useState("");
  const [responsibleName, setResponsibleName] = useState([]);
  const [inputDepartmentName, setInputDepartmentName] = useState("");
  const [departmentName, setDepartmentName] = useState([]);

  const createOption = (label) => ({
    label,
    value: label,
  });

  const resetFilter = () => {
    setInputTaskNo("");
    setInputTaskName("");
    setInputProjectNo("");
    setInputProjectName("");
    setInputItemNo("");
    setInputObserverName("");
    setInputResponsibleName("");
    setInputDepartmentName("");
    setTaskNo("");
    setTaskName("");
    setProjectNo("");
    setProjectName("");
    setItemNo("");
    setObserverName("");
    setResponsibleName("");
    setDepartmentName("");
  };

  const handleKeyDown = (event = React.KeyboardEventHandler, field) => {
    switch (event.key) {
      case "Enter":
      case "Tab":
        switch (field) {
          case "taskNo":
            if (!inputTaskNo) return;
            setTaskNo((prev) => [...prev, createOption(inputTaskNo)]);
            setInputTaskNo("");
            break;
          case "taskName":
            if (!inputTaskName) return;
            setTaskName((prev) => [...prev, createOption(inputTaskName)]);
            setInputTaskName("");
            break;
          case "projectNo":
            if (!inputProjectNo) return;
            setProjectNo((prev) => [...prev, createOption(inputProjectNo)]);
            setInputProjectNo("");
            break;
          case "projectName":
            if (!inputProjectName) return;
            setProjectName((prev) => [...prev, createOption(inputProjectName)]);
            setInputProjectName("");
            break;
          case "itemNo":
            if (!inputItemNo) return;
            setItemNo((prev) => [...prev, createOption(inputItemNo)]);
            setInputItemNo("");
            break;
          case "observerName":
            if (!inputObserverName) return;
            setObserverName((prev) => [
              ...prev,
              createOption(inputObserverName),
            ]);
            setInputObserverName("");
            break;
          case "responsibleName":
            if (!inputResponsibleName) return;
            setResponsibleName((prev) => [
              ...prev,
              createOption(inputResponsibleName),
            ]);
            setInputResponsibleName("");
            break;
          case "departmentName":
            if (!inputDepartmentName) return;
            setDepartmentName((prev) => [
              ...prev,
              createOption(inputDepartmentName),
            ]);
            setInputDepartmentName("");
            break;
          default:
            break;
        }
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!CheckRoleIndex(pathName)) {
      navigate("/404");
    }
  }, [dispatch, navigate]);

  const createFormSubmit = () => {
    let formSubmit = {
      begin_date: formData.begin_date,
      end_date: formData.end_date,
      task_no: "",
      task_name: "",
      project_no: "",
      project_name: "",
      item_no: "",
      observer_name: "",
      responsible_name: "",
      department_name: "",
      status: "",
    };

    if (taskNo.length > 0) {
      var array_task_no = [];
      taskNo.map((item, _) => {
        array_task_no.push(item.value);
      });
      formSubmit.task_no = array_task_no.join("|");
    }

    if (taskName.length > 0) {
      var array_task_name = [];
      taskName.map((item, _) => {
        array_task_name.push(item.value);
      });
      formSubmit.task_name = array_task_name.join("|");
    }

    if (projectNo.length > 0) {
      var array_project_no = [];
      projectNo.map((item, _) => {
        array_project_no.push(item.value);
      });
      formSubmit.project_no = array_project_no.join("|");
    }

    if (projectName.length > 0) {
      var array_project_name = [];
      projectName.map((item, _) => {
        array_project_name.push(item.value);
      });
      formSubmit.project_name = array_project_name.join("|");
    }

    if (itemNo.length > 0) {
      var array_item_no = [];
      itemNo.map((item, _) => {
        array_item_no.push(item.value);
      });
      formSubmit.item_no = array_item_no.join("|");
    }

    if (observerName.length > 0) {
      var array_observer_name = [];
      observerName.map((item, _) => {
        array_observer_name.push(item.value);
      });
      formSubmit.observer_name = array_observer_name.join("|");
    }

    if (responsibleName.length > 0) {
      var array_responsible_name = [];
      responsibleName.map((item, _) => {
        array_responsible_name.push(item.value);
      });
      formSubmit.responsible_name = array_responsible_name.join("|");
    }

    if (departmentName.length > 0) {
      var array_department_name = [];
      departmentName.map((item, _) => {
        array_department_name.push(item.value);
      });
      formSubmit.department_name = array_department_name.join("|");
    }

    if (formData.status.length > 0) {
      formSubmit.status = formData.status.join("|");
    }

    return formSubmit;
  };

  const generateReport = async (e) => {
    e.preventDefault();

    setOpenFilter(false);

    try {
      dispatch(setPopup({ open: true, type: "processing" }));
      const response = await InstanceApi.post(
        "/reports/generate-tasks",
        createFormSubmit()
      );
      if (response) {
        dispatch(setPopup({ open: false }));
        setDataTable(response.data.data);
      }
    } catch (err) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: err.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
      console.error(err.response.data.message);
    }
  };

  const exportReport = async (e) => {
    e.preventDefault();

    try {
      dispatch(setPopup({ open: true, type: "processing" }));
      const response = await InstanceApi.post(
        "/reports/export-tasks",
        createFormSubmit()
      );
      if (response) {
        dispatch(setPopup({ open: false }));

        dispatch(setPopup({ open: true, type: "download" }));

        const downloadUrl = "/download?file_path=" + response.data.data;
        console.log(downloadUrl);
        DownloadFile(downloadUrl);

        setTimeout(() => {
          dispatch(setPopup({ open: false }));
        }, 3000);
      }
    } catch (err) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: err.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
      console.error(err.response.data.message);
    }
  };

  return (
    <>
      <PageHeader>
        <PageHeaderTitle>Task Report</PageHeaderTitle>
      </PageHeader>
      <Card>
        <div
          className="cursor-pointer"
          onClick={() => setOpenFilter(!openFilter)}
        >
          <CardHeader>
            <CardTitle>Report Filter</CardTitle>
            <CardAction>
              <span className="px-2 text-xl font-bold cursor-pointer">
                {openFilter ? "-" : "+"}
              </span>
            </CardAction>
          </CardHeader>
        </div>
        {openFilter && (
          <>
            <CardBody>
              <div className="grid grid-cols-4 items-center gap-4">
                <div className="form-group">
                  <label className="control-label">Begin Date</label>
                  <input
                    id="begin_date"
                    title="Begin Date"
                    type="date"
                    name="begin_date"
                    className="form-control"
                    value={formData.begin_date}
                    onChange={(e) =>
                      setFormData({ ...formData, begin_date: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">End Date</label>
                  <input
                    id="end_date"
                    title="End Date"
                    type="date"
                    name="end_date"
                    className="form-control"
                    value={formData.end_date}
                    onChange={(e) =>
                      setFormData({ ...formData, end_date: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="form-group">
                  <label className="control-label">Task No</label>
                  <MultiSelectText
                    inputValue={inputTaskNo}
                    onChange={(newValue) => setTaskNo(newValue)}
                    onInputChange={(newValue) => setInputTaskNo(newValue)}
                    onKeyDown={(e) => handleKeyDown(e, "taskNo")}
                    value={taskNo}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">Task Name</label>
                  <MultiSelectText
                    inputValue={inputTaskName}
                    onChange={(newValue) => setTaskName(newValue)}
                    onInputChange={(newValue) => setInputTaskName(newValue)}
                    onKeyDown={(e) => handleKeyDown(e, "taskName")}
                    value={taskName}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">Project No</label>
                  <MultiSelectText
                    inputValue={inputProjectNo}
                    onChange={(newValue) => setProjectNo(newValue)}
                    onInputChange={(newValue) => setInputProjectNo(newValue)}
                    onKeyDown={(e) => handleKeyDown(e, "projectNo")}
                    value={projectNo}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">Project Name</label>
                  <MultiSelectText
                    inputValue={inputProjectName}
                    onChange={(newValue) => setProjectName(newValue)}
                    onInputChange={(newValue) => setInputProjectName(newValue)}
                    onKeyDown={(e) => handleKeyDown(e, "projectName")}
                    value={projectName}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">Item No</label>
                  <MultiSelectText
                    inputValue={inputItemNo}
                    onChange={(newValue) => setItemNo(newValue)}
                    onInputChange={(newValue) => setInputItemNo(newValue)}
                    onKeyDown={(e) => handleKeyDown(e, "itemNo")}
                    value={itemNo}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">Observer</label>
                  <MultiSelectText
                    inputValue={inputObserverName}
                    onChange={(newValue) => setObserverName(newValue)}
                    onInputChange={(newValue) => setInputObserverName(newValue)}
                    onKeyDown={(e) => handleKeyDown(e, "observerName")}
                    value={observerName}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">Responsible</label>
                  <MultiSelectText
                    inputValue={inputResponsibleName}
                    onChange={(newValue) => setResponsibleName(newValue)}
                    onInputChange={(newValue) =>
                      setInputResponsibleName(newValue)
                    }
                    onKeyDown={(e) => handleKeyDown(e, "responsibleName")}
                    value={responsibleName}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">Department</label>
                  <MultiSelectText
                    inputValue={inputDepartmentName}
                    onChange={(newValue) => setDepartmentName(newValue)}
                    onInputChange={(newValue) =>
                      setInputDepartmentName(newValue)
                    }
                    onKeyDown={(e) => handleKeyDown(e, "departmentName")}
                    value={departmentName}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">Status</label>
                  <Select
                    placeholder={"Choose Reference Project..."}
                    className="basic-multi-select"
                    isMulti
                    options={dataOptionStatus}
                    onChange={(e) => {
                      var array = [];
                      e.map((item, _) => {
                        array.push(item.value);
                      });
                      setFormData({
                        ...formData,
                        status: array,
                      });
                    }}
                  />
                </div>
              </div>
            </CardBody>
            <CardFooter>
              <div className="flex flex-row items-center gap-2">
                <>
                  <Button
                    title="Generate Report"
                    icon="BiCog"
                    color="primary"
                    onClick={generateReport}
                  />
                  <Button
                    title="Reset"
                    icon="BiReset"
                    color="secondary"
                    onClick={resetFilter}
                  />
                </>
              </div>
            </CardFooter>
          </>
        )}
      </Card>
      <div className="mt-4"></div>
      {dataTable && (
        <Card>
          <CardHeader>
            <CardAction>
              <Button
                title="Download Report"
                icon="BiDownload"
                color="primary"
                onClick={exportReport}
              />
            </CardAction>
          </CardHeader>
          <CardBody>
            <div className="w-full max-h-[700px] overflow-auto">
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ minWidth: "50px" }}>NO</th>
                    <th style={{ minWidth: "150px" }}>TASK NO</th>
                    <th style={{ minWidth: "200px" }}>TASK NAME</th>
                    <th style={{ minWidth: "200px" }}>PROJECT NO</th>
                    <th style={{ minWidth: "400px" }}>PROJECT NAME</th>
                    <th style={{ minWidth: "200px" }}>ITEM NO</th>
                    <th style={{ minWidth: "200px" }}>ITEM VALUE</th>
                    <th style={{ minWidth: "150px" }}>OBSERVER NAME</th>
                    <th style={{ minWidth: "50px" }}>OBSERVER POSITION</th>
                    <th style={{ minWidth: "200px" }}>RESPONSIBLE NAME</th>
                    <th style={{ minWidth: "200px" }}>RESPONSIBLE POSITION</th>
                    <th style={{ minWidth: "150px" }}>DEPARTMENT</th>
                    <th style={{ minWidth: "150px" }}>TASK DURATION</th>
                    <th style={{ minWidth: "150px" }}>ASSIGN DATE</th>
                    <th style={{ minWidth: "150px" }}>BEGIN DATE</th>
                    <th style={{ minWidth: "50px" }}>END DATE</th>
                    <th style={{ minWidth: "200px" }}>STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  {dataTable &&
                    dataTable.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.task_no}</td>
                        <td>{item.task_name}</td>
                        <td>{item.project_no}</td>
                        <td>{item.project_name}</td>
                        <td>{item.mo_no}</td>
                        <td>{item.mo_value}</td>
                        <td>{item.observer_name}</td>
                        <td>{item.observer_position_name}</td>
                        <td>{item.responsible_name}</td>
                        <td>{item.responsible_position_name}</td>
                        <td>{item.department_name}</td>
                        <td>{item.task_duration}</td>
                        <td>{item.assign_date}</td>
                        <td>{item.begin_date}</td>
                        <td>{item.end_date}</td>
                        <td>{item.status}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
}

export default Index;
