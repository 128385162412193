import React, { useState, useEffect, useRef } from "react";
import { InstanceApi } from "../../../helpers/InstanceApi";
import { Validate } from "../../../helpers/Validate";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardAction,
  CardFooter,
} from "../../../components/Card";
import { CheckRoleAction } from "../../../helpers/CheckRole";
import { Button } from "../../../components/Button";
import Select from "../../../components/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  resetFormData as resetFormDataMoRev,
  setFormData as setFormDataMoRev,
  setModal as setModalMoRev,
  setDataTemporary as setDataTemporaryMoRev,
  submit as submitMoRev,
  setFormDelete,
  setFormAttachment,
} from "../../../stores/pm/projectMoRevSlice";
import { getDataOptions as getDataOptionProduct } from "../../../stores/master/productSlice";
import { getDataOptions as getDataOptionStation } from "../../../stores/master/stationSlice";
import { getDataOptions as getDataOptionProgram } from "../../../stores/master/programSlice";
import { setPopup } from "../../../stores/popupSlice";
import ModalForm from "../../../components/ModalForm";
import Inputmask from "inputmask";
import { BiEditAlt, BiSearch, BiTrash } from "react-icons/bi";

export default function TabMoWidget(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const { moList, responsibleId, ownerId } = props;
  const dispatch = useDispatch();
  const dataProject = useSelector((state) => state.project.dataView);
  const dataTask = useSelector((state) => state.task.dataView);
  const openModalMoRev = useSelector((state) => state.projectMoRev.openModal);
  const dataOptionProduct = useSelector((state) => state.product.dataOptions);
  const dataOptionStation = useSelector((state) => state.station.dataOptions);
  const dataOptionProgram = useSelector((state) => state.program.dataOptions);
  const [modalAttachment, setModalAttachment] = useState(false);
  const formDataMoRev = useSelector((state) => state.projectMoRev.formData);
  const formDelete = useSelector((state) => state.projectMoRev.formDelete);
  const formAttachment = useSelector(
    (state) => state.projectMoRev.formAttachment
  );
  const formValidateMoRev = useSelector(
    (state) => state.projectMoRev.formValidate
  );
  const dataTemporaryMoRev = useSelector(
    (state) => state.projectMoRev.dataTemporary
  );
  const [tabOpen, setTabOpen] = useState("tab-mo");
  const [formTypeMoRev, setFormTypeMoRev] = useState("create");
  const [indexEditMoRev, setIndexEditMoRev] = useState();

  const [listItem, setListItem] = useState([]);

  // attachment
  const inputRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);
  const [file, setFile] = useState();

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const saveAttachment = () => {
    var data = [
      ...formAttachment,
      {
        name: file.name,
        value: file,
      },
    ];
    dispatch(setFormAttachment(data));
    setModalAttachment(false);
    setFile(undefined);
  };

  const deleteAttachment = (deleteIndex) => {
    let data = formAttachment.filter((_, index) => index !== deleteIndex);
    dispatch(setFormAttachment(data));
  };

  // Item
  const handleInputMoRev = (e) => {
    if (e.target.name === "mo_value") {
      const value = Number(e.target.value.toString().replace(/\./g, ""));
      dispatch(setFormDataMoRev({ name: e.target.name, value: value }));
    } else {
      dispatch(
        setFormDataMoRev({ name: e.target.name, value: e.target.value })
      );
    }
  };

  useEffect(() => {
    dispatch(getDataOptionProgram({ stationId: formDataMoRev.station_id }));
  }, [dispatch, formDataMoRev.station_id]);

  const addRevisionMo = () => {
    setTabOpen("tab-moRev");
    const array = [];
    moList.map((item, index) => {
      const data = { ...item, current_mo_no: item.mo_no };
      array.push(data);
      return true;
    });

    dispatch(setDataTemporaryMoRev(array));
  };

  useEffect(() => {
    if (openModalMoRev) {
      if (formTypeMoRev === "create") {
        dispatch(resetFormDataMoRev());
        dispatch(
          setFormDataMoRev({
            name: "project_id",
            value: dataProject?.id || dataTask?.project_id,
          })
        );
      }
      dispatch(getDataOptionStation());
      dispatch(getDataOptionProduct({ clientId: dataProject.client_id }));

      let inputMoValue = new Inputmask({
        alias: "currency",
        prefix: "",
        groupSeparator: ".",
        radixPoint: ",",
        digits: 0,
        allowMinus: false,
        allowPlus: true,
        placeholder: "0",
      });
      inputMoValue.mask(document.getElementById("mo_value"));

      let inputSpot = new Inputmask({
        alias: "numeric",
        digits: 0,
        allowMinus: false,
        allowPlus: true,
        placeholder: "0",
      });
      inputSpot.mask(document.getElementById("spot"));
    }
  }, [dispatch, formTypeMoRev, openModalMoRev]);

  const checkMoNo = async (mo_no, type) => {
    const errorMsg = "Item No already exists!";
    try {
      let response = false;
      if (formDataMoRev.current_mo_no === formDataMoRev.mo_no) {
        response = true;
      } else {
        response = await InstanceApi.get(
          "/pm/projects/mo/check?mo_no=" + mo_no
        );
      }

      if (response) {
        let filtered = [];
        if (type === "create") {
          filtered = [...dataTemporaryMoRev].filter(
            (data) =>
              data.mo_no.toString().toLowerCase().indexOf(mo_no.toLowerCase()) >
              -1
          );
        } else {
          filtered = [...dataTemporaryMoRev].filter(
            (_, index) => index !== indexEditMoRev
          );

          filtered = [...filtered].filter(
            (data) =>
              data.mo_no.toString().toLowerCase().indexOf(mo_no.toLowerCase()) >
              -1
          );
        }

        if (filtered.length > 0) {
          dispatch(
            setPopup({
              open: true,
              type: "error",
              message: errorMsg,
              handleOk: () => {
                dispatch(setPopup({ open: false }));
              },
            })
          );
        } else {
          if (type === "create") {
            dispatch(
              setDataTemporaryMoRev([...dataTemporaryMoRev, formDataMoRev])
            );
          } else {
            const temp = [...dataTemporaryMoRev];
            temp[indexEditMoRev] = formDataMoRev;

            dispatch(setDataTemporaryMoRev(temp));
          }
          dispatch(setModalMoRev(false));
        }
      }
    } catch (error) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: errorMsg,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
    }
  };

  const addDataMoRev = () => {
    setFormTypeMoRev("create");
    dispatch(setModalMoRev(true));
  };

  const editDataMoRev = (editIndex) => {
    dispatch(resetFormDataMoRev());
    setIndexEditMoRev(editIndex);
    setFormTypeMoRev("edit");

    const temp = [...dataTemporaryMoRev];
    const item = temp[editIndex];
    Object.entries(item).forEach((row) => {
      dispatch(setFormDataMoRev({ name: row[0], value: row[1] }));
    });
    dispatch(setFormDataMoRev({ name: "notes", value: "" }));
    dispatch(setModalMoRev(true));
  };

  const deleteDataMoRev = (deleteIndex) => {
    const temp = [...dataTemporaryMoRev];
    const item = temp[deleteIndex];
    if (item.id) {
      dispatch(resetFormDataMoRev());
      setIndexEditMoRev(deleteIndex);
      setFormTypeMoRev("delete");

      // const temp = [...dataTemporaryMoRev];
      // const item = temp[editIndex];
      Object.entries(item).forEach((row) => {
        dispatch(setFormDataMoRev({ name: row[0], value: row[1] }));
      });
      dispatch(setFormDataMoRev({ name: "notes", value: "" }));
      dispatch(setModalMoRev(true));
    } else {
      let data = dataTemporaryMoRev.filter((_, index) => index !== deleteIndex);
      dispatch(setDataTemporaryMoRev(data));
    }
  };

  const saveDataMoRev = (type) => {
    if (type === "delete") {
      if (formDataMoRev.notes === "") {
        dispatch(
          setPopup({
            open: true,
            type: "error",
            message: "Notes is required!",
            handleOk: () => {
              dispatch(setPopup({ open: false }));
            },
          })
        );
      } else {
        const temp = [...dataTemporaryMoRev];
        temp[indexEditMoRev] = { ...formDataMoRev, is_delete: 1 };

        dispatch(setDataTemporaryMoRev(temp));
        dispatch(setFormDelete([...formDelete, formDataMoRev.id]));
        dispatch(setModalMoRev(false));
      }
    } else {
      const validate = Validate(formValidateMoRev, formDataMoRev);

      if (!validate.isValid) {
        dispatch(
          setPopup({
            open: true,
            type: "error",
            message: validate.message,
            handleOk: () => {
              dispatch(setPopup({ open: false }));
            },
          })
        );
      } else {
        // check available mo no
        checkMoNo(formDataMoRev.mo_no, type);
      }
    }
  };

  useEffect(() => {
    setListItem(moList);
  }, [moList]);

  const handleSearch = (value) => {
    const filtered = [...moList].filter(
      (data) =>
        data.mo_no.toString().toLowerCase().indexOf(value) > -1 ||
        data.mo_value.toString().toLowerCase().indexOf(value) > -1 ||
        data.description.toString().toLowerCase().indexOf(value) > -1 ||
        data.product_name.toString().toLowerCase().indexOf(value) > -1 ||
        data.spot.toString().toLowerCase().indexOf(value) > -1 ||
        data.program_name.toString().toLowerCase().indexOf(value) > -1 ||
        data.station_name.toString().toLowerCase().indexOf(value) > -1 ||
        data?.on_revision.toString().toLowerCase().indexOf(value) > -1 ||
        data?.notes?.toString().toLowerCase().indexOf(value) > -1
    );

    setListItem(filtered);
  };

  return (
    <>
      {modalAttachment && (
        <ModalForm
          modalTitle="Upload Attachment"
          modalSize="medium"
          closeModal={() => setModalAttachment(false)}
        >
          <form onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
            <div
              className={`modal-upload-box ${
                dragActive ? "border-slate-800" : ""
              }`}
            >
              <div className="body">
                <input
                  id="input-file-upload"
                  type="file"
                  className="hidden"
                  ref={inputRef}
                  onChange={handleChange}
                />
                <p>
                  Drag and drop your file here or{" "}
                  <span
                    className="cursor-pointer underline text-primary-hover"
                    onClick={onButtonClick}
                  >
                    browse
                  </span>
                </p>
                {file && <p>{file.name}</p>}
              </div>
              <div className="mt-6 flex flex-row gap-2 items-center justify-center">
                {file ? (
                  <Button
                    title="Upload"
                    icon="BiUpload"
                    color="primary"
                    onClick={saveAttachment}
                  />
                ) : (
                  <Button title="Upload" icon="BiUpload" color="disabled" />
                )}
              </div>

              {dragActive && (
                <div
                  className="absolute w-full h-full rounded top-0 right-0 left-0 bottom-0"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
            </div>
          </form>
        </ModalForm>
      )}

      {openModalMoRev && (
        <ModalForm
          modalTitle={
            formTypeMoRev === "create"
              ? "Add Item"
              : formTypeMoRev === "edit"
              ? "Edit Item"
              : "Delete Item"
          }
          modalSize="medium"
          closeModal={() => dispatch(setModalMoRev(false))}
        >
          <div className="w-full">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="form-group">
                <label className="control-label">
                  Item No. <span className="text-danger">*</span>
                </label>
                <input
                  id="mo_no"
                  title="Item No."
                  type="text"
                  name="mo_no"
                  className={`form-control ${
                    formTypeMoRev === "delete" && "disabled"
                  }`}
                  value={formDataMoRev.mo_no}
                  onChange={handleInputMoRev}
                  required
                  disabled={formTypeMoRev === "delete" && true}
                />
              </div>
              <div className="form-group">
                <label className="control-label">
                  Item Value <span className="text-danger">*</span>
                </label>
                <input
                  id="mo_value"
                  title="Item Value"
                  type="text"
                  name="mo_value"
                  className={`form-control ${
                    formTypeMoRev === "delete" && "disabled"
                  }`}
                  value={formDataMoRev.mo_value}
                  onChange={handleInputMoRev}
                  required
                  disabled={formTypeMoRev === "delete" && true}
                />
              </div>
            </div>

            <div className="form-group">
              <label className="control-label">
                Item Description <span className="text-danger">*</span>
              </label>
              <input
                id="description"
                title="Item Description"
                type="text"
                name="description"
                className={`form-control ${
                  formTypeMoRev === "delete" && "disabled"
                }`}
                value={formDataMoRev.description}
                onChange={handleInputMoRev}
                required
                disabled={formTypeMoRev === "delete" && true}
              />
            </div>
            <div className="form-group">
              <label className="control-label">
                Product <span className="text-danger">*</span>
              </label>
              <Select
                id="product_id"
                title="Product"
                name="product_id"
                placeholder={"Choose..."}
                value={dataOptionProduct.filter(
                  (option) => option.value === formDataMoRev.product_id
                )}
                onChange={(e) => {
                  dispatch(
                    setFormDataMoRev({ name: "product_id", value: e?.value })
                  );
                  dispatch(
                    setFormDataMoRev({ name: "product_name", value: e?.label })
                  );
                }}
                options={dataOptionProduct}
                required
                disabled={formTypeMoRev === "delete" && true}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
              <div className="col-span-1 form-group">
                <label className="control-label">
                  Spot <span className="text-danger">*</span>
                </label>
                <input
                  id="spot"
                  title="Spot"
                  type="text"
                  name="spot"
                  className={`form-control ${
                    formTypeMoRev === "delete" && "disabled"
                  }`}
                  value={formDataMoRev.spot}
                  onChange={handleInputMoRev}
                  required
                  disabled={formTypeMoRev === "delete" && true}
                />
              </div>

              <div className="col-span-2 form-group">
                <label className="control-label">
                  Media Channel <span className="text-danger">*</span>
                </label>
                <Select
                  id="station_id"
                  title="Media Channel"
                  name="station_id"
                  placeholder={"Choose..."}
                  value={dataOptionStation.filter(
                    (option) => option.value === formDataMoRev.station_id
                  )}
                  onChange={(e) => {
                    dispatch(
                      setFormDataMoRev({ name: "station_id", value: e?.value })
                    );
                    dispatch(
                      setFormDataMoRev({
                        name: "station_name",
                        value: e?.label,
                      })
                    );
                  }}
                  options={dataOptionStation}
                  required
                  disabled={formTypeMoRev === "delete" && true}
                />
              </div>
              <div className="col-span-2 form-group">
                <label className="control-label">
                  Program <span className="text-danger">*</span>
                </label>
                <Select
                  id="program_id"
                  title="Program"
                  name="program_id"
                  placeholder={"Choose..."}
                  value={dataOptionProgram.filter(
                    (option) => option.value === formDataMoRev.program_id
                  )}
                  onChange={(e) => {
                    dispatch(
                      setFormDataMoRev({ name: "program_id", value: e?.value })
                    );
                    dispatch(
                      setFormDataMoRev({
                        name: "program_name",
                        value: e?.label,
                      })
                    );
                  }}
                  options={dataOptionProgram}
                  required
                  disabled={formTypeMoRev === "delete" && true}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label">
                Notes{" "}
                {formTypeMoRev === "delete" && (
                  <span className="text-danger">*</span>
                )}
              </label>
              <textarea
                id="notes"
                title="Notes"
                name="notes"
                className="form-control h-60"
                value={formDataMoRev.notes}
                onChange={handleInputMoRev}
                required={formTypeMoRev === "delete" && true}
              />
            </div>
          </div>

          <div className="mt-8 flex gap-2">
            <Button
              title={`${formTypeMoRev === "delete" ? "Delete" : "Save"}`}
              icon="BiSave"
              color="primary"
              onClick={() => saveDataMoRev(formTypeMoRev)}
            />
            <Button
              title="Cancel"
              icon="BiX"
              color="secondary"
              onClick={() => dispatch(setModalMoRev(false))}
            />
          </div>
        </ModalForm>
      )}
      <Card>
        <CardHeader>
          <CardTitle>
            {tabOpen === "tab-mo" ? "Items" : "Revision Items"}
          </CardTitle>
          {CheckRoleAction("/pm/projects", "revision_mo") &&
            (user.reference_id === ownerId ||
              user.reference_id === responsibleId) && (
              <CardAction>
                {tabOpen === "tab-mo" && moList?.length > 0 && (
                  <Button
                    title="Add Revision Item"
                    icon="BiPlus"
                    size="sm"
                    color="warning"
                    onClick={addRevisionMo}
                  />
                )}
                {tabOpen === "tab-moRev" && (
                  <Button
                    title="Add Item"
                    icon="BiPlus"
                    size="sm"
                    color="primary"
                    onClick={addDataMoRev}
                  />
                )}
              </CardAction>
            )}
        </CardHeader>
        <CardBody>
          {!moList && (
            <div className="text-center font-semibold">Loading...</div>
          )}
          {listItem && (
            <>
              {tabOpen === "tab-mo" && (
                <>
                  <div className="mb-2">
                    <div className="relative">
                      <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <BiSearch className="w-5 h-5 text-gray-300" />
                      </div>
                      <input
                        className="form-control-search"
                        placeholder="Search..."
                        onChange={(e) =>
                          handleSearch(e.target.value.toString().toLowerCase())
                        }
                      />
                    </div>
                  </div>
                  <div className="space-y-2">
                    {listItem.length === 0 ? (
                      <span className="py-4 block text-center font-semibold">
                        No item
                      </span>
                    ) : (
                      <>
                        <div className="flex flex-col gap-2">
                          {listItem.map((item, index) => (
                            <div
                              key={index}
                              className="p-4 border border-slate-tertiary rounded-md hover:bg-slate-tertiary transition duration-200"
                            >
                              <div className="w-full flex items-center justify-end gap-1">
                                {item.revision_count > 0 && (
                                  <div className="mb-2 badge badge-info">
                                    Total Revision : {item.revision_count}
                                  </div>
                                )}
                                {item.on_revision === 1 && (
                                  <div className="mb-2 badge badge-info">
                                    On Revision Item
                                  </div>
                                )}
                              </div>
                              <div className="flex flex-row items-start justify-between gap-4">
                                <div className="flex-1 font-semibold">
                                  Item No. : {item.mo_no}
                                </div>
                                <div className="flex flex-row items-center gap-2">
                                  <div className="text-lg font-semibold">
                                    {(CheckRoleAction(
                                      "/pm/projects",
                                      "view_mo_value"
                                    ) ||
                                      user.reference_id === responsibleId ||
                                      user.reference_id === ownerId) &&
                                      item.mo_value.toLocaleString("id-ID", {
                                        style: "currency",
                                        currency: "IDR",
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 2,
                                      })}
                                  </div>
                                </div>
                              </div>
                              <div className="mt-4 grid grid-cols-8 gap-2 text-sm">
                                <div className="col-span-8">
                                  <span className="mb-2 block text-text-secondary">
                                    Item Description
                                  </span>
                                  <span className="block">
                                    {item.description}
                                  </span>
                                </div>
                                <div className="col-span-2">
                                  <span className="mb-2 block text-text-secondary">
                                    Product
                                  </span>
                                  <span className="block">
                                    {item.product_name}
                                  </span>
                                </div>
                                <div className="col-span-1">
                                  <span className="mb-2 block text-text-secondary">
                                    Spot
                                  </span>
                                  <span className="block">{item.spot}</span>
                                </div>
                                <div className="col-span-2">
                                  <span className="mb-2 block text-text-secondary">
                                    Program
                                  </span>
                                  <span className="block">
                                    {item.program_name} @ {item.station_name}
                                  </span>
                                </div>
                                <div className="col-span-3">
                                  <span className="mb-2 block text-text-secondary">
                                    Notes
                                  </span>
                                  <span className="block">{item.notes}</span>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
              {tabOpen === "tab-moRev" && (
                <>
                  <div className="space-y-2">
                    {dataTemporaryMoRev.map(
                      (item, index) =>
                        !item.is_delete && (
                          <div key={index} className="flex flex-row gap-2">
                            <div
                              className={`flex-1 p-4 border border-slate-tertiary rounded-md hover:bg-slate-tertiary transition duration-200 ${
                                item.on_revision && "bg-slate-tertiary"
                              }`}
                            >
                              <div className="flex flex-row items-start justify-between gap-4">
                                <div className="flex-1 font-semibold">
                                  Item No. : {item.mo_no}
                                </div>
                                <div className="flex flex-row items-center gap-2">
                                  <div className="text-lg font-semibold">
                                    {(CheckRoleAction(
                                      "/pm/projects",
                                      "view_mo_value"
                                    ) ||
                                      user.reference_id === responsibleId ||
                                      user.reference_id === ownerId) &&
                                      item.mo_value.toLocaleString("id-ID", {
                                        style: "currency",
                                        currency: "IDR",
                                        maximumFractionDigits: 0,
                                      })}
                                  </div>
                                </div>
                              </div>
                              <div className="mt-4 grid grid-cols-8 gap-4 text-sm">
                                <div className="col-span-8">
                                  <span className="mb-2 block text-text-secondary">
                                    Item Description
                                  </span>
                                  <span className="block">
                                    {item.description}
                                  </span>
                                </div>
                                <div className="col-span-2">
                                  <span className="mb-2 block text-text-secondary">
                                    Product
                                  </span>
                                  <span className="block">
                                    {item.product_name}
                                  </span>
                                </div>
                                <div className="col-span-1">
                                  <span className="mb-2 block text-text-secondary">
                                    Spot
                                  </span>
                                  <span className="block">{item.spot}</span>
                                </div>
                                <div className="col-span-2">
                                  <span className="mb-2 block text-text-secondary">
                                    Program
                                  </span>
                                  <span className="block">
                                    {item.program_name} @ {item.station_name}
                                  </span>
                                </div>
                                <div className="col-span-3">
                                  <span className="mb-2 block text-text-secondary">
                                    Notes
                                  </span>
                                  <span className="block">{item.notes}</span>
                                </div>
                              </div>
                            </div>
                            <div className="flex-none p-2 flex flex-col items-center justify-start gap-2">
                              {item.on_revision ? (
                                <span className="btn-action w-[22px]"></span>
                              ) : (
                                <>
                                  <span
                                    className="btn-action"
                                    onClick={() => editDataMoRev(index)}
                                  >
                                    <BiEditAlt size="22" />
                                  </span>
                                  <span
                                    className="btn-action"
                                    onClick={() => deleteDataMoRev(index)}
                                  >
                                    <BiTrash size="22" />
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                        )
                    )}
                  </div>
                  <div className="mt-8 form-group">
                    <div className="mb-2 flex flex-row items-center justify-between gap-4">
                      <label className="control-label">Attachments</label>
                      <Button
                        title="Add Attachment"
                        icon="BiPlus"
                        size="sm"
                        color="primary"
                        onClick={() => setModalAttachment(true)}
                      />
                    </div>
                    <table className="table">
                      <thead>
                        <tr>
                          <th style={{ width: "5%" }}>No.</th>
                          <th>Files</th>
                          <th style={{ width: "5%" }}>Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formAttachment.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>
                              <span
                                className="btn-action"
                                onClick={() => deleteAttachment(index)}
                              >
                                <BiTrash size="22" />
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </>
          )}
        </CardBody>
        {tabOpen === "tab-moRev" && (
          <CardFooter>
            <div className="mt-8 flex flex-row items-center gap-2">
              <Button
                title="Save"
                icon="BiSave"
                color="primary"
                onClick={() => dispatch(submitMoRev())}
              />
              <Button
                title="Cancel"
                icon="BiX"
                color="secondary"
                onClick={() => {
                  setTabOpen("tab-mo");
                }}
              />
            </div>
          </CardFooter>
        )}
      </Card>
    </>
  );
}
