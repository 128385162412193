import { createSlice } from "@reduxjs/toolkit";
import { InstanceApi } from "../../helpers/InstanceApi";

export const menuSlice = createSlice({
  name: "menu",
  initialState: {
    dataTables: undefined,
  },
  reducers: {
    setDataTables: (state, action) => {
      state.dataTables = action.payload;
    },
  },
});

export const getDataTables = () => async (dispatch) => {
  try {
    dispatch(setDataTables(undefined));

    const response = await InstanceApi.get("/master/menu");
    if (response) {
      dispatch(setDataTables(response.data.data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const {
  setDataTables,
} = menuSlice.actions;
export default menuSlice.reducer;
