import React, { useEffect, useState } from "react";
import { BiLink, BiFile, BiCheck, BiHistory } from "react-icons/bi";
import { Button } from "../../../components/Button";
import { Card, CardBody } from "../../../components/Card";
import {
  PageHeader,
  PageHeaderAction,
  PageHeaderTitle,
} from "../../../components/PageHeader";

import { useSelector, useDispatch } from "react-redux";
import {
  startTask,
  setDataTemporaryFinishNotes,
  setDataTemporaryApprovalNotes,
  setDataTemporaryAttachment,
} from "../../../stores/pm/taskSlice";
import LoadingShow from "./loadingShow";
import TabAttachment from "./tabAttachment";
import CardResponsible from "./cardResponsible";
import CardObserver from "./cardObserver";
import TabFinish from "./tabFinish";
import { CheckRoleAction } from "../../../helpers/CheckRole";
import { checkApproval } from "../../../stores/wf/workflowSlice";
import TabApproval from "./tabApproval";
import TabMo from "./tabMo";
import Page404 from "./../..//404";
import TabActivity from "./tabActivity";

function ShowContent(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const { onClickBack } = props;

  const dispatch = useDispatch();
  const dataTask = useSelector((state) => state.task.dataView);
  const approval = useSelector((state) => state.workflow.approval);
  const [openTab, setOpenTab] = useState("tab-activity");

  useEffect(() => {
    dispatch(
      checkApproval({ obj: "Task", type: "Finish", objId: dataTask?.id })
    );
    dispatch(setDataTemporaryFinishNotes(""));
    dispatch(setDataTemporaryApprovalNotes(""));
    dispatch(setDataTemporaryAttachment([]));
  }, [dispatch, dataTask]);

  return (
    <>
      {dataTask === null ? (
        <Page404 />
      ) : !dataTask ? (
        <LoadingShow />
      ) : (
        <>
          <PageHeader>
            <PageHeaderTitle>
              Task <small>Detail of task</small>
            </PageHeaderTitle>
            <PageHeaderAction>
              <Button
                title="Back"
                icon="BiArrowBack"
                color="secondary"
                onClick={onClickBack}
              />
            </PageHeaderAction>
          </PageHeader>

          <div className="flex md:flex-row gap-4">
            <div className="md:w-3/4 space-y-4">
              <Card>
                <CardBody>
                  <div className="space-y-4">
                    <div className="mb-6">
                      <h1 className="font-semibold">
                        [#{dataTask.task_no}] {dataTask.task_name}
                      </h1>
                    </div>

                    {dataTask.project_id && (
                      <div className="w-full">
                        <span className="label-detail">
                          This task in group (project)
                        </span>
                        <div>
                          <a
                            href={`/pm/projects/view/${dataTask.project_id}`}
                            className="inline-block"
                          >
                            <span className="flex flex-row gap-2 items-center text-primary-hover">
                              <BiLink /> [#{dataTask.project_no}]{" "}
                              {dataTask.project_name}
                            </span>
                          </a>
                        </div>
                      </div>
                    )}
                    <div className="flex flex-row items-center gap-2 self-start">
                      {dataTask.status === "Open" &&
                        dataTask.responsible_id === user.reference_id &&
                        CheckRoleAction("/pm/tasks", "start") && (
                          <Button
                            title="Start Task"
                            icon="BiCheck"
                            color="primary"
                            onClick={() =>
                              dispatch(startTask({ team_id: user.id }))
                            }
                          />
                        )}
                    </div>
                  </div>
                </CardBody>
              </Card>

              <div className="tabs">
                <div className="tab-nav">
                  <button
                    type="button"
                    className={`${openTab === "tab-activity" && "active"}`}
                    onClick={() => setOpenTab("tab-activity")}
                  >
                    <BiHistory /> Last Activity
                  </button>
                  <button
                    type="button"
                    className={`${openTab === "tab-attachment" && "active"}`}
                    onClick={() => setOpenTab("tab-attachment")}
                  >
                    <BiFile /> Attachments
                  </button>
                  {dataTask.alias === "media_order" &&
                    dataTask.status !== "Open" &&
                    dataTask.status !== "In Progress" && (
                      <button
                        type="button"
                        className={`${openTab === "tab-mo" && "active"}`}
                        onClick={() => setOpenTab("tab-mo")}
                      >
                        <BiFile /> Item
                      </button>
                    )}
                  {dataTask.status === "In Progress" &&
                    user.reference_id === dataTask.responsible_id &&
                    CheckRoleAction("/pm/tasks", "finish") && (
                      <button
                        type="button"
                        className={`${openTab === "tab-finish" && "active"}`}
                        onClick={() => setOpenTab("tab-finish")}
                      >
                        <BiCheck /> Finish Task
                      </button>
                    )}
                  {approval && dataTask.status === "Finish" && (
                    <button
                      type="button"
                      className={`${openTab === "tab-approval" && "active"}`}
                      onClick={() => setOpenTab("tab-approval")}
                    >
                      <BiCheck /> Approve / Reject Task
                    </button>
                  )}
                </div>
                {openTab === "tab-activity" && (
                  <div className="tab-panel">
                    <TabActivity />
                  </div>
                )}
                {openTab === "tab-attachment" && (
                  <div className="tab-panel">
                    <TabAttachment />
                  </div>
                )}
                {openTab === "tab-mo" &&
                  dataTask.alias === "media_order" &&
                  dataTask.status !== "Open" &&
                  dataTask.status !== "In Progress" && (
                    <div className="tab-panel">
                      <TabMo />
                    </div>
                  )}
                {openTab === "tab-finish" &&
                  dataTask.status === "In Progress" && (
                    <div className="tab-panel">
                      <TabFinish />
                    </div>
                  )}
                {openTab === "tab-approval" && dataTask.status === "Finish" && (
                  <div className="tab-panel">
                    <TabApproval />
                  </div>
                )}
              </div>
            </div>
            <div className="md:w-1/4 space-y-4">
              <div className="space-y-2">
                <div
                  className={`py-2 px-4 w-full text-sm shadow-lg shadow-shadow rounded-lg relative ${
                    dataTask.status === "Open"
                      ? "bg-open"
                      : dataTask.status === "In Progress"
                      ? "bg-warning"
                      : dataTask.status === "Finish"
                      ? "bg-info"
                      : "bg-success"
                  }`}
                >
                  <span className="text-white font-semibold">
                    {dataTask.status}{" "}
                    {dataTask.status === "Finish" && "and waiting approval"}
                  </span>{" "}
                  <span className="text-white">
                    {dataTask.status !== "Completed" && "since "}
                    {dataTask.updated_at_convert}
                  </span>
                </div>
                {dataTask.status === "Open" &&
                  dataTask.responsible_id === null && (
                    <div className="py-2 px-4 w-full shadow-lg shadow-shadow rounded-lg bg-info text-white text-sm">
                      Waiting to assign responsible
                    </div>
                  )}
                {dataTask.status === "Open" &&
                  dataTask.responsible_id !== null && (
                    <div className="py-2 px-4 w-full shadow-lg shadow-shadow rounded-lg bg-info text-white text-sm">
                      Waiting to start by {dataTask.responsible_name}
                    </div>
                  )}
                {dataTask.status === "In Progress" && (
                  <div className="py-2 px-4 w-full shadow-lg shadow-shadow rounded-lg bg-info text-white text-sm">
                    Waiting to finish by {dataTask.responsible_name}
                  </div>
                )}
                {dataTask.approval_id && (
                  <div className="py-2 px-4 w-full shadow-lg shadow-shadow rounded-lg bg-info text-white text-sm">
                    Waiting approval by {dataTask.approval_by}
                  </div>
                )}
                {dataTask.reject_count > 0 && (
                  <div className="py-2 px-4 w-full shadow-lg shadow-shadow rounded-lg bg-info text-white text-sm">
                    Total Reject : {dataTask.reject_count}
                  </div>
                )}
                <Card>
                  <CardBody>
                    <div className="space-y-4">
                      <div className="w-full">
                        <span className="label-detail">Department</span>
                        <span className="text-text-secondary">
                          {dataTask.department_name ?? "-"}
                        </span>
                      </div>
                      <div className="w-full">
                        <span className="label-detail">Assign Date</span>
                        <span className="text-text-secondary">
                          {dataTask.assign_date_convert ?? "-"}
                        </span>
                      </div>
                      <div className="w-full">
                        <span className="label-detail">Task Date</span>
                        <div className="w-full flex flex-row gap-2 items-center text-text-secondary">
                          <span>{dataTask.begin_date_convert}</span>
                          to
                          <span>{dataTask.end_date_convert}</span>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <CardObserver />
                <CardResponsible />
                {/* <CardHistory /> */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ShowContent;
