import { InstanceApi } from "./InstanceApi";
import { apiUrl } from "../config/Constant";

export const DownloadFile = async (downloadUrl) => {
  // const response = await InstanceApi.get(apiUrl + downloadUrl);
  // if (response) {
  //   console.log(response);
  //   const a = document.createElement("a");
  //   a.href = response.data.file;
  //   a.download = response.data.file_name;
  //   a.click();
  //   a.remove();
  // }

  if (downloadUrl) {
    const a = document.createElement("a");
    a.href = apiUrl + downloadUrl;
    a.target = '_blank';
    a.click();
    a.remove();
  }
};
