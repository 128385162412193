import { createSlice } from '@reduxjs/toolkit';

export const popupSlice = createSlice({
    name: 'popup',
    initialState: {
        openPopup: {
            open: false,
            type: '',
            message: ''
        }
    },
    reducers: {
        setPopup: (state, action) => {
            state.openPopup = action.payload
        }
    }
})

export const { setPopup } = popupSlice.actions
export default popupSlice.reducer

