import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./authSlice";
import layoutSlice from "./layoutSlice";
import popupSlice from "./popupSlice";

// GENERAL
import notificationSlice from "./general/notificationSlice";

// MASTER
import clientSlice from "./master/clientSlice";
import departmentSlice from "./master/departmentSlice";
import positionSlice from "./master/positionSlice";
import employeeSlice from "./master/employeeSlice";
import stationSlice from "./master/stationSlice";
import programSlice from "./master/programSlice";
import productSlice from "./master/productSlice";
import menuSlice from "./master/menuSlice";

// PROJECT MANAGEMENT
import pmDashboardSlice from "./pm/pmDashboardSlice";
import pmDefaultTaskSlice from "./pm/pmDefaultTaskSlice";
import projectSlice from "./pm/projectSlice";
import projectAttachmentSlice from "./pm/projectAttachmentSlice";
import projectMoSlice from "./pm/projectMoSlice";
import projectMoRevSlice from "./pm/projectMoRevSlice";
import projectHistorySlice from "./pm/projectHistorySlice";
import taskSlice from "./pm/taskSlice";
import taskAttachmentSlice from "./pm/taskAttachmentSlice";
import taskObserverSlice from "./pm/taskObserverSlice";
import taskResponsibleSlice from "./pm/taskResponsibleSlice";
import taskHistorySlice from "./pm/taskHistorySlice";
import kpiAdjustmentSlice from "./pm/kpiAdjustmentSlice";

// PRODUCTIONS
import prodDashboardSlice from "./prod/prodDashboardSlice";
import prodDefaultTaskSlice from "./prod/prodDefaultTaskSlice";
import prodProjectSlice from "./prod/prodProjectSlice";
import prodProjectAttachmentSlice from "./prod/prodProjectAttachmentSlice";
import prodProjectHistorySlice from "./prod/prodProjectHistorySlice";
import prodTaskSlice from "./prod/prodTaskSlice";
import prodTaskAssignAttachmentSlice from "./prod/prodTaskAssignAttachmentSlice";
import prodTaskAttachmentSlice from "./prod/prodTaskAttachmentSlice";
import prodTaskObserverSlice from "./prod/prodTaskObserverSlice";
import prodTaskResponsibleSlice from "./prod/prodTaskResponsibleSlice";
import prodTaskHistorySlice from "./prod/prodTaskHistorySlice";

// WORKFLOWS
import workflowSlice from "./wf/workflowSlice";
import workflowDetailSlice from "./wf/workflowDetailSlice";

// USER MANAGEMENT
import userSlice from "./um/userSlice";
import userRightSlice from "./um/userRightSlice";
import changePasswordSlice from "./um/changePasswordSlice";

// EMAIL NOTIFICATION
import emailSmtpSlice from "./email/emailSmtpSlice";
import emailTemplateSlice from "./email/emailTemplateSlice";

export default configureStore({
  reducer: {
    auth: authSlice,
    layout: layoutSlice,
    popup: popupSlice,
    notification: notificationSlice,
    client: clientSlice,
    department: departmentSlice,
    position: positionSlice,
    employee: employeeSlice,
    station: stationSlice,
    program: programSlice,
    product: productSlice,
    menu: menuSlice,
    pmDashboard: pmDashboardSlice,
    pmDefaultTask: pmDefaultTaskSlice,
    project: projectSlice,
    projectAttachment: projectAttachmentSlice,
    projectMo: projectMoSlice,
    projectMoRev: projectMoRevSlice,
    projectHistory: projectHistorySlice,
    task: taskSlice,
    taskAttachment: taskAttachmentSlice,
    taskObserver: taskObserverSlice,
    taskResponsible: taskResponsibleSlice,
    taskHistory: taskHistorySlice,
    kpiAdjustment: kpiAdjustmentSlice,
    prodDashboard: prodDashboardSlice,
    prodDefaultTask: prodDefaultTaskSlice,
    prodProject: prodProjectSlice,
    prodProjectAttachment: prodProjectAttachmentSlice,
    prodProjectHistory: prodProjectHistorySlice,
    prodTask: prodTaskSlice,
    prodTaskAssignAttachment: prodTaskAssignAttachmentSlice,
    prodTaskAttachment: prodTaskAttachmentSlice,
    prodTaskObserver: prodTaskObserverSlice,
    prodTaskResponsible: prodTaskResponsibleSlice,
    prodTaskHistory: prodTaskHistorySlice,
    workflow: workflowSlice,
    workflowDetail: workflowDetailSlice,
    user: userSlice,
    userRight: userRightSlice,
    changePassword: changePasswordSlice,
    emailSmtp: emailSmtpSlice,
    emailTemplate: emailTemplateSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
