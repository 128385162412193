import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDataByProject as getDataMoRevByProject } from "../../../stores/pm/projectMoRevSlice";
import TabMoRevHistoryWidget from "../tabs/tabMoRevHistoryWidget";

function TabMoRevHistory() {
  const dispatch = useDispatch();
  const dataProject = useSelector((state) => state.project.dataView);

  useEffect(() => {
    dispatch(getDataMoRevByProject({ projectId: dataProject.id }));
  }, [dispatch, dataProject.id]);

  return (
    <TabMoRevHistoryWidget
      ownerId={dataProject.owner_id}
    />
  );
}

export default TabMoRevHistory;
