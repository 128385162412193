export const CheckRoleIndex = (pathName) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const roles = JSON.parse(user.user_right.roles);
  const filtered = [...roles].filter((parent) => {
    const f1 = [...parent.sub].filter(({ url }) => url === pathName);
    if (f1.length > 0) {
      return f1[0].is_allow;
    }
  });

  return filtered.length > 0 ? true : false;
};

export const CheckRoleAction = (pathName, actionName) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const roles = JSON.parse(user.user_right.roles);
  const filtered = [...roles].filter((parent) => {
    const f1 = [...parent.sub].filter(({ url }) => url === pathName);
    if (f1.length > 0) {
      const f2 = [...f1[0].actions].filter(({ name }) => name === actionName);

      if (f2.length > 0) {
        return f2[0].is_allow;
      }
    }
  });

  return filtered.length > 0 ? true : false;
};
