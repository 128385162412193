import { createSlice } from "@reduxjs/toolkit";
import { InstanceApi } from "../../helpers/InstanceApi";
import { Validate } from "../../helpers/Validate";
import { setPopup } from "../popupSlice";

export const departmentSlice = createSlice({
  name: "department",
  initialState: {
    openModal: false,
    dataTables: undefined,
    dataOptions: [],
    dataFilter: [],
    dataView: undefined,
    formValidate: {
      department_code: { required: true, title: "Department Code" },
      department_name: { required: true, title: "Department Name" },
    },
    formData: {
      id: "",
      department_code: "",
      department_name: "",
      active: 1,
    },
  },
  reducers: {
    resetDataView: (state) => {
      state.dataView = undefined;
    },
    setModal: (state, action) => {
      state.openModal = action.payload;
    },
    setDataTables: (state, action) => {
      state.dataTables = action.payload;
    },
    setDataOptions: (state, action) => {
      state.dataOptions = action.payload;
    },
    setDataFilter: (state, action) => {
      state.dataFilter = action.payload;
    },
    setDataView: (state, action) => {
      state.dataView = action.payload;
    },
    setFormData: (state, action) => {
      state.formData[action.payload.name] = action.payload.value;
    },
  },
});

export const resetFormData = () => async (dispatch, getState) => {
  const formData = getState().department.formData;
  Object.keys(formData).forEach((key) => {
    dispatch(setFormData({ name: key, value: "" }));
  });
};

export const getDataTables = (params) => async (dispatch) => {
  try {
    dispatch(setDataTables(undefined));
    dispatch(setDataView(undefined));
    dispatch(setPopup({ open: true, type: "loading" }));

    const response = await InstanceApi.get("/master/departments", {
      params: params,
    });
    if (response) {
      dispatch(setDataTables(response.data.data));
      dispatch(setPopup({ open: false }));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getDataOptions = () => async (dispatch) => {
  try {
    const response = await InstanceApi.get("/master/departments?active=1");
    if (response) {
      const data = response.data.data.map((item) => {
        return { value: item.id, label: item.department_name };
      });

      dispatch(setDataOptions(data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getDataView = (params) => async (dispatch) => {
  try {
    dispatch(setPopup({ open: true, type: "loading" }));

    const response = await InstanceApi.get("/master/departments/" + params.id);
    if (response) {
      dispatch(setDataView(response.data.data));
      dispatch(setPopup({ open: false }));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(error.response.data.message);
  }
};

export const getFormData = (params) => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "loading" }));

    const response = await InstanceApi.get("/master/departments/" + params.id);
    if (response) {
      const data = response.data.data;
      const formData = getState().department.formData;
      Object.keys(formData).forEach((key) => {
        dispatch(setFormData({ name: key, value: data?.[key] ?? "" }));
      });

      dispatch(setPopup({ open: false }));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(error.response.data.message);
  }
};

export const submit = (params) => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "processing" }));
    const limit = 100
    const formValidate = getState().department.formValidate;
    const formData = getState().department.formData;
    const validate = Validate(formValidate, formData);

    if (!validate.isValid) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: validate.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
    } else {
      let response = "";
      if (params.formType === "create") {
        response = await InstanceApi.post("/master/departments", formData);
        dispatch(
          setPopup({
            open: true,
            type: "success",
            message: response.data.message,
            handleOk: () => {
              dispatch(setPopup({ open: false }));
              dispatch(setModal(false));
              dispatch(resetFormData());
              dispatch(getDataTables({ limit: limit, page: 1, search: "" }));
            },
          })
        );
      } else {
        response = await InstanceApi.patch(
          "/master/departments/" + formData.id,
          formData
        );
        dispatch(
          setPopup({
            open: true,
            type: "success",
            message: response.data.message,
            handleOk: () => {
              dispatch(setPopup({ open: false }));
              dispatch(setModal(false));
              dispatch(resetFormData());
              dispatch(getDataTables({ limit: limit, page: 1, search: "" }));
            },
          })
        );
      }
    }
  } catch (err) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: err.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(err.response.data.message);
  }
};

export const deleteData = (params) => (dispatch) => {
  try {
    dispatch(
      setPopup({
        open: true,
        type: "delete",
        message: "Are you sure to delete this data?",
        handleOk: async () => {
          try {
            dispatch(setPopup({ open: true, type: "processing" }));
            
            const response = await InstanceApi.delete(
              "/master/departments/" + params.id
            );
            if (response) {
              dispatch(
                setPopup({
                  open: true,
                  type: "success",
                  message: response.data.message,
                  handleOk: () => {
                    dispatch(setPopup({ open: false }));
                    dispatch(getDataTables());
                  },
                })
              );
            }
          } catch (error) {
            dispatch(
              setPopup({
                open: true,
                type: "error",
                message: error.message,
                handleOk: () => {
                  dispatch(setPopup({ open: false }));
                },
              })
            );
          }
        },
        handleCancel: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  } catch (err) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: err.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const {
  resetDataView,
  setModal,
  setDataTables,
  setDataOptions,
  setDataFilter,
  setDataView,
  setFormData,
} = departmentSlice.actions;
export default departmentSlice.reducer;
