import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiChevronRight, BiFile, BiSearch } from "react-icons/bi";
import { getDataByProject } from "../../../stores/pm/projectHistorySlice";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import ModalForm from "../../../components/ModalForm";
import { setPopup } from "../../../stores/popupSlice";
import { DownloadFile } from "../../../helpers/DownloadFile";
import parse from "html-react-parser";

function TabActivity() {
  const dispatch = useDispatch();
  const dataProject = useSelector((state) => state.project.dataView);
  const dataByProject = useSelector(
    (state) => state.projectHistory.dataByProject
  );
  const [openModalAttachment, setOpenModalAttachment] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [listViewAttachment, setListViewAttachment] = useState([]);
  const [listItem, setListItem] = useState([]);

  useEffect(() => {
    dispatch(getDataByProject({ projectId: dataProject.id }));
  }, [dispatch, dataProject.id]);

  const viewAttachment = (title, message, projectHistoryId) => {
    setModalTitle(title);
    setModalMessage(message);
    let filtered = {};
    filtered = [...dataByProject].filter(
      (data) => data.id === projectHistoryId
    );
    if (filtered[0]) {
      console.log(filtered.attachment);
      setListViewAttachment(filtered[0].attachment);
    }

    setOpenModalAttachment(true);
  };

  const getDownloadFile = (path) => {
    dispatch(setPopup({ open: true, type: "download" }));

    const downloadUrl = "/download?file_path=" + path;
    DownloadFile(downloadUrl);

    setTimeout(() => {
      dispatch(setPopup({ open: false }));
    }, 3000);
  };

  useEffect(() => {
    setListItem(dataByProject);
  }, [dataByProject]);

  const handleSearch = (value) => {
    const filtered = [...dataByProject].filter(
      (data) =>
        data.created_by.toString().toLowerCase().indexOf(value) > -1 ||
        data.created_at_convert.toString().toLowerCase().indexOf(value) > -1 ||
        data.message.toString().toLowerCase().indexOf(value) > -1 ||
        data?.notes?.toString().toLowerCase().indexOf(value) > -1
    );

    setListItem(filtered);
  };

  return (
    <>
      {openModalAttachment && (
        <ModalForm
          modalTitle={modalTitle}
          modalSize="medium"
          closeModal={() => setOpenModalAttachment(false)}
        >
          <div className="w-full mb-4">{modalMessage}</div>
          <div className="flex flex-col gap-2">
            {listViewAttachment.map((item, index) => (
              <div
                key={index}
                className="flex flex-row items-center gap-2 bg-slate-50 border border-slate-tertiary hover:bg-slate-tertiary rounded-lg truncate text-left cursor-pointer"
                onClick={() => getDownloadFile(item.file_path)}
              >
                <div className="flex-1 flex flex-col gap-4 py-4 px-4 ">
                  <div className="truncate">
                    <span className="truncate text-base font-semibold">
                      {" "}
                      {item.file_name}
                    </span>
                  </div>

                  <div className="grid grid-cols-5 gap-2">
                    <div className="col-span-1 text-sm">
                      <span className="mb-2 block text-text-secondary">
                        Size
                      </span>
                      <span className="block">{item.file_size}</span>
                    </div>
                    <div className="col-span-2 text-sm">
                      <span className="mb-2 block text-text-secondary">
                        From
                      </span>
                      <span className="block">{item.type}</span>
                    </div>
                    <div className="col-span-2 text-sm">
                      <span className="mb-2 block text-text-secondary">
                        Created Date
                      </span>
                      <span className="block">{item.created_at_convert}</span>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-center px-4">
                  <BiChevronRight size={20} />
                </div>
              </div>
            ))}
          </div>
        </ModalForm>
      )}
      <Card>
        <CardHeader>
          <CardTitle>Last Activity</CardTitle>
        </CardHeader>
        <CardBody>
          {!dataByProject && (
            <div className="text-center font-semibold">Loading...</div>
          )}
          {listItem && (
            <>
              <div className="mb-2">
                <div className="relative">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <BiSearch className="w-5 h-5 text-gray-300" />
                  </div>
                  <input
                    className="form-control-search"
                    placeholder="Search..."
                    onChange={(e) =>
                      handleSearch(e.target.value.toString().toLowerCase())
                    }
                  />
                </div>
              </div>
              <div className="space-y-2">
                {listItem?.length === 0 ? (
                  <div className="Py-4 text-center font-semibold">
                    No activity
                  </div>
                ) : (
                  <>
                    {listItem?.map((item, index) => (
                      <div
                        key={index}
                        className="bg-slate-50 border border-slate-tertiary rounded-lg text-left"
                      >
                        <div className="flex-1 flex flex-col gap-4 py-4 px-4">
                          <div className="flex items-center justify-between gap-4">
                            <span className="truncate text-base font-semibold">
                              {item.created_by}
                            </span>
                            <div className="flex items-center gap-4">
                              {item.attachment.length > 0 && (
                                <button
                                  type="button"
                                  className="btn btn-xs btn-secondary"
                                  onClick={() =>
                                    viewAttachment(
                                      `${item.type} Project Attachments`,
                                      item.message,
                                      item.id
                                    )
                                  }
                                >
                                  <BiFile /> {item.attachment.length}{" "}
                                  Attachments
                                </button>
                              )}
                              <span className="text-text-secondary text-sm font-normal">
                                {item.created_at_convert}
                              </span>
                            </div>
                          </div>
                          <div className="w-full">
                            <div className="text-base">{item.message}</div>
                          </div>
                          {item.notes && item.notes !== "" && (
                            <div className="w-full">
                              <span className="mb-2 block text-sm text-text-secondary">
                                Notes
                              </span>
                              <span className="block">
                                {parse(item.notes.toString())}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
}

export default TabActivity;
