import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "../../../components/Card";

import { useSelector } from "react-redux";

function CardObserver() {
  const dataTask = useSelector((state) => state.prodTask.dataView);

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Observers</CardTitle>
        </CardHeader>
        <CardBody>
          {dataTask.observer_id && (
            <div className="space-y-4">
              {!dataTask.observer_id && (
                <span className="block text-center">Empty observer</span>
              )}
              <div className="flex-1 flex flex-col gap-1">
                <span className="block font-semibold">
                  {dataTask.observer_name}
                </span>
                <span className="block text-sm text-text-secondary">
                  {dataTask.observer_position_name}
                </span>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
}

export default CardObserver;
