import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/Button";
import {
  PageHeader,
  PageHeaderAction,
  PageHeaderTitle,
} from "../../../components/PageHeader";
import { CheckRoleAction, CheckRoleIndex } from "../../../helpers/CheckRole";

import {
  setModal,
} from "../../../stores/pm/pmDefaultTaskSlice";
import Form from "./form";
import IndexTable from "./indexTable";

function Index() {
  const pathName = "/pm/default-tasks";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const openModal = useSelector((state) => state.pmDefaultTask.openModal);
  const [formType, setFormType] = useState();
  const [dataId, setDataId] = useState();

  useEffect(() => {
    if (!CheckRoleIndex(pathName)) {
      navigate("/404");
    }
  }, [dispatch, navigate]);

  return (
    <>
      {openModal && <Form formType={formType} dataId={dataId} />}

        <>
          <PageHeader>
            <PageHeaderTitle>Default Tasks </PageHeaderTitle>
            <PageHeaderAction>
              {CheckRoleAction(pathName, "create") && (
                <Button
                  title="Add New"
                  icon="BiPlus"
                  color="primary"
                  onClick={() => {
                    setFormType("create");
                    dispatch(setModal(true));
                  }}
                />
              )}
            </PageHeaderAction>
          </PageHeader>
         <IndexTable setDataId={setDataId} setFormType={setFormType} />
        </>
    </>
  );
}

export default Index;
