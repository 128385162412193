import { createSlice } from "@reduxjs/toolkit";
import { InstanceApi } from "../../helpers/InstanceApi";
import { Validate } from "../../helpers/Validate";
import { setPopup } from "../popupSlice";

import { getDataByTask as getDataAttachmentByTask } from "./prodTaskAttachmentSlice";

export const prodTaskSlice = createSlice({
  name: "prodTask",
  initialState: {
    openModal: false,
    openModalResponsible: false,
    openShowTask: false,
    modalAddTask: false,
    indexDisplay: "table",
    dataCount: undefined,
    dataDeadline: undefined,
    dataTables: undefined,
    dataOptions: [],
    dataView: undefined,
    dataByProject: undefined,
    formData: {
      id: "",
      project_id: "",
      department_id: "",
      task_no: "",
      task_name: "",
      owner_id: "",
      observer_id: "",
      responsible_id: "",
      assign_date: "",
      file_path: "",
    },
    formAssign: {
      id: "",
      responsible_id: "",
    },
  },
  reducers: {
    setModal: (state, action) => {
      state.openModal = action.payload;
    },
    setModalResponsible: (state, action) => {
      state.openModalResponsible = action.payload;
    },
    setShowTask: (state, action) => {
      state.openShowTask = action.payload;
    },
    setIndexDisplay: (state, action) => {
      state.indexDisplay = action.payload;
    },
    setDataTables: (state, action) => {
      state.dataTables = action.payload;
    },
    setDataOptions: (state, action) => {
      state.dataOptions = action.payload;
    },
    setDataByProject: (state, action) => {
      state.dataByProject = action.payload;
    },
    setDataView: (state, action) => {
      state.dataView = action.payload;
    },
    setFormData: (state, action) => {
      state.formData[action.payload.name] = action.payload.value;
    },
    setFormAssign: (state, action) => {
      state.formAssign[action.payload.name] = action.payload.value;
    },
    setDataCount: (state, action) => {
      state.dataCount = action.payload;
    },
    setDataDeadline: (state, action) => {
      state.dataDeadline = action.payload;
    },
  },
});

export const resetFormData = () => async (dispatch, getState) => {
  const formData = getState().task.formData;
  Object.keys(formData).forEach((key) => {
    dispatch(setFormData({ name: key, value: "" }));
  });
};

export const getDataTables = (params) => async (dispatch) => {
  try {
    dispatch(setDataTables(undefined));
    dispatch(setPopup({ open: true, type: "loading" }));

    const response = await InstanceApi.get("/prod/tasks", {params: params});
    if (response) {
      dispatch(setDataTables(response.data.data));
      dispatch(setDataView(undefined));
      dispatch(setPopup({ open: false }));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const getDataByProject = (params) => async (dispatch) => {
  try {
    dispatch(setDataByProject(undefined));
    const response = await InstanceApi.get(
      "/prod/tasks/project/" + params.projectId
    );
    if (response) {
      dispatch(setDataByProject(response.data.data));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const getDataView = (params) => async (dispatch) => {
  try {
    dispatch(setPopup({ open: true, type: "loading" }));

    const response = await InstanceApi.get("/prod/tasks/" + params.id);
    if (response) {
      dispatch(setDataView(response.data.data));
      dispatch(getDataAttachmentByTask({ taskId: params.id }));
      dispatch(setPopup({ open: false }));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const getFormData = (params) => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "loading" }));

    const response = await InstanceApi.get("/prod/tasks/" + params.id);
    if (response) {
      const data = response.data.data;
      const formData = getState().task.formData;

      Object.keys(formData).forEach((key) => {
        dispatch(setFormData({ name: key, value: data?.[key] }));
      });

      dispatch(setPopup({ open: false }));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const assignTask = (params) => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "processing" }));
    const dataTask = getState().prodTask.dataView;
    const formAssign = getState().prodTask.formAssign;
    const response = await InstanceApi.post("/prod/tasks/assign", formAssign);
    if (response) {
      dispatch(
        setPopup({
          open: true,
          type: "success",
          message: response.data.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
            dispatch(setModalResponsible(false));
            dispatch(getDataView({ id: dataTask.id }));
          },
        })
      );
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const deleteResponsible = () => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "processing" }));
    const dataTask = getState().prodTask.dataView;
    const response = await InstanceApi.post("/prod/tasks/delete-responsible", {
      id: dataTask.id,
    });
    if (response) {
      dispatch(
        setPopup({
          open: true,
          type: "success",
          message: response.data.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
            dispatch(getDataView({ id: dataTask.id }));
          },
        })
      );
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const submit = (params) => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "processing" }));
    const formData = getState().task.formData;
    const validate = Validate(formData);

    if (!validate.isValid) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: validate.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
    } else {
      let response = "";
      if (params.formType === "create") {
        response = await InstanceApi.post("/prod/tasks", formData);
        if (response) {
          if (params.fromSubmit === "project") {
            dispatch(
              setPopup({
                open: true,
                type: "success",
                message: response.data.message,
                handleOk: () => {
                  dispatch(setPopup({ open: false }));
                  dispatch(setModal(false));
                  dispatch(getDataTables());
                  dispatch(
                    getDataByProject({ projectId: formData.project_id })
                  );
                },
              })
            );
          } else {
            dispatch(
              setPopup({
                open: true,
                type: "success",
                message: response.data.message,
                handleOk: () => {
                  dispatch(setPopup({ open: false }));
                  dispatch(setModal(false));
                  // dispatch( getDataView({id: response.data.data.id}) )
                  window.location.href = "/prod/tasks/" + response.data.data.id;
                },
              })
            );
          }
        }
      } else {
        response = await InstanceApi.patch(
          "/prod/tasks/" + formData.id,
          formData
        );
        if (response) {
          dispatch(
            setPopup({
              open: true,
              type: "success",
              message: response.data.message,
              handleOk: () => {
                dispatch(setPopup({ open: false }));
                dispatch(setModal(false));
                dispatch(resetFormData());
                dispatch(getDataView({ id: formData.id }));
              },
            })
          );
        }
      }
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const deleteData = (params) => (dispatch) => {
  try {
    dispatch(
      setPopup({
        open: true,
        type: "delete",
        message: "Are you sure to delete this data?",
        handleOk: async () => {
          try {
            const response = await InstanceApi.delete(
              "/prod/tasks/" + params.id
            );
            if (response) {
              dispatch(
                setPopup({
                  open: true,
                  type: "success",
                  message: response.data.message,
                  handleOk: () => {
                    dispatch(setPopup({ open: false }));
                    window.location.href = "/prod/tasks";
                  },
                })
              );
            }
          } catch (error) {
            dispatch(
              setPopup({
                open: true,
                type: "error",
                message: error.response.data.message,
                handleOk: () => {
                  dispatch(setPopup({ open: false }));
                },
              })
            );
          }
        },
        handleCancel: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const {
  setModal,
  setModalResponsible,
  setDataCount,
  setDataDeadline,
  setShowTask,
  setIndexDisplay,
  setDataTables,
  setDataOptions,
  setDataByProject,
  setDataView,
  setFormData,
  setFormAssign,
} = prodTaskSlice.actions;
export default prodTaskSlice.reducer;
