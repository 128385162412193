import React, { useState, useEffect, useRef } from "react";
import {
  PageHeader,
  PageHeaderAction,
  PageHeaderTitle,
} from "../../../components/PageHeader";
import { Button } from "../../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import { useDispatch, useSelector } from "react-redux";
import {
  getFormData,
  setFormData,
  submit,
} from "../../../stores/email/emailTemplateSlice";
import { BiAlarm, BiHelpCircle, BiStar } from "react-icons/bi";
import ReactQuill from "react-quill";
import ModalForm from "../../../components/ModalForm";

export default function Edit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formData = useSelector((state) => state.emailTemplate.formData);
  const [formVariable, setFormVariable] = useState([]);
  const [modalHelpVariable, setModalHelpVariable] = useState(false);

  useEffect(() => {
    dispatch(getFormData({ id: id }));
  }, [dispatch]);

  useEffect(() => {
    if (formData?.variable) {
      const variable = JSON.parse(formData?.variable);
      setFormVariable(variable);
    }
  }, [formData]);

  const handleInput = (e) => {
    dispatch(setFormData({ name: e.target.name, value: e.target.value }));
  };

  const editorModules = {
    toolbar: "#toolbar",
    // toolbar: {
    //   container: [
    //     // [{ 'qlplaceholder': ["[GuestName]", "[HotelName]"] }],
    //     [{ header: [1, 2, false] }],
    //     ["bold", "italic", "underline", "strike", "blockquote"],
    //     [
    //       { list: "ordered" },
    //       { list: "bullet" },
    //       { indent: "-1" },
    //       { indent: "+1" },
    //     ],
    //     ["link"],
    //     ["clean"],
    //   ],
    //   // handlers: {
    //   //   placeholder: function (value) {
    //   //     if (value) {
    //   //       // const cursorPosition = quilRef.getSelection().index;
    //   //       // quilRef.insertText(cursorPosition, value);
    //   //       // quilRef.setSelection(cursorPosition + value.length);
    //   //     }
    //   //   },
    //   // },
    // },
  };

  const quilRef = useRef();

  const CustomToolbar = () => (
    <div id="toolbar">
      <select className="ql-header">
        <option value="1"></option>
        <option value="2"></option>
      </select>
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <select className="ql-color">
        <option value="red"></option>
        <option value="green"></option>
        <option value="blue"></option>
        <option value="orange"></option>
        <option value="violet"></option>
        <option value="#d0d1d2"></option>
      </select>
      <button className="ql-insertStar">
        <span className="octicon octicon-star">★</span>
      </button>
    </div>
  );

  function handleClickVariable(value) {
    const cursorPosition = quilRef.current.getEditor().getSelection()?.index;
    if (cursorPosition) {
      // const cursorPosition = quilRef.getSelection().index;
      // quilRef.current.getEditor().insertText(cursorPosition, "★");
      quilRef.current.getEditor().insertText(cursorPosition, value);
      quilRef.current
        .getEditor()
        .setSelection(cursorPosition + value.length);
    }
  }

  const editorFormat = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];

  return (
    <>
      {modalHelpVariable && (
        <ModalForm
          modalSize="small"
          modalTitle="Add/Remove Variable"
          closeModal={() => setModalHelpVariable(false)}
        >
          <h3 className="text-sm font-semibold">Add Variable</h3>
          <ol>
            <li>Click in text editor area</li>
            <li>Next click variable button</li>
          </ol>
          <h3 className="text-sm font-semibold">Remove Variable</h3>
          <ol>
            <li>Block string of variable value</li>
            <li>Next press Backspace / Delete</li>
          </ol>
        </ModalForm>
      )}
      <PageHeader>
        <PageHeaderTitle>
          Email Template <small>Edit email template</small>
        </PageHeaderTitle>
        <PageHeaderAction>
          <Button
            title="Save"
            icon="BiSave"
            color="primary"
            onClick={() => dispatch(submit())}
          />
          <Button
            title="Cancel"
            icon="BiX"
            color="secondary"
            onClick={() => navigate("/email/template")}
          />
        </PageHeaderAction>
      </PageHeader>
      <Card>
        <CardHeader>
          <CardTitle>Data Template</CardTitle>
        </CardHeader>
        <CardBody>
          <div className="form-group w-1/3 md:w-1/3">
            <label className="control-label">
              Template Name
            </label>
            <input
              id="name"
              title="Template Name"
              type="text"
              name="name"
              className="form-control disabled"
              disabled
              value={formData.name}
              onChange={handleInput}
            />
          </div>
          <div className="form-group">
            <label className="control-label">
              Subject <span className="text-danger">*</span>
            </label>
            <input
              id="subject"
              title="Subject"
              type="text"
              name="subject"
              className="form-control"
              value={formData.subject}
              onChange={handleInput}
              required
            />
          </div>
          <div className="form-group">
            <label className="control-label">CC</label>
            <textarea
              id="cc"
              title="CC"
              name="cc"
              className="form-control !h-16"
              placeholder="email-1@example.com,email-2@example.com,email-3@example.com"
              value={formData.cc}
              onChange={handleInput}
            >
              {formData.cc}
            </textarea>
            <div className="text-sm text-text-secondary italic">
              Add a comma separator if there are more than 1 email
            </div>
          </div>
          <div className="form-group">
            <label className="control-label">BCC</label>
            <textarea
              id="bcc"
              title="BCC"
              name="bcc"
              className="form-control !h-16"
              placeholder="email-1@example.com,email-2@example.com,email-3@example.com"
              value={formData.bcc}
              onChange={handleInput}
            >
              {formData.bcc}
            </textarea>
            <div className="text-sm text-text-secondary italic">
              Add a comma separator if there are more than 1 email
            </div>
          </div>
          <div className="form-group">
            <label className="control-label">
              Body Email <span className="text-danger">*</span>
            </label>
            <div className="form-control h-72">
              <div className="text-editor">
                {/* <CustomToolbar /> */}
                <div id="toolbar">
                  <div>
                    <select className="ql-header" defaultValue={false}>
                      <option value="1"></option>
                      <option value="2"></option>
                      <option value="false"></option>
                    </select>
                    <span className="ql-formats">
                      <button className="ql-bold"></button>
                      <button className="ql-italic"></button>
                      <button className="ql-underline"></button>
                      <button className="ql-strike"></button>
                      <button className="ql-blockquote"></button>
                    </span>
                    <span className="ql-formats">
                      <button className="ql-list" value="ordered"></button>
                      <button className="ql-list" value="bullet"></button>
                      <button className="ql-indent" value="-1"></button>
                      <button className="ql-indent" value="+1"></button>
                    </span>
                    <span className="ql-formats">
                      <button className="ql-link"></button>
                    </span>
                    <span className="ql-formats">
                      <button className="ql-clean"></button>
                    </span>
                  </div>
                  <div className="mt-2 pt-2 border-t border-slate-tertiary">
                    <span>Variable : </span>
                    <span className="ql-formats">
                      {formVariable.length > 0 &&
                        formVariable.map((item, index) => (
                          <button
                            key={index}
                            className="!w-fit !px-4 !mr-1 !text-white !text-sm !bg-primary !rounded-md"
                            style={{ width: "50px important" }}
                            onClick={() => handleClickVariable(`${item.value}`)}
                          >
                            {item.label}
                          </button>
                        ))}
                      <button onClick={() => setModalHelpVariable(true)}>
                        <BiHelpCircle size={20} color="#aaaaaa" />
                      </button>
                    </span>
                    {/* <button
                      id="custom-button"
                      className="bg-primary"
                      onClick={() => handleClickVariable("{$from_name}")}
                    >
                      <BiStar />
                    </button> */}
                  </div>
                </div>
                <ReactQuill
                  ref={quilRef}
                  id="body"
                  title="Body Email"
                  name="body"
                  className="form-control h-60"
                  value={formData.body}
                  onChange={(e) =>
                    dispatch(setFormData({ name: "body", value: e }))
                  }
                  required
                  modules={editorModules}
                  formats={editorFormat}
                />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
