import React, { useEffect, useState } from "react";
import {
  PageHeader,
  PageHeaderTitle,
  PageHeaderAction,
} from "../../../components/PageHeader";
import { BiTask } from "react-icons/bi";
import Select from "../../../components/Select";
import { Button } from "../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  resetFormData,
  setFormData,
  submit,
  setFormTask,
  getDataMo,
  setDataMo,
} from "../../../stores/prod/prodProjectSlice";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import { getDataList as getDataListTask } from "../../../stores/prod/prodDefaultTaskSlice";
import {
  getCompletedDataOptions,
  getCompletedMoDataOptions,
  setCompletedMoDataOptions,
} from "../../../stores/pm/projectSlice";

import TabFormTask from "./tabFormTask";
import { CheckRoleAction } from "../../../helpers/CheckRole";

export default function Form() {
  const pathName = "/prod/projects";
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openTab, setOpenTab] = useState("tab-task");
  const formData = useSelector((state) => state.prodProject.formData);
  const dataOptionProjectCompleted = useSelector(
    (state) => state.project.completedDataOptions
  );
  const dataOptionCompletedMo = useSelector(
    (state) => state.project.completedMoDataOptions
  );
  const defaultTaskList = useSelector(
    (state) => state.prodDefaultTask.dataList
  );
  const dataMo = useSelector((state) => state.prodProject.dataMo);
  const [pageTitle, setPageTitle] = useState();
  let { id } = useParams();

  useEffect(() => {
    if (!CheckRoleAction(pathName, "create")) {
      navigate("/404");
    }
    dispatch(resetFormData());
    dispatch(setDataMo(undefined));
    dispatch(setCompletedMoDataOptions([]));
    dispatch(getCompletedDataOptions());
    dispatch(getDataListTask());

    setPageTitle("Create");
    dispatch(setFormData({ name: "owner_id", value: user.id }));
  }, [dispatch, navigate, id, user.id]);

  const handleInput = (e) => {
    dispatch(setFormData(e.target));
  };

  useEffect(() => {
    dispatch(setFormTask(defaultTaskList));
  }, [dispatch, defaultTaskList]);

  useEffect(() => {
    dispatch(setCompletedMoDataOptions([]));
    if (formData.project_id) {
      dispatch(getCompletedMoDataOptions({ projectId: formData.project_id }));
    } else {
      dispatch(setFormData({ name: "project_mo_id", value: "" }));
    }
  }, [dispatch, formData.project_id]);

  useEffect(() => {
    dispatch(setDataMo(undefined));
  }, [dataOptionCompletedMo]);

  return (
    <>
      <PageHeader>
        <PageHeaderTitle>
          Projects <small>{pageTitle}</small>
        </PageHeaderTitle>
        <PageHeaderAction>
          <Button
            title="Save"
            icon="BiSave"
            color="primary"
            onClick={() => dispatch(submit())}
          />
          <Button
            title="Cancel"
            icon="BiX"
            color="secondary"
            onClick={() => navigate("/prod/projects")}
          />
        </PageHeaderAction>
      </PageHeader>
      <div className="space-y-4">
        <Card>
          <CardHeader>
            <CardTitle>Detail Project</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="w-full">
              <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
                <div className="cols-span-1">
                  <div className="form-group">
                    <label className="control-label">
                      Completed Project <span className="text-danger">*</span>
                    </label>
                    <Select
                      id="project_id"
                      title="Completed Project"
                      name="project_id"
                      placeholder={"Choose Completed Project..."}
                      value={dataOptionProjectCompleted.filter(
                        (option) => option.value === formData.project_id
                      )}
                      onChange={(e) =>
                        dispatch(
                          setFormData({ name: "project_id", value: e?.value })
                        )
                      }
                      options={dataOptionProjectCompleted}
                      required={true}
                    />
                  </div>
                </div>
                <div className="cols-span-1">
                  <div className="form-group">
                    <label className="control-label">
                      Item No. <span className="text-danger">*</span>
                    </label>
                    <Select
                      id="project_mo_id"
                      title="Item No."
                      name="project_mo_id"
                      placeholder={"Choose Item No..."}
                      value={dataOptionCompletedMo.filter(
                        (option) => option.value === formData.project_mo_id
                      )}
                      onChange={(e) => {
                        dispatch(getDataMo({ id: e?.value }));
                        dispatch(
                          setFormData({
                            name: "project_mo_id",
                            value: e?.value,
                          })
                        );
                      }}
                      options={dataOptionCompletedMo}
                      required={true}
                    />
                  </div>
                </div>
              </div>
              {dataMo && (
                <div className="mb-4 p-4 border border-slate-tertiary rounded-md w-full">
                  <div className="grid grid-cols-10 gap-2 text-sm">
                    <div className="col-span-2">
                      <span className="mb-2 block text-text-secondary font-semibold">
                        Item No
                      </span>
                      <span className="block font-bold">{dataMo.mo_no}</span>
                    </div>
                    <div className="col-span-3">
                      <span className="mb-2 block text-text-secondary">
                        Item Description
                      </span>
                      <span className="block">{dataMo.description}</span>
                    </div>
                    <div className="col-span-2">
                      <span className="mb-2 block text-text-secondary">
                        Product
                      </span>
                      <span className="block">{dataMo.product_name}</span>
                    </div>
                    <div className="col-span-1">
                      <span className="mb-2 block text-text-secondary">
                        Spot
                      </span>
                      <span className="block">{dataMo.spot}</span>
                    </div>
                    <div className="col-span-2">
                      <span className="mb-2 block text-text-secondary">
                        Program
                      </span>
                      <span className="block">
                        {dataMo.program_name} @ {dataMo.station_name}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
                <div className="cols-span-1">
                  <div className="form-group">
                    <label className="control-label">
                      Project Name <span className="text-danger">*</span>
                    </label>
                    <input
                      id="project_name"
                      title="Project Name"
                      type="text"
                      name="project_name"
                      className="form-control"
                      value={formData.project_name}
                      onChange={handleInput}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label">
                  Description <span className="text-danger">*</span>
                </label>
                <textarea
                  id="descriptions"
                  title="Description"
                  name="descriptions"
                  className="form-control h-60"
                  defaultValue={formData.descriptions}
                  onChange={handleInput}
                  required={true}
                />
              </div>
            </div>
          </CardBody>
        </Card>

        <div className="tabs">
          <div className="tab-nav">
            <button
              type="button"
              className={`btn ${openTab === "tab-task" ? "active" : ""}`}
              onClick={() => setOpenTab("tab-task")}
            >
              <BiTask /> Task
            </button>
          </div>
          {openTab === "tab-task" && (
            <div className="tab-panel">
              <TabFormTask />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
