import React from 'react'
import ShowContent from './showContent'

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setShowTask } from '../../../stores/prod/prodTaskSlice'

function Show(props) {
  const id = props.taskId
  const projectId = props.projectId
  const fromSubmit = props.fromSubmit
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleClickBack = () => {
    dispatch( setShowTask(false) )
    navigate(`/prod/projects/view/${projectId}`)
  }

  return (
    <ShowContent taskId={id} projectId={projectId} fromSubmit={fromSubmit} onClickBack={handleClickBack} />
  )
}

export default Show