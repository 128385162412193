import React from "react";

export const PageHeader = ({ children }) => {
  return <div className="page-header">{children}</div>;
};

export const PageHeaderTitle = ({ children }) => {
  return <div className="page-header-title">{children}</div>;
};

export const PageHeaderAction = ({ children }) => {
  return <div className="page-header-action">{children}</div>;
};
