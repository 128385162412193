import React, { useState, useRef } from "react";
import { Button } from "../../../components/Button";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../components/Card";
import { useDispatch, useSelector } from "react-redux";
import { setPopup } from "../../../stores/popupSlice";
import { CardTitle } from "../../../components/Card";
import { InstanceApi } from "../../../helpers/InstanceApi";
import ModalForm from "../../../components/ModalForm";
import { BiTrash } from "react-icons/bi";
import { getDataView } from "../../../stores/pm/projectSlice";

function TabApprovalFinish() {
  const dispatch = useDispatch();
  const dataProject = useSelector((state) => state.project.dataView);
  const [dataAttachment, setAttachment] = useState([]);
  const [modalAttachment, setModalAttachment] = useState(false);
  const [approvalNotes, setApprovalNotes] = useState("");

  // attachment
  const inputRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);
  const [file, setFile] = useState();

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const saveAttachment = () => {
    setAttachment([
      ...dataAttachment,
      {
        name: file.name,
        value: file,
      },
    ]);

    setModalAttachment(false);
    setFile(undefined);
  };

  const deleteAttachment = (deleteIndex) => {
    let data = dataAttachment.filter((row, index) => {
      if (deleteIndex !== index) {
        return true;
      }
    });
    setAttachment(data);
  };

  const handleSubmit = async (e, approvalType) => {
    e.preventDefault();

    dispatch(setPopup({ open: true, type: "processing" }));
    const formData = new FormData();

    // Update the formData object
    formData.append("approval_notes", approvalNotes);
    formData.append("id", dataProject.id);
    formData.append("project_id", dataProject.id);
    if (dataAttachment.length > 0) {
      dataAttachment.map((item, index) => {
        formData.append("attachment[]", item.value, item.name);
      });
    }

    try {
      const response = await InstanceApi.post(
        `/pm/projects/${approvalType}/finish`,
        formData
      );
      if (response) {
        dispatch(
          setPopup({
            open: true,
            type: "success",
            message: response.data.message,
            handleOk: () => {
              dispatch(setPopup({ open: false }));
              window.location.reload();
            },
          })
        );
      }
    } catch (error) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: error.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
    }
  };

  return (
    <>
      {modalAttachment && (
        <ModalForm
          modalTitle="Upload Attachment"
          modalSize="medium"
          closeModal={() => setModalAttachment(false)}
        >
          <form onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
            <div
              className={`modal-upload-box ${
                dragActive ? "border-slate-800" : ""
              }`}
            >
              <div className="body">
                <input
                  id="input-file-upload"
                  type="file"
                  className="hidden"
                  ref={inputRef}
                  onChange={handleChange}
                />
                <p>
                  Drag and drop your file here or{" "}
                  <span
                    className="cursor-pointer underline text-primary-hover"
                    onClick={onButtonClick}
                  >
                    browse
                  </span>
                </p>
                {file && <p>{file.name}</p>}
              </div>
              <div className="mt-6 flex flex-row gap-2 items-center justify-center">
                {file ? (
                  <Button
                    title="Upload"
                    icon="BiUpload"
                    color="primary"
                    onClick={saveAttachment}
                  />
                ) : (
                  <Button title="Upload" icon="BiUpload" color="disabled" />
                )}
              </div>

              {dragActive && (
                <div
                  className="absolute w-full h-full rounded top-0 right-0 left-0 bottom-0"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
            </div>
          </form>
        </ModalForm>
      )}

      <Card>
        <CardHeader>
          <CardTitle>Approve / Reject Finish Project</CardTitle>
        </CardHeader>

        <CardBody>
          <div className="form-group">
            <label className="control-label">Finish Notes</label>
            <p>{dataProject.notes}</p>
          </div>
          <div className="form-group">
            <label className="control-label">Approval Notes</label>
            <textarea
              id="notes"
              title="Description"
              name="notes"
              className="form-control"
              defaultValue={approvalNotes}
              onChange={(e) => setApprovalNotes(e.target.value)}
            />
          </div>
          <div className="form-group">
            <div className="mb-2 flex flex-row items-center justify-between gap-4">
              <label className="control-label">Attachments</label>
              <Button
                title="Add Attachment"
                icon="BiPlus"
                size="sm"
                color="primary"
                onClick={() => setModalAttachment(true)}
              />
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: "5%" }}>No.</th>
                  <th>Files</th>
                  <th style={{ width: "5%" }}>Remove</th>
                </tr>
              </thead>
              <tbody>
                {dataAttachment.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>
                      <span
                        className="btn-action"
                        onClick={() => deleteAttachment(index)}
                      >
                        <BiTrash size="22" />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
        <CardFooter>
          <div className="mt-8 flex flex-row items-center gap-2">
            <Button
              title="Approve"
              icon="BiCheck"
              color="primary"
              onClick={(e) => handleSubmit(e, "approve")}
            />
            <Button
              title="Reject"
              icon="BiX"
              color="secondary"
              onClick={(e) => handleSubmit(e, "reject")}
            />
          </div>
        </CardFooter>
      </Card>
    </>
  );
}

export default TabApprovalFinish;
