import React, { useEffect, useState } from "react";
import {
  PageHeader,
  PageHeaderTitle,
  PageHeaderAction,
} from "../../../components/PageHeader";
import Select from "../../../components/Select";
import { Button } from "../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getFormData,
  setFormData,
  submit,
  resetFormData,
  getDataOptionUser as getDataOptionUserList,
  getDataOptionEmployee as getDataOptionEmployeeList,
  getDataOptionPosition as getDataOptionPositionList,
} from "../../../stores/um/userRightSlice";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import { getDataTables as getDataTablesMenu } from "../../../stores/master/menuSlice";
import { getDataOptions as getDataOptionEmployee } from "../../../stores/master/employeeSlice";
import { getDataOptions as getDataOptionPosition } from "../../../stores/master/positionSlice";
import { getDataOptions as getDataOptiondepartment } from "../../../stores/master/departmentSlice";

export default function Form() {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.userRight.formData);
  const dataOptionEmployee = useSelector((state) => state.employee.dataOptions);
  const dataOptionPosition = useSelector((state) => state.position.dataOptions);
  const dataOptionDepartment = useSelector(
    (state) => state.department.dataOptions
  );
  const dataOptionUserList = useSelector((state) => state.userRight.dataOptionUser);
  const dataOptionEmployeeList = useSelector((state) => state.userRight.dataOptionEmployee);
  const dataOptionPositionList = useSelector((state) => state.userRight.dataOptionPosition);
  const dataTableMenu = useSelector((state) => state.menu.dataTables)
  const dataOptionBy = [
    { value: "User", label: "User" },
    { value: "Employee", label: "Employee" },
    { value: "Position", label: "Position" },
  ];
  let { id } = useParams();
  const [formPrivileges, setFormPrivileges] = useState([]);

  useEffect(() => {
    setFormPrivileges([]);
    dispatch(getFormData({ id: id }));
    dispatch(getDataTablesMenu());
    dispatch(getDataOptiondepartment());
    dispatch(getDataOptionEmployee());
    dispatch(getDataOptionPosition());
  }, [dispatch, id, user.id, user.name]);

  useEffect(() => {
    switch (formData.type) {
      case 'User':
        dispatch(getDataOptionUserList({id: formData?.reference_id}));
        break;
      case 'Employee':
        dispatch(getDataOptionEmployeeList({id: formData?.reference_id}));
        break;
      case 'Position':
        dispatch(getDataOptionPositionList({id: formData?.reference_id}));
        break;
      default:
        break;
    }
  }, [dispatch, formData])

  const handleInput = (e) => {
    dispatch(setFormData(e.target));
  };

  useEffect(() => {
    if (formData.roles) {
      setFormPrivileges(JSON.parse(formData.roles));
    }
  }, [formData]);

  useEffect(() => {
    if(dataTableMenu){
      var newList = [];
      dataTableMenu.map((item, index) => {
        let itemPriv = undefined
        formPrivileges.map((itemPrivileges, _) => {
          if(itemPrivileges['name'] === item.name){
            itemPriv = itemPrivileges
          }
        })

        if(itemPriv){
          newList.push(itemPriv);
        } else {
          newList.push(item)
        }
      })

      setFormPrivileges(newList)
    }    
  }, [dataTableMenu]);

  const Menu = (props) => {
    const item = props.item;
    return (
      <>
        <tr className="hover:bg-white">
          <td>
            <div className="form-checkbox">
              <input
                type="checkbox"
                value={item?.id}
                checked={formPrivileges?.[props.parentIndex]?.["is_allow"]}
                onChange={() => {
                  var pri = [...formPrivileges];
                  var priParent = pri[props.parentIndex];
                  priParent = { ...priParent, is_allow: !priParent.is_allow };

                  pri[props.parentIndex] = priParent;

                  setFormPrivileges(pri);
                }}
              />
              <div className="font-bold ml-4">{item?.name}</div>
            </div>
          </td>
        </tr>
        {item?.sub.length > 0 && (
          <SubMenu parentIndex={props.parentIndex} item={item?.sub} />
        )}
      </>
    );
  };

  const SubMenu = (props) => {
    return props.item.map((sub, i_sub) => {
      return (
        <tr key={`sub_${i_sub}`} className="hover:bg-white">
          <td className="indent-4 hover:bg-white">
            <div className="form-checkbox">
              <input
                type="checkbox"
                value={sub.id}
                checked={
                  formPrivileges?.[props.parentIndex]?.["sub"][i_sub][
                    "is_allow"
                  ]
                }
                onChange={() => {
                  var pri = [...formPrivileges];
                  var sub = pri[props.parentIndex]["sub"][i_sub];
                  sub = { ...sub, is_allow: !sub.is_allow };

                  var newSub = [...pri[props.parentIndex]["sub"]];
                  newSub[i_sub] = sub;

                  var priParent = { ...pri[props.parentIndex] };
                  priParent.sub = newSub;

                  pri[props.parentIndex] = priParent;

                  setFormPrivileges(pri);
                }}
              />
              <div className="font-bold">{sub.name}</div>
            </div>
            <div className="ml-8">
              {sub.actions?.map((action, i_action) => (
                <div key={i_action}>
                  <div className="form-checkbox">
                    <input
                      type="checkbox"
                      checked={action.is_allow}
                      onChange={(e) => {
                        var pri = [...formPrivileges];
                        var subAction =
                          pri[props.parentIndex]["sub"][i_sub]["actions"][
                            i_action
                          ];
                        subAction = {
                          ...subAction,
                          is_allow: !subAction.is_allow,
                        };

                        var newAction = [
                          ...pri[props.parentIndex]["sub"][i_sub]["actions"],
                        ];
                        newAction[i_action] = subAction;

                        var sub = pri[props.parentIndex]["sub"][i_sub];
                        // action : view_by_department / view_by_position / view_by_employee
                        switch (action.name) {
                          case "view_by_department":
                            if (!e.target.checked === true) {
                              sub = { ...sub, department_id: [] };
                            }
                            break;
                          case "view_by_position":
                            if (!e.target.checked === true) {
                              sub = { ...sub, position_id: [] };
                            }
                            break;
                          case "view_by_employee":
                            if (!e.target.checked === true) {
                              sub = { ...sub, employee_id: [] };
                            }
                            break;
                          default:
                            break;
                        }

                        sub = { ...sub, actions: newAction };

                        var newSub = [...pri[props.parentIndex]["sub"]];
                        newSub[i_sub] = sub;

                        var priParent = { ...pri[props.parentIndex] };
                        priParent.sub = newSub;

                        pri[props.parentIndex] = priParent;

                        setFormPrivileges(pri);
                      }}
                    />
                    {action.name}
                  </div>
                  {action.name === "view_by_department" &&
                    action.is_allow &&
                    dataOptionDepartment.length > 0 && (
                      <div className="mt-2 mb-4 block indent-12 max-h-56 max-w-fit pr-8 slim-overflow overflow-y-auto">
                        <div className="font-bold">Departments</div>
                        <div>
                          {dataOptionDepartment.map((dept, i_dept) => (
                            <div
                              key={i_dept}
                              className="form-checkbox flex flex-row items-center"
                              style={{ display: "block" }}
                            >
                              <input
                                type="checkbox"
                                checked={
                                  formPrivileges[props.parentIndex]["sub"][
                                    i_sub
                                  ]["department_id"].includes(dept.value)
                                    ? true
                                    : false
                                }
                                className="mr-4"
                                onChange={(e) => {
                                  var pri = [...formPrivileges];
                                  var department =
                                    pri[props.parentIndex]["sub"][i_sub][
                                      "department_id"
                                    ];
                                  if (e.target.checked === true) {
                                    if (!department.includes(dept.value)) {
                                      department = [...department, dept.value];
                                    }
                                  } else {
                                    var arr = department.filter(
                                      (item) => item !== dept.value
                                    );
                                    department = arr;
                                  }

                                  var sub =
                                    pri[props.parentIndex]["sub"][i_sub];
                                  sub = { ...sub, department_id: department };

                                  var newSub = [
                                    ...pri[props.parentIndex]["sub"],
                                  ];
                                  newSub[i_sub] = sub;

                                  var priParent = { ...pri[props.parentIndex] };
                                  priParent.sub = newSub;

                                  pri[props.parentIndex] = priParent;

                                  setFormPrivileges(pri);
                                }}
                              />
                              {dept.label}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  {action.name === "view_by_position" &&
                    action.is_allow &&
                    dataOptionPosition.length > 0 && (
                      <div className="mt-2 mb-4 block indent-12 max-h-56 max-w-fit pr-8 slim-overflow overflow-y-auto">
                        <div className="font-bold">Positions</div>
                        <div>
                          {dataOptionPosition.map((post, i_post) => (
                            <div
                              key={i_post}
                              className="form-checkbox flex flex-row items-center"
                              style={{ display: "block" }}
                            >
                              <input
                                type="checkbox"
                                checked={
                                  formPrivileges[props.parentIndex]["sub"][
                                    i_sub
                                  ]["position_id"].includes(post.value)
                                    ? true
                                    : false
                                }
                                className="mr-4"
                                onChange={(e) => {
                                  var pri = [...formPrivileges];
                                  var department =
                                    pri[props.parentIndex]["sub"][i_sub][
                                      "position_id"
                                    ];
                                  if (e.target.checked === true) {
                                    if (!department.includes(post.value)) {
                                      department = [...department, post.value];
                                    }
                                  } else {
                                    var arr = department.filter(
                                      (item) => item !== post.value
                                    );
                                    department = arr;
                                  }

                                  var sub =
                                    pri[props.parentIndex]["sub"][i_sub];
                                  sub = { ...sub, position_id: department };

                                  var newSub = [
                                    ...pri[props.parentIndex]["sub"],
                                  ];
                                  newSub[i_sub] = sub;

                                  var priParent = { ...pri[props.parentIndex] };
                                  priParent.sub = newSub;

                                  pri[props.parentIndex] = priParent;

                                  setFormPrivileges(pri);
                                }}
                              />
                              {post.label}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  {action.name === "view_by_employee" &&
                    action.is_allow &&
                    dataOptionEmployee.length > 0 && (
                      <div className="mt-2 mb-4 block indent-12 max-h-56 max-w-fit pr-8 slim-overflow overflow-y-auto">
                        <div className="font-semibold">Employees</div>
                        <div>
                          {dataOptionEmployee.map((emp, i_emp) => (
                            <div
                              key={i_emp}
                              className="form-checkbox flex flex-row items-center"
                              style={{ display: "block" }}
                            >
                              <input
                                type="checkbox"
                                checked={
                                  formPrivileges[props.parentIndex]["sub"][
                                    i_sub
                                  ]["employee_id"].includes(emp.value)
                                    ? true
                                    : false
                                }
                                className="mr-4"
                                onChange={(e) => {
                                  var pri = [...formPrivileges];
                                  var department =
                                    pri[props.parentIndex]["sub"][i_sub][
                                      "employee_id"
                                    ];
                                  if (e.target.checked === true) {
                                    if (!department.includes(emp.value)) {
                                      department = [...department, emp.value];
                                    }
                                  } else {
                                    var arr = department.filter(
                                      (item) => item !== emp.value
                                    );
                                    department = arr;
                                  }

                                  var sub =
                                    pri[props.parentIndex]["sub"][i_sub];
                                  sub = { ...sub, employee_id: department };

                                  var newSub = [
                                    ...pri[props.parentIndex]["sub"],
                                  ];
                                  newSub[i_sub] = sub;

                                  var priParent = { ...pri[props.parentIndex] };
                                  priParent.sub = newSub;

                                  pri[props.parentIndex] = priParent;

                                  setFormPrivileges(pri);
                                }}
                              />
                              {emp.label}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                </div>
              ))}
            </div>
          </td>
        </tr>
      );
    });
  };

  const handleSubmit = () => {
    dispatch(submit({ formType: "edit", roles: formPrivileges }));
  };

  return (
    <>
      <form>
        <PageHeader>
          <PageHeaderTitle>
            User Rights <small>Edit</small>
          </PageHeaderTitle>
          <PageHeaderAction>
            <Button
              title="Save"
              icon="BiSave"
              color="primary"
              onClick={() => handleSubmit()}
            />
            <Button
              title="Cancel"
              icon="BiX"
              color="secondary"
              onClick={() => navigate("/um/user-rights")}
            />
          </PageHeaderAction>
        </PageHeader>
        <div className="space-y-4">
          <Card>
            <CardHeader>
              <CardTitle>Detail User Right</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="w-full">
                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
                  <div className="cols-span-1">
                    <div className="form-group">
                      <label className="control-label">
                        User Right Name <span className="text-danger">*</span>
                      </label>
                      <input
                        id="name"
                        title="User Right Name"
                        type="text"
                        name="name"
                        className="form-control"
                        value={formData.name}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4">
                  <div className="cols-span-1">
                    <div className="form-group">
                      <label className="control-label">
                        User Right By <span className="text-danger">*</span>
                      </label>
                      <Select
                        id="type"
                        title="User Right By"
                        name="type"
                        placeholder={"Choose User Right By..."}
                        value={dataOptionBy.filter(
                          (option) => option.value === formData.type
                        )}
                        onChange={(e) =>
                          dispatch(
                            setFormData({ name: "type", value: e?.value })
                          )
                        }
                        options={dataOptionBy}
                        isClearable={false}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="cols-span-2">
                    {formData.type === "User" && (
                      <div className="form-group">
                        <label className="control-label">
                          User List <span className="text-danger">*</span>
                        </label>
                        <Select
                          id="type"
                          title="User"
                          name="type"
                          placeholder={"Choose User..."}
                          value={dataOptionUserList.filter(
                            (option) => option.value === formData.reference_id
                          )}
                          onChange={(e) =>
                            dispatch(
                              setFormData({
                                name: "reference_id",
                                value: e?.value,
                              })
                            )
                          }
                          options={dataOptionUserList}
                          isClearable={false}
                          required={true}
                        />
                      </div>
                    )}
                    {formData.type === "Employee" && (
                      <div className="form-group">
                        <label className="control-label">
                          Employee List <span className="text-danger">*</span>
                        </label>
                        <Select
                          id="type"
                          title="Employee"
                          name="type"
                          placeholder={"Choose Employee..."}
                          value={dataOptionEmployeeList.filter(
                            (option) => option.value === formData.reference_id
                          )}
                          onChange={(e) =>
                            dispatch(
                              setFormData({
                                name: "reference_id",
                                value: e?.value,
                              })
                            )
                          }
                          options={dataOptionEmployeeList}
                          isClearable={false}
                          required={true}
                        />
                      </div>
                    )}
                    {formData.type === "Position" && (
                      <div className="form-group">
                        <label className="control-label">
                          Position List <span className="text-danger">*</span>
                        </label>
                        <Select
                          id="type"
                          title="Position"
                          name="type"
                          placeholder={"Choose Position..."}
                          value={dataOptionPositionList.filter(
                            (option) => option.value === formData.reference_id
                          )}
                          onChange={(e) =>
                            dispatch(
                              setFormData({
                                name: "reference_id",
                                value: e?.value,
                              })
                            )
                          }
                          options={dataOptionPositionList}
                          isClearable={false}
                          required={true}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className="form-group">
                  <label className="control-label">Privileges</label>
                </div>
                <table className="table">
                  <tbody>
                    {formPrivileges &&
                      formPrivileges.map((item, index) => {
                        return (
                          <Menu key={index} parentIndex={index} item={item} />
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </div>
      </form>
    </>
  );
}
