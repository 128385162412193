import React, { useState } from "react";
import { useEffect } from "react";
import * as BI from "react-icons/bi";
import { NavLink, useLocation } from "react-router-dom";

const SetLink = (props) => {
  const sub = props.sub;
  let activeClassName = "active";

  return (
    <div className="item">
      <NavLink
        to={sub.url}
        className={({ isActive }) => (isActive ? activeClassName : undefined)}
      >
        <span>{React.createElement(BI[sub.icon], { size: 22 })}</span>
        <span>{sub.name}</span>
      </NavLink>
    </div>
  );
};

export default function Sidebar() {
  const [sidebars, setSidebars] = useState();
  const user = JSON.parse(localStorage.getItem("user"));
  
  useEffect(() => {
    if (user) {
      setSidebars(JSON.parse(user?.user_right?.roles));
    } 
  }, [])


  return (
    sidebars && (
      <div className="sidebar">
        {sidebars.map(
          (sidebar, index) =>
            sidebar.is_allow && (
              <div key={index} className="mb-4">
                <div className="header">
                  <span>{sidebar.name.toUpperCase()}</span>
                </div>
                {sidebar.sub.length > 0 &&
                  sidebar.sub.map(
                    (sub, indexSub) =>
                      sub.is_allow && (
                        <SetLink key={"sub" + indexSub} sub={sub} />
                      )
                  )}
              </div>
            )
        )}
      </div>
    )
  );
}
