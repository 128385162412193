import { createSlice } from "@reduxjs/toolkit";
import { InstanceApi } from "../../helpers/InstanceApi";
import { setPopup } from "../popupSlice";

export const taskHistorySlice = createSlice({
  name: "taskHistory",
  initialState: {
    dataByTask: undefined,
  },
  reducers: {
    setDataByTask: (state, action) => {
      state.dataByTask = action.payload;
    },
  },
});

export const getDataByTask = (params) => async (dispatch) => {
  try {
    dispatch(setDataByTask(undefined));
    const response = await InstanceApi.get(
      "/pm/tasks/" + params.taskId + "/history"
    );
    if (response) {
      dispatch(setDataByTask(response.data.data));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(error);
  }
};

export const {
  setDataByTask,
} = taskHistorySlice.actions;
export default taskHistorySlice.reducer;
