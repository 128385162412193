import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/Button";
import {
  PageHeader,
  PageHeaderAction,
  PageHeaderTitle,
} from "../../../components/PageHeader";

import { CheckRoleAction, CheckRoleIndex } from "../../../helpers/CheckRole";
import IndexTable from "./indexTable";

function Index() {
  const pathName = "/pm/projects";
  const navigate = useNavigate();

  useEffect(() => {
    if (!CheckRoleIndex(pathName)) {
      navigate("/404");
    }
  }, [navigate]);

  return (
    <>
      <PageHeader>
        <PageHeaderTitle>Projects</PageHeaderTitle>
        <PageHeaderAction>
          {CheckRoleAction(pathName, "create") && (
            <Button
              title="Add New"
              icon="BiPlus"
              color="primary"
              onClick={() => navigate(`${pathName}/create`)}
            />
          )}
        </PageHeaderAction>
      </PageHeader>
      <IndexTable />
    </>
  );
}

export default Index;
