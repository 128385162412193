import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDataByTask } from "../../../stores/prod/prodTaskAttachmentSlice";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardAction,
} from "../../../components/Card";
import { Button } from "../../../components/Button";
import {
  setModal,
  deleteData,
} from "../../../stores/prod/prodTaskAttachmentSlice";
import FormAttachment from "./formAttachment";
import { setPopup } from "../../../stores/popupSlice";
import { DownloadFile } from "../../../helpers/DownloadFile";
import { BiEditAlt, BiTrash } from "react-icons/bi";

export default function TabProdAttachment() {
  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const dataTask = useSelector((state) => state.prodTask.dataView);
  const dataByTask = useSelector(
    (state) => state.prodTaskAttachment.dataByTask
  );
  const openModal = useSelector((state) => state.prodTaskAttachment.openModal);
  const [formType, setFormType] = useState(undefined);
  const [editIndex, setEditIndex] = useState(0);

  useEffect(() => {
    dispatch(getDataByTask({ taskId: dataTask.id }));
  }, [dispatch, dataTask.id]);

  const getDownloadFile = (path) => {
    dispatch(setPopup({ open: true, type: "download" }));

    const downloadUrl = "/download?file_path=" + path;
    DownloadFile(downloadUrl);

    setTimeout(() => {
      dispatch(setPopup({ open: false }));
    }, 3000);
  };

  const addData = () => {
    setFormType("create");
    dispatch(setModal(true));
  };

  const editData = (index) => {
    setFormType("edit");
    setEditIndex(index);
    dispatch(setModal(true));
  };

  return (
    <>
      {openModal && (
        <FormAttachment formType={formType} editIndex={editIndex} />
      )}
      {!openModal && (
        <Card>
          <CardHeader>
            <CardTitle>
              Attachments{" "}
              <span className="text-sm font-normal">
                ({dataByTask?.length} files)
              </span>
            </CardTitle>
            {user.reference_id === dataTask.responsible_id &&
              dataTask.total_attachment < dataTask.spot &&
              dataTask.project_status === "In Progress" && (
                <CardAction>
                  <Button
                    title="Add"
                    icon="BiPlus"
                    size="sm"
                    color="primary"
                    onClick={() => addData()}
                  />
                </CardAction>
              )}
          </CardHeader>
          <CardBody>
            {!dataByTask && (
              <div className="text-center font-semibold">Loading...</div>
            )}
            {dataByTask && (
              <div className="space-y-2">
                {dataByTask.length === 0 && (
                  <div className="text-center font-semibold">
                    Empty attachment
                  </div>
                )}
                {dataByTask.map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-row justify-between gap-2"
                  >
                    <div
                      className="flex-1 py-2 px-4 bg-slate-50 border border-slate-tertiary hover:bg-slate-tertiary rounded-lg truncate text-left cursor-pointer"
                      onClick={() => getDownloadFile(item.file_path)}
                    >
                      <div className="grid grid-cols-8 gap-4">
                        <div className="text-sm col-span-1">
                          <span className="mb-1 block text-text-secondary">
                            Spot No.
                          </span>
                          <span className="block">{item.spot}</span>
                        </div>
                        <div className="text-sm col-span-2">
                          <span className="mb-1 block text-text-secondary">
                            Showtimes
                          </span>
                          <span className="block">
                            {item.showtimes_convert ?? "-"}
                          </span>
                        </div>
                        <div className="text-sm col-span-4">
                          <span className="mb-1 block text-text-secondary">
                            Filename
                          </span>
                          <span className="block">{item.file_name}</span>
                        </div>
                        <div className="text-sm col-span-1">
                          <span className="mb-1 block text-text-secondary">
                            File Size
                          </span>
                          <span className="block">{item.file_size}</span>
                        </div>
                      </div>
                    </div>
                    {dataTask.project_status === "In Progress" && (
                      <div className="flex-none p-2 flex flex-col items-center justify-start gap-2">
                        <span className="btn-action">
                          <BiEditAlt
                            size="16"
                            onClick={() => editData(index)}
                          />
                        </span>
                        {index === dataByTask.length - 1 && (
                          <span
                            className="btn-action"
                            onClick={() =>
                              dispatch(deleteData({ id: item.id }))
                            }
                          >
                            <BiTrash size="16" />
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </CardBody>
        </Card>
      )}
    </>
  );
}
