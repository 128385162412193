import React from "react";
import { Card, CardBody } from "../../../components/Card";
import { PageHeader, PageHeaderTitle } from "../../../components/PageHeader";

function LoadingShow() {
  return (
    <>
      <PageHeader>
        <PageHeaderTitle>
          Task <small>Detail of task</small>
        </PageHeaderTitle>
      </PageHeader>

      <div className="flex md:flex-row gap-4">
        <div className="md:w-3/4 space-y-4">
          <Card>
            <CardBody>
              <div className="space-y-4">
                {/* task title */}
                <div className="w-2/4 h-4 loading"></div>
                {/* task project name */}
                <div className="space-y-2">
                  <div className="w-1/12 h-2 loading"></div>
                  <div className="w-2/4 h-2 loading"></div>
                </div>
                {/* task description */}
                <div className="space-y-2">
                  <div className="w-3/4 h-2 loading"></div>
                  <div className="w-2/4 h-2 loading"></div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              {/* subtask */}
              <div className="mb-6 w-32 h-4 loading"></div>
              <div className="space-y-4">
                <div className="w-2/4 h-4 loading"></div>
                <div className="w-3/4 h-4 loading"></div>
                <div className="w-2/4 h-4 loading"></div>
                <div className="w-4/5 h-4 loading"></div>
                <div className="w-2/5 h-4 loading"></div>
              </div>
            </CardBody>
          </Card>
          {/* tabs */}
          <div className="tabs">
            <div className="tab-nav">
              <div className="w-32 h-4 loading"></div>
              <div className="w-32 h-4 loading"></div>
              <div className="w-32 h-4 loading"></div>
            </div>
            <div className="tab-panel">
              <Card>
                <CardBody>
                  <div className="w-full space-y-4">
                    <div className="flex flex-row items-start gap-4">
                      <div className="avatar-md bg-slate-tertiary animate-pulse"></div>
                      <div className="w-full space-y-1">
                        <div className="flex flex-row items-center gap-4">
                          <span className="w-32 h-2 loading"></span>
                          <span className="w-10 h-2 loading"></span>
                        </div>
                        <div className="w-96 h-16 loading"></div>
                      </div>
                    </div>
                    <div className="flex flex-row items-start gap-4">
                      <div className="avatar-md bg-slate-tertiary animate-pulse"></div>
                      <div className="w-full space-y-1">
                        <div className="flex flex-row items-center gap-4">
                          <span className="w-32 h-2 loading"></span>
                          <span className="w-10 h-2 loading"></span>
                        </div>
                        <div className="w-96 h-16 loading"></div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
        <div className="md:w-1/4 space-y-4">
          <Card>
            <CardBody>
              {/* task info */}
              <div className="space-y-4">
                <div className="w-24 h-4 loading"></div>
                <div className="space-y-2">
                  <div className="w-1/4 h-2 loading"></div>
                  <div className="w-2/4 h-2 loading"></div>
                </div>
                <div className="space-y-2">
                  <div className="w-1/4 h-2 loading"></div>
                  <div className="w-2/4 h-2 loading"></div>
                </div>
                <div className="w-full h-6 loading"></div>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              {/* responsible */}
              <div className="mb-6 w-1/2 h-4 loading"></div>
              <div className="space-y-4">
                <div className="flex flex-row items-center gap-4">
                  <div className="avatar-md bg-slate-tertiary animate-pulse"></div>
                  <div className="flex-1 flex flex-col gap-2">
                    <div className="w-32 h-2 loading"></div>
                    <div className="w-24 h-2 loading"></div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              {/* observers */}
              <div className="mb-6 w-1/2 h-4 loading"></div>
              <div className="space-y-4">
                <div className="flex flex-row items-center gap-4">
                  <div className="avatar-md bg-slate-tertiary animate-pulse"></div>
                  <div className="flex-1 flex flex-col gap-2">
                    <div className="w-32 h-2 loading"></div>
                    <div className="w-24 h-2 loading"></div>
                  </div>
                </div>
                <div className="flex flex-row items-center gap-4">
                  <div className="avatar-md bg-slate-tertiary animate-pulse"></div>
                  <div className="flex-1 flex flex-col gap-2">
                    <div className="w-32 h-2 loading"></div>
                    <div className="w-24 h-2 loading"></div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default LoadingShow;
