import React from "react";
import { PageHeader, PageHeaderTitle } from "../../../components/PageHeader";
import { Card, CardBody } from "../../../components/Card";

const LoadingRow = () => {
  const divWidth = ["w-full", "w-2/4", "w-3/4"];

  return (
    <div className="grid grid-cols-3 gap-4">
      <div
        className={`${
          divWidth[Math.floor(Math.random() * divWidth.length)]
        } h-2 loading`}
      ></div>
      <div
        className={`${
          divWidth[Math.floor(Math.random() * divWidth.length)]
        } h-2 loading`}
      ></div>
      <div
        className={`${
          divWidth[Math.floor(Math.random() * divWidth.length)]
        } h-2 loading`}
      ></div>
    </div>
  );
};

function LoadingTable() {
  return (
    <>
      <PageHeader>
        <PageHeaderTitle>Users</PageHeaderTitle>
      </PageHeader>
      <Card>
        <CardBody>
          <div className="mb-10 w-full flex gap-2">
            <div className="block w-96 h-6 loading"></div>
          </div>
          <div className="mb-8 grid grid-cols-3 gap-4">
            <div className="w-40 h-4 loading"></div>
            <div className="w-40 h-4 loading"></div>
            <div className="w-40 h-4 loading"></div>
          </div>
          <div className="mb-10 space-y-6">
            <LoadingRow />
            <LoadingRow />
            <LoadingRow />
            <LoadingRow />
            <LoadingRow />
            <LoadingRow />
            <LoadingRow />
            <LoadingRow />
            <LoadingRow />
            <LoadingRow />
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default LoadingTable;
