import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../components/Button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from "../../../components/Card";
import {
  PageHeader,
  PageHeaderAction,
  PageHeaderTitle,
} from "../../../components/PageHeader";
import { setFormData, submit } from "../../../stores/um/changePasswordSlice";
import { CheckRoleIndex } from "../../../helpers/CheckRole";
import { useNavigate } from "react-router-dom";

function Index() {
  const pathName = "/um/change-password";

  const user = localStorage.getItem("user");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.changePassword.formData);

  useEffect(() => {
    if (!CheckRoleIndex(pathName)) {
      navigate("/404");
    }
  }, [navigate]);

  useEffect(() => {
    const item = JSON.parse(user);
    dispatch(setFormData({ name: "username", value: item.username }));
  }, [dispatch, user]);

  const handleInput = (e) => {
    dispatch(setFormData({ name: e.target.name, value: e.target.value }));
  };

  return (
    <>
      <PageHeader>
        <PageHeaderTitle>Change Username & Password</PageHeaderTitle>
        <PageHeaderAction>
          <Button
            title="Save"
            icon="BiSave"
            color="primary"
            onClick={() => dispatch(submit())}
          />
        </PageHeaderAction>
      </PageHeader>
      <div className="space-y-4">
        <Card>
          <CardHeader>
            <CardTitle>Username & Password</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="w-1/3">
              <div className="form-group">
                <label className="control-label">
                  Username<span className="text-danger">*</span>
                </label>
                <input
                  id="username"
                  title="Project Name"
                  type="text"
                  name="username"
                  className="form-control"
                  value={formData.username}
                  onChange={handleInput}
                  required
                />
              </div>
              <div className="form-group">
                <label className="control-label">
                  New Password<span className="text-danger">*</span>
                </label>
                <input
                  id="password"
                  title="Project Name"
                  type="password"
                  name="password"
                  className="form-control"
                  value={formData.password}
                  onChange={handleInput}
                  required
                />
              </div>
              <div className="form-group">
                <label className="control-label">
                  Retype New Password<span className="text-danger">*</span>
                </label>
                <input
                  id="retype_password"
                  title="Project Name"
                  type="password"
                  name="retype_password"
                  className="form-control"
                  value={formData.retype_password}
                  onChange={handleInput}
                  required
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default Index;
