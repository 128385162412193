import React, { useEffect, useState } from "react";
import "./styles/global.css";
import AppRoute from "./AppRoute";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./stores/store";
import Layout from "./pages/layouts";
import LoginForm from "./pages/auth/login";

function App() {
  document.title = "Adlink System";

  const ISSERVER = typeof window === "undefined";
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    if (!ISSERVER) {
      const login = localStorage.getItem("isLogin");
      const accessToken = localStorage.getItem("access_token");

      if (window.location.pathname !== "/" && !login && !accessToken) {
        window.location.href = "/";
      }

      if (window.location.pathname === "/" && localStorage.getItem("isLogin")) {
        window.location.href = "/dashboard";
      }

      if (accessToken) {
        const item = JSON.parse(accessToken);
        const now = new Date();

        if (now.getTime() > item.expiry) {
          localStorage.removeItem("isLogin");
          localStorage.removeItem("user");
          localStorage.removeItem("access_token");

          window.location.href = "/";
        }
      }

      setIsLogin(login);
    }
  }, [ISSERVER]);

  if (!isLogin) {
    const pathName = window.location.pathname;
    return (
      <Provider store={store}>
        {!isLogin && <>{pathName === "/" && <LoginForm />}</>}
      </Provider>
    );
  } else {
    return (
      <BrowserRouter>
        <Provider store={store}>
          {!isLogin && <LoginForm />}
          {isLogin && (
            <Layout>
              <AppRoute />
            </Layout>
          )}
        </Provider>
      </BrowserRouter>
    );
  }
}

export default App;
