import { createSlice } from "@reduxjs/toolkit";
import { InstanceApi } from "../../helpers/InstanceApi";
import { Validate } from "../../helpers/Validate";
import { setPopup } from "../popupSlice";

export const kpiAdjustmentSlice = createSlice({
  name: "kpiAdjustment",
  initialState: {
    dataTables: undefined,
    dataFilter: [],
    dataView: undefined,
    dataByProject: undefined,
  },
  reducers: {
    setDataTables: (state, action) => {
      state.dataTables = action.payload;
    },
    setDataByProject: (state, action) => {
      state.dataByProject = action.payload;
    },
    setDataFilter: (state, action) => {
      state.dataFilter = action.payload;
    },
  },
});

export const getDataTables = (params) => async (dispatch) => {
  try {
    dispatch(setDataTables(undefined));
    dispatch(setPopup({ open: true, type: "loading" }));

    const response = await InstanceApi.get("/pm/kpi-adjustments", {params: params});
    if (response) {
      dispatch(setDataTables(response.data.data));
      dispatch(setPopup({ open: false }));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getDataByProject = (params) => async (dispatch) => {
  try {
    dispatch(setDataByProject(undefined));
    const response = await InstanceApi.get(
      "/pm/kpi-adjustments/project/" + params.projectId
    );
    if (response) {
      dispatch(setDataByProject(response.data.data));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const submit = (params) => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "processing" }));
    const formData = getState().kpiAdjustment.dataByProject;

    const response = await InstanceApi.patch(
      "/pm/kpi-adjustments/" + params.projectId,
      formData
    );
    if (response) {
      dispatch(
        setPopup({
          open: true,
          type: "success",
          message: response.data.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
            window.location.href = "/pm/kpi-adjustments";
          },
        })
      );
    }
  } catch (err) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: err.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(err.response.data.message);
  }
};

export const { setDataTables, setDataFilter, setDataByProject } =
  kpiAdjustmentSlice.actions;
export default kpiAdjustmentSlice.reducer;
