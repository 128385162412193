import { createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../config/Constant";
import { InstanceApi } from "./../helpers/InstanceApi";
import { Validate } from "./../helpers/Validate";
import { setPopup } from "./popupSlice";
import axios from "axios";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    openPopup: { open: false },
    openModal: false,
    data: undefined,
    formData: {
      username: "",
      password: "",
    },
  },
  reducers: {
    resetData: (state) => {
      state.data = undefined;
    },
    resetFormData: (state) => {
      state.formData = { name: "" };
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    inputData: (state, action) => {
      state.formData[action.payload.name] = action.payload.value;
    },
  },
});

export const login = () => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "processing" }));
    const dataAuth = getState().auth.formData;
    const validate = Validate(dataAuth);

    if (!validate.isValid) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: validate.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
    } else {
      const response = await axios.post(apiUrl + "/auth/login", dataAuth);
      if (response) {
        const date = new Date();
        const expiredTime = new Date(
          date.getTime() + parseInt(response.data.data.expiry) * 60000
        );

        localStorage.setItem("isLogin", true);
        localStorage.setItem("user", JSON.stringify(response.data.data));
        localStorage.setItem(
          "access_token",
          JSON.stringify({
            value: response.data.data.access_token,
            expiry: expiredTime.getTime(),
          })
        );
        dispatch(setPopup({ open: false }));
        window.location.href = "/dashboard";
      }
    }
  } catch (err) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: err.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(err.response.data.message);
  }
};
export const logout = () => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "processing" }));
    const dataAuth = getState().auth.formData;
    const response = await InstanceApi.post("/auth/logout", dataAuth);
    if (response) {
      window.location.href = "/";
      localStorage.removeItem("isLogin");
      localStorage.removeItem("user");
      localStorage.removeItem("access_token");
      dispatch(setPopup({ open: false }));
    }
  } catch (err) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: err.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(err.response.data.message);
  }
};

export const { resetData, resetFormData, setData, inputData } =
  authSlice.actions;
export default authSlice.reducer;
