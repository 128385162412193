import React from 'react'
import {PageHeader, PageHeaderTitle} from '../../../components/PageHeader'
import {Card, CardBody} from '../../../components/Card'

const LoadingRow = () => {
  const divWidth = ["w-full", "w-2/4", "w-3/4"];

  return (
    <div className="p-4 border border-slate-tertiary rounded-md hover:bg-slate-tertiary transition duration-200 cursor-pointer flex flex-col gap-4">
      <div className="grid grid-cols-3 gap-4">
        <div className='col-span-1 h-4 loading'></div>
      </div>
      <div className="grid grid-cols-4 gap-4">
        <div
          className={`${
            divWidth[Math.floor(Math.random() * divWidth.length)]
          } h-4 loading`}
        ></div>
        <div
          className={`${
            divWidth[Math.floor(Math.random() * divWidth.length)]
          } h-4 loading`}
        ></div>
        <div
          className={`${
            divWidth[Math.floor(Math.random() * divWidth.length)]
          } h-4 loading`}
        ></div>
        <div
          className={`${
            divWidth[Math.floor(Math.random() * divWidth.length)]
          } h-4 loading`}
        ></div>
      </div>
    </div>
  );
};

function LoadingShow() {
  return (
    <>
      <PageHeader>
        <PageHeaderTitle>
          Project <small>Detail of project</small>
        </PageHeaderTitle>
      </PageHeader>

      <div className="flex md:flex-row gap-6">
        <div className="md:w-3/4">
          <Card>
            <CardBody>
              <div className="space-y-6">
                <div className="w-2/4 h-4 loading"></div>
                <div className="space-y-4">
                  <div className="w-2/4 h-2 loading"></div>
                  <div className="w-1/4 h-2 loading"></div>
                  <div className="w-3/4 h-2 loading"></div>
                </div>
              </div>
            </CardBody>
          </Card>
          <div className="h-4"></div>
          <div className="mb-4">
            <div className="flex flex-row items-center gap-2">
              <div className="w-32 h-6 loading"></div>
            </div>
          </div>
          <Card>
            <CardBody>
              <div className="mb-10 w-full flex gap-2">
                <div className="block w-96 h-6 loading"></div>
              </div>
              <div className="mb-10 space-y-6">
                <LoadingRow />
                <LoadingRow />
                <LoadingRow />
                <LoadingRow />
                <LoadingRow />
                <LoadingRow />
                <LoadingRow />
                <LoadingRow />
                <LoadingRow />
                <LoadingRow />
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="md:w-1/4 space-y-4">
          <Card>
            <CardBody>
              <div className="space-y-4">
                <div className="space-y-2">
                  <div className="w-1/4 h-2 loading"></div>
                  <div className="w-2/4 h-2 loading"></div>
                </div>
                <div className="space-y-2">
                  <div className="w-1/4 h-2 loading"></div>
                  <div className="w-2/4 h-2 loading"></div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div className="space-y-4">
                <div className="space-y-2">
                  <div className="w-1/4 h-2 loading"></div>
                  <div className="w-2/4 h-2 loading"></div>
                </div>
                <div className="space-y-2">
                  <div className="w-1/4 h-2 loading"></div>
                  <div className="w-2/4 h-2 loading"></div>
                </div>
                <div className="space-y-2">
                  <div className="w-1/4 h-2 loading"></div>
                  <div className="w-2/4 h-2 loading"></div>
                </div>
                <div className="space-y-2">
                  <div className="w-1/4 h-2 loading"></div>
                  <div className="w-2/4 h-2 loading"></div>
                </div>
                <div className="space-y-2">
                  <div className="w-1/4 h-2 loading"></div>
                  <div className="w-2/4 h-2 loading"></div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default LoadingShow