import React, { useEffect } from "react";
import { Button } from "../../../components/Button";
import ModalForm from "../../../components/ModalForm";

import { useSelector, useDispatch } from "react-redux";
import {
  resetFormData,
  setFormData,
  setModalAddDefaultTask,
  submitModalAddDefaultTask,
} from "../../../stores/pm/pmDefaultTaskSlice";
import Select from "../../../components/Select";

import { getDataOptions as getDataOptionDepartment } from "../../../stores/master/departmentSlice";

function ModalAddDefaultTask() {
  const dispatch = useDispatch();
  const openPopup = useSelector((state) => state.popup.openPopup);
  const openModal = useSelector((state) => state.pmDefaultTask.modalAddDefaultTask);
  const formData = useSelector((state) => state.pmDefaultTask.formData);
  const dataOptionDepartment = useSelector(
    (state) => state.department.dataOptions
  );

  useEffect(() => {
    dispatch(resetFormData());
    dispatch(getDataOptionDepartment());
  }, [dispatch]);

  const handleInput = (e) => {
    dispatch(setFormData(e.target));
  };

  return (
    <>
      {!openPopup.open && openModal && (
        <ModalForm
          modalTitle="Add Default Task"
          modalSize="small"
          closeModal={() => dispatch(setModalAddDefaultTask(false))}
        >
          <div className="w-full">
            <div className="form-group">
              <label className="control-label">
                Department <span className="text-danger">*</span>
              </label>
              <Select
                id="department_id"
                title="Department"
                name="department_id"
                placeholder={"Choose..."}
                defaultValue={dataOptionDepartment.filter(
                  (option) => option.value === formData.department_id
                )}
                onChange={(e) =>
                  dispatch(
                    setFormData({ name: "department_id", value: e?.value })
                  )
                }
                options={dataOptionDepartment}
                required={true}
              />
            </div>
            <div className="form-group">
              <label className="control-label">
                Task Name <span className="text-danger">*</span>
              </label>
              <input
                id="task_name"
                title="Task Name"
                type="text"
                name="task_name"
                className="form-control"
                value={formData.task_name}
                onChange={handleInput}
                required
              />
            </div>
            <div className="form-group">
              <label className="control-label">
                Task Duration (days) <span className="text-danger">*</span>
              </label>
              <input
                id="task_duration"
                title="Task Duration"
                type="number"
                name="task_duration"
                className="form-control"
                value={formData.task_duration}
                onChange={handleInput}
                required
              />
            </div>
          </div>

          <div className="mt-8 flex gap-2">
            <Button
              title="Save"
              icon="BiSave"
              color="primary"
              onClick={() => dispatch(submitModalAddDefaultTask({ formType: 'create' }))}
              />
            <Button
              title="Cancel"
              icon="BiX"
              color="secondary"
              onClick={() => dispatch(setModalAddDefaultTask(false))}
            />
          </div>
        </ModalForm>
      )}
    </>
  );
}

export default ModalAddDefaultTask;
