import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDataByProject } from "../../../stores/pm/projectMoSlice";
import TabMoWidget from "../tabs/tabMoWidget";

function TabMo() {
  const dispatch = useDispatch();
  const dataTask = useSelector((state) => state.task.dataView);
  const dataByProject = useSelector((state) => state.projectMo.dataByProject);

  useEffect(() => {
    dispatch(getDataByProject({ projectId: dataTask.project_id }));
  }, [dispatch, dataTask.project_id]);

  return <TabMoWidget moList={dataByProject} responsibleId={dataTask.responsible_id} />;
}

export default TabMo;
