import React from "react";
import { Routes, Route } from "react-router-dom";

// const Dashboard = React.lazy(() => import('./pages/dashboard'))
// const Profil = React.lazy(() => import('./pages/profil') )

import Page404 from "./pages/404";

import Dashboard from "./pages/dashboard";

// MASTER DATA
import Client from "./pages/master/clients";
import Department from "./pages/master/departments";
import Position from "./pages/master/positions";
import Employee from "./pages/master/employees";
import Station from "./pages/master/stations";
import Program from "./pages/master/programs";
import Product from "./pages/master/products";

// PROJECT MANAGEMENT
import PmDefaultTask from "./pages/pm/defaultTasks";
import Project from "./pages/pm/projects";
import ProjectForm from "./pages/pm/projects/form";
import ProjectShow from "./pages/pm/projects/show";
import TaskShowByProject from "./pages/pm/tasks/showFromProject";
import Task from "./pages/pm/tasks";
import TaskShow from "./pages/pm/tasks/show";
import KpiAdjustment from "./pages/pm/kpiAdjustments";
import KpiAdjustmentForm from "./pages/pm/kpiAdjustments/form";

// PRODUCTIONS
import ProdDefaultTask from "./pages/prod/defaultTasks";
import ProdProject from "./pages/prod/projects";
import ProdProjectForm from "./pages/prod/projects/form";
import ProdProjectShow from "./pages/prod/projects/show";
import ProdTask from "./pages/prod/tasks";
import ProdTaskShow from "./pages/prod/tasks/show";

// WORKFLOWS
import Workflow from "./pages/wf/workflows";
import WorkflowForm from "./pages/wf/workflows/form";

// REPORTS
import ReportProject from "./pages/reports/project";
import ReportTask from "./pages/reports/task";
import ReportClient from "./pages/reports/client";
import ReportKpiEmployee from "./pages/reports/kpiEmployee";
import ReportProduction from "./pages/reports/productions";
import ReportLogProject from "./pages/reports/logProject";
import ReportLogs from "./pages/reports/logs";

// USER MANAGEMENT
import User from "./pages/um/users";
import UserRight from "./pages/um/user_rights";
import UserRightCreate from "./pages/um/user_rights/create";
import UserRightEdit from "./pages/um/user_rights/edit";
import UserRightCopy from "./pages/um/user_rights/copy";
import ChangePassword from "./pages/um/change_password";
// import UserRightShow from "./pages/um/user_rights/show";

// EMAIL NOTIFICATION
import EmailSmtp from "./pages/email/smtp";
import EmailTemplate from "./pages/email/template";
import EmailTemplateEdit from "./pages/email/template/edit";

function AppRoute() {
  return (
    <Routes>
      {/* <Route path='/404' element={<Page404 />} /> */}

      <Route path="/dashboard" element={<Dashboard />} />

      {/* Master */}
      <Route path="/master/clients" element={<Client />} />
      <Route path="/master/departments" element={<Department />} />
      <Route path="/master/positions" element={<Position />} />
      <Route path="/master/employees" element={<Employee />} />
      <Route path="/master/stations" element={<Station />} />
      <Route path="/master/programs" element={<Program />} />
      <Route path="/master/products" element={<Product />} />

      {/* Project Management */}
      <Route path="/pm/default-tasks" element={<PmDefaultTask />} />
      <Route path="/pm/projects" element={<Project />} />
      <Route path="/pm/projects/view/:id" element={<ProjectShow />} />
      <Route path="/pm/projects/view/:projectId/task/:taskId" element={<TaskShowByProject />} />
      <Route path="/pm/projects/create" element={<ProjectForm />} />
      <Route path="/pm/tasks" element={<Task />} />
      <Route path="/pm/tasks/:id" element={<TaskShow />} />
      <Route path="/pm/kpi-adjustments" element={<KpiAdjustment />} />
      <Route
        path="/pm/kpi-adjustments/edit/:id"
        element={<KpiAdjustmentForm />}
      />

      {/* Productions */}
      <Route path="/prod/default-tasks" element={<ProdDefaultTask />} />
      <Route path="/prod/projects" element={<ProdProject />} />
      <Route path="/prod/projects/view/:id" element={<ProdProjectShow />} />
      <Route path="/prod/projects/create" element={<ProdProjectForm />} />
      <Route path="/prod/tasks" element={<ProdTask />} />
      <Route path="/prod/tasks/:id" element={<ProdTaskShow />} />

      {/* Workflows */}
      <Route path="/wf/workflows" element={<Workflow />} />
      <Route path="/wf/workflows/edit/:id" element={<WorkflowForm />} />

      {/* Reports */}
      <Route path="/reports/projects" element={<ReportProject />} />
      <Route path="/reports/tasks" element={<ReportTask />} />
      <Route path="/reports/clients" element={<ReportClient />} />
      <Route path="/reports/kpi-employee" element={<ReportKpiEmployee />} />
      <Route path="/reports/productions" element={<ReportProduction />} />
      <Route
        path="/reports/log-projects-tasks"
        element={<ReportLogProject />}
      />
      <Route path="/reports/logs" element={<ReportLogs />} />

      {/* User Management */}
      <Route path="/um/users" element={<User />} />
      <Route path="/um/user-rights" element={<UserRight />} />
      {/* <Route path="/um/user-rights/view/:id" element={<UserRightShow />} /> */}
      <Route path="/um/user-rights/create" element={<UserRightCreate />} />
      <Route path="/um/user-rights/edit/:id" element={<UserRightEdit />} />
      <Route path="/um/user-rights/copy/:id" element={<UserRightCopy />} />
      <Route path="/um/change-password" element={<ChangePassword />} />

      {/* Email Notification */}
      <Route path="/email/smtp" element={<EmailSmtp />} />
      <Route path="/email/template" element={<EmailTemplate />} />
      <Route path="/email/template/edit/:id" element={<EmailTemplateEdit />} />


      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}

export default AppRoute;
