import { createSlice } from "@reduxjs/toolkit";
import { InstanceApi } from "../../helpers/InstanceApi";
import { Validate } from "../../helpers/Validate";
import { setPopup } from "../popupSlice";

export const changePasswordSlice = createSlice({
  name: "changePassword",
  initialState: {
    formValidate: {
      username: { required: true, title: "Username" },
      password: { required: true, title: "New Password" },
      retype_password: { required: true, title: "Retype New Password" },
    },
    formData: {
      id: "",
      username: "",
      password: "",
      retype_password: "",
    },
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData[action.payload.name] = action.payload.value;
    },
  },
});

export const resetFormData = () => async (dispatch, getState) => {
  const formData = getState().changePassword.formData;
  Object.keys(formData).forEach((key) => {
    dispatch(setFormData({ name: key, value: "" }));
  });
};

export const submit = (params) => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "processing" }));
    const formValidate = getState().changePassword.formValidate;
    const formData = getState().changePassword.formData;
    const validate = Validate(formValidate, formData);

    console.log(formData);
    if (formData.password !== formData.retype_password) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: "Retype new password is wrong!",
          handleOk: () => {
            dispatch(setPopup({ open: false }));
            dispatch(setFormData({ name: "retype_password", value: "" }));
          },
        })
      );
    } else {
      if (!validate.isValid) {
        dispatch(
          setPopup({
            open: true,
            type: "error",
            message: validate.message,
            handleOk: () => {
              dispatch(setPopup({ open: false }));
            },
          })
        );
      } else {
        let response = await InstanceApi.patch("/um/change-password", formData);
        dispatch(
          setPopup({
            open: true,
            type: "success",
            message: response.data.message,
            handleOk: () => {
              dispatch(setPopup({ open: false }));
              window.location.href = "/um/change-password";
            },
          })
        );
      }
    }
  } catch (err) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: err.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(err.response.data.message);
  }
};

export const { setFormData } = changePasswordSlice.actions;
export default changePasswordSlice.reducer;
