import React, { useEffect } from "react";
import { Button } from "../../../components/Button";
import { useSelector, useDispatch } from "react-redux";
import {
  resetFormData,
  getFormData,
  submit,
} from "../../../stores/wf/workflowSlice";
import { PageHeader, PageHeaderAction, PageHeaderTitle } from "../../../components/PageHeader";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, CardTitle } from "../../../components/Card";
import CardDetail from "./cardDetail";
import { setPopup } from "../../../stores/popupSlice";
import { InstanceApi } from "../../../helpers/InstanceApi";
import { DownloadFile } from "../../../helpers/DownloadFile";

function Form() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.workflow.formData);
  const {id} = useParams();

  useEffect(() => {
    dispatch(resetFormData());
      dispatch(getFormData({ id: id }));
  }, [dispatch, id]);

  const exportData = async (e) => {
    e.preventDefault();

    try {
      dispatch(setPopup({ open: true, type: "processing" }));
      const response = await InstanceApi.post(
        "/wf/workflows/export-data",
        {object: formData.object, type: formData.type}
      );
      if (response) {
        dispatch(setPopup({ open: false }));

        dispatch(setPopup({ open: true, type: "download" }));

        const downloadUrl = "/download?file_path=" + response.data.data;
        console.log(downloadUrl);
        DownloadFile(downloadUrl);

        setTimeout(() => {
          dispatch(setPopup({ open: false }));
        }, 3000);
      }
    } catch (err) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: err.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
      console.error(err.response.data.message);
    }
  };

  return (
    <>
      <PageHeader>
        <PageHeaderTitle>
          Workflows <small>Edit</small>
        </PageHeaderTitle>
        <PageHeaderAction>
          <Button
            title="Export"
            icon="BiSave"
            color="primary"
            onClick={exportData}
          />
          <Button
            title="Save"
            icon="BiSave"
            color="primary"
            onClick={() => dispatch(submit())}
          />
          <Button
            title="Back"
            icon="BiArrowBack"
            color="secondary"
            onClick={() => navigate("/wf/workflows")}
          />
        </PageHeaderAction>
      </PageHeader>
      <div className="space-y-4">
        <Card>
          <CardHeader>
            <CardTitle>Workflow</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="w-full">
              <div className="grid grid-cols-1 md:grid-cols-6 md:gap-4">
                <div className="cols-span-1">
                  <div className="form-group">
                    <label className="control-label">
                      Object <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="object"
                      className="form-control disabled"
                      value={formData.object}
                      disabled
                      required
                    />
                  </div>
                </div>
                <div className="cols-span-1">
                  <div className="form-group">
                    <label className="control-label">
                      Type <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="type"
                      className="form-control disabled"
                      value={formData.type}
                      disabled
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
        <CardDetail />
      </div>
    </>
  );
}

export default Form;
