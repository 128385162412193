import React, { useEffect, useState } from "react";
import { Button } from "../../../components/Button";
import ModalForm from "../../../components/ModalForm";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageHeader,
  PageHeaderAction,
  PageHeaderTitle,
} from "../../../components/PageHeader";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import { getDataView } from "../../../stores/pm/projectSlice";
import { getDataByProject, setDataByProject, submit } from "../../../stores/pm/kpiAdjustmentSlice";
import { CheckRoleAction } from "../../../helpers/CheckRole";

function Form() {
  const pathName = "/pm/kpi-adjustments";
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataProject = useSelector((state) => state.project.dataView);
  const dataByProject = useSelector(
    (state) => state.kpiAdjustment.dataByProject
  );

  useEffect(() => {
    if (!CheckRoleAction(pathName, "edit")) {
      navigate("/404");
    }
    dispatch(getDataView({ id: id }));
    dispatch(getDataByProject({ projectId: id }));
  }, [dispatch, navigate]);

  const handleInput = (index, e) => {
    const temp = [...dataByProject]
    const item = temp[index];
    temp[index] = {...item, score: e.target.value}

    dispatch(setDataByProject(temp));
  }

  return (
    <>
      <PageHeader>
        <PageHeaderTitle>
          KPI Adjustments <small>Edit</small>
        </PageHeaderTitle>
        <PageHeaderAction>
          {/* <Button
            title="Save"
            icon="BiSave"
            color="primary"
            onClick={() => dispatch(submit())}
          />
          <Button
            title="Back"
            icon="BiArrowBack"
            color="secondary"
            onClick={() => navigate("/wf/workflows")}
          /> */}
        </PageHeaderAction>
      </PageHeader>
      <div className="flex md:flex-row gap-4">
        <div className="md:w-3/4 space-y-4">
          <Card>
            <CardBody>
              <div className="space-y-4">
                <div className="mb-6 flex flex-row items-start justify-between gap-1">
                  <div className="flex-1">
                    <h1 className="font-semibold">
                      [#{dataProject?.project_no}] {dataProject?.project_name}
                    </h1>
                  </div>
                  {dataProject?.urgent > 0 && (
                    <div className="flex-none">
                      <div className="badge badge-warning">Urgent</div>
                    </div>
                  )}
                </div>
                <div className="w-full">
                  <span className="label-detail">Client</span>
                  <p>{dataProject?.client_name}</p>
                </div>
                <div className="w-full">
                  <span className="label-detail">Description</span>
                  <p>{dataProject?.descriptions}</p>
                </div>
                {dataProject?.urgent > 0 && (
                  <div className="w-full">
                    <span className="label-detail">Urgent Notes</span>
                    <p>{dataProject?.urgent_notes}</p>
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="md:w-1/4 space-y-4">
          <div className="space-y-2">
            <div
              className={`py-2 px-4 w-full shadow-lg shadow-shadow rounded-lg bg-completed`}
            >
              <span className="text-white text-sm">
                Completed {dataProject?.updated_at_convert}
              </span>
            </div>
            <Card>
              <CardBody>
                <div className="space-y-4">
                  <div className="w-full">
                    <span className="label-detail">Created on</span>
                    <span className="text-text-secondary">
                      {dataProject?.created_at_convert}
                    </span>
                  </div>
                  <div className="w-full">
                    <span className="label-detail">Deadline</span>
                    <span className="text-text-secondary">
                      {dataProject?.deadline_convert}
                    </span>
                  </div>
                  <div className="w-full">
                    <span className="label-detail">Project Date</span>
                    <div className="w-full flex flex-row gap-2 items-center text-text-secondary">
                      <span>{dataProject?.begin_date_convert}</span>
                      to
                      <span>{dataProject?.end_date_convert}</span>
                    </div>
                  </div>
                  <div className="w-full">
                    <span className="label-detail">Project Owner</span>
                    <div className="flex flex-row items-center gap-2">
                      <span className="team-name">
                        {dataProject?.owner_name}
                      </span>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <Card>
          <CardHeader>
            <CardTitle>KPI Employees</CardTitle>
          </CardHeader>
          <CardBody>
            <table className="table">
              <thead>
                <tr>
                  <th>Employee</th>
                  <th>Task Name</th>
                  <th>Assign Date</th>
                  <th>Task Duration</th>
                  <th>Begin Date</th>
                  <th>End Date</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                {dataByProject &&
                  dataByProject.map((item, index) => (
                    <tr key={index}>
                      <td
                        className=" space-y-2"
                        style={{ verticalAlign: "top" }}
                      >
                        <span>{item.fullname}</span>
                        <div className="flex flex-col">
                          <span className="text-[11px] text-slate-secondary">
                            {item.position_name}
                          </span>
                          <span className="text-[11px] text-slate-secondary">
                            @ {item.department_name}
                          </span>
                        </div>
                      </td>
                      <td style={{ verticalAlign: "top" }}>
                        [#{item.task_no}] {item.task_name}
                      </td>
                      <td style={{ verticalAlign: "top" }}>
                        {item.assign_date_convert}
                      </td>
                      <td style={{ verticalAlign: "top" }}>
                        {item.task_duration} days
                      </td>
                      <td style={{ verticalAlign: "top" }}>
                        {item.begin_date_convert}
                      </td>
                      <td style={{ verticalAlign: "top" }}>
                        {item.end_date_convert}
                      </td>
                      <td width={"10%"} style={{ verticalAlign: "top" }}>
                        <input
                          type="number"
                          className="form-control"
                          min={0}
                          value={item.score ?? ""}
                          onChange={(e) => handleInput(index, e)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </CardBody>
          <CardFooter>
            <div className="mt-8 flex flex-row items-center gap-2">
              <Button
                title='Save'
                icon="BiCheck"
                color="primary"
                onClick={() => dispatch(submit({projectId: id}))}
              />
              <Button
                title="Cancel"
                icon="BiX"
                color="secondary"
                onClick={() => navigate(pathName)}
              />
            </div>
          </CardFooter>
        </Card>
      </div>
    </>
  );
}

export default Form;
