import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import { Button } from "../../../components/Button";

import { useSelector, useDispatch } from "react-redux";
import {
  deleteData,
  cancelProject,
  finishProject,
} from "../../../stores/prod/prodProjectSlice";

import Form from "./form";
import TabTask from "./tabTask";
import { BiTask, BiLink } from "react-icons/bi";
import { CheckRoleAction } from "../../../helpers/CheckRole";

function TabOverview() {
  const user = JSON.parse(localStorage.getItem("user"));
  const pathName = "/prod/projects";
  const dispatch = useDispatch();
  const openModal = useSelector((state) => state.prodProject.openModal);
  const dataProject = useSelector((state) => state.prodProject.dataView);
  const [openTab, setOpenTab] = useState("tab-task");

  return (
    <>
      {openModal && <Form formType="edit" />}
      <div className="flex md:flex-row gap-4">
        <div className="md:w-3/4 space-y-4">
          <Card>
            <CardBody>
              <div className="space-y-4">
                <div className="mb-6">
                  <h1 className="font-semibold">
                    [#{dataProject.project_no}] {dataProject.project_name}
                  </h1>
                </div>

                <div className="w-full">
                  <span className="label-detail">
                    This production project is reference of project
                  </span>
                  <div>
                    <a
                      href={`/pm/projects/view/${dataProject.ref_project_id}`}
                      className="inline-block"
                    >
                      <span className="flex flex-row gap-2 items-center text-primary-hover">
                        <BiLink /> [#{dataProject.ref_project_no}]{" "}
                        {dataProject.ref_project_name}
                      </span>
                    </a>
                  </div>
                </div>
                <div className="w-full">
                  <span className="label-detail">Client</span>
                  <p>{dataProject.ref_client_name}</p>
                </div>
                <div className="w-full">
                  <span className="label-detail">Description</span>
                  <p>{dataProject.descriptions}</p>
                </div>
              </div>
              {user.reference_id === dataProject.owner_id && (
                <div className="mt-10 w-full flex flex-row items-center gap-2">
                  {CheckRoleAction(pathName, "finish") &&
                    dataProject.status === "In Progress" &&
                    dataProject.total_spot === dataProject.total_attachment && (
                        <Button
                          title="Finish Project"
                          icon="BiCheck"
                          color="primary"
                          onClick={() => dispatch(finishProject())}
                        />
                    )}
                  {dataProject.status === "In Progress" &&
                    dataProject.total_attachment > 0 && (
                      <Button
                        title="Cancel Project"
                        icon="BiX"
                        color="secondary"
                        onClick={() => dispatch(cancelProject())}
                      />
                    )}
                  {CheckRoleAction(pathName, "delete") &&
                    dataProject.status === "In Progress" && (
                        <Button
                          title="Delete Project"
                          icon="BiTrash"
                          color="secondary"
                          onClick={() =>
                            dispatch(deleteData({ id: dataProject.id }))
                          }
                        />
                    )}
                </div>
              )}
            </CardBody>
          </Card>

          <div className="tabs">
            <div className="tab-nav">
              <button
                type="button"
                className={`btn ${openTab === "tab-task" ? "active" : ""}`}
                onClick={() => setOpenTab("tab-task")}
              >
                <BiTask /> Tasks
              </button>
            </div>

            {openTab === "tab-task" && (
              <div className="tab-panel">
                <TabTask />
              </div>
            )}
          </div>
        </div>
        <div className="md:w-1/4 space-y-4">
          <div className="space-y-2">
            <div
              className={`py-2 px-4 w-full shadow-lg shadow-shadow rounded-lg ${
                dataProject.status === "In Progress"
                  ? "bg-inprogress"
                  : dataProject.status === "Canceled"
                  ? "bg-danger"
                  : "bg-success"
              }`}
            >
              <span className="text-white font-semibold">
                {dataProject.status}
              </span>
              <span className="text-white text-sm">
                {dataProject.status !== "Completed" ? " since " : " "}
                {dataProject.updated_at_convert}
              </span>
            </div>
            <Card>
              <CardBody>
                <div className="space-y-4">
                  <div className="w-full">
                    <span className="label-detail">Created on</span>
                    <span className="text-text-secondary">
                      {dataProject.created_at_convert}
                    </span>
                  </div>
                  <div className="w-full">
                    <span className="label-detail">Project Owner</span>
                    <div className="flex flex-row items-center gap-2">
                      <span className="team-name">
                        {dataProject.owner_name}
                      </span>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <Card>
            <CardHeader>
              <CardTitle>Item Detail</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="space-y-4">
                <div className="w-full">
                  <span className="label-detail">Item No.</span>
                  <span className="text-text-secondary">
                    {dataProject.mo_no}
                  </span>
                </div>
                <div className="w-full">
                  <span className="label-detail">Product</span>
                  <div className="flex flex-row items-center gap-2">
                    <span className="team-name">
                      {dataProject.product_name}
                    </span>
                  </div>
                </div>
                <div className="w-full">
                  <span className="label-detail">Item Spot</span>
                  <div className="flex flex-row items-center gap-2">
                    <span className="team-name">{dataProject.spot}</span>
                  </div>
                </div>
                <div className="w-full">
                  <span className="label-detail">Program</span>
                  <div className="flex flex-row items-center gap-2">
                    <span className="team-name">
                      {dataProject.program_name}
                    </span>
                  </div>
                </div>
                <div className="w-full">
                  <span className="label-detail">Station</span>
                  <div className="flex flex-row items-center gap-2">
                    <span className="team-name">
                      {dataProject.station_name}
                    </span>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Progress Detail</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="space-y-4">
                <div className="w-full">
                  <span className="label-detail">Total Task</span>
                  <span className="text-text-secondary">
                    {dataProject.total_task}
                  </span>
                </div>
                <div className="w-full">
                  <span className="label-detail">Item Spot / Task</span>
                  <div className="flex flex-row items-center gap-2">
                    <span className="team-name">{dataProject.spot}</span>
                  </div>
                </div>
                <div className="w-full">
                  <span className="label-detail">Required Attachments</span>
                  <div className="flex flex-row items-center gap-2">
                    <span className="team-name">{dataProject.total_spot}</span>
                  </div>
                </div>
                <div className="w-full">
                  <span className="label-detail">Total Attachment</span>
                  <div className="flex flex-row items-center gap-2">
                    <span className="team-name">
                      {dataProject.total_attachment}
                    </span>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default TabOverview;
