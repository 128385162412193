import React from "react";
import ShowContent from "./showContent";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setShowTask } from "../../../stores/pm/taskSlice";
import { useEffect } from "react";
import {
  setDataView as setDataViewTask,
  getDataView,
} from "../../../stores/pm/taskSlice";
import { setDataByTask as setDataResponsibleByTask } from "../../../stores/pm/taskResponsibleSlice";
import { setDataByTask as setDataObserverByTask } from "../../../stores/pm/taskObserverSlice";
import { setDataByTask as setDataAttachmentByTask } from "../../../stores/pm/taskAttachmentSlice";

function Show() {
  const { projectId, taskId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDataViewTask(undefined));
    dispatch(setDataResponsibleByTask([]));
    dispatch(setDataObserverByTask([]));
    dispatch(setDataAttachmentByTask([]));

    if (!projectId && !taskId) {
      navigate(`/pm/projects/view/${projectId}`);
    } else {
      dispatch(getDataView({ id: taskId }));
    }

    console.log(projectId, taskId);
  }, [dispatch, navigate, projectId, taskId]);

  const handleClickBack = () => {
    dispatch(setShowTask(false));
    navigate(`/pm/projects/view/${projectId}`);
  };

  return (
    <ShowContent
      taskId={taskId}
      projectId={projectId}
      fromSubmit={"project"}
      onClickBack={handleClickBack}
    />
  );
}

export default Show;
