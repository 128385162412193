import { createSlice } from "@reduxjs/toolkit";
import { InstanceApi } from "../../helpers/InstanceApi";
import { Validate } from "../../helpers/Validate";
import { setPopup } from "../popupSlice";

export const projectMoSlice = createSlice({
  name: "projectMo",
  initialState: {
    openModal: false,
    dataByProject: undefined,
    dataOptions: [],
    dataTemporary: [],
    dataTemporaryAttachment: [],
    dataView: undefined,
    formValidate: {
      mo_no: { required: true, title: "MO No." },
      mo_value: { required: true, title: "MO Value" },
      spot: { required: true, title: "Spot" },
      product_id: { required: true, title: "Product" },
      station_id: { required: true, title: "Station" },
      program_id: { required: true, title: "Program" },
    },
    formData: {
      id: "",
      project_id: "",
      mo_no: "",
      mo_value: "",
      description: "",
      spot: "",
      product_id: "",
      product_name: "",
      station_id: "",
      station_name: "",
      program_id: "",
      program_name: "",
      notes: "",
    },
  },
  reducers: {
    setModal: (state, action) => {
      state.openModal = action.payload;
    },
    setDataTemporary: (state, action) => {
      state.dataTemporary = action.payload;
    },
    setDataTemporaryAttachment: (state, action) => {
      state.dataTemporaryAttachment = action.payload;
    },
    setDataByProject: (state, action) => {
      state.dataByProject = action.payload;
    },
    setDataOptions: (state, action) => {
      state.dataOptions = action.payload;
    },
    setDataView: (state, action) => {
      state.dataView = action.payload;
    },
    setFormData: (state, action) => {
      state.formData[action.payload.name] = action.payload.value;
    },
  },
});

export const resetFormData = () => async (dispatch, getState) => {
  const formData = getState().projectMo.formData;
  Object.keys(formData).forEach((key) => {
    dispatch(setFormData({ name: key, value: "" }));
  });
};

export const getDataByProject = (params) => async (dispatch) => {
  try {
    dispatch(setDataByProject(undefined));
    const response = await InstanceApi.get(
      "/pm/projects/" + params.projectId + "/mo"
    );
    if (response) {
      dispatch(setDataByProject(response.data.data));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(error);
  }
};

export const getDataView = (params) => async (dispatch) => {
  try {
    dispatch(setPopup({ open: true, type: "loading" }));

    const response = await InstanceApi.get("/pm/projects/mo/" + params.id);
    if (response) {
      dispatch(setDataView(response.data.data));
      dispatch(setPopup({ open: false }));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(error.response.data.message);
  }
};

export const submit = () => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "processing" }));
    const formData = getState().projectMo.formData;
    const validate = Validate(formData);

    if (!validate.isValid) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: validate.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
    } else {
      const response = await InstanceApi.post("/pm/projects/mo", formData);
      if (response) {
        dispatch(
          setPopup({
            open: true,
            type: "success",
            message: response.data.message,
            handleOk: () => {
              dispatch(setPopup({ open: false }));
              dispatch(setModal(false));
              dispatch(resetFormData());
              dispatch(getDataByProject({ projectId: formData.project_id }));
            },
          })
        );
      }
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(error);
  }
};

export const deleteData = (params) => (dispatch, getState) => {
  try {
    const dataProject = getState().project.dataView;
    dispatch(
      setPopup({
        open: true,
        type: "delete",
        message: "Are you sure to delete this from MO?",
        handleOk: async () => {
          dispatch(setPopup({ open: true, type: "processing" }));

          try {
            const response = await InstanceApi.delete(
              "/pm/projects/mo/" + params.id
            );
            if (response) {
              dispatch(
                setPopup({
                  open: true,
                  type: "success",
                  message: response.data.message,
                  handleOk: () => {
                    dispatch(setPopup({ open: false }));
                    dispatch(getDataByProject({ id: dataProject.id }));
                  },
                })
              );
            }
          } catch (error) {
            dispatch(
              setPopup({
                open: true,
                type: "error",
                message: error.message,
                handleOk: () => {
                  dispatch(setPopup({ open: false }));
                },
              })
            );
            console.error(error);
          }
        },
        handleCancel: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(error);
  }
};

export const {
  setModal,
  setDataTemporary,
  setDataTemporaryAttachment,
  setDataOptions,
  setDataByProject,
  setFormData,
  setDataView,
} = projectMoSlice.actions;
export default projectMoSlice.reducer;
