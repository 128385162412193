import { createSlice } from "@reduxjs/toolkit";
import { InstanceApi } from "../../helpers/InstanceApi";
import { Validate } from "../../helpers/Validate";
import { setPopup } from "../popupSlice";
import { setShowTask } from "./taskSlice";

export const projectSlice = createSlice({
  name: "project",
  initialState: {
    openModal: false,
    openModalCancel: false,
    modalAddTask: false,
    detailTabOpen: "tab-task",
    dataCount: undefined,
    dataDeadline: undefined,
    dataTables: undefined,
    dataCompleted: undefined,
    dataOptions: [],
    completedDataOptions: [],
    completedMoDataOptions: [],
    dataView: undefined,
    formValidate: {
      client_id: { required: true, title: "Client" },
      project_name: { required: true, title: "Project Name" },
      descriptions: { required: true, title: "Description" },
      deadline: { required: true, title: "Deadline" },
    },
    formData: {
      id: "",
      reference_project_id: "",
      client_id: "",
      project_no: "",
      project_name: "",
      descriptions: "",
      owner_id: "",
      deadline: "",
      urgent: "",
      urgent_notes: "",
      begin_date: "",
      end_date: "",
      status: "",
      progress: "",
      file_path: "",
      notes: "",
      archive: "",
    },
    formTask: [],
    rowTask: {
      task_id: "",
      task_duration: "",
    },
  },
  reducers: {
    setModal: (state, action) => {
      state.openModal = action.payload;
    },
    setModalCancel: (state, action) => {
      state.openModalCancel = action.payload;
    },
    setModalAddTask: (state, action) => {
      state.modalAddTask = action.payload;
    },
    setDetailTabOpen: (state, action) => {
      state.detailTabOpen = action.payload;
    },
    setDataTables: (state, action) => {
      state.dataTables = action.payload;
    },
    setDataCompleted: (state, action) => {
      state.dataCompleted = action.payload;
    },
    setDataOptions: (state, action) => {
      state.dataOptions = action.payload;
    },
    setCompletedDataOptions: (state, action) => {
      state.completedDataOptions = action.payload;
    },
    setCompletedMoDataOptions: (state, action) => {
      state.completedMoDataOptions = action.payload;
    },
    setDataView: (state, action) => {
      state.dataView = action.payload;
    },
    setFormData: (state, action) => {
      if (action.payload.name === "urgent") {
        let value = 0;
        if (action.payload.checked === true) {
          value = 1;
        } else {
          value = 0;
        }
        // if (action.payload.checked === 1) value = 1;
        // if (action.payload.checked === 0) value = 0;

        state.formData[action.payload.name] = value;
      } else {
        state.formData[action.payload.name] = action.payload.value;
      }
    },
    setDataCount: (state, action) => {
      state.dataCount = action.payload;
    },
    setDataDeadline: (state, action) => {
      state.dataDeadline = action.payload;
    },
    setFormTask: (state, action) => {
      state.formTask = action.payload;
    },
    addFormTask: (state, action) => {
      state.formTask.push(action.payload);
    },
  },
});

export const resetFormData = () => async (dispatch, getState) => {
  const formData = getState().project.formData;
  Object.keys(formData).forEach((key) => {
    dispatch(setFormData({ name: key, value: "" }));
  });
};

export const getDataTables = (params) => async (dispatch) => {
  try {
    dispatch(setDataTables(undefined));
    dispatch(setPopup({ open: true, type: "loading" }));

    const response = await InstanceApi.get("/pm/projects", { params: params });
    if (response) {
      dispatch(setDataTables(response.data.data));
      dispatch(setDataView(undefined));
      dispatch(setPopup({ open: false }));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const getDataCompleted = () => async (dispatch) => {
  try {
    const response = await InstanceApi.get("/pm/projects/completed-prod");
    if (response) {
      dispatch(setDataCompleted(response.data.data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getDataOptions = () => async (dispatch) => {
  try {
    const response = await InstanceApi.get("/pm/projects/option");
    if (response) {
      const data = response.data.data.map((item) => {
        return {
          value: item.id,
          label: `[#${item.project_no}] ${item.project_name}`,
        };
      });

      dispatch(setDataOptions(data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getCompletedDataOptions = () => async (dispatch) => {
  try {
    const response = await InstanceApi.get("/pm/projects/completed");
    if (response) {
      const data = response.data.data.map((item) => {
        return {
          value: item.id,
          label: `[#${item.project_no}] ${item.project_name}`,
        };
      });

      dispatch(setCompletedDataOptions(data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getCompletedMoDataOptions = (params) => async (dispatch) => {
  try {
    const response = await InstanceApi.get(
      `/pm/projects/completed/mo/${params.projectId}`
    );
    if (response) {
      const data = response.data.data.map((item) => {
        return {
          value: item.id,
          label: item.mo_no,
        };
      });

      dispatch(setCompletedMoDataOptions(data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getDataView = (params) => async (dispatch) => {
  try {
    dispatch(setPopup({ open: true, type: "loading" }));
    const response = await InstanceApi.get("/pm/projects/" + params.id);
    if (response) {
      if (response.data.data) {
        dispatch(setDataView(response.data.data));
        dispatch(setShowTask(false));
        dispatch(setPopup({ open: false }));
      } else {
        window.location.href = "/404";
      }
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const getFormData = (params) => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "loading" }));

    const response = await InstanceApi.get("/pm/projects/" + params.id);
    if (response) {
      const data = response.data.data;
      const formData = getState().project.formData;

      Object.keys(formData).forEach((key) => {
        dispatch(setFormData({ name: key, value: data?.[key] }));
      });

      dispatch(setPopup({ open: false }));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const finishProject = () => async (dispatch, getState) => {
  try {
    dispatch(
      setPopup({
        open: true,
        type: "confirm",
        message: "Are you sure to finish this project?",
        handleOk: async () => {
          try {
            dispatch(setPopup({ open: true, type: "processing" }));

            const dataProject = getState().project.dataView;
            const response = await InstanceApi.post("/pm/projects/finish", {
              id: dataProject.id,
            });

            if (response) {
              dispatch(
                setPopup({
                  open: true,
                  type: "success",
                  message: response.data.message,
                  handleOk: () => {
                    dispatch(setPopup({ open: false }));
                    dispatch(setModal(false));
                    dispatch(getDataView({ id: dataProject.id }));
                  },
                })
              );
            }
          } catch (error) {
            dispatch(
              setPopup({
                open: true,
                type: "error",
                message: error.response.data.message,
                handleOk: () => {
                  dispatch(setPopup({ open: false }));
                },
              })
            );
          }
        },
        handleCancel: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};
export const cancelProject = () => async (dispatch, getState) => {
  try {
    dispatch(
      setPopup({
        open: true,
        type: "confirm",
        message: "Are you sure to cancel this project?",
        handleOk: async () => {
          try {
            dispatch(setPopup({ open: true, type: "processing" }));

            const dataProject = getState().project.dataView;
            const response = await InstanceApi.post("/pm/projects/cancel", {
              id: dataProject.id,
            });

            if (response) {
              dispatch(
                setPopup({
                  open: true,
                  type: "success",
                  message: response.data.message,
                  handleOk: () => {
                    dispatch(setPopup({ open: false }));
                    dispatch(setModal(false));
                    dispatch(getDataView({ id: dataProject.id }));
                  },
                })
              );
            }
          } catch (error) {
            dispatch(
              setPopup({
                open: true,
                type: "error",
                message: error.response.data.message,
                handleOk: () => {
                  dispatch(setPopup({ open: false }));
                },
              })
            );
          }
        },
        handleCancel: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const submit = () => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "processing" }));
    const formValidate = getState().project.formValidate;
    const formData = getState().project.formData;
    const formTask = getState().project.formTask;
    const formAttachment = getState().projectAttachment.dataTemporary;
    const validate = Validate(formValidate, formData);

    const submitForm = new FormData();
    submitForm.append("project", JSON.stringify(formData));
    // submitForm.append("tasks", JSON.parse(formTask));
    if (formTask.length > 0) {
      formTask.map((item, _) =>
        submitForm.append("tasks[]", JSON.stringify(item))
      );
    }
    if (formAttachment.length > 0) {
      formAttachment.map((item, _) =>
        submitForm.append("attachment[]", item.value, item.name)
      );
    }

    if (!validate.isValid) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: validate.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
    } else {
      let response = await InstanceApi.post("/pm/projects", submitForm);

      if (response) {
        dispatch(
          setPopup({
            open: true,
            type: "success",
            message: response.data.message,
            handleOk: () => {
              dispatch(setPopup({ open: false }));
              dispatch(setModal(false));
              dispatch(resetFormData());
              window.location.href =
                "/pm/projects/view/" + response.data.data.id;
            },
          })
        );
      }
    }
  } catch (error) {   
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const deleteData = (params) => (dispatch) => {
  try {
    dispatch(
      setPopup({
        open: true,
        type: "delete",
        message: "Are you sure to delete this data?",
        handleOk: async () => {
          try {
            dispatch(setPopup({ open: true, type: "processing" }));

            const response = await InstanceApi.delete(
              "/pm/projects/" + params.id
            );
            if (response) {
              dispatch(
                setPopup({
                  open: true,
                  type: "success",
                  message: response.data.message,
                  handleOk: () => {
                    dispatch(setPopup({ open: false }));
                    window.location.href = "/pm/projects";
                  },
                })
              );
            }
          } catch (error) {
            dispatch(
              setPopup({
                open: true,
                type: "error",
                message: error.message,
                handleOk: () => {
                  dispatch(setPopup({ open: false }));
                },
              })
            );
          }
        },
        handleCancel: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  } catch (err) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: err.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const getDataCount = (params) => async (dispatch) => {
  try {
    dispatch(setDataCount(undefined));

    let paramsString = [];
    Object.entries(params).forEach((data) => {
      paramsString.push(data.join("="));
    });

    const response = await InstanceApi.get(
      "/pm/projects/count?" + paramsString.join("&")
    );
    if (response) {
      const data = response.data;
      dispatch(setDataCount(data));
    }
  } catch (error) {}
};

export const getDataDeadline = () => async (dispatch) => {
  try {
    dispatch(setDataDeadline(undefined));

    const response = await InstanceApi.get("/pm/projects/deadline");
    if (response) {
      dispatch(setDataDeadline(response.data));
    }
  } catch (error) {}
};

export const {
  setModal,
  setModalCancel,
  setModalAddTask,
  setDetailTabOpen,
  setDataCount,
  setDataDeadline,
  setDataTables,
  setDataCompleted,
  setDataOptions,
  setCompletedDataOptions,
  setCompletedMoDataOptions,
  setDataView,
  setFormData,
  setFormTask,
  addFormTask,
} = projectSlice.actions;
export default projectSlice.reducer;
