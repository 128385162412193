import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PageHeader, PageHeaderTitle } from "../../../components/PageHeader";
import { CheckRoleIndex } from "../../../helpers/CheckRole";

import IndexTable from "./indexTable";

function Index() {
  const pathName = "/pm/kpi-adjustments";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!CheckRoleIndex(pathName)) {
      navigate("/404");
    }
  }, [dispatch, navigate]);

  const onClickEdit = (id) => {
    navigate(`/pm/kpi-adjustments/edit/${id}`);
  };

  return (
    <>
      <PageHeader>
        <PageHeaderTitle>Kpi Adjustments</PageHeaderTitle>
      </PageHeader>
      <IndexTable onClickEdit={onClickEdit} />
    </>
  );
}

export default Index;
