import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from "../../../components/Card";
import { CheckRoleAction } from "../../../helpers/CheckRole";
import { Button } from "../../../components/Button";
import Select from "../../../components/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  setFormData,
  setModal,
  setModalApproval,
  submit,
} from "../../../stores/pm/projectMoRevSlice";
import ModalForm from "../../../components/ModalForm";
import TabMoRevApprovalWidget from "./tabMoRevApprovalWidget";

export default function TabMoRevWidget(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const { responsibleId, ownerId } = props;
  const dispatch = useDispatch();
  const openModal = useSelector((state) => state.projectMoRev.openModal);
  const openModalApproval = useSelector(
    (state) => state.projectMoRev.openModalApproval
  );
  const dataOptionProduct = useSelector((state) => state.product.dataOptions);
  const dataOptionStation = useSelector((state) => state.station.dataOptions);
  const dataOptionProgram = useSelector((state) => state.program.dataOptions);
  const formData = useSelector((state) => state.projectMoRev.formData);
  const dataViewMo = useSelector((state) => state.projectMo.dataView);
  const dataRevPendingByProject = useSelector(
    (state) => state.projectMoRev.dataPendingByProject
  );

  const handleInput = (e) => {
    dispatch(setFormData({ name: e.target.name, value: e.target.value }));
  };

  const [dataApproval, setDataApproval] = useState(undefined);
  const [approvalId, setApprovalId] = useState(undefined);
  const openApproval = (approvalId) => {
    setApprovalId(undefined);
    setApprovalId(approvalId);
    setDataApproval(undefined);
    setDataApproval(dataRevPendingByProject[approvalId]);

    dispatch(setModalApproval(true));
  };

  return (
    <>
      {openModal && (
        <ModalForm
          modalTitle="Revision Item"
          modalSize="medium"
          closeModal={() => dispatch(setModal(false))}
        >
          <div className="w-full">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="col-span-1">
                <h3 className="font-semibold">Detail Item</h3>
                <hr className="my-2 border-slate-tertiary" />
                <div className="flex flex-col gap-2">
                  <div className="w-full">
                    <span className="label-detail">Item No.</span>
                    <p>{dataViewMo?.mo_no}</p>
                  </div>
                  <div className="w-full">
                    <span className="label-detail">Item Value</span>
                    <p>
                      {dataViewMo?.mo_value.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                  <div className="w-full">
                    <span className="label-detail">Product Name</span>
                    <p>{dataViewMo?.product_name}</p>
                  </div>
                  <div className="w-full">
                    <span className="label-detail">Spot</span>
                    <p>{dataViewMo?.spot}</p>
                  </div>
                  <div className="w-full">
                    <span className="label-detail">Media Channel</span>
                    <p>{dataViewMo?.station_name}</p>
                  </div>
                  <div className="w-full">
                    <span className="label-detail">Program</span>
                    <p>{dataViewMo?.program_name}</p>
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <h3 className="font-semibold">Revision To</h3>
                <hr className="my-2 border-slate-tertiary" />
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="form-group">
                    <label className="control-label">
                      Item No. <span className="text-danger">*</span>
                    </label>
                    <input
                      id="to_mo_no"
                      title="Item No."
                      type="text"
                      name="to_mo_no"
                      className="form-control"
                      value={formData.to_mo_no}
                      onChange={handleInput}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label className="control-label">
                      Item Value <span className="text-danger">*</span>
                    </label>
                    <input
                      id="to_mo_value"
                      title="Item Value"
                      type="text"
                      name="to_mo_value"
                      className="form-control"
                      value={formData.to_mo_value}
                      onChange={handleInput}
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label">
                    Product <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="to_product_id"
                    title="Product"
                    name="to_product_id"
                    placeholder={"Choose..."}
                    value={dataOptionProduct.filter(
                      (option) => option.value === formData.to_product_id
                    )}
                    onChange={(e) => {
                      dispatch(
                        setFormData({ name: "to_product_id", value: e?.value })
                      );
                    }}
                    options={dataOptionProduct}
                    required={true}
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                  <div className="col-span-1 form-group">
                    <label className="control-label">
                      Spot <span className="text-danger">*</span>
                    </label>
                    <input
                      id="to_spot"
                      title="Spot"
                      type="text"
                      name="to_spot"
                      className="form-control"
                      value={formData.to_spot}
                      onChange={handleInput}
                      required
                    />
                  </div>

                  <div className="col-span-2 form-group">
                    <label className="control-label">
                      Media Channel <span className="text-danger">*</span>
                    </label>
                    <Select
                      id="to_station_id`"
                      title="Media Channel"
                      name="to_station_id`"
                      placeholder={"Choose..."}
                      value={dataOptionStation.filter(
                        (option) => option.value === formData.to_station_id
                      )}
                      onChange={(e) =>
                        dispatch(
                          setFormData({
                            name: "to_station_id",
                            value: e?.value,
                          })
                        )
                      }
                      options={dataOptionStation}
                      required={true}
                    />
                  </div>
                  <div className="col-span-2 form-group">
                    <label className="control-label">
                      Program <span className="text-danger">*</span>
                    </label>
                    <Select
                      id="to_program_id`"
                      title="Program"
                      name="to_program_id`"
                      placeholder={"Choose..."}
                      value={dataOptionProgram.filter(
                        (option) => option.value === formData.to_program_id
                      )}
                      onChange={(e) =>
                        dispatch(
                          setFormData({
                            name: "to_program_id",
                            value: e?.value,
                          })
                        )
                      }
                      options={dataOptionProgram}
                      required={true}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label">Notes</label>
                  <textarea
                    id="notes"
                    title="Notes"
                    name="notes"
                    className="form-control h-60"
                    defaultValue={formData.notes}
                    onChange={handleInput}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-8 flex gap-4">
            <Button
              title="Save"
              icon="BiSave"
              color="primary"
              onClick={() => {
                dispatch(submit());
              }}
            />
            <Button
              title="Cancel"
              icon="BiX"
              color="secondary"
              onClick={() => dispatch(setModal(false))}
            />
          </div>
        </ModalForm>
      )}

      {openModalApproval && setDataApproval && (
        <ModalForm
          modalTitle={`Approval Revision Item No. ${
            dataApproval?.type === "New"
              ? dataApproval?.to_mo_no
              : dataApproval?.from_mo_no
          }`}
          modalSize="medium"
          closeModal={() => dispatch(setModalApproval(false))}
        >
          <TabMoRevApprovalWidget approvalId={approvalId} />
        </ModalForm>
      )}
      <Card>
        <CardHeader>
          <CardTitle>Revision Item</CardTitle>
        </CardHeader>
        <CardBody>
          {!dataRevPendingByProject && (
            <div className="text-center font-semibold">Loading...</div>
          )}
          {dataRevPendingByProject && (
            <>
              <div className="space-y-2">
                {dataRevPendingByProject?.length === 0 ? (
                  <span className="block text-center font-semibold">
                    No item
                  </span>
                ) : (
                  <div className="flex flex-col gap-2">
                    {dataRevPendingByProject &&
                      dataRevPendingByProject.map(
                        (item, index) =>
                          item.approval_id !== null && (
                            <div
                              key={index}
                              className="p-4 border border-slate-tertiary rounded-md hover:bg-slate-tertiary transition duration-200"
                            >
                              <div className="mb-6 flex flex-row items-center justify-between gap-2">
                                <span className="font-semibold">
                                  {item.type === "New" && "New Item"}
                                  {item.type === "Update" && "Update Item"}
                                  {item.type === "Delete" && "Delete Item"}
                                </span>
                                <div className="flex flex-row items-center justify-between gap-2">
                                  {item.approval_id && (
                                    <div
                                      className="badge badge-info"
                                      style={{ maxWidth: "200px" }}
                                    >
                                      <span className="truncate font-normal">
                                        Waiting approval by {item.approval_by}
                                      </span>
                                    </div>
                                  )}
                                  {item.is_approval && (
                                    <Button
                                      title="Approval"
                                      icon="BiCheck"
                                      size="sm"
                                      color="primary"
                                      onClick={() => openApproval(index)}
                                    />
                                  )}
                                </div>
                              </div>
                              {item.type === "New" && (
                                <div className="w-full">
                                  <div className="grid grid-cols-10 gap-2 text-sm">
                                    <div className="col-span-1">
                                      <div className="h-10 w-10 flex items-center justify-center bg-success text-white rounded">
                                        New
                                      </div>
                                    </div>
                                    <div className="col-span-9 grid grid-cols-9 gap-2">
                                      <div className="col-span-2">
                                        <span className="mb-2 block text-text-secondary">
                                          Item No.
                                        </span>
                                        <span className="block">
                                          {item.to_mo_no}
                                        </span>
                                      </div>
                                      <div className="col-span-7">
                                        <span className="mb-2 block text-text-secondary">
                                          Item Description
                                        </span>
                                        <span className="block">
                                          {item.to_description}
                                        </span>
                                      </div>
                                      <div className="col-span-2">
                                        <span className="mb-2 block text-text-secondary">
                                          Item Value
                                        </span>
                                        <span className="block">
                                          {(CheckRoleAction(
                                            "/pm/projects",
                                            "view_mo_value"
                                          ) ||
                                            user.reference_id ===
                                              responsibleId ||
                                            user.reference_id === ownerId) &&
                                            item.to_mo_value.toLocaleString(
                                              "id-ID",
                                              {
                                                style: "currency",
                                                currency: "IDR",
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 2,
                                              }
                                            )}
                                        </span>
                                      </div>
                                      <div className="col-span-2">
                                        <span className="mb-2 block text-text-secondary">
                                          Product
                                        </span>
                                        <span className="block">
                                          {item.to_product_name}
                                        </span>
                                      </div>
                                      <div className="col-span-1">
                                        <span className="mb-2 block text-text-secondary">
                                          Spot
                                        </span>
                                        <span className="block">
                                          {item.to_spot}
                                        </span>
                                      </div>
                                      <div className="col-span-2">
                                        <span className="mb-2 block text-text-secondary">
                                          Program
                                        </span>
                                        <span className="block">
                                          {item.to_program_name} @{" "}
                                          {item.to_station_name}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {item.type === "Update" && (
                                <>
                                  <div className="w-full">
                                    <div className="grid grid-cols-10 gap-2 text-sm">
                                      <div className="col-span-1">
                                        <div className="h-10 w-10 flex items-center justify-center bg-info text-white rounded">
                                          From
                                        </div>
                                      </div>
                                      <div className="col-span-9 grid grid-cols-9 gap-2">
                                        <div className="col-span-2">
                                          <span className="mb-2 block text-text-secondary">
                                            Item No.
                                          </span>
                                          <span className="block">
                                            {item.from_mo_no}
                                          </span>
                                        </div>
                                        <div className="col-span-7">
                                          <span className="mb-2 block text-text-secondary">
                                            Item Description
                                          </span>
                                          <span className="block">
                                            {item.from_description}
                                          </span>
                                        </div>
                                        <div className="col-span-2">
                                          <span className="mb-2 block text-text-secondary">
                                            Item Value
                                          </span>
                                          <span className="block">
                                            {(CheckRoleAction(
                                              "/pm/projects",
                                              "view_mo_value"
                                            ) ||
                                              user.reference_id ===
                                                responsibleId ||
                                              user.reference_id === ownerId) &&
                                              item.from_mo_value.toLocaleString(
                                                "id-ID",
                                                {
                                                  style: "currency",
                                                  currency: "IDR",
                                                  minimumFractionDigits: 0,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                          </span>
                                        </div>
                                        <div className="col-span-2">
                                          <span className="mb-2 block text-text-secondary">
                                            Product
                                          </span>
                                          <span className="block">
                                            {item.from_product_name}
                                          </span>
                                        </div>
                                        <div className="col-span-1">
                                          <span className="mb-2 block text-text-secondary">
                                            Spot
                                          </span>
                                          <span className="block">
                                            {item.from_spot}
                                          </span>
                                        </div>
                                        <div className="col-span-2">
                                          <span className="mb-2 block text-text-secondary">
                                            Program
                                          </span>
                                          <span className="block">
                                            {item.from_program_name} @{" "}
                                            {item.from_station_name}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-6 w-full">
                                    <div className="mt-4 grid grid-cols-10 gap-2 text-sm">
                                      <div className="col-span-1">
                                        <div className="h-10 w-10 flex items-center justify-center bg-success text-white rounded">
                                          To
                                        </div>
                                      </div>
                                      <div className="col-span-9 grid grid-cols-9 gap-2">
                                        <div className="col-span-2">
                                          <span className="mb-2 block text-text-secondary">
                                            Item No.
                                          </span>
                                          <span className="block">
                                            {item.to_mo_no}
                                          </span>
                                        </div>
                                        <div className="col-span-7">
                                          <span className="mb-2 block text-text-secondary">
                                            Item Description
                                          </span>
                                          <span className="block">
                                            {item.to_description}
                                          </span>
                                        </div>
                                        <div className="col-span-2">
                                          <span className="mb-2 block text-text-secondary">
                                            Item Value
                                          </span>
                                          <span className="block">
                                            {(CheckRoleAction(
                                              "/pm/projects",
                                              "view_mo_value"
                                            ) ||
                                              user.reference_id ===
                                                responsibleId ||
                                              user.reference_id === ownerId) &&
                                              item.to_mo_value.toLocaleString(
                                                "id-ID",
                                                {
                                                  style: "currency",
                                                  currency: "IDR",
                                                  minimumFractionDigits: 0,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                          </span>
                                        </div>
                                        <div className="col-span-2">
                                          <span className="mb-2 block text-text-secondary">
                                            Product
                                          </span>
                                          <span className="block">
                                            {item.to_product_name}
                                          </span>
                                        </div>
                                        <div className="col-span-1">
                                          <span className="mb-2 block text-text-secondary">
                                            Spot
                                          </span>
                                          <span className="block">
                                            {item.to_spot}
                                          </span>
                                        </div>
                                        <div className="col-span-2">
                                          <span className="mb-2 block text-text-secondary">
                                            Program
                                          </span>
                                          <span className="block">
                                            {item.to_program_name} @{" "}
                                            {item.to_station_name}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {item.type === "Delete" && (
                                <div className="w-full">
                                  <div className="grid grid-cols-10 gap-2 text-sm">
                                    <div className="col-span-1">
                                      <div className="h-10 w-10 flex items-center justify-center bg-danger text-white rounded">
                                        Delete
                                      </div>
                                    </div>
                                    <div className="col-span-2">
                                      <span className="mb-2 block text-text-secondary">
                                        Item No.
                                      </span>
                                      <span className="block">
                                        {item.from_mo_no}
                                      </span>
                                    </div>
                                    <div className="col-span-2">
                                      <span className="mb-2 block text-text-secondary">
                                        Item Value
                                      </span>
                                      <span className="block">
                                        {(CheckRoleAction(
                                          "/pm/projects",
                                          "view_mo_value"
                                        ) ||
                                          user.reference_id === responsibleId ||
                                          user.reference_id === ownerId) &&
                                          item.from_mo_value.toLocaleString(
                                            "id-ID",
                                            {
                                              style: "currency",
                                              currency: "IDR",
                                              minimumFractionDigits: 0,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                      </span>
                                    </div>
                                    <div className="col-span-2">
                                      <span className="mb-2 block text-text-secondary">
                                        Product
                                      </span>
                                      <span className="block">
                                        {item.from_product_name}
                                      </span>
                                    </div>
                                    <div className="col-span-1">
                                      <span className="mb-2 block text-text-secondary">
                                        Spot
                                      </span>
                                      <span className="block">
                                        {item.from_spot}
                                      </span>
                                    </div>
                                    <div className="col-span-2">
                                      <span className="mb-2 block text-text-secondary">
                                        Program
                                      </span>
                                      <span className="block">
                                        {item.from_program_name} @{" "}
                                        {item.from_station_name}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="mt-4 w-full">
                                <span className="font-semibold">
                                  Revision Notes :
                                </span>
                                <div className="mt-2 text-sm">{item.notes}</div>
                              </div>
                            </div>
                          )
                      )}
                  </div>
                )}
              </div>
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
}
