import React from "react";
import * as BI from "react-icons/bi";

export const Button = (props) => {
  return (
    <button
      type="button"
      className={`btn btn-${props.size} btn-${props.color}`}
      onClick={props.onClick}
    >
      {props.icon && React.createElement(BI[props.icon])}
      <span>{props.title}</span>
    </button>
  );
};

export const RoundButton = (props) => {
  return (
    <button
      type="button"
      className={`btn-rounded btn-${props.color}`}
      onClick={props.onClick}
    >
      {props.icon && React.createElement(BI[props.icon])}
    </button>
  );
};

export const IconButton = (props) => {
  return (
    <button
      type="button"
      className={`btn-icon btn-icon-${props.size} btn-${props.color}`}
      onClick={props.onClick}
    >
      {props.icon && React.createElement(BI[props.icon])}
    </button>
  );
};

export const LinkButton = (props) => {
  return (
    <button
      type="button"
      className={`btn-link btn-link-${props.size}`}
      onClick={props.onClick}
    >
      {props.icon && React.createElement(BI[props.icon])}
      <span>{props.title}</span>
    </button>
  );
};
