import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import styles from "../styles/DataTable.module.css";

export default function DataTable(props) {
  const {
    columns,
    data,
    onClickRow,
    bulkAction,
    filter,
    options,
    customFilter,
  } = props;
  const showing = [10, 20, 50, 100, 500];
  const totalData = data.length;
  const [totalDataFiltered, setTotalDataFiltered] = useState(data.length);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataTableSearch, setDataTableSearch] = useState({});
  const [tableBody, setTableBody] = useState(data);
  const [showEntries, setShowEntries] = useState(showing[3]);
  const [showingInfo, setShowingInfo] = useState();
  const [pagination, setPagination] = useState([]);
  const [sortBy, setSortBy] = useState({});
  const sortType = [undefined, "asc", "desc"];
  const defaultOptions = {
    sorting: options?.sorting || false,
    searching: options?.searching || false,
    bulkAction: options?.bulkAction || false,
  };

  const updateShowingInfo = () => {
    let fromEntries =
      totalDataFiltered > 0 ? showEntries * (currentPage - 1) + 1 : 0;
    let toEntries = showEntries * currentPage;
    toEntries = toEntries > totalDataFiltered ? totalDataFiltered : toEntries;
    const showInfo = `Showing ${fromEntries} to ${toEntries} of ${totalDataFiltered} entries ${
      totalDataFiltered < totalData
        ? "(filtered from " + totalData + " total entries)"
        : ""
    }`;

    setShowingInfo(showInfo);
  };

  const updatePagination = () => {
    let totalPage = Math.ceil(totalDataFiltered / showEntries);

    let arrayPage = [];
    if (totalPage > 10) {
      const middlePage = Math.floor(totalPage / 2);

      let start0 = 1;
      let start1 = 3;
      let end0 = totalPage - 2;
      let end1 = totalPage;

      if (currentPage > start1) {
        arrayPage.push("...");
        start0 = currentPage - 2;
        start1 = currentPage;
      }
      for (start0; start0 <= start1; start0++) {
        if (start0 < end0) {
          arrayPage.push(start0);
        } else {
          arrayPage.push(start0 - 3);
        }
      }
      if (currentPage < middlePage - 1) {
        arrayPage.push("...");
      }
      if (currentPage < middlePage) {
        arrayPage.push(middlePage);
      }
      if (currentPage < end0 - 1) {
        arrayPage.push("...");
      }

      for (end0; end0 <= end1; end0++) {
        arrayPage.push(end0);
      }
    } else {
      for (let index = 1; index <= totalPage; index++) {
        arrayPage.push(index);
      }
    }

    arrayPage.sort(function (a, b) {
      return a - b;
    });

    setPagination(arrayPage);
  };

  const generateTableBody = () => {
    let filtered = data;

    if (customFilter) {
      filtered = customFilter(filtered, dataTableSearch);
    } else {
      if (Object.keys(dataTableSearch).length) {
        Object.entries(dataTableSearch).forEach((item) => {
          if (item[0] === "global") {
            filtered = [...filtered].filter((data) =>
              columns
                .map(
                  (column) =>
                    column?.assessor &&
                    data?.[column?.assessor] &&
                    data?.[column?.assessor]
                      .toString()
                      .toLowerCase()
                      .indexOf(item[1]) > -1
                )
                .includes(true)
            );
          } else {
            filtered = [...filtered].filter(
              (data) => data[item[0]] === item[1]
            );
          }
        });
      }
    }

    if (Object.keys(sortBy).length) {
      Object.entries(sortBy).forEach((item) => {
        filtered = [...filtered].sort((a, b) => {
          let comparison = 0;

          if (a[item[0]] > b[item[0]]) {
            comparison = 1;
          } else if (a[item[0]] < b[item[0]]) {
            comparison = -1;
          }

          return item[1] === "desc" ? comparison * -1 : comparison;
        });
      });
    }

    let tbody = [];
    tbody = filtered.filter((row, index) => {
      let start = (currentPage - 1) * showEntries;
      let end = currentPage * showEntries;
      if (index >= start && index < end) {
        return true;
      } else {
        return false;
      }
    });

    setTableBody(tbody);
    setTotalDataFiltered(filtered.length);
  };

  useEffect(() => {
    generateTableBody();
    updatePagination();
    setCurrentPage(1);
    updateShowingInfo();
  }, [data, showEntries, totalDataFiltered]);

  useEffect(() => {
    updateShowingInfo();
    updatePagination();
    generateTableBody();
  }, [currentPage]);

  useEffect(() => {
    generateTableBody();
  }, [sortBy, dataTableSearch]);

  // PAGINATION
  const ButttonPagination = (props) => {
    const { value } = props;
    if (value === "...") {
      return (
        <button type="button" className="btn-dots">
          {value}
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className={`${styles.btnPage} ${
            currentPage === value && styles.btnPage_Active
          }`}
          onClick={() => setCurrentPage(value)}
        >
          {value}
        </button>
      );
    }
  };

  // SORTING
  const generateSort = (name, type) => {
    let typeValue = undefined;
    if (sortType.indexOf(type) === sortType.length) {
      typeValue = sortType[0];
    } else {
      typeValue = sortType[sortType.indexOf(type) + 1];
    }

    if (typeValue) {
      setSortBy({
        ...sortBy,
        [name]: typeValue,
      });
    } else {
      delete sortBy[name];
      setSortBy({ ...sortBy });
    }
  };

  useEffect(() => {
    if (Object.keys(columns).length) {
      Object.entries(columns).forEach((column) => {
        setSortBy({
          ...sortBy,
          [column.assessor]: undefined,
        });
      });
    }
  }, []);

  if (data) {
    return (
      <div className={styles.dataTable_Box}>
        <div className={styles.dataTable_Header}>
          {defaultOptions.searching && (
            <div className={styles.dataTable_Search}>
              <div>
                <BiSearch />
              </div>
              <input
                type="search"
                placeholder="Search..."
                onChange={(e) =>
                  e?.target.value
                    ? setDataTableSearch({
                        ...dataTableSearch,
                        global: e.target.value.toLowerCase(),
                      })
                    : (delete dataTableSearch.global,
                      setDataTableSearch({ ...dataTableSearch }))
                }
              />
            </div>
          )}
          {/* filter */}
          {filter.length > 0 &&
            filter.map((Item, index) => (
              <Item
                key={index}
                classFilterBox={styles.dataTable_Select}
                filterOnChange={(e) =>
                  e?.target.value
                    ? setDataTableSearch({
                        ...dataTableSearch,
                        [e.target.name]: e.target.value,
                      })
                    : (delete dataTableSearch[e.target.name],
                      setDataTableSearch({ ...dataTableSearch }))
                }
              />
            ))}
          {defaultOptions.bulkAction && (
            <div className={styles.dataTable_Select}>
              <select onChange={bulkAction.onChange}>
                <option value="">Action</option>
                {bulkAction.options.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
        <div className={styles.dataTable_Body}>
          {tableBody && (
            <table className={styles.dataTable}>
              <thead>
                <tr>
                  {columns &&
                    columns.map((column, index) => (
                      <th key={index}>
                        <div>
                          <span>{column.header}</span>
                          {defaultOptions.sorting && column.assessor && (
                            <span
                              className={`${styles.sort} ${
                                styles[sortBy[column.assessor]]
                              }`}
                              onClick={() =>
                                generateSort(
                                  column.assessor,
                                  sortBy[column.assessor]
                                )
                              }
                            ></span>
                          )}
                        </div>
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {tableBody && tableBody.length > 0 ? (
                  tableBody.map((row, iRow) => (
                    <tr
                      key={iRow}
                      onClick={() => onClickRow && onClickRow(row.id)}
                      style={onClickRow && { cursor: "pointer" }}
                    >
                      {columns.map((column, iCell) => (
                        <td key={iCell} style={column.tdStyle}>
                          {column.Cell
                            ? column.Cell(row)
                            : row?.[column.assessor]}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr key={1}>
                    <td colSpan={columns.length} className="text-center">
                      No data
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
        <div className={styles.dataTable_Footer}>
          <div className={styles.showingInfo}>
            <div className={styles.dataTable_Select}>
              <select
                onChange={(e) => setShowEntries(e.target.value)}
                value={showEntries}
              >
                {showing.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <div>{showingInfo}</div>
          </div>
          <div className={styles.pagination}>
            <button
              type="button"
              className={styles.btnQuick}
              onClick={() => setCurrentPage(1)}
            >
              <img
                style={{ height: "18px", margin: "0 auto" }}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAANVJREFUSEtjZKAxYKSx+QyjFhAM4cETRAkF/QIsLH/y5/SUNsKcjU0M3UtE+QBq0H4GBgYDBgaGxjk9pQ3YxLCFF0ELkA1iZGC4+PsPiwPIIBaWP2ALYWILJhR+INkCSg0HWYjTB9QwHKcF1DIcrwWsLH8O/Gdg0GdgYLjw5w+LI0gxuhiucEeOC7xBRA1L8KYiUFBRaglRyZQSSwhaAApPZJ/8/88YOLe3ZAM2MZLzAbIGkIHMzH8dQIYjFxXoYmQVFQSLTDwKiAqiUQsoCQGCemkeBwCu6qoZY5sVLQAAAABJRU5ErkJggg=="
                alt=""
              />
            </button>
            <button
              type="button"
              className={styles.btnQuick}
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === pagination[0] ?? false}
            >
              <img
                style={{ height: "18px", margin: "0 auto" }}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAL9JREFUSEtjZKAxYKSx+QyjFhAM4cEXRAkF/QIsLH/y5/SUNhJ0PgMDaZEMNXw/AwODAQMDQ+OcntIGQpYQHUTIhjMyMFz8/YfFYcGEwg9UsYBcw0GWE/QBJYYTtIBSw4mygJXlz4H/DAz6DAwMF/78YXEkJtyR44WoIKLEEoIWgFwDCipyLSHKAkosIdoCdEv+/2cMnNtbsoEq+QDZEFBwMTP/dSDGcIKpiJDriJEnKYiIMRBdzagFBEON5kEEACMtZRmcDwI5AAAAAElFTkSuQmCC"
                alt=""
              />
            </button>
            {pagination.map((value, index) => (
              <ButttonPagination key={index} value={value} />
            ))}
            <button
              type="button"
              className={styles.btnQuick}
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={
                currentPage === pagination[pagination.length - 1] ?? false
              }
            >
              <img
                style={{ height: "18px", margin: "0 auto" }}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAALlJREFUSEvt1LENwkAMBdBvYXo2gRFgA0ZAggFA0EMPEhmBDRiBjACb0OMoyB2i4L4tRaJI6st/53+6E3T8Scf56IFiw/9T0Wp3PJhpdak2z+K2PxZQE3g4gD2Au5nOIggFLNbn0VCtboFxFKEAnziL0EAWCQEZJAWo2g3ARIDHy3T669BDgJ9DJNwnpoFMOA1kw2lguT3NRdor0/n3LacrcqRpBnXkFtMTRN6e9ARZhK6oB7INFP97A04+axn4iq7hAAAAAElFTkSuQmCC"
                alt=""
              />
            </button>
            <button
              type="button"
              className={styles.btnQuick}
              onClick={() =>
                setCurrentPage(pagination[[pagination.length - 1]])
              }
            >
              <img
                style={{ height: "18px", margin: "0 auto" }}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAM1JREFUSEtjZKAxYKSx+QyjFhAM4YELopSS7oY/f1gmLJhQ+AHmTGxihLyA1QcggxgYGOoZGBgu/PnD4giyBJsYIcNB8lgtSCjoF2Bl+XPgPwODPswSkGJ0MWTf4bIMZxxQyxK8kUwNSwimIkotIcoCFpY/+xkYGAwYGRgu/v7D4gAKb3QxXPFBMIgoMRxnKgJJgIKGUsPxJlNqGI7TgpSS7gIGBoZ+WJhDMxqGGNkZDaSRpkUFMS4jVg3BZEqsQSQXFZQaDNM/6gOCIQkA2au0GYe21MEAAAAASUVORK5CYII="
                alt=""
              />
            </button>
          </div>
        </div>
      </div>
    );
  }
}
