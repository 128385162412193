export const Validate = (formValidate, formData) => {
  let isValid = false;
  let validateMessage = "";

  for (const [key, value] of Object.entries(formValidate)) {
    isValid = true;

    if (value.required) {
      if (formData[key] === "") {
        validateMessage = value.title + " is required!";
        isValid = false;

        break;
      }
    }
  }

  return { isValid: isValid, message: validateMessage };
};
