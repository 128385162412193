import React, { useEffect, useState } from "react";
import { Button } from "../../../components/Button";
import ModalForm from "../../../components/ModalForm";

import { useSelector, useDispatch } from "react-redux";
import {
  resetFormData,
  setModal,
  getFormData,
  setFormData,
  submit,
  getDataOptions as getDataOptionPosition,
} from "../../../stores/master/positionSlice";
import Select from "../../../components/Select";

import { getDataOptions as getDataOptionDepartment } from "../../../stores/master/departmentSlice";

function Form(props) {
  const { formType, dataId } = props;
  const dispatch = useDispatch();
  const openPopup = useSelector((state) => state.popup.openPopup);
  const openModal = useSelector((state) => state.position.openModal);
  const formData = useSelector((state) => state.position.formData);
  const dataOptionPosition = useSelector((state) => state.position.dataOptions);
  const dataOptionDepartment = useSelector(
    (state) => state.department.dataOptions
  );
  const [modalTitle, setModalTitle] = useState();

  useEffect(() => {
    dispatch(resetFormData());
    dispatch(getDataOptionPosition());
    dispatch(getDataOptionDepartment());

    if (formType === "create") {
      setModalTitle("Add Position");
      dispatch(setFormData({ name: "active", value: 1 }));
    } else {
      setModalTitle("Edit Position");
      dispatch(getFormData({ id: dataId }));
    }
  }, [dispatch, formType, dataId]);

  const handleInput = (e) => {
    dispatch(setFormData(e.target));
  };

  return (
    <>
      {!openPopup.open && openModal && (
        <ModalForm
          modalTitle={modalTitle}
          modalSize="small"
          closeModal={() => dispatch(setModal(false))}
        >
          <div className="w-full">
            <div className="form-group">
              <label className="control-label">
                Position Code <span className="text-danger">*</span>
              </label>
              <input
                id="position_code"
                title="Position Code"
                type="text"
                name="position_code"
                className="form-control"
                value={formData.position_code}
                onChange={handleInput}
                required
              />
            </div>
            <div className="form-group">
              <label className="control-label">
                Position Name <span className="text-danger">*</span>
              </label>
              <input
                id="position_name"
                title="Position Name"
                type="text"
                name="position_name"
                className="form-control"
                value={formData.position_name}
                onChange={handleInput}
                required
              />
            </div>
            <div className="form-group">
              <label className="control-label">Supervisor</label>
              <Select
                id="parent_id"
                title="Supervisor"
                name="parent_id"
                placeholder={"Choose..."}
                defaultValue={dataOptionPosition.filter(
                  (option) => option.value === formData.parent_id
                )}
                onChange={(e) =>
                  dispatch(setFormData({ name: "parent_id", value: e?.value }))
                }
                options={dataOptionPosition}
                required={false}
              />
            </div>
            <div className="form-group">
              <label className="control-label">
                Department <span className="text-danger">*</span>
              </label>
              <Select
                id="department_id"
                title="Department"
                name="department_id"
                placeholder={"Choose..."}
                defaultValue={dataOptionDepartment.filter(
                  (option) => option.value === formData.department_id
                )}
                onChange={(e) =>
                  dispatch(
                    setFormData({ name: "department_id", value: e?.value })
                  )
                }
                options={dataOptionDepartment}
                required={true}
              />
            </div>
            <div className="form-group">
              <label className="control-label">Active</label>
              <div className="form-checkbox">
                <input
                  id="active"
                  title="Active"
                  type="checkbox"
                  name="active"
                  checked={formData?.active?.toString() === "1" ? true : false}
                  onChange={(e) => {
                    if (e.target.checked) {
                      e.target.value = 1;
                    } else {
                      e.target.value = 0;
                    }
                    handleInput(e);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="mt-8 flex gap-2">
            <Button
              title="Save"
              icon="BiSave"
              color="primary"
              onClick={() => dispatch(submit({ formType: formType }))}
            />
            <Button
              title="Cancel"
              icon="BiX"
              color="secondary"
              onClick={() => dispatch(setModal(false))}
            />
          </div>
        </ModalForm>
      )}
    </>
  );
}

export default Form;
