import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/Button";
import {
  PageHeader,
  PageHeaderAction,
  PageHeaderTitle,
} from "../../../components/PageHeader";
import { CheckRoleAction, CheckRoleIndex } from "../../../helpers/CheckRole";
import IndexTable from "./indexTable";

function Index() {
  const pathName = "/prod/projects";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!CheckRoleIndex(pathName)) {
      navigate("/404");
    }
  }, [dispatch, navigate]);

  const onClickView = (id) => {
    navigate(`${pathName}/view/${id}`);
  };

  return (
    <>
      <PageHeader>
        <PageHeaderTitle>Projects</PageHeaderTitle>
        <PageHeaderAction>
          {CheckRoleAction(pathName, "create") && (
            <Button
              title="Add New"
              icon="BiPlus"
              color="primary"
              onClick={() => navigate(`${pathName}/create`)}
            />
          )}
        </PageHeaderAction>
      </PageHeader>
      <IndexTable onClickView={onClickView} />
    </>
  );
}

export default Index;
