import { createSlice } from "@reduxjs/toolkit";
import { InstanceApi } from "../../helpers/InstanceApi";
import { Validate } from "../../helpers/Validate";
import { setPopup } from "../popupSlice";

export const workflowDetailSlice = createSlice({
  name: "workflowDetail",
  initialState: {
    openModal: false,
    dataByWorkflow: undefined,
    dataOptions: [],
    dataTemporary: [],
    level: [
      { value: 1, label: 1 },
      { value: 2, label: 2 },
      { value: 3, label: 3 },
    ],
    requestFrom: [
      { value: "All", label: "All" },
      { value: "Employee", label: "Employee" },
      { value: "Position", label: "Position" },
    ],
    approvalBy: [
      { value: "Employee", label: "Employee" },
      { value: "Position", label: "Position" },
    ],
    formValidate: {
      level: { required: true, title: "Level" },
      request_from: { required: true, title: "Request From" },
      approval_by: { required: true, title: "Approval By" },
    },
    formData: {
      id: "",
      workflow_id: "",
      level: "",
      request_from: "",
      request_reference_id: "",
      approval_by: "",
      approval_reference_id: "",
      related_user: "",
      next_level: "",
      request_position_name: "",
      request_employee_name: "",
      approval_position_name: "",
      approval_employee_name: "",
    },
  },
  reducers: {
    setModal: (state, action) => {
      state.openModal = action.payload;
    },
    setDataTemporary: (state, action) => {
      state.dataTemporary = action.payload;
    },
    setDataByWorkflow: (state, action) => {
      state.dataByWorkflow = action.payload;
    },
    setDataOptions: (state, action) => {
      state.dataOptions = action.payload;
    },
    setFormData: (state, action) => {
      state.formData[action.payload.name] = action.payload.value;
    },
  },
});

export const resetFormData = () => async (dispatch, getState) => {
  const formData = getState().workflowDetail.formData;
  Object.keys(formData).forEach((key) => {
    dispatch(setFormData({ name: key, value: "" }));
  });
};

export const getDataByWorkflow = (params) => async (dispatch) => {
  try {
    dispatch(setDataByWorkflow(undefined));
    dispatch(setDataTemporary([]));
    const response = await InstanceApi.get(
      "/wf/workflows/" + params.workflowId + "/detail"
    );
    if (response) {
      dispatch(setDataByWorkflow(response.data.data));
      dispatch(setDataTemporary(response.data.data));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(error);
  }
};

export const submit = () => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "processing" }));
    const formData = getState().workflowDetail.formData;
    const validate = Validate(formData);

    if (!validate.isValid) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: validate.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
    } else {
      const response = await InstanceApi.post("/wf/workflows/detail", formData);
      if (response) {
        dispatch(
          setPopup({
            open: true,
            type: "success",
            message: response.data.message,
            handleOk: () => {
              dispatch(setPopup({ open: false }));
              dispatch(setModal(false));
              dispatch(resetFormData());
              dispatch(getDataByWorkflow({ workflowId: formData.workflow_id }));
            },
          })
        );
      }
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(error);
  }
};

export const deleteData = (params) => (dispatch, getState) => {
  try {
    const dataWorkflow = getState().workflow.dataView;
    dispatch(
      setPopup({
        open: true,
        type: "delete",
        message: "Are you sure to delete this from MO?",
        handleOk: async () => {
          dispatch(setPopup({ open: true, type: "processing" }));

          try {
            const response = await InstanceApi.delete(
              "/wf/workflows/detail/" + params.id
            );
            if (response) {
              dispatch(
                setPopup({
                  open: true,
                  type: "success",
                  message: response.data.message,
                  handleOk: () => {
                    dispatch(setPopup({ open: false }));
                    dispatch(getDataByWorkflow({ id: dataWorkflow.id }));
                  },
                })
              );
            }
          } catch (error) {
            dispatch(
              setPopup({
                open: true,
                type: "error",
                message: error.message,
                handleOk: () => {
                  dispatch(setPopup({ open: false }));
                },
              })
            );
            console.error(error);
          }
        },
        handleCancel: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(error);
  }
};

export const {
  setModal,
  setDataTemporary,
  setDataOptions,
  setDataByWorkflow,
  setFormData,
} = workflowDetailSlice.actions;
export default workflowDetailSlice.reducer;
