import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PageHeader, PageHeaderTitle } from "../../components/PageHeader";

import { CheckRoleIndex } from "../../helpers/CheckRole";
import {
  Card,
  CardAction,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/Card";
import { Button } from "../../components/Button";
import { setPopup } from "../../stores/popupSlice";
import { InstanceApi } from "../../helpers/InstanceApi";
import { DownloadFile } from "../../helpers/DownloadFile";
import Select from "../../components/Select";
import MultiSelectText from "../../components/MultiSelectText";

function Index() {
  const pathName = "/reports/log-projects-tasks";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dataTable, setDataTable] = useState();
  const [formData, setFormData] = useState({
    begin_date: "",
    end_date: "",
    module_name: "",
    log_type: "",
  });

  const [openFilter, setOpenFilter] = useState(false);

  const [inputProjectNo, setInputProjectNo] = useState("");
  const [projectNo, setProjectNo] = useState([]);
  const [inputObjectName, setInputObjectName] = useState("");
  const [objectName, setObjectName] = useState([]);

  const createOption = (label) => ({
    label,
    value: label,
  });

  const resetFilter = () => {
    setInputProjectNo("");
    setProjectNo([]);
    setInputObjectName("");
    setObjectName([]);
    setFormData({ ...formData, module_name: "", log_type: "" });

    console.log(formData)
  };

  const handleKeyDown = (event = React.KeyboardEventHandler, field) => {
    switch (event.key) {
      case "Enter":
      case "Tab":
        switch (field) {
          case "projectNo":
            if (!inputProjectNo) return;
            setProjectNo((prev) => [...prev, createOption(inputProjectNo)]);
            setInputProjectNo("");
            break;
          case "objectName":
            if (!inputObjectName) return;
            setObjectName((prev) => [...prev, createOption(inputObjectName)]);
            setInputObjectName("");
            break;
          default:
            break;
        }
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!CheckRoleIndex(pathName)) {
      navigate("/404");
    }
  }, [dispatch, navigate]);

  const createFormSubmit = () => {
    let formSubmit = {
      begin_date: formData.begin_date,
      end_date: formData.end_date,
      module_name: "",
      project_no: "",
      object_name: "",
      log_type: "",
    };

    if (formData.module_name.length > 0) {
      var array_module_name = [];
      formData.module_name.map((item, _) => {
        array_module_name.push(item.value);
      });
      formSubmit.module_name = array_module_name.join("|");
    }

    if (projectNo.length > 0) {
      var array_project_no = [];
      projectNo.map((item, _) => {
        array_project_no.push(item.value);
      });
      formSubmit.project_no = array_project_no.join("|");
    }

    if (objectName.length > 0) {
      var array_object_name = [];
      objectName.map((item, _) => {
        array_object_name.push(item.value);
      });
      formSubmit.object_name = array_object_name.join("|");
    }

    if (formData.log_type.length > 0) {
      var array_log_type = [];
      formData.log_type.map((item, _) => {
        array_log_type.push(item.value);
      });
      formSubmit.log_type = array_log_type.join("|");
    }

    return formSubmit;
  };

  const generateReport = async (e) => {
    e.preventDefault();

    setOpenFilter(false);

    try {
      dispatch(setPopup({ open: true, type: "processing" }));
      const response = await InstanceApi.post(
        "/reports/generate-log-projects-tasks",
        createFormSubmit()
      );
      if (response) {
        dispatch(setPopup({ open: false }));
        setDataTable(response.data.data);
      }
    } catch (err) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: err.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
      console.error(err.response.data.message);
    }
  };

  const exportReport = async (e) => {
    e.preventDefault();

    try {
      dispatch(setPopup({ open: true, type: "processing" }));
      const response = await InstanceApi.post(
        "/reports/export-log-projects-tasks",
        createFormSubmit()
      );
      if (response) {
        dispatch(setPopup({ open: false }));

        dispatch(setPopup({ open: true, type: "download" }));

        const downloadUrl = "/download?file_path=" + response.data.data;
        console.log(downloadUrl);
        DownloadFile(downloadUrl);

        setTimeout(() => {
          dispatch(setPopup({ open: false }));
        }, 3000);
      }
    } catch (err) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: err.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
      console.error(err.response.data.message);
    }
  };

  return (
    <>
      <PageHeader>
        <PageHeaderTitle>Log Project and Task</PageHeaderTitle>
      </PageHeader>
      <Card>
        <div
          className="cursor-pointer"
          onClick={() => setOpenFilter(!openFilter)}
        >
          <CardHeader>
            <CardTitle>Report Filter</CardTitle>
            <CardAction>
              <span className="px-2 text-xl font-bold cursor-pointer">
                {openFilter ? "-" : "+"}
              </span>
            </CardAction>
          </CardHeader>
        </div>
        {openFilter && (
          <>
            <CardBody>
              <div className="grid grid-cols-4 items-center gap-4">
                <div className="form-group">
                  <label className="control-label">Begin Date</label>
                  <input
                    id="begin_date"
                    title="Begin Date"
                    type="date"
                    name="begin_date"
                    className="form-control"
                    value={formData.begin_date}
                    onChange={(e) =>
                      setFormData({ ...formData, begin_date: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">End Date</label>
                  <input
                    id="end_date"
                    title="End Date"
                    type="date"
                    name="end_date"
                    className="form-control"
                    value={formData.end_date}
                    onChange={(e) =>
                      setFormData({ ...formData, end_date: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="form-group">
                  <label className="control-label">Module Name</label>
                  <Select
                    placeholder={"Choose Module Name..."}
                    className="basic-multi-select"
                    isMulti
                    options={[
                      { label: "Project", value: "Project" },
                      { label: "Task", value: "Task" },
                    ]}
                    value={formData.module_name}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        module_name: e,
                      });
                    }}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">Project No</label>
                  <MultiSelectText
                    inputValue={inputProjectNo}
                    onChange={(newValue) => setProjectNo(newValue)}
                    onInputChange={(newValue) => setInputProjectNo(newValue)}
                    onKeyDown={(e) => handleKeyDown(e, "projectNo")}
                    value={projectNo}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">Object Name</label>
                  <MultiSelectText
                    inputValue={inputObjectName}
                    onChange={(newValue) => setObjectName(newValue)}
                    onInputChange={(newValue) => setInputObjectName(newValue)}
                    onKeyDown={(e) => handleKeyDown(e, "objectName")}
                    value={objectName}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">Log Type</label>
                  <Select
                    placeholder={"Choose Log Type..."}
                    className="basic-multi-select"
                    isMulti
                    value={formData.log_type}
                    options={[
                      { label: "New Project", value: "New" },
                      { label: "Finish Project", value: "Finish" },
                      { label: "Cancel Project", value: "Cancel" },
                      {
                        label: "Approve Start Project",
                        value: "Approve Start",
                      },
                      { label: "Reject Start Project", value: "Reject Start" },
                      {
                        label: "Approve Finish Project",
                        value: "Approve Finish",
                      },
                      {
                        label: "Reject Finish Project",
                        value: "Reject Finish",
                      },
                      {
                        label: "Approve Cancel Project",
                        value: "Approve Cancel",
                      },
                      {
                        label: "Reject Cancel Project",
                        value: "Reject Cancel",
                      },
                      { label: "Assign Task", value: "Assign" },
                      { label: "Start Task", value: "Start" },
                      { label: "Finish Task", value: "Finish" },
                      { label: "Approve Task", value: "Approve" },
                      { label: "Reject Task", value: "Reject" },
                    ]}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        log_type: e,
                      });
                    }}
                  />
                </div>
              </div>
            </CardBody>
            <CardFooter>
              <div className="flex flex-row items-center gap-2">
                <>
                  <Button
                    title="Generate Report"
                    icon="BiCog"
                    color="primary"
                    onClick={generateReport}
                  />
                  <Button
                    title="Reset"
                    icon="BiReset"
                    color="secondary"
                    onClick={resetFilter}
                  />
                </>
              </div>
            </CardFooter>
          </>
        )}
      </Card>
      <div className="mt-4"></div>
      {dataTable && (
        <Card>
          <CardHeader>
            <CardAction>
              <Button
                title="Download Report"
                icon="BiDownload"
                color="primary"
                onClick={exportReport}
              />
            </CardAction>
          </CardHeader>
          <CardBody>
            <div className="w-full max-h-[700px] overflow-auto">
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ minWidth: "50px" }}>NO</th>
                    <th style={{ minWidth: "150px" }}>CREATED DATE</th>
                    <th style={{ minWidth: "150px" }}>CREATED BY</th>
                    <th style={{ minWidth: "150px" }}>MODULE</th>
                    <th style={{ minWidth: "150px" }}>PROJECT NO</th>
                    <th style={{ minWidth: "150px" }}>OBJECT NAME</th>
                    <th style={{ minWidth: "150px" }}>LOG TYPE</th>
                    <th style={{ minWidth: "150px" }}>DESCRIPTION</th>
                    <th style={{ minWidth: "150px" }}>NOTES</th>
                  </tr>
                </thead>
                <tbody>
                  {dataTable &&
                    dataTable.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.created_at}</td>
                        <td>{item.created_by}</td>
                        <td>{item.module_name}</td>
                        <td>{item.project_no}</td>
                        <td>{item.object_name}</td>
                        <td>{item.log_type}</td>
                        <td>{item.descriptions}</td>
                        <td>{item.notes}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
}

export default Index;
