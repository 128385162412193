import React, { useEffect, useState } from "react";
import styles from "./../../../styles/DataTable.module.css";
import { BiSearch, BiSearchAlt, BiFilterAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getDataTables } from "../../../stores/pm/taskSlice";
import { Button } from "../../../components/Button";
import ModalForm from "../../../components/ModalForm";
import MultiSelectText from "../../../components/MultiSelectText";
import { Link } from "react-router-dom";
import Select from "../../../components/Select";
import DataTable2 from "../../../components/DataTable2";

export default function IndexTable() {
  const dispatch = useDispatch();
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [formFilter, setFormFilter] = useState({
    begin_date: "",
    end_date: "",
    status: [],
    inputTaskNo: "",
    task_no: [],
    inputTaskName: "",
    task_name: [],
    inputProjectNo: "",
    project_no: [],
    inputProjectName: "",
    project_name: [],
    inputDepartmentName: "",
    department_name: [],
    inputObserverName: "",
    observer_name: [],
    inputResponsibleName: "",
    responsible_name: [],
  });
  const [filterData, setFilterData] = useState();

  const dataOptionStatus = [
    { value: "Open", label: "Open" },
    { value: "In Progress", label: "In Progress" },
    { value: "Finish", label: "Finish" },
    { value: "Completed", label: "Completed" },
  ];

  const dataTables = useSelector((state) => state.task.dataTables);
  const [showEntries, setShowEntries] = useState(100);
  const [dataTableSearch, setDataTableSearch] = useState("");
  const [tableBody, setTableBody] = useState(dataTables);

  useEffect(() => {
    setTableBody(dataTables?.data);
  }, [dataTables]);

  // DATATABLES
  const columns = [
    {
      header: "Task No",
      assessor: "task_no",
      Cell: (row) => {
        return (
          <div className="flex flex-col gap-1">
            <Link
              to={`${window.location}/${row.id}`}
              className="text-link hover:text-link-hover cursor-pointer"
            >
              #{row.task_no}
            </Link>
            {row.urgent === 1 && (
              <span className="w-fit py-1 px-2 bg-warning text-xs text-white rounded-full">
                Urgent
              </span>
            )}
          </div>
        );
      },
    },
    {
      header: "Task Name",
      assessor: "task_name",
      Cell: (row) => {
        return (
          <Link
            to={`${window.location}/${row.id}`}
            className="text-link hover:text-link-hover cursor-pointer"
          >
            {row.task_name}
          </Link>
        );
      },
    },
    {
      header: "Project Name",
      assessor: "project_name",
      Cell: (row) => {
        return (
          <div className="flex flex-col gap-1">
            <div className="font-semibold">#{row.project_no}</div>
            <div className="text-sm">{row.project_name}</div>
          </div>
        );
      },
    },
    {
      header: "Department",
      assessor: "department_name",
    },
    {
      header: "Observer",
      assessor: "observer_name",
    },
    {
      header: "Responsible",
      assessor: "responsible_name",
    },
    {
      header: "Task Date",
      Cell: (row) => {
        return (
          <div className="flex flex-col gap-1">
            <span className="block whitespace-nowrap">
              {row.begin_date_convert}
            </span>
            to
            <span className="block whitespace-nowrap">
              {row.end_date_convert}
            </span>
          </div>
        );
      },
    },
    {
      header: "Status",
      assessor: "status",
      tdStyle: { width: "5%" },
      Cell: (row) => {
        return (
          <>
            <div
              className={`block badge badge-${row.status.replace(/\s/g, "")}`}
            >
              {row.status}
            </div>

            {row.status === "Open" && row.responsible_name === null && (
              <div className="mt-1 block badge badge-info">
                Waiting To Assign
              </div>
            )}
            {row.status === "Open" && row.responsible_name !== null && (
              <div className="mt-1 block badge badge-info">
                Waiting To Start
              </div>
            )}
            {row.status === "In Progress" && (
              <div className="mt-1 block badge badge-info">
                Waiting To Finish
              </div>
            )}
            {row.approval_id && (
              <div className="mt-1 block badge badge-info">
                Waiting Approval
              </div>
            )}
          </>
        );
      },
    },
    {
      header: "Actions",
      tdStyle: { width: "5%" },
      Cell: (row) => {
        return (
          <div className="flex flex-row items-center justify-end gap-2">
            <Link to={`${window.location}/${row.id}`} className="btn-action">
              <BiSearchAlt size="22" />
            </Link>
          </div>
        );
      },
    },
  ];

  const handleChangeShowEntries = (show) => {
    setShowEntries(show);
    dispatch(getDataTables({ limit: show, page: 1, search: dataTableSearch, ...filterData }));
  };

  const handleChangePage = (page) => {
    dispatch(
      getDataTables({
        limit: showEntries,
        page: page,
        search: dataTableSearch,
        ...filterData,
      })
    );
  };

  useEffect(() => {
    if (dataTableSearch) {
      const timeOutId = setTimeout(
        () =>
          dispatch(
            getDataTables({
              limit: showEntries,
              page: 1,
              search: dataTableSearch,
              ...filterData,
            })
          ),
        1000
      );
      return () => clearTimeout(timeOutId);
    } else {
      dispatch(
        getDataTables({
          limit: showEntries,
          page: 1,
          search: dataTableSearch,
          ...filterData,
        })
      );
    }
  }, [dispatch, dataTableSearch]);

  // FILTER
  const createOption = (label) => ({
    label,
    value: label,
  });

  const onChangeFilter = (fieldName, value) => {
    setFormFilter({ ...formFilter, [fieldName]: value });
  };

  const handleResetFilter = () => {
    setFormFilter({
      begin_date: "",
      end_date: "",
      status: [],
      inputProjectNo: "",
      project_no: [],
      inputProjectName: "",
      project_name: [],
      inputDepartmentName: "",
      department_name: [],
      inputObserverName: "",
      observer_name: [],
    });
    setFilterData(undefined)
    setFiltered(false);
  };

  const handleKeyDown = (
    event = React.KeyboardEventHandler,
    inputName,
    fieldName
  ) => {
    switch (event.key) {
      case "Enter":
      case "Tab":
        if (!formFilter[inputName]) return;
        setFormFilter({
          ...formFilter,
          [fieldName]: [
            ...formFilter[fieldName],
            createOption(formFilter[inputName]),
          ],
          [inputName]: "",
        });
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const applyFilter = () => {
    let formSubmit = {
      begin_date: formFilter.begin_date,
      end_date: formFilter.end_date,
    };
    for (const key in formFilter) {
      if (!["begin_date", "end_date"].includes(key)) {
        if (Array.isArray(formFilter[key])) {
          if (formFilter[key]?.length > 0) {
            var new_aray = [];
            formFilter[key].map((item, _) => {
              new_aray.push(item.value);
            });
            formSubmit[key] = new_aray.join("|");
          }
        } else {
          if (formFilter[key] !== "") {
            formSubmit[key] = formFilter[key];
          }
        }
      }
    }

    setOpenFilterModal(false);
    setFilterData(formSubmit)
    dispatch(
      getDataTables({
        limit: showEntries,
        page: 1,
        search: dataTableSearch,
        ...formSubmit,
      })
    );

    for (const key in formSubmit) {
      if (formSubmit[key] !== "") {
        setFiltered(true);
      }
    }
  };

  return (
    <>
      {openFilterModal && (
        <ModalForm
          modalTitle="Filter"
          modalSize="medium"
          closeModal={() => setOpenFilterModal(false)}
        >
          <div className="grid grid-cols-4 items-center gap-4">
            <div className="form-group">
              <label className="control-label">Begin Date</label>
              <input
                id="begin_date"
                title="Begin Date"
                type="date"
                name="begin_date"
                className="form-control"
                value={formFilter.begin_date}
                onChange={(e) =>
                  setFormFilter({ ...formFilter, begin_date: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label className="control-label">End Date</label>
              <input
                id="end_date"
                title="End Date"
                type="date"
                name="end_date"
                className="form-control"
                value={formFilter.end_date}
                onChange={(e) =>
                  setFormFilter({ ...formFilter, end_date: e.target.value })
                }
              />
            </div>
          </div>
          <div className="w-full">
            <div className="form-group">
              <label className="control-label">Task No</label>
              <MultiSelectText
                inputValue={formFilter.inputTaskNo}
                onChange={(newValue) => onChangeFilter("task_no", newValue)}
                onInputChange={(newValue) =>
                  onChangeFilter("inputTaskNo", newValue)
                }
                onKeyDown={(e) => handleKeyDown(e, "inputTaskNo", "task_no")}
                value={formFilter.task_no}
              />
            </div>
            <div className="form-group">
              <label className="control-label">Task Name</label>
              <MultiSelectText
                inputValue={formFilter.inputTaskName}
                onChange={(newValue) => onChangeFilter("task_name", newValue)}
                onInputChange={(newValue) =>
                  onChangeFilter("inputTaskName", newValue)
                }
                onKeyDown={(e) =>
                  handleKeyDown(e, "inputTaskName", "task_name")
                }
                value={formFilter.task_name}
              />
            </div>
            <div className="form-group">
              <label className="control-label">Project No</label>
              <MultiSelectText
                inputValue={formFilter.inputProjectNo}
                onChange={(newValue) => onChangeFilter("project_no", newValue)}
                onInputChange={(newValue) =>
                  onChangeFilter("inputProjectNo", newValue)
                }
                onKeyDown={(e) =>
                  handleKeyDown(e, "inputProjectNo", "project_no")
                }
                value={formFilter.project_no}
              />
            </div>
            <div className="form-group">
              <label className="control-label">Project Name</label>
              <MultiSelectText
                inputValue={formFilter.inputProjectName}
                onChange={(newValue) =>
                  onChangeFilter("project_name", newValue)
                }
                onInputChange={(newValue) =>
                  onChangeFilter("inputProjectName", newValue)
                }
                onKeyDown={(e) =>
                  handleKeyDown(e, "inputProjectName", "project_name")
                }
                value={formFilter.project_name}
              />
            </div>
            <div className="form-group">
              <label className="control-label">Department</label>
              <MultiSelectText
                inputValue={formFilter.inputDepartmentName}
                onChange={(newValue) =>
                  onChangeFilter("department_name", newValue)
                }
                onInputChange={(newValue) =>
                  onChangeFilter("inputDepartmentName", newValue)
                }
                onKeyDown={(e) =>
                  handleKeyDown(e, "inputDepartmentName", "department_name")
                }
                value={formFilter.department_name}
              />
            </div>
            <div className="form-group">
              <label className="control-label">Observer</label>
              <MultiSelectText
                inputValue={formFilter.inputObserverName}
                onChange={(newValue) =>
                  onChangeFilter("observer_name", newValue)
                }
                onInputChange={(newValue) =>
                  onChangeFilter("inputObserverName", newValue)
                }
                onKeyDown={(e) =>
                  handleKeyDown(e, "inputObserverName", "observer_name")
                }
                value={formFilter.observer_name}
              />
            </div>
            <div className="form-group">
              <label className="control-label">Responsible</label>
              <MultiSelectText
                inputValue={formFilter.inputResponsibleName}
                onChange={(newValue) =>
                  onChangeFilter("responsible_name", newValue)
                }
                onInputChange={(newValue) =>
                  onChangeFilter("inputResponsibleName", newValue)
                }
                onKeyDown={(e) =>
                  handleKeyDown(e, "inputResponsibleName", "responsible_name")
                }
                value={formFilter.responsible_name}
              />
            </div>
            <div className="form-group">
              <label className="control-label">Status</label>
              <Select
                placeholder={"Choose Reference Project..."}
                className="basic-multi-select"
                isMulti
                options={dataOptionStatus}
                value={formFilter.status}
                onChange={(e) => {
                  var array = [];
                  e.map((item, _) => {
                    array.push({ label: item.value, value: item.value });
                  });
                  setFormFilter({
                    ...formFilter,
                    status: array,
                  });
                }}
              />
            </div>
          </div>

          <div className="mt-8 flex gap-2">
            <Button
              title="Apply"
              icon="BiCheck"
              color="primary"
              onClick={applyFilter}
            />
            <Button
              title="Reset"
              icon="BiX"
              color="secondary"
              onClick={handleResetFilter}
            />
          </div>
        </ModalForm>
      )}

      <div className={styles.dataTable_Box}>
        <div className={styles.dataTable_Header}>
          <div className={styles.dataTable_Search}>
            <div>
              <BiSearch />
            </div>
            <input
              type="search"
              placeholder="Search..."
              onChange={(e) => setDataTableSearch(e.target.value)}
            />
          </div>
          <div className="relative">
            <button
              type="button"
              className="btn-icon btn-icon-md btn-primary"
              onClick={() => setOpenFilterModal(true)}
            >
              <BiFilterAlt />
            </button>
            {filtered && (
              <div className="absolute -top-1 -right-1 h-3 w-3 rounded-full bg-danger"></div>
            )}
          </div>
        </div>
        <DataTable2
          columns={columns}
          tableBody={tableBody}
          dataTables={dataTables}
          handleChangeShowEntries={handleChangeShowEntries}
          showEntries={showEntries}
          handleChangePage={handleChangePage}
        />
      </div>
    </>
  );
}
