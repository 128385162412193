import React, { useEffect } from "react";
import {
  PageHeader,
  PageHeaderAction,
  PageHeaderTitle,
} from "../../../components/PageHeader";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import { Button } from "../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { getFormData, setFormData, submit } from "../../../stores/email/emailSmtpSlice";
import { CheckRoleAction } from "../../../helpers/CheckRole";

export default function Index() {
  const pathName = "/email/smtp";
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.emailSmtp.formData);

  useEffect(() => {
    dispatch(getFormData({ id: 1 }));
  }, [dispatch]);

  const handleInput = (e) => {
    dispatch(setFormData({ name: e.target.name, value: e.target.value }));
  };

  return (
    <>
      <PageHeader>
        <PageHeaderTitle>SMTP Configuration</PageHeaderTitle>
        <PageHeaderAction>
          {CheckRoleAction(pathName, "edit") && (
            <Button
              title="Update"
              icon="BiSave"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                dispatch(submit());
              }}
            />
          )}
        </PageHeaderAction>
      </PageHeader>

      <Card>
        <CardBody>
          <div className="w-full md:w-1/3">
            <div className="form-group">
              <label className="control-label">
                SMTP Host <span className="text-danger">*</span>
              </label>
              <input
                id="smtp_host"
                title="SMTP Host"
                type="text"
                name="smtp_host"
                className="form-control"
                value={formData.smtp_host}
                onChange={handleInput}
              />
            </div>
            <div className="form-group">
              <label className="control-label">
                SMTP Port <span className="text-danger">*</span>
              </label>
              <input
                id="smtp_port"
                title="SMTP Port"
                type="text"
                name="smtp_port"
                className="form-control"
                value={formData.smtp_port}
                onChange={handleInput}
              />
            </div>
            <div className="form-group">
              <label className="control-label">
                SMTP Username <span className="text-danger">*</span>
              </label>
              <input
                id="smtp_username"
                title="SMTP Username"
                type="text"
                name="smtp_username"
                className="form-control"
                value={formData.smtp_username}
                onChange={handleInput}
              />
            </div>
            <div className="form-group">
              <label className="control-label">
                SMTP Password <span className="text-danger">*</span>
              </label>
              <input
                id="smtp_password"
                title="SMTP Password"
                type="text"
                name="smtp_password"
                className="form-control"
                value={formData.smtp_password}
                onChange={handleInput}
              />
            </div>
            <div className="form-group">
              <label className="control-label">
                SMTP Encryption <span className="text-danger">*</span>
              </label>
              <input
                id="smtp_encryption"
                title="SMTP Encryption"
                type="text"
                name="smtp_encryption"
                className="form-control"
                value={formData.smtp_encryption}
                onChange={handleInput}
              />
            </div>
            <div className="form-group !mb-0">
              <label className="control-label">
                From Name <span className="text-danger">*</span>
              </label>
              <input
                id="from_name"
                title="From Name"
                type="text"
                name="from_name"
                className="form-control"
                value={formData.from_name}
                onChange={handleInput}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
