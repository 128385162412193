import React, { useEffect, useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DataTable from "../../../components/DataTable";
import { PageHeader, PageHeaderTitle } from "../../../components/PageHeader";

import { getDataTables } from "../../../stores/wf/workflowSlice";
import LoadingTable from "./loadingTable";

function Index() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.workflow.dataTables);
  const [dataTables, setDataTables] = useState(data);

  useEffect(() => {
    dispatch(getDataTables());
  }, [dispatch]);

  useEffect(() => {
    setDataTables(data);
  }, [data]);

  const onClickEdit = (id) => {
    navigate(`/wf/workflows/edit/${id}`);
  };

  // datatable columns
  const columns = [
    {
      header: "Object Name",
      assessor: "object",
    },
    {
      header: "Object Type",
      assessor: "type",
    },
    {
      header: "Actions",
      tdStyle: { width: "5%" },
      Cell: (row) => {
        return (
          <div className="flex flex-row items-center justify-end gap-2">
            <span
              className="text-info cursor-pointer"
              onClick={() => onClickEdit(row.id)}
            >
              <BiEditAlt size="22" />
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {!dataTables && <LoadingTable />}
      {dataTables && (
        <>
          <PageHeader>
            <PageHeaderTitle>Workflows</PageHeaderTitle>
          </PageHeader>
          <DataTable
            columns={columns}
            data={dataTables}
            options={{
              sorting: true,
              searching: true,
              bulkAction: false,
            }}
            bulkAction={{}}
            filter={[]}
          />
        </>
      )}
    </>
  );
}

export default Index;
