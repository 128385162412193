import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PageHeader, PageHeaderTitle } from "../../components/PageHeader";

import { CheckRoleIndex } from "../../helpers/CheckRole";
import {
  Card,
  CardAction,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/Card";
import { Button } from "../../components/Button";
import { setPopup } from "../../stores/popupSlice";
import { InstanceApi } from "../../helpers/InstanceApi";
import { DownloadFile } from "../../helpers/DownloadFile";
import Select from "../../components/Select";
import MultiSelectText from "../../components/MultiSelectText";

function Index() {
  const pathName = "/reports/projects";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dataTable, setDataTable] = useState();
  const [formData, setFormData] = useState({
    begin_date: "",
    end_date: "",
    status: "",
  });

  const [openFilter, setOpenFilter] = useState(false);
  const dataOptionStatus = [
    { value: "In Progress", label: "In Progress" },
    { value: "Finish", label: "Finish" },
    { value: "Completed", label: "Completed" },
    { value: "Canceled", label: "Canceled" },
  ];

  const [inputProjectNo, setInputProjectNo] = useState("");
  const [projectNo, setProjectNo] = useState([]);
  const [inputProjectName, setInputProjectName] = useState("");
  const [projectName, setProjectName] = useState([]);
  const [inputClientName, setInputClientName] = useState("");
  const [clientName, setClientName] = useState([]);
  const [inputItemNo, setInputItemNo] = useState("");
  const [itemNo, setItemNo] = useState([]);
  const [inputProductName, setInputProductName] = useState("");
  const [productName, setProductName] = useState([]);
  const [inputProgramName, setInputProgramName] = useState("");
  const [programName, setProgramName] = useState([]);
  const [inputStationName, setInputStationName] = useState("");
  const [stationName, setStationName] = useState([]);

  const createOption = (label) => ({
    label,
    value: label,
  });

  const resetFilter = () => {
    setInputProjectNo("");
    setProjectNo([]);
    setInputProjectName("");
    setProjectName([]);
    setInputClientName("");
    setClientName([]);
    setInputItemNo("");
    setItemNo([]);
    setInputProductName("");
    setProductName([]);
    setInputProgramName("");
    setProgramName([]);
    setInputStationName("");
    setStationName([]);
  };

  const handleKeyDown = (event = React.KeyboardEventHandler, field) => {
    switch (event.key) {
      case "Enter":
      case "Tab":
        switch (field) {
          case "projectNo":
            if (!inputProjectNo) return;
            setProjectNo((prev) => [...prev, createOption(inputProjectNo)]);
            setInputProjectNo("");
            break;
          case "projectName":
            if (!inputProjectName) return;
            setProjectName((prev) => [...prev, createOption(inputProjectName)]);
            setInputProjectName("");
            break;
          case "clientName":
            if (!inputClientName) return;
            setClientName((prev) => [...prev, createOption(inputClientName)]);
            setInputClientName("");
            break;
          case "itemNo":
            if (!inputItemNo) return;
            setItemNo((prev) => [...prev, createOption(inputItemNo)]);
            setInputItemNo("");
            break;
          case "productName":
            if (!inputProductName) return;
            setProductName((prev) => [...prev, createOption(inputProductName)]);
            setInputProductName("");
            break;
          case "programName":
            if (!inputProgramName) return;
            setProgramName((prev) => [...prev, createOption(inputProgramName)]);
            setInputProgramName("");
            break;
          case "stationName":
            if (!inputStationName) return;
            setStationName((prev) => [...prev, createOption(inputStationName)]);
            setInputStationName("");
            break;
          default:
            break;
        }
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!CheckRoleIndex(pathName)) {
      navigate("/404");
    }
  }, [dispatch, navigate]);

  const createFormSubmit = () => {
    let formSubmit = {
      begin_date: formData.begin_date,
      end_date: formData.end_date,
      project_no: "",
      project_name: "",
      client_name: "",
      item_no: "",
      product_name: "",
      program_name: "",
      station_name: "",
      status: "",
    };

    if (projectNo.length > 0) {
      var array_project_no = [];
      projectNo.map((item, _) => {
        array_project_no.push(item.value);
      });
      formSubmit.project_no = array_project_no.join("|");
    }

    if (projectName.length > 0) {
      var array_project_name = [];
      projectName.map((item, _) => {
        array_project_name.push(item.value);
      });
      formSubmit.project_name = array_project_name.join("|");
    }

    if (clientName.length > 0) {
      var array_client_name = [];
      clientName.map((item, _) => {
        array_client_name.push(item.value);
      });
      formSubmit.client_name = array_client_name.join("|");
    }

    if (itemNo.length > 0) {
      var array_item_no = [];
      itemNo.map((item, _) => {
        array_item_no.push(item.value);
      });
      formSubmit.item_no = array_item_no.join("|");
    }

    if (productName.length > 0) {
      var array_product_name = [];
      productName.map((item, _) => {
        array_product_name.push(item.value);
      });
      formSubmit.product_name = array_product_name.join("|");
    }

    if (programName.length > 0) {
      var array_program_name = [];
      programName.map((item, _) => {
        array_program_name.push(item.value);
      });
      formSubmit.program_name = array_program_name.join("|");
    }

    if (stationName.length > 0) {
      var array_station_name = [];
      stationName.map((item, _) => {
        array_station_name.push(item.value);
      });
      formSubmit.station_name = array_station_name.join("|");
    }

    if (formData.status.length > 0) {
      formSubmit.status = formData.status.join("|");
    }

    return formSubmit;
  };

  const generateReport = async (e) => {
    e.preventDefault();

    setOpenFilter(false);

    try {
      dispatch(setPopup({ open: true, type: "processing" }));
      const response = await InstanceApi.post(
        "/reports/generate-projects",
        createFormSubmit()
      );
      if (response) {
        dispatch(setPopup({ open: false }));
        setDataTable(response.data.data);
      }
    } catch (err) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: err.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
      console.error(err.response.data.message);
    }
  };

  const exportReport = async (e) => {
    e.preventDefault();

    try {
      dispatch(setPopup({ open: true, type: "processing" }));
      const response = await InstanceApi.post(
        "/reports/export-projects",
        createFormSubmit()
      );
      if (response) {
        dispatch(setPopup({ open: false }));

        dispatch(setPopup({ open: true, type: "download" }));

        const downloadUrl = "/download?file_path=" + response.data.data;
        DownloadFile(downloadUrl);

        setTimeout(() => {
          dispatch(setPopup({ open: false }));
        }, 3000);
      }
    } catch (err) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: err.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
      console.error(err.response.data.message);
    }
  };

  return (
    <>
      <PageHeader>
        <PageHeaderTitle>Project Report</PageHeaderTitle>
      </PageHeader>
      <Card>
        <div
          className="cursor-pointer"
          onClick={() => setOpenFilter(!openFilter)}
        >
          <CardHeader>
            <CardTitle>Report Filter</CardTitle>
            <CardAction>
              <span className="px-2 text-xl font-bold cursor-pointer">
                {openFilter ? "-" : "+"}
              </span>
            </CardAction>
          </CardHeader>
        </div>
        {openFilter && (
          <>
            <CardBody>
              <div className="grid grid-cols-4 items-center gap-4">
                <div className="form-group">
                  <label className="control-label">Begin Date</label>
                  <input
                    id="begin_date"
                    title="Begin Date"
                    type="date"
                    name="begin_date"
                    className="form-control"
                    value={formData.begin_date}
                    onChange={(e) =>
                      setFormData({ ...formData, begin_date: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">End Date</label>
                  <input
                    id="end_date"
                    title="End Date"
                    type="date"
                    name="end_date"
                    className="form-control"
                    value={formData.end_date}
                    onChange={(e) =>
                      setFormData({ ...formData, end_date: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="form-group">
                  <label className="control-label">Project No</label>
                  <MultiSelectText
                    inputValue={inputProjectNo}
                    onChange={(newValue) => setProjectNo(newValue)}
                    onInputChange={(newValue) => setInputProjectNo(newValue)}
                    onKeyDown={(e) => handleKeyDown(e, "projectNo")}
                    value={projectNo}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">Project Name</label>
                  <MultiSelectText
                    inputValue={inputProjectName}
                    onChange={(newValue) => setProjectName(newValue)}
                    onInputChange={(newValue) => setInputProjectName(newValue)}
                    onKeyDown={(e) => handleKeyDown(e, "projectName")}
                    value={projectName}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">Client</label>
                  <MultiSelectText
                    inputValue={inputClientName}
                    onChange={(newValue) => setClientName(newValue)}
                    onInputChange={(newValue) => setInputClientName(newValue)}
                    onKeyDown={(e) => handleKeyDown(e, "clientName")}
                    value={clientName}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">Item No</label>
                  <MultiSelectText
                    inputValue={inputItemNo}
                    onChange={(newValue) => setItemNo(newValue)}
                    onInputChange={(newValue) => setInputItemNo(newValue)}
                    onKeyDown={(e) => handleKeyDown(e, "itemNo")}
                    value={itemNo}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">Product</label>
                  <MultiSelectText
                    inputValue={inputProductName}
                    onChange={(newValue) => setProductName(newValue)}
                    onInputChange={(newValue) => setInputProductName(newValue)}
                    onKeyDown={(e) => handleKeyDown(e, "productName")}
                    value={productName}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">Program</label>
                  <MultiSelectText
                    inputValue={inputProgramName}
                    onChange={(newValue) => setProgramName(newValue)}
                    onInputChange={(newValue) => setInputProgramName(newValue)}
                    onKeyDown={(e) => handleKeyDown(e, "programName")}
                    value={programName}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">Station</label>
                  <MultiSelectText
                    inputValue={inputStationName}
                    onChange={(newValue) => setStationName(newValue)}
                    onInputChange={(newValue) => setInputStationName(newValue)}
                    onKeyDown={(e) => handleKeyDown(e, "stationName")}
                    value={stationName}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">Status</label>
                  <Select
                    placeholder={"Choose Status..."}
                    className="basic-multi-select"
                    isMulti
                    options={dataOptionStatus}
                    onChange={(e) => {
                      var array = [];
                      e.map((item, _) => {
                        array.push(item.value);
                      });
                      setFormData({
                        ...formData,
                        status: array,
                      });
                    }}
                  />
                </div>
              </div>
            </CardBody>
            <CardFooter>
              <div className="flex flex-row items-center gap-2">
                <>
                  <Button
                    title="Generate Report"
                    icon="BiCog"
                    color="primary"
                    onClick={generateReport}
                  />
                  <Button
                    title="Reset"
                    icon="BiReset"
                    color="secondary"
                    onClick={resetFilter}
                  />
                </>
              </div>
            </CardFooter>
          </>
        )}
      </Card>
      <div className="mt-4"></div>
      {dataTable && (
        <Card>
          <CardHeader>
            <CardAction>
              <Button
                title="Download Report"
                icon="BiDownload"
                color="primary"
                onClick={exportReport}
              />
            </CardAction>
          </CardHeader>
          <CardBody>
            <div className="w-full max-h-[700px] overflow-auto">
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ minWidth: "50px" }}>NO</th>
                    <th style={{ minWidth: "150px" }}>PROJECT NO</th>
                    <th style={{ minWidth: "200px" }}>PROJECT NAME</th>
                    <th style={{ minWidth: "200px" }}>REFERENCE TO</th>
                    <th style={{ minWidth: "400px" }}>DESCRIPTIONS</th>
                    <th style={{ minWidth: "200px" }}>CLIENT NAME</th>
                    <th style={{ minWidth: "200px" }}>MO NO</th>
                    <th style={{ minWidth: "150px" }}>MO VALUE</th>
                    <th style={{ minWidth: "50px" }}>SPOT</th>
                    <th style={{ minWidth: "200px" }}>PRODUCT NAME</th>
                    <th style={{ minWidth: "200px" }}>PROGRAM NAME</th>
                    <th style={{ minWidth: "150px" }}>STATION NAME</th>
                    <th style={{ minWidth: "150px" }}>OWNER NAME</th>
                    <th style={{ minWidth: "150px" }}>DEADLINE</th>
                    <th style={{ minWidth: "150px" }}>BEGIN DATE</th>
                    <th style={{ minWidth: "150px" }}>END DATE</th>
                    <th style={{ minWidth: "50px" }}>URGENT</th>
                    <th style={{ minWidth: "200px" }}>URGENT NOTES</th>
                    <th style={{ minWidth: "200px" }}>STATUS</th>
                    <th style={{ minWidth: "50px" }}>TOTAL TASKS</th>
                    <th style={{ minWidth: "50px" }}>IN PROGRESS TASKS</th>
                    <th style={{ minWidth: "50px" }}>COMPLETED TASKS</th>
                  </tr>
                </thead>
                <tbody>
                  {dataTable &&
                    dataTable.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.project_no}</td>
                        <td>{item.project_name}</td>
                        <td>
                          {item.reference_no &&
                            "[#" +
                              item.reference_no +
                              "] " +
                              item.reference_name}
                        </td>
                        <td>
                          {item.descriptions
                            .replace(/(<([^>]+)>)/gi, "")
                            .substring(0, 150)}
                          ...
                        </td>
                        <td>{item.client_name}</td>
                        <td>{item.mo_no}</td>
                        <td className="text-right">{item.mo_value}</td>
                        <td className="text-center">{item.spot}</td>
                        <td>{item.product_name}</td>
                        <td>{item.program_name}</td>
                        <td>{item.station_name}</td>
                        <td>{item.owner_name}</td>
                        <td>{item.deadline}</td>
                        <td>{item.begin_date}</td>
                        <td>{item.end_date}</td>
                        <td>{item.urgent === 1 ? "Yes" : "No"}</td>
                        <td>{item.urgent_notes}</td>
                        <td>{item.status}</td>
                        <td>{item.total_task}</td>
                        <td>{item.total_inprogress_task}</td>
                        <td>{item.total_completed_task}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
}

export default Index;
