import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from "../../../components/Card";
import Select from "../../../components/Select";

import { useSelector, useDispatch } from "react-redux";
import {
  resetFormData,
  getDataView as getDataViewTask,
  setDataView as setDataViewTask,
  setShowTask,
  getDataByProject,
} from "../../../stores/prod/prodTaskSlice";

function TabTask() {
  const dispatch = useDispatch();
  const dataProject = useSelector((state) => state.prodProject.dataView);
  const dataTask = useSelector((state) => state.prodTask.dataByProject);
  const [dataTable, setDataTable] = useState([]);
  const [globalSearch, setGlobalSearch] = useState("");

  useEffect(() => {
    dispatch(getDataByProject({ projectId: dataProject.id }));
  }, [dispatch, dataProject.id]);

  useEffect(() => {
    dispatch(resetFormData());
  }, [dispatch]);

  useEffect(() => {
    if (dataTask) {
      let filtered = [...dataTask].filter(
        (data) =>
          data.task_no.toString().toLowerCase().indexOf(globalSearch) > -1 ||
          data.task_name.toLowerCase().indexOf(globalSearch) > -1 ||
          data.department_name?.toLowerCase().indexOf(globalSearch) > -1 ||
          data.observer_name?.toLowerCase().indexOf(globalSearch) > -1 ||
          data.responsible_name?.toLowerCase().indexOf(globalSearch) > -1
      );

      setDataTable(filtered);
    }
  }, [dataTask, globalSearch]);

  const onClickView = (id) => {
    dispatch(setDataViewTask(undefined));

    dispatch(getDataViewTask({ id: id }));
    dispatch(setShowTask(true));
  };

  return (
    <>
      <div className="space-y-6">
        <Card>
          <CardHeader>
            <CardTitle>Tasks</CardTitle>
          </CardHeader>
          <CardBody>
            {!dataTask && (
              <div className="text-center font-semibold">Loading...</div>
            )}
            {dataTask && (
              <>
                <div className="mb-6 w-full flex gap-2">
                  <div className="block w-96">
                    <div className="relative">
                      <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <BiSearch className="w-5 h-5 text-gray-300" />
                      </div>
                      <input
                        className="form-control-search"
                        placeholder="Search..."
                        onChange={(e) =>
                          setGlobalSearch(
                            e.target.value.toString().toLowerCase()
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  {dataTable.length > 0 &&
                    dataTable.map((item, index) => (
                      <div
                        key={index}
                        className="p-4 border border-slate-tertiary rounded-md hover:bg-slate-tertiary transition duration-200 cursor-pointer"
                        onClick={() => onClickView(item.id)}
                      >
                        <div className="flex flex-row items-start justify-between gap-4">
                          <div className="flex-1 font-semibold">
                            [#{item.task_no}] {item.task_name}
                          </div>
                          {item.total_attachment === item.spot ? (
                            <div className="badge text-xs badge-Completed">
                              Completed
                            </div>
                          ) : (
                            <div className="badge text-xs badge-InProgress">
                              In Progress
                            </div>
                          )}
                        </div>
                        <div className="mt-4 grid grid-cols-4 gap-4">
                          <div className="text-sm">
                            <span className="mb-2 block text-text-secondary">
                              Department
                            </span>
                            <span className="block">
                              {item.department_name}
                            </span>
                          </div>
                          <div className="text-sm">
                            <span className="mb-2 block text-text-secondary">
                              Observer
                            </span>
                            {item.observer_name ? (
                              <span className="block">
                                {item.observer_name}
                              </span>
                            ) : (
                              <span className="block text-danger">
                                Not assigned
                              </span>
                            )}
                          </div>
                          <div className="text-sm">
                            <span className="mb-2 block text-text-secondary">
                              Responsible
                            </span>
                            {item.responsible_name ? (
                              <span className="block">
                                {item.responsible_name}
                              </span>
                            ) : (
                              <span className="block text-danger">
                                Not assigned
                              </span>
                            )}
                          </div>
                          <div className="text-sm">
                            <span className="mb-2 block text-text-secondary">
                              Total Attachment
                            </span>
                            <span className="block">
                              {item.total_attachment} / {item.spot}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default TabTask;
