import React, { useEffect, useState } from "react";
import { Button } from "../../../components/Button";
import ModalForm from "../../../components/ModalForm";

import { useSelector, useDispatch } from "react-redux";
import {
  resetFormData,
  setModal,
  getFormData,
  setFormData,
  submit,
} from "../../../stores/master/stationSlice";

function Form(props) {
  const { formType, dataId } = props;
  const dispatch = useDispatch();
  const openPopup = useSelector((state) => state.popup.openPopup);
  const openModal = useSelector((state) => state.station.openModal);
  const formData = useSelector((state) => state.station.formData);
  const [modalTitle, setModalTitle] = useState();

  useEffect(() => {
    dispatch(resetFormData());

    if (formType === "create") {
      setModalTitle("Add Media Channel");
      dispatch(setFormData({ name: "active", value: 1 }));
    } else {
      setModalTitle("Edit Media Channel");
      dispatch(getFormData({ id: dataId }));
    }
  }, [dispatch, formType, dataId]);

  const handleInput = (e) => {
    dispatch(setFormData(e.target));
  };

  return (
    <>
      {!openPopup.open && openModal && (
        <ModalForm
          modalTitle={modalTitle}
          modalSize="small"
          closeModal={() => dispatch(setModal(false))}
        >
          <div className="w-full">
            <div className="form-group">
              <label className="control-label">
                Media Channel Name <span className="text-danger">*</span>
              </label>
              <input
                id="station_name"
                title="Media Channel Name"
                type="text"
                name="station_name"
                className="form-control"
                value={formData.station_name}
                onChange={handleInput}
                required
              />
            </div>
            <div className="form-group">
              <label className="control-label">Active</label>
              <div className="form-checkbox">
                <input
                  id="active"
                  title="Active"
                  type="checkbox"
                  name="active"
                  checked={formData?.active?.toString() === "1" ? true : false}
                  onChange={(e) => {
                    if (e.target.checked) {
                      e.target.value = 1;
                    } else {
                      e.target.value = 0;
                    }
                    handleInput(e);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="mt-8 flex gap-2">
            <Button
              title="Save"
              icon="BiSave"
              color="primary"
              onClick={() => dispatch(submit({ formType: formType }))}
            />
            <Button
              title="Cancel"
              icon="BiX"
              color="secondary"
              onClick={() => dispatch(setModal(false))}
            />
          </div>
        </ModalForm>
      )}
    </>
  );
}

export default Form;
