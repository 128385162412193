import { createSlice } from "@reduxjs/toolkit";
import { InstanceApi } from "../../helpers/InstanceApi";
import { Validate } from "../../helpers/Validate";
import { setPopup } from "../popupSlice";

export const projectHistorySlice = createSlice({
  name: "projectHistory",
  initialState: {
    dataByProject: undefined,
  },
  reducers: {
    setDataByProject: (state, action) => {
      state.dataByProject = action.payload;
    },
  },
});

export const getDataByProject = (params) => async (dispatch) => {
  try {
    dispatch(setDataByProject(undefined));
    const response = await InstanceApi.get(
      "/pm/projects/" + params.projectId + "/history"
    );
    if (response) {
      dispatch(setDataByProject(response.data.data));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(error);
  }
};

export const {
  setDataByProject,
} = projectHistorySlice.actions;
export default projectHistorySlice.reducer;
