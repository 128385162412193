import { createSlice } from "@reduxjs/toolkit";
import { InstanceApi } from "../../helpers/InstanceApi";
import { Validate } from "../../helpers/Validate";
import { setPopup } from "../popupSlice";

export const prodTaskAttachmentSlice = createSlice({
  name: "prodTaskAttachment",
  initialState: {
    openModal: false,
    dataByTask: undefined,
    dataView: undefined,
    lastSpot: 0,
    formData: {
      id: "",
      task_id: "",
      file_name: "",
      file: "",
      mime_type: "",
      file_size: "",
    },
  },
  reducers: {
    setModal: (state, action) => {
      state.openModal = action.payload;
    },
    setDataByTask: (state, action) => {
      state.dataByTask = action.payload;
    },
    setDataView: (state, action) => {
      state.dataView = action.payload;
    },
    setLastSpot: (state, action) => {
      state.lastSpot = action.payload;
    },
    setFormData: (state, action) => {
      state.formData[action.payload.name] = action.payload.value;
    },
  },
});

export const resetFormData = () => async (dispatch, getState) => {
  const formData = getState().prodTaskAttachment.formData;
  Object.keys(formData).forEach((key) => {
    dispatch(setFormData({ name: key, value: "" }));
  });
};

export const getLastSpot = (params) => async (dispatch) => {
  try {
    dispatch(setLastSpot(0));
    const response = await InstanceApi.get(
      "/prod/tasks/" + params.taskId + "/last-spot"
    );
    if (response) {
      dispatch(setLastSpot(response.data.data));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(error);
  }
};

export const getDataByTask = (params) => async (dispatch) => {
  try {
    dispatch(setDataByTask(undefined));
    const response = await InstanceApi.get(
      "/prod/tasks/" + params.taskId + "/attachment"
    );
    if (response) {
      dispatch(setDataByTask(response.data.data));
    }
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
    console.error(error);
  }
};

export const getDataView = (params) => async (dispatch, getState) => {
  try {
    dispatch(setPopup({ open: true, type: "loading" }));
    const dataByTask = getState().prodTaskAttachment.dataByTask;
    let selected = dataByTask[params.index];
    selected = {
      ...selected,
      showtimes: selected?.showtimes.replace(" ", "T"),
    };

    dispatch(
      setDataView({
        ...selected,
      })
    );
    dispatch(setPopup({ open: false }));
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.response.data.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const deleteData = (params) => (dispatch, getState) => {
  try {
    const dataTask = getState().prodTask.dataView;
    dispatch(
      setPopup({
        open: true,
        type: "delete",
        message: "Are you sure to delete this from attachment?",
        handleOk: async () => {
          dispatch(setPopup({ open: true, type: "processing" }));
          try {
            const response = await InstanceApi.delete(
              "/prod/tasks/attachment/" + params.id
            );
            dispatch(
              setPopup({
                open: true,
                type: "success",
                message: response.data.message,
                handleOk: () => {
                  dispatch(setPopup({ open: false }));
                  dispatch(getDataByTask({ taskId: dataTask.id }));
                },
              })
            );
          } catch (error) {
            dispatch(
              setPopup({
                open: true,
                type: "error",
                message: error.message,
                handleOk: () => {
                  dispatch(setPopup({ open: false }));
                },
              })
            );
            console.error(error);
          }
        },
        handleCancel: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  } catch (error) {
    dispatch(
      setPopup({
        open: true,
        type: "error",
        message: error.message,
        handleOk: () => {
          dispatch(setPopup({ open: false }));
        },
      })
    );
  }
};

export const {
  setModal,
  setDataByTask,
  setDataView,
  setFormData,
  setLastSpot,
} = prodTaskAttachmentSlice.actions;
export default prodTaskAttachmentSlice.reducer;
