import React, { useState, useRef, useEffect } from "react";
import { Button } from "../../../components/Button";
import ModalForm from "../../../components/ModalForm";
import { InstanceApi } from "../../../helpers/InstanceApi";

import { useSelector, useDispatch } from "react-redux";
import {
  getDataByTask,
  getDataView,
  getLastSpot,
  setModal,
} from "../../../stores/prod/prodTaskAttachmentSlice";
import { getDataView as getDataViewTask } from "../../../stores/prod/prodTaskSlice";
import { setPopup } from "../../../stores/popupSlice";

function FormAttachment(props) {
  const { formType, editIndex } = props;
  const dispatch = useDispatch();
  const openPopup = useSelector((state) => state.popup.openPopup);
  const dataTask = useSelector((state) => state.prodTask.dataView);
  const lastSpot = useSelector((state) => state.prodTaskAttachment.lastSpot);
  const openModal = useSelector((state) => state.prodTaskAttachment.openModal);
  const dataView = useSelector((state) => state.prodTaskAttachment.dataView);
  const inputRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);
  const [file, setFile] = useState();
  const [formData, setFormData] = useState({
    id: "",
    spot: "",
    showtimes: "",
  });

  useEffect(() => {
    if (formType === "create") {
      dispatch(getLastSpot({ taskId: dataTask.id }));
      setFormData({ ...formData, lastSpot });
    } else {
      dispatch(getDataView({ index: editIndex }));
    }
  }, [dispatch, openModal, dataTask.id, formType, editIndex]);

  useEffect(() => {
    if (formType === "edit") {
      console.log(dataView);
      setFormData({ ...formData, ...dataView });
    }
  }, [formType, dataView]);

  useEffect(() => {
    if (formType === "create") {
      setFormData({ ...formData, spot: lastSpot });
    }
  }, [formType, lastSpot]);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(setPopup({ open: true, type: "upload" }));
    const formSubmit = new FormData();

    // Update the formData object

    try {
      let response = undefined;
      if (formType === "create") {
        formSubmit.append("task_id", dataTask.id);
        formSubmit.append("spot", formData.spot);
        formSubmit.append("showtimes", formData.showtimes);
        formSubmit.append("attachment", file, file.name);

        response = await InstanceApi.post(`/prod/tasks/attachment`, formSubmit);
      } else {
        formSubmit.append("id", formData.id);
        formSubmit.append("task_id", dataTask.id);
        formSubmit.append("spot", formData.spot);
        formSubmit.append("showtimes", formData.showtimes);
        if (file) {
          formSubmit.append("attachment", file, file.name);
        }

        response = await InstanceApi.post(
          `/prod/tasks/attachment/${formData.id}`,
          formSubmit
        );
      }
      if (response) {
        dispatch(
          setPopup({
            open: true,
            type: "success",
            message: response.data.message,
            handleOk: () => {
              dispatch(setPopup({ open: false }));
              dispatch(setModal(false));
              dispatch(getDataByTask({ taskId: dataTask.id }));
              dispatch(getDataViewTask({ id: dataTask.id }));
            },
          })
        );
      }
    } catch (error) {
      dispatch(
        setPopup({
          open: true,
          type: "error",
          message: error.message,
          handleOk: () => {
            dispatch(setPopup({ open: false }));
          },
        })
      );
    }
  };

  return (
    <>
      {!openPopup.open && openModal && (
        <ModalForm
          modalTitle={
            formType === "create" ? "Upload Attachment" : "Update Attachment"
          }
          modalSize="medium"
          closeModal={() => dispatch(setModal(false))}
        >
          <form onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="form-group">
                <label className="control-label">
                  Spot No. <span className="text-danger">*</span>
                </label>
                <input
                  id="spot"
                  title="Spot No."
                  type="text"
                  name="spot"
                  className="form-control disabled"
                  value={formData.spot}
                  readOnly={true}
                />
              </div>
              <div className="form-group">
                <label className="control-label">Showtimes</label>
                <input
                  id="showtaimes"
                  title="Showtimes"
                  type="datetime-local"
                  name="showtaimes"
                  className="form-control"
                  value={formData?.showtimes}
                  onChange={(e) =>
                    setFormData({ ...formData, showtimes: e.target.value })
                  }
                />
              </div>
            </div>
            <div
              className={`modal-upload-box ${
                dragActive ? "border-slate-800" : ""
              } !h-32`}
            >
              <div className="body">
                <input
                  id="input-file-upload"
                  type="file"
                  className="hidden"
                  ref={inputRef}
                  onChange={handleChange}
                />
                <p>
                  Drag and drop your file here or{" "}
                  <span
                    className="cursor-pointer underline text-primary-hover"
                    onClick={onButtonClick}
                  >
                    browse
                  </span>
                </p>
                {file && <p>{file.name}</p>}
              </div>

              {dragActive && (
                <div
                  className="absolute w-full h-full rounded top-0 right-0 left-0 bottom-0"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
            </div>
            <div className="mt-6">
              {formType === "create" ? (
                file && formData.spot ? (
                  <Button
                    title="Upload"
                    icon="BiUpload"
                    color="primary"
                    onClick={handleSubmit}
                  />
                ) : (
                  <Button title="Upload" icon="BiUpload" color="disabled" />
                )
              ) : (
                <Button
                  title="Save"
                  icon="BiSave"
                  color="primary"
                  onClick={handleSubmit}
                />
              )}
            </div>
          </form>
        </ModalForm>
      )}
    </>
  );
}

export default FormAttachment;
