import React from "react"
import ReactSelect from 'react-select'

export default function Select(props) {
    const customStyles = {
      control: (base, { isDisabled }) => ({
        ...base,
        "&:hover": { borderColor: "none" },
        "&:focus": { borderColor: "none" },
        borderColor: "#e5e7eb",
        boxShadow: "none",
        borderRadius: "0.375rem",
        height: props.isMulti ? 'auto' : 40,
        backgroundColor: isDisabled ? "#e2e8f0" : "white",
      }),
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      input: (styles) => ({ ...styles, borderColor: "none" }),
    };
    
    return (
        <ReactSelect
            styles={customStyles}
            menuPortalTarget={document.body}
            isClearable={true}
            isDisabled={props.disabled}
            {...props}
        />
    )
}