import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setModal,
  setOpenNotification,
  getDataAll,
  getDataNew,
} from "./../../stores/general/notificationSlice";
import { Link } from "react-router-dom";

function Notification(props) {
  const dispatch = useDispatch();
  const dataNew = useSelector((state) => state.notification.dataNew);

  useEffect(() => {
    dispatch(getDataNew());
  }, [dispatch]);

  const handleViewAll = () => {
    dispatch(setOpenNotification(false));
    dispatch(getDataAll());
    dispatch(setModal(true));
  };

  return (
    <>
      <div className="notification-container" {...props}>
        <div className="notification-card">
          <span className="notification-card-title">Notifications</span>
          <div className="notification-content">
            {dataNew && dataNew.length > 0 ? (
              dataNew.map((item, index) => (
                <Link
                  key={index}
                  to={
                    item.title.toLowerCase().indexOf("project") === -1
                      ? `/pm/tasks/${item.object_id}`
                      : `/pm/projects/view/${item.object_id}`
                  }
                >
                  <div key={index} className="notification-list">
                    <div className="header">
                      <span className="title">{item.created_by}</span>
                      <span className="date">{item.created_at_convert}</span>
                    </div>
                    <div className="content">
                      <p>{item.message}</p>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <div className="p-4 text-center">No notifications</div>
            )}
          </div>
          {dataNew && dataNew.length > 0 && (
            <div
              className="py-3 px-4 text-center text-[#4F79DB] text-sm font-semibold cursor-pointer"
              onClick={() => handleViewAll()}
            >
              View all
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Notification;
